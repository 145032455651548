import type { Action } from 'redux';
import type { AddonExports } from './types';

export const ADDON_REQUESTED = 'ADDON/REQUESTED' as const;
export const requestAddon = (id: string, hash: string | null) => ({
  type: ADDON_REQUESTED,
  payload: { id, hash },
});

export const ADDON_LOADED = 'ADDON/LOADED' as const;
export const addonLoaded = (id: string, hash: string | null, addon: AddonExports | null) => ({
  type: ADDON_LOADED,
  payload: { id, hash, addon },
});

export const ADDON_LOAD_FAILED = 'ADDON/LOAD_FAILED' as const;
export const addonLoadFailed = (id: string, hash: string | null, error: Error) => ({
  type: ADDON_LOAD_FAILED,
  payload: { id, hash, error },
});

export const ADDON_ACTION_PREFIX = '@/';
export const addonAction = (addonId: string, action: Action) => ({
  type: `${ADDON_ACTION_PREFIX}${action.type} (${addonId})`,
  payload: { addonId, addonHash: undefined, action } as { addonId: string; addonHash?: string | null; action: Action },
});

export const EVENT_ACTION_PREFIX = 'EVENT/';

export const addonBroadcastAction = (action: Action) => addonAction('*', action);

export type WrappedAddonAction = ReturnType<typeof addonAction>;

export function isWrappedAddonAction(action: any): action is WrappedAddonAction {
  return action.type && action.type.startsWith(ADDON_ACTION_PREFIX);
}

export type AddonActions = ReturnType<
  | typeof requestAddon
  | typeof addonLoaded
  | typeof addonLoadFailed
>