import { createReducer } from 'utils/redux';
import {
  DOC_FREE_RETURN_ORDER_SUBMITTED,
  DOC_FREE_RETURN_ORDER_FAILED,
  DOC_FREE_RETURN_ORDER_PRODUCTS_RECEIVED,
} from './actions';
import { arrayToObject } from 'utils/helpers';

export const initialState = { products: {} };

export default createReducer(initialState, {
  [DOC_FREE_RETURN_ORDER_SUBMITTED]: state => ({ ...state, isFailed: undefined }),
  [DOC_FREE_RETURN_ORDER_FAILED]: state => ({ ...state, isFailed: true }),
  [DOC_FREE_RETURN_ORDER_PRODUCTS_RECEIVED]: (state, action) => ({
    ...state,
    products: { ...state.products, ...arrayToObject(action.payload.products, product => product.id) },
  }),
});