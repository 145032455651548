import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Action, ActionCreatorsMapObject, bindActionCreators, Dispatch } from 'redux';
import { useCallback } from 'react';
import { addonAction } from '../actions';
import type { StateWithAddons } from '../types';

type Wrapper<F> = (func: F) => F;
type AddonStateSelector<R = any> = (state: StateWithAddons) => R;

function memoizeWrapper<F>(wrap: Wrapper<F>): Wrapper<F> {
  let lastWrappable: F | undefined = undefined;
  let lastWrapper: F | undefined = undefined;

  return (wrappable: F): F => {
    if (lastWrappable !== wrappable) {
      lastWrappable = wrappable;
      lastWrapper = wrap(wrappable);
    }

    return lastWrapper as F;
  };
}

const createMapStateWrapper = (selectState: AddonStateSelector) => {
  function wrapMapState(mapStateToProps: any) {
    if (mapStateToProps) {
      return (state: any, ownProps: any) => {
        const result = mapStateToProps(selectState(state), ownProps);
        if (typeof result === 'function')
          return wrapMapState(result);

        return result;
      };
    }

    return mapStateToProps;
  }

  return wrapMapState;
};

const createMapDispatchWrapper = (wrapDispatch: Wrapper<Dispatch>) => (mapDispatchToProps: any) => {
  if (!mapDispatchToProps) {
    return (dispatch: Dispatch) => ({ dispatch: wrapDispatch(dispatch) });
  }

  if (typeof mapDispatchToProps === 'object') {
    return (dispatch: Dispatch) => {
      const wrappedDispatch = wrapDispatch(dispatch);

      return ({
        ...bindActionCreators(mapDispatchToProps as ActionCreatorsMapObject, wrappedDispatch),
        dispatch: wrappedDispatch,
      });
    };
  }

  return (dispatch: Dispatch, ownProps: any) => {
    const wrappedDispatch = wrapDispatch(dispatch);

    return {
      ...mapDispatchToProps(wrappedDispatch, ownProps),
      dispatch: wrappedDispatch,
    };
  };
};

function createAddonConnect(selectState: AddonStateSelector, wrapDispatch: Wrapper<Dispatch>) {
  const wrapMapState = createMapStateWrapper(selectState);
  const wrapMapDispatch = createMapDispatchWrapper(wrapDispatch);

  return (
    mapStateToProps: any = undefined,
    mapDispatchToProps: any = undefined,
    mergeProps: any = undefined,
    options: any = undefined,
  ) => connect(
    wrapMapState(mapStateToProps),
    wrapMapDispatch(mapDispatchToProps),
    mergeProps,
    options,
  );
}

const createUseSelectorHook = (selectState: AddonStateSelector) => (selector: (state: any) => any, equalityFn = undefined) => {
  const scopeSelector = useCallback(state => selector(selectState(state)), [selector]);
  return useSelector(scopeSelector, equalityFn);
};

const createUseDispatchHook = (wrapDispatch: Wrapper<Dispatch>) => () => wrapDispatch(useDispatch());

export default function (addonId: string) {
  const selectState = (state: StateWithAddons) => state.addons.scopes[addonId];
  const wrapDispatch = memoizeWrapper((dispatch: Dispatch) => ((action: Action) => dispatch(addonAction(addonId, action))) as Dispatch);

  return {
    connect: createAddonConnect(selectState, wrapDispatch),
    useDispatch: createUseDispatchHook(wrapDispatch),
    useSelector: createUseSelectorHook(selectState),
    shallowEqual,
  };
}
