import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

export default history => {
  const locationObservable = Observable.create(observer => {
    return history.listen(location => observer.next(location));
  }).pipe(share());
  return {
    get locationObservable() {
      return locationObservable;
    },
    get location() {
      return history.location;
    },
    push(location) {
      history.push(location);
    },
    replace(location) {
      history.replace(location);
    },
    goBack() {
      history.goBack();
    },
    onStatusCodeResolved(statusCode) {
    },
    onNavigating(location, statusCode) {
      return true;
    },
  };
};