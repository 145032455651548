import PropTypes from 'prop-types';
import { AbilityTo } from 'behavior/user/constants';
import { PagingTypes } from 'behavior/pages/volumeOrderList';

const withPageProps = Component => {
  Component.selectProps = ({
    products,
    totalCount,
    selectedSorting,
    sortingEnabled = true,
    viewModeSwitchEnabled,
    selectedViewMode,
    pagingType,
    showThumbnails,
    lastViewedEnabled,
    relevanceAvailable,
    id,
    trackProductListView,
    pageTitle,
  }) => {
    return ({
      products,
      totalCount,
      selectedSorting,
      sortingEnabled,
      viewModeSwitchEnabled,
      selectedViewMode,
      pagingType,
      showThumbnails,
      lastViewedEnabled,
      relevanceAvailable,
      id,
      trackProductListView,
      pageTitle,
    });
  };

  return Component;
};

export default withPageProps;

export const pagePropTypes = {
  products: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  selectedSorting: PropTypes.object,
  sortingEnabled: PropTypes.bool.isRequired,
  viewModeSwitchEnabled: PropTypes.bool,
  selectedViewMode: PropTypes.string,
  pagingType: PropTypes.oneOf(Object.values(PagingTypes)),
  showThumbnails: PropTypes.bool.isRequired,
  id: PropTypes.string,
  lastViewedEnabled: PropTypes.bool,
  relevanceAvailable: PropTypes.bool,
  trackProductListView: PropTypes.func.isRequired,
  pageTitle: PropTypes.string,
  abilities: PropTypes.shape({
    [AbilityTo.OrderProducts]: PropTypes.bool,
  }),
  hasBasket: PropTypes.bool.isRequired,
};