import { useCallback } from 'react';
import Field, { fieldPropTypes } from '../Field';
import { FileInput } from 'components/primitives/form';

const componentBuilder = ({ field: { name, value, ...field }, form: { setFieldValue, setFieldTouched }, ...props }) => {
  const onChange = useCallback(value => {
    setFieldTouched(name, true, false);
    setFieldValue(name, value, true);
  }, [name, setFieldValue, setFieldTouched]);

  return <FileInput {...field} {...props} name={name} value={value || null} onChange={onChange} />;
};

// eslint-disable-next-line react/no-multi-comp
const FileField = ({ fieldName, fieldTitle, validation, ...attributes }) => (
  <Field
    fieldName={fieldName}
    fieldTitle={fieldTitle}
    validation={validation}
    component={componentBuilder}
    {...attributes}
  />
);

FileField.propTypes = fieldPropTypes;

export default FileField;