import PropTypes from 'prop-types';

const Item = ({ index, onClick, getUrl, pageLbl }) => {
  const textIndex = index + 1;
  return (
    <li data-index={index}>
      <a onClick={onClick.bind(this, index)} href={getUrl(index++)} title={pageLbl + textIndex}>
        <span className="visually-hidden">{pageLbl}</span>
        {textIndex}
      </a>
    </li>
  );
};

Item.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  getUrl: PropTypes.func.isRequired,
  pageLbl: PropTypes.string.isRequired,
};

export default Item;