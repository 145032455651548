import { groupBy, map, mergeMap, switchMap, takeUntil, pluck } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { PRODUCT_SET_REQUESTED, productSetReceived } from './actions';
import { LOCATION_CHANGED } from 'behavior/events';
import { productsQuery, loadCalculatedFieldsQuery } from './queries';

export default (action$, state$, { api }) => {
  const reset$ = action$.pipe(ofType(LOCATION_CHANGED));

  return action$.pipe(
    ofType(PRODUCT_SET_REQUESTED),
    groupBy(action => action.payload.contentBlockId, action => action.payload),
    mergeMap(group => group.pipe(
      switchMap(({ contentBlockId, productSetId, size, sorting, calculate }) => {
        const variables = {
          id: productSetId,
          options: {
            page: { index: 0, size },
            sorting,
          },
          loadCategories: state$.value.analytics && state$.value.analytics.isTrackingEnabled,
        };
        const query = calculate ? loadCalculatedFieldsQuery : productsQuery;

        return api.graphApi(query, variables).pipe(
          pluck('catalog', 'productSet'),
          map(productSet => productSetReceived(contentBlockId, getProductSetProducts(productSet), calculate)),
          takeUntil(reset$),
        );
      }),
    )),
  );

  function getProductSetProducts(productSet) {
    if (productSet)
      return productSet.products.products;

    return [];
  }
};