import styles from './Video.module.scss';
import { memo, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { VideoProviderTypes, parseVideoData } from './helpers';
import { LazyLoadComponent } from 'components/primitives/responsiveImages';
import YoutubePlayer from './YoutubePlayer';
import VimeoPlayer from './VimeoPlayer';

const BackgroundVideo = ({ src, className = '', mutedSound }) => {
  const videoData = parseVideoData(src);

  if (!videoData)
    return null;

  const wrapperRef = useRef(null);

  const eventHandlers = useMemo(() => {
    switch (videoData.provider) {
      case VideoProviderTypes.Youtube:
        return {
          onStateChange: e => {
            if (e.data === window.YT.PlayerState.ENDED) {
              e.target.playVideo();
            }
            if (e.data === window.YT.PlayerState.PLAYING) {
              wrapperRef.current.classList.add(styles.playing);
            }
          },
        };
      case VideoProviderTypes.Vimeo:
        return {
          play: () => {
            wrapperRef.current.classList.add(styles.playing);
          },
        };
      default:
        return null;
    }
  }, [videoData.provider]);

  return (
    <div className={`${styles.wrapper} ${styles.buffering} ${className}`} ref={wrapperRef}>
      {/* LazyLoadComponent placeholder width and height should be the same as wrapper width/height for correct detection
      if element is in the current viewport in case of long by horizontal axis content blocks. Also placeholder width should be
      at least 1px so Mac OS Safari will work properly with elements which are using Intersection Observer API to handle their
      visibility and placed inside <iframe> element. */}
      <LazyLoadComponent width="100%" height="100%">
        {videoData.provider === VideoProviderTypes.Youtube &&
          <YoutubePlayer
            key={videoData.videoId}
            videoId={videoData.videoId}
            enablejsapi="1"
            playsinline="1"
            autoplay="1"
            controls="0"
            disablekb="1"
            fs="0"
            cc_load_policy="1"
            iv_load_policy="3"
            modestbranding="1"
            mute={mutedSound ? '1' : '0'}
            autohide="1"
            events={eventHandlers}
          />
        }
        {videoData.provider === VideoProviderTypes.Vimeo &&
          <VimeoPlayer
            key={videoData.videoId}
            videoId={videoData.videoId}
            autopause={false}
            byline={false}
            transparent={false}
            autoplay
            loop
            controls={false}
            muted={mutedSound}
            events={eventHandlers}
          />
        }
      </LazyLoadComponent>
    </div>
  );
};

BackgroundVideo.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  mutedSound: PropTypes.bool,
};

export default memo(BackgroundVideo);
