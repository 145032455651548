import {
  updateProductCalculatedFieldsQuery,
  reviewsQuery,
  addReview,
  requestVolumePricesQuery,
} from './queries';
import {
  UPDATE_PRODUCT_CALCULATED_FIELDS,
  productCalculatedFieldsLoaded,
  REVIEWS_REQUESTED,
  reviewsReceived,
  REVIEW_SUBMITTED,
  reviewProcessed,
  VOLUME_PRICES_REQUESTED,
  volumePriceReceived,
} from './actions';
import { switchMap, map, takeUntil, exhaustMap, pluck, filter, mergeMap } from 'rxjs/operators';
import { rewriteTo } from 'behavior/routing';
import { LOCATION_CHANGED } from 'behavior/events';
import { routesBuilder } from 'routes';
import { retryWithToast, catchApiErrorWithToast } from 'behavior/errorHandling';
import { merge, of } from 'rxjs';
import { resetCaptcha } from 'behavior/captcha';
import { unlockForm, FormLockKeys } from 'behavior/pages';

const productEpic = (action$, _, { api, logger }) => {
  const locationChanged$ = action$.ofType(LOCATION_CHANGED);

  const onFieldsRequested$ = action$.ofType(UPDATE_PRODUCT_CALCULATED_FIELDS).pipe(
    switchMap(action => api.graphApi(updateProductCalculatedFieldsQuery, action.payload).pipe(
      map(mapResponseToAction),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );

  const onReviewsRequested$ = action$.ofType(REVIEWS_REQUESTED).pipe(
    exhaustMap(action => api.graphApi(reviewsQuery, action.payload).pipe(
      pluck('catalog', 'products', 'products', '0', 'reviews'),
      filter(r => r && r.list && r.list.length),
      map(r => reviewsReceived(r.list)),
      takeUntil(locationChanged$),
    )),
  );

  const resetCaptchaAction = resetCaptcha();
  const reviewProcessedAction = reviewProcessed(true);
  const onReviewSubmitted$ = action$.ofType(REVIEW_SUBMITTED).pipe(
    exhaustMap(action => api.graphApi(addReview, { data: action.payload }).pipe(
      mergeMap(_ => [reviewProcessedAction, resetCaptchaAction, unlockForm(FormLockKeys.Review)]),
      catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptchaAction, unlockForm(FormLockKeys.Review))),
      retryWithToast(action$, logger, _ => of(unlockForm(FormLockKeys.Review))),
      takeUntil(locationChanged$),
    )),
  );

  const onVolumePricesRequested$ = action$.ofType(VOLUME_PRICES_REQUESTED).pipe(
    switchMap(action => api.graphApi(requestVolumePricesQuery, action.payload).pipe(
      map(data => {
        const volumePrices = data.catalog.volumePrices;
        const { variantId, uomId } = action.payload;

        return volumePriceReceived({ prices: volumePrices, variantId, uomId });
      }),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );

  return merge(onFieldsRequested$, onReviewsRequested$, onReviewSubmitted$, onVolumePricesRequested$);
};

export default productEpic;

function mapResponseToAction(data) {
  const product = data.catalog.products.products[0];
  if (!product)
    return rewriteTo(routesBuilder.forNotFound());

  return productCalculatedFieldsLoaded(product);
}