import { PROFILER_TIMING_RECEIVED, PROFILER_CLOSED } from './actions';
import { createReducer } from 'utils/redux';

export const initialState = {
  timings: [],
};

export default createReducer(initialState, {
  [PROFILER_TIMING_RECEIVED]: onTimingReceived,
  [PROFILER_CLOSED]: onClose,
});

function onTimingReceived(state, action) {
  const timings = state.timings;
  const previous = timings.length >= 40 ? timings.slice(0, 39) : timings;

  return {
    timings: [action.payload, ...previous],
  };
}

function onClose() {
  return initialState;
}
