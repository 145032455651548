import Field, { fieldPropTypes } from '../Field';
import { RadioButtonGroup } from 'components/primitives/form';

const componentBuilder = ({ field, form: { setFieldValue }, ...rest }) => (
  <RadioButtonGroup {...field} {...rest} onChange={value => setFieldValue(field.name, value)} />
);

// eslint-disable-next-line react/no-multi-comp
const RadioField = ({ fieldName, fieldTitle, validation, ...attributes }) => (
  <Field
    fieldName={fieldName}
    fieldTitle={fieldTitle}
    validation={validation}
    component={componentBuilder}
    {...attributes}
  />
);

RadioField.propTypes = fieldPropTypes;

export default RadioField;