import { ofType } from 'redux-observable';
import { EMPTY, fromEvent } from 'rxjs';
import {
  map,
  filter,
  withLatestFrom,
  ignoreElements,
  first,
  switchMap,
} from 'rxjs/operators';

import { VIEWER_CHANGED } from 'behavior/events';
import { DATALAYER_READY_EVENT } from 'behavior/analytics';
import { ANALYTICS_TRACKERS_INITIALIZE } from './actions';
import { pushToDataLayer } from './dataLayer';
import { createUserIdPayload } from './payload';

export const viewerChanged = (action$, state$) => action$.pipe(
  ofType(VIEWER_CHANGED, ANALYTICS_TRACKERS_INITIALIZE),
  withLatestFrom(state$),
  map(([_, state]) => state),
  filter(({ analytics }) => analytics && analytics.isTrackingEnabled),
  map(({ user: { id: userId } }) => ({ userId })),
  pushToDataLayer(state$, createUserIdPayload),
  ignoreElements(),
);

export const dataLayerInit = (_, state$, { scope }) => {
  if (scope !== 'CLIENT')
    return EMPTY;

  return fromEvent(document, DATALAYER_READY_EVENT).pipe(
    switchMap(_ => state$.pipe(
      first(({ settings, analytics, user }) => 
        settings 
        && settings.loaded 
        && analytics 
        && analytics.isTrackingEnabled
        && user,
      ),
      map(({ user: { id: userId } }) => ({ userId })),
      pushToDataLayer(state$, createUserIdPayload),
    )),
    ignoreElements(),
  );
};
