import styles from '../PLP.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import { Placeholder } from 'components/primitives/placeholders';
import { connect } from 'react-redux';
import { addProducts } from 'behavior/basket';
import ListContext from './ListContext';
import scrollIntoView from 'scroll-into-view';

const AddToBasketButton = ({ addProducts }) => {
  const { quantities, resetQuantities } = useContext(ListContext);

  const placeholder = <Placeholder className={styles.addToBasketBtnPlaceholder} />;

  return (
    <div className={styles.addToBasket}>
      <SanaButton className={`${btnStyles.btnAction} btn-action`}
        textKey="AddToBasket"
        onClick={addToBasket.bind(this, quantities, resetQuantities, addProducts)}
        placeholder={placeholder}
        disabled={quantities.size === 0}
      />
    </div>
  );

};

AddToBasketButton.propTypes = {
  addProducts: PropTypes.func.isRequired,
};

export default connect(null, { addProducts })(AddToBasketButton);

export function addToBasket(quantities, resetQuantities, addProducts) {
  const lines = [];

  for (const [productId, value] of quantities) {
    if (value instanceof Map) {
      for (const [uomId, uom] of value) {
        for (const [variantId, quantity] of uom) {
          const productLine = {
            productId,
            uomId,
            quantity: quantity.value,
          };

          if (variantId)
            productLine.variantId = variantId;

          lines.push(productLine);
        }
      }

      continue;
    }

    const { quantity, uomId } = value;
    if (!value.quantity.isValid) {
      const id = `${productId}_qty`;
      const invalidQuantityInput = document.getElementById(id);

      scrollIntoView(invalidQuantityInput, { time: 200 }, () => invalidQuantityInput.focus());

      document.getElementById(id).focus();
      return;
    }

    lines.push({
      productId,
      quantity: quantity.value,
      uomId,
    });
  }

  if (lines.length > 0)
    addProducts(lines);

  resetQuantities();
}