import { PRODUCT_SET_RECEIVED } from './actions';
import { VIEWER_CHANGED } from 'behavior/events';
import { deleteProductCalculatedInfo } from 'behavior/products/product';
import { createReducer } from 'utils/redux';

export default createReducer(null, {
  [PRODUCT_SET_RECEIVED]: onProductSetReceived,
  [VIEWER_CHANGED]: onViewerChanged,
});

function onProductSetReceived(state, action) {
  const { contentBlockId, products, calculated } = action.payload;
  const stateProducts = getStateProducts(state, contentBlockId);

  const productSet = stateProducts && stateProducts.length && calculated
    ? { products: updateCalculatedInfo(stateProducts, products), calculated: true }
    : { products };

  return {
    ...state,
    productSets: {
      ...state.productSets,
      [contentBlockId]: productSet,
    },
  };

  function getStateProducts(state, contentBlockId) {
    return state.productSets
      && state.productSets[contentBlockId]
      && state.productSets[contentBlockId].products;
  }

  function updateCalculatedInfo(products, calculatedProducts) {
    return products.map(product => {
      const calculated = calculatedProducts.find(({ id }) => id === product.id);
      return { ...product, ...calculated };
    });
  }
}

function onViewerChanged(state, action) {
  if (!state.productSets)
    return state;

  const productSets = {};
  for (const key of Object.keys(state.productSets)) {
    const productSet = { ...state.productSets[key] };
    productSet.products = productSet.products.map(deleteProductCalculatedInfo);

    productSets[key] = productSet;
  }

  return { ...state, productSets };
}
