import {
  PRODUCT_CALCULATED_FIELDS_LOADED,
  REVIEWS_RECEIVED,
  REVIEW_PROCESSED,
  VOLUME_PRICES_RECEIVED,
} from './actions';
import { VIEWER_CHANGED } from 'behavior/events';
import { createReducer } from 'utils/redux';
import { deleteProductCalculatedInfo } from 'behavior/products/product';

export default createReducer(null, {
  [PRODUCT_CALCULATED_FIELDS_LOADED]: onProductLoaded,
  [REVIEWS_RECEIVED]: onReviewsReceived,
  [REVIEW_PROCESSED]: onReviewProcessed,
  [VOLUME_PRICES_RECEIVED]: onVolumePricesReceived,
  [VIEWER_CHANGED]: onViewerChanged,
});

function onProductLoaded(state, action) {
  const stateVariants = state.product && state.product.variants;
  const { variants } = action.payload;

  if (variants) {
    for (const variant of variants) {
      const stateVariant = stateVariants.find(v => v.id === variant.id);
      if (stateVariant)
        variant.bomComponents = stateVariant.bomComponents;
    }
  }

  return {
    ...state,
    product: {
      ...state.product,
      ...action.payload,
      loaded: true,
    },
  };
}

function onReviewsReceived(state, action) {
  return {
    ...state,
    product: {
      ...state.product,
      reviews: {
        total: state.product.reviews.total,
        avg: state.product.reviews.avg,
        list: state.product.reviews.list.concat(action.payload),
      },
    },
  };
}

function onReviewProcessed(state, action) {
  return {
    ...state,
    product: {
      ...state.product,
      reviews: {
        ...state.product.reviews,
        saved: action.payload ? Date.now() : null,
      },
    },
  };
}

function onVolumePricesReceived(state, action) {
  return {
    ...state,
    volumePrices: action.payload,
  };
}

function onViewerChanged(state, _action) {
  if (!state.product)
    return state;

  const product = deleteProductCalculatedInfo(state.product);
  return { ...state, product };
}