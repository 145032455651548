import { RouteName } from 'routes';
import { redirect } from './helpers';

const canonicalRoutes = new Set([
  RouteName.ContentPage,
  RouteName.NewsDetail,
  RouteName.FaqPage,
  RouteName.ProductList,
  RouteName.VolumeOrderList,
  RouteName.Home,
]);

export function canonical(canonicalRoutes, route, location, dependencies) {
  const { routeName, canonicalUrl } = route;

  if (!canonicalUrl || !canonicalRoutes.has(routeName))
    return;

  return redirect(canonicalUrl, location, route, dependencies);
}

export default canonical.bind(null, canonicalRoutes);
