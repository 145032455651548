import PropTypes from 'prop-types';
import { useVolumeOrderListRoute } from './hooks';

const WithVolumeOrderListNavigationRoute = ({ children, url, pageId, withoutBackTo }) => {

  const routeBuilder = useVolumeOrderListRoute(pageId, url, withoutBackTo);

  return children(url, routeBuilder);
};

WithVolumeOrderListNavigationRoute.propTypes = {
  children: PropTypes.func.isRequired,
  pageId: PropTypes.string.isRequired,
  withoutBackTo: PropTypes.bool,
};

export default WithVolumeOrderListNavigationRoute;

