import styles from './Facets.module.scss';
import { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import SelectedFacets from './SelectedFacets';
import AvailableFacets from './AvailableFacets';
import { Placeholder } from 'components/primitives/placeholders';
import FacetsContext from './FacetsContext';
import { UseSanaTexts } from 'components/sanaText';

const textsToLoad = ['Filters'];

const Facets = ({ isMobile }) => {
  const {
    multiSelect,
    hasFacets,
    selectedFacets,
    availableFacets,
    getUrl,
    urlWithoutFacets,
  } = useContext(FacetsContext);

  if (!hasFacets)
    return null;

  return (
    <UseSanaTexts options={textsToLoad} dontWait>{
      ([filtersText]) => (
        <nav className={`${isMobile ? `${styles.mobile} ` : ''}${styles.facets}`} aria-label={filtersText}>
          {selectedFacets.length > 0 && <SelectedFacets facets={selectedFacets} getUrl={getUrl} getEmptyUrl={urlWithoutFacets} />}
          {availableFacets.length > 0
            ? <AvailableFacets facets={availableFacets} isMobile={isMobile} getUrl={getUrl} multiSelect={multiSelect} />
            : isMobile
              ? null
              : <Placeholder className={styles.facetsPlaceholder} lineCount={8} />
          }
        </nav>
      )}
    </UseSanaTexts>
  );
};

Facets.propTypes = {
  isMobile: PropTypes.bool,
};

export default memo(Facets);
