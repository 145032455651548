import styles from '../../Checkout.module.scss';
import PropTypes from 'prop-types';
import { Radio } from 'components/primitives/form';
import { useSimpleTexts, SimpleText } from 'components/sanaText';
import { MethodCost } from '../MethodCost';

export const PickupLocations = ({ locations, locationId, onChange, currency }) => {
  const [freeCostText, costsLbl] = useSimpleTexts(['FreeCosts', 'Costs']).texts;

  const handleLocationChange = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <div className={`${styles.locationsList} ${styles.noIcons}`}>
      <h3 aria-live="polite" className={styles.locationsTitle}>
        <SimpleText textKey="ShippingMethods_SelectPickupLocationTitle" />
      </h3>
      <div>
        {locations.map(location => (
          <div key={location.id}>
            <div className={styles.item}>
              <Radio
                name="pickup"
                value={location.id}
                className={styles.radio}
                checked={location.id === locationId}
                onChange={handleLocationChange}
              >
                {location.address}
              </Radio>
              <MethodCost cost={location.cost} ariaLabel={costsLbl} freeCostText={freeCostText} currency={currency} />
            </div>
            {location.distance &&
              <div className={styles.description}>
                <SimpleText textKey="Distance" />{' '}
                <strong>{location.distance}</strong>
              </div>
            }
          </div>
        ))}
      </div>
    </div>
  );
};

PickupLocations.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      address: PropTypes.string,
      cost: PropTypes.number,
      distance: PropTypes.string,
    }).isRequired,
  ).isRequired,
  locationId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  currency: PropTypes.object,
};
