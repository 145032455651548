export const ADMINTEXTS_REQUESTED = 'ADMINTEXTS_REQUESTED';
export const loadTexts = keys => ({
  type: ADMINTEXTS_REQUESTED,
  payload: keys,
});

export const ADMINTEXTS_LOADED = 'ADMINTEXTS_LOADED';
export const textsLoaded = texts => ({
  type: ADMINTEXTS_LOADED,
  payload: texts,
});
