import PropTypes from 'prop-types';
import { DocumentType, QuoteStatus } from 'behavior/documents';
import { TaxFocusMode, TaxAmountPosition, TaxDisplayMode } from 'behavior/settings/constants';

export const orderStatusPropType = PropTypes.oneOf(['OPEN', 'RELEASED', 'CANCELLED']); // OrderStatus from behavior/documents

export const quoteStatusPropType = PropTypes.oneOf(Object.values(QuoteStatus));

export const documentTypePropType = PropTypes.oneOf(Object.values(DocumentType));

export const paymentStatusPropType = PropTypes.oneOf(['', 'new', 'inprogress', 'paid', 'cancelled', 'unpaid', 'error']); // PaymentStatus from behavior/documents

export const taxFocusModePropType = PropTypes.oneOf(Object.values(TaxFocusMode));

export const taxAmountPositionPropType = PropTypes.oneOf(Object.values(TaxAmountPosition));

export const taxDisplayModePropType = PropTypes.oneOf(Object.values(TaxDisplayMode));
