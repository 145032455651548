import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useRoutesChecker = (routes, usePrevRoute) => {
  const currentRouteName = useSelector(({ routing: { routeData, previous } }) => {
    const routeName = routeData && routeData.routeName;
    const prevRouteName = previous && previous.routeData && previous.routeData.routeName;
    return usePrevRoute && prevRouteName ? prevRouteName : routeName;
  });

  return useMemo(() => {
    return currentRouteName && routes.includes(currentRouteName);
  }, [currentRouteName, routes]);
};