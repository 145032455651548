import styles from './SubAccount.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components/primitives/table';
import { SanaButton } from 'components/primitives/buttons';
import { RichText, UseSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { useIsMobileViewport, useCurrencyInfo } from 'utils/hooks';
import { formatAsPrice } from 'utils/format';
import { useDispatch } from 'react-redux';
import { removeSubAccount } from 'behavior/pages/subAccounts';
import { Link } from 'components/primitives/links';
import { useRoutes } from 'components/primitives/route';
import { routesBuilder } from 'routes';
import { editRoute, routeIdReplacement, newRoute } from './constants';
import useConfirmation from 'components/objects/confirmation';
import { useModal } from 'components/objects/modals';
import SubAccountRemovalFailureModal from './SubAccountRemovalFailureModal';
import RemoveButton from './RemoveButton';
import EditLink from './EditLink';
import { StatusSuccessIcon, StatusFailedIcon } from 'components/primitives/icons';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview, useHandlerLockerInPreview } from 'components/objects/preview';

const textKeys = [
  'ButtonText_Edit', 'ButtonAltText_Edit',
  'Name', 'Active', 'Authorize', 'Order', 'Prices', 'Stock', 'Limit',
];

const List = ({ list, options = {}, removalFailureInfo }) => {
  const currencyInfo = useCurrencyInfo();
  const dispatch = useDispatch();
  const { showConfirmation, clearConfirmation, confirmationModal } = useConfirmation();
  const [editPath, newPath = ''] = useRoutes([editRoute, newRoute]);
  const isMobile = useIsMobileViewport();
  const { hide, show: showRemovalFailureModal, opened } = useModal();
  const isPreview = useIsPreview();

  const addAccountBtn = (
    <SanaButton
      textKey="NewSubAccount"
      className={`${styles.newSubBtn} ${btnStyles.btnSmall}`}
      component={Link}
      to={newRoute}
      url={newPath}
    />
  );

  useEffect(() => clearConfirmation, []);
  useEffect(() => {
    if (removalFailureInfo)
      showRemovalFailureModal();
  }, [removalFailureInfo]);

  const remove = useHandlerLockerInPreview(account => {
    const deleteAccount = () => dispatch(removeSubAccount(account.id));
    showConfirmation(
      deleteAccount,
      'DeleteConfirmation',
      <RichText textKey={'SubAccounts_DeleteMessage'} formatWith={[account.name]} />,
      { okText: 'ButtonText_Delete' },
      undefined,
      styles.confirmationPopup,
    );
  });

  if (!list || list.length === 0)
    return (
      <>
        {addAccountBtn}
        <p className="msg-block"><RichText textKey="SubAccounts_NoSubAccounts" /></p>
      </>
    );

  return (
    <>
      {addAccountBtn}
      <UseSanaTexts options={textKeys}>
        {([editText, editAltText,
          nameText, activeText, authorizeText, orderText, pricesText, stockText, limitText,
        ]) => (
          <Table className={styles.subAccounts}>
            <thead>
              <tr>
                <th id="accName-header">{makeSimpleText(nameText)}</th>
                <th className={styles.status} id="active-header">{makeSimpleText(activeText)}</th>
                <th className={styles.status} id="authorize-header">{makeSimpleText(authorizeText)}</th>
                {options.orderProducts && <th className={styles.status} id="order-header">{makeSimpleText(orderText)}</th>}
                {options.showPrices && <th className={styles.status} id="prices-header">{makeSimpleText(pricesText)}</th>}
                {options.showStock && <th className={styles.status} id="stock-header">{makeSimpleText(stockText)}</th>}
                <th className={styles.limit} id="limit-header">{makeSimpleText(limitText)}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {list.map(account => {
                let limitToShow = '-';
                if (account.limits && account.limits.length) {
                  const { amount } = account.limits[0];
                  if (amount)
                    limitToShow = currencyInfo ? formatAsPrice(amount, currencyInfo) : amount;
                }
                return (
                  <tr key={account.id}>
                    <td className={styles.accNameCell}>{isPreview ? <Placeholder /> : account.name}</td>
                    {renderBooleanColumn(account.active, styles.activeCell)}
                    {renderBooleanColumn(account.canAuthorizeOrders, styles.authorizeCell)}
                    {options.orderProducts && renderBooleanColumn(account.canOrder, styles.orderCell)}
                    {options.showPrices && renderBooleanColumn(account.canSeePrices, styles.pricesCell)}
                    {options.showStock && renderBooleanColumn(account.canSeeStock, styles.stockCell)}
                    <td className={styles.limit}>{limitToShow}</td>
                    <td className={styles.actions}>
                      <EditLink
                        url={isPreview ? null : editPath ? editPath.replace(routeIdReplacement, account.id) : ''}
                        to={isPreview ? null : routesBuilder.forSubAccount.bind(null, account.id)}
                        title={editAltText}
                        linkText={editText}
                        showBtnText={isMobile}
                      />
                      <RemoveButton onClick={remove.bind(this, account)} showBtnText={isMobile} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </UseSanaTexts>
      {confirmationModal}
      <SubAccountRemovalFailureModal opened={opened} hide={hide} removalInfo={removalFailureInfo} />
    </>
  );
};

List.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    active: PropTypes.bool,
    canAuthorizeOrders: PropTypes.bool,
    canOrder: PropTypes.bool,
    canSeePrices: PropTypes.bool,
    canSeeStock: PropTypes.bool,
    limits: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number.isRequired,
    })),
  })),
  options: PropTypes.shape({
    orderProducts: PropTypes.bool,
    showPrices: PropTypes.bool,
    showStock: PropTypes.bool,
  }),
};

const Memoized = memo(List);

Memoized.selectPropsFromPage = function ({ subAccounts, options, removalFailureInfo }) {
  return { list: subAccounts && subAccounts.list, options, removalFailureInfo };
};

export default Memoized;

/* eslint-disable react/no-multi-comp */
function renderBooleanColumn(bool, className = '') {
  return (
    <td className={`${styles.status} ${className}`}>
      {bool
        ? <StatusSuccessIcon className={`${styles.bool} ${styles.ok}`} />
        : <StatusFailedIcon className={`${styles.bool} ${styles.failed}`} />
      }
    </td>
  );
}
