import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from './hooks';

/**
 * Component which provides array of desired Sana texts to it's children function.
 * @returns {React.ReactNode} - children function with passed Sana texts array.
 */
const UseSanaTexts = ({
  children,
  options,
  dontWait,
  props,
  placeholder = null,
}) => {
  const { texts, loaded } = useSanaTexts(options);

  return useMemo(() => {
    if (!dontWait && !loaded)
      return placeholder;

    if (!children)
      return null;

    if (props)
      return children(texts, props) || null;

    return children(texts) || null;
  }, [children, texts, dontWait, props]);
};

UseSanaTexts.propTypes = {
  children: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      key: PropTypes.string,
      fallback: PropTypes.string,
    }),
  ])),
  dontWait: PropTypes.bool,
  props: PropTypes.any,
  placeholder: PropTypes.node,
};

export default UseSanaTexts;