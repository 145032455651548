import styles from './ForgotPassword.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { RichText, SimpleText } from 'components/sanaText';
import ForgotPasswordForm from './ForgotPasswordForm';
import { BackTo } from 'components/primitives/links';
import { routesBuilder } from 'routes';

const backTexts = ['ButtonText_BackToLogin', 'ButtonText_Login'];

const ForgotPassword = ({ email }) => (
  <>
    <div className={styles.backTo}>
      <BackTo to={routesBuilder.forLogin} texts={backTexts} />
    </div>
    <h1>
      <SimpleText textKey="ForgotPassword" />
    </h1>
    <div>
      <RichText textKey="ForgotPassword_Intro" />
    </div>
    <Row>
      <Col md={8} lg={7}>
        <ForgotPasswordForm email={email} className={styles.forgotPassword} />
      </Col>
    </Row>
  </>
);

ForgotPassword.propTypes = {
  email: PropTypes.string,
};

const Memoized = memo(ForgotPassword);

Memoized.selectPropsFromPage = ({ options }) => options;

export default Memoized;