import { memo } from 'react';
import PropTypes from 'prop-types';
import { Documents } from '../documents';
import { DocumentType } from 'behavior/documents';
import { routesBuilder } from 'routes';
import List from './List';

const pageTextKeys = {
  listHeader: 'RecentAuthorizeOrders',
  loadMore: 'ShowNextNAuthorizeOrders',
  noItems: 'NoAuthorizeOrderHistory',
};

const orderAuthorizationsRoute = routesBuilder.forOrderAuthorizations();

const Page = memo(({ authorizations, totalCount, size, filter }) => {
  return (
    <Documents
      documents={authorizations}
      totalCount={totalCount}
      size={size}
      filter={filter}
      loadMoreRoute={orderAuthorizationsRoute}
      textKeys={pageTextKeys}
      documentType={DocumentType.Order}
      showAuthorizationStatusFilter
    >
      <List authorizations={authorizations} />
    </Documents>
  );
});

Page.propTypes = {
  authorizations: PropTypes.array,
  totalCount: PropTypes.number,
  size: PropTypes.number,
  filter: PropTypes.object,
};

Page.selectPropsFromPage = ({ orderAuthorizations, size, filter }) => ({
  authorizations: orderAuthorizations && orderAuthorizations.items,
  totalCount: orderAuthorizations && orderAuthorizations.totalCount,
  size,
  filter,
});

export default Page;
