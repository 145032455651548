import { pipe } from 'rxjs';
import {
  withLatestFrom,
  map,
  filter,
  tap,
} from 'rxjs/operators';

const dataLayer = (dataLayerName, data) => {
  const dataLayer = typeof window !== 'undefined' && window[dataLayerName];
  if (!dataLayer)
    return;

  dataLayer.push(data);
};

export const pushToDataLayer = (state$, createPayload) => pipe(
  withLatestFrom(state$),
  filter(([_, { analytics }]) => analytics && analytics.trackers),
  map(([data, { analytics }]) => ({
    data,
    dataLayersNames: getDataLayersNames(analytics.trackers),
  })),
  filter(({ dataLayersNames }) => dataLayersNames.length),
  tap(({ data, dataLayersNames }) =>
    dataLayersNames.forEach(dataLayerName => dataLayer(dataLayerName, createPayload(data))),
  ),
  map(({ data }) => data),
);

const getDataLayersNames = trackers => {
  const dataLayersNames = [];
  trackers.forEach(t => {
    if (!dataLayersNames.some(layerName => layerName === t.dataLayerName))
      dataLayersNames.push(t.dataLayerName);
  });
  return dataLayersNames;
};