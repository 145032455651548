import styles from '../Lines.module.scss';
import LineTemplatePropTypes from './LineTemplatePropTypes';
import { SimpleText } from 'components/sanaText';
import { useUpdateContext } from '../../updateContext';
import { StockAvailability } from 'components/objects/stockAvailability';
import { getAvailableDate } from 'utils/baker';

const LineTemplate = ({
  productInfo,
  productActionBlock,
  actionLinks,
  quantityBox,
  uomLabel,
  priceNode,
  productDiscount,
  subTotal,
  className = null,
  isGroupedLine,
  id,
  stockStatus,
  quantity
}) => {
  const { isB2BPreset } = useUpdateContext();

  let quantityColSpan = 1;
  if (!uomLabel) {
    quantityColSpan++;
    if (!priceNode)
      quantityColSpan++;
      if (!stockStatus)
        quantityColSpan++;
  }

  // CHECK STOCK AVAILABILITY
  const stockAvailabilityCheck = stockStatus.filter(item => item.stockLevel > 0);
  let stockAvailabilityText = <SimpleText textKey="Availability_Out_Of_Stock" />;
  if (stockAvailabilityCheck.length > 0) {
    //stockAvailabilityText = (stockAvailabilityCheck[0].name === 'In Stock') ? <SimpleText textKey="Availability_In_Stock" /> : <span>{stockAvailabilityCheck[0].name}</span>;
    stockAvailabilityText = <span>{getAvailableDate(stockStatus, quantity)}</span>;
  }

  return (
    <tbody className={className} id={id}>
      <tr className={styles.productBlock}>
        {(isB2BPreset || productActionBlock) && (
          <td className={styles.productActionCell} aria-hidden={!isGroupedLine}>
            {productActionBlock}
          </td>
        )}
        <td className={styles.productInfoCell} colSpan={productActionBlock ? '2' : '3'} headers="basketProduct" aria-describedby="basketProduct">
          {productInfo}
        </td>
      </tr>
      <tr className={`${styles.priceBlock} ${priceNode ? '' : styles.noPrice}`}>
        <td className={styles.quantityCell} colSpan={quantityColSpan} headers="basketQuantity" aria-describedby="basketQuantity">
          {quantityBox}
        </td>
        {uomLabel &&
          <td className={styles.uomCell} colSpan={priceNode ? '1' : '2'} headers="basketUom" aria-describedby="basketUom">
            {uomLabel}
          </td>
        }
        {stockStatus &&
          <td className={styles.colStockAvailabilityMobile} colSpan={priceNode ? '1' : '2'} headers="basketQuantity" aria-describedby="basketQuantity">
            <StockAvailability component={stockAvailabilityText} stockStatus={stockStatus} currentQuantity={quantity} />
          </td>
        }
        {priceNode && (
          <td className={styles.costCell} headers="basketPrice" aria-describedby="basketPrice">
            {priceNode}
          </td>
        )}
      </tr>
      <tr className={`${styles.rowTotal} ${productDiscount ? styles.noBorder : styles.bottomIndent} ${subTotal ? '' : styles.noPrice}`}>
        <td colSpan={subTotal ? '2' : '3'} headers="basketProduct" aria-describedby="basketProduct">
          {actionLinks}
        </td>
        {subTotal && (
          <td colSpan={3} className={styles.costCell} headers="basketTotal" aria-describedby="basketTotal">
            <span className={styles.totalLabel}><SimpleText textKey="Total_Label" /></span> {subTotal}
          </td>
        )}
      </tr>
      {productDiscount && (
        <tr className={styles.rowDiscount}>
          <td colSpan="3" headers="basketTotal" aria-describedby="basketTotal">
            {productDiscount}
          </td>
        </tr>
      )}
    </tbody>
  );
};

LineTemplate.propTypes = LineTemplatePropTypes;

export default LineTemplate;