import { useRef, useMemo } from 'react';
import type { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { loadSanaTexts } from 'behavior/sanaText';
import SanaTextsContext from './SanaTextsContext';

type Props = {
  children: ReactNode;
}

const SanaTextsProvider = ({ children }: Props) => {
  const requestedKeys = useRequestedKeys();
  const dispatch = useDispatch();

  const context = useMemo(() => ({
    loadSanaTexts(textKeys: string[]): void {
      const keysToRequest = [];

      for (const key of textKeys) {
        if (requestedKeys.has(key))
          continue;

        keysToRequest.push(key);
        requestedKeys.add(key);
      }

      if (keysToRequest.length)
        dispatch(loadSanaTexts(keysToRequest));
    },
  }), []);

  return (
    <SanaTextsContext.Provider value={context}>
      {children}
    </SanaTextsContext.Provider>
  );
};

export default SanaTextsProvider;

function useRequestedKeys() {
  const ref = useRef<Set<string>>();
  if (!ref.current)
    ref.current = new Set<string>();

  return ref.current;
}
