import styles from '../../Order.module.scss';
import PropTypes from 'prop-types';
import { Placeholder } from 'components/primitives/placeholders';

const PreviewLine = ({
  options: {
    isB2CCustomer,
    showUOM,
    showStock,
    showPrices,
    showDiscounts,
    showExtraColumns,
    showShippingStatus,
  },
  lineTexts,
}) => (
  <tr>
    <td>
      <span className={styles.name}>{lineTexts.id}</span>
      <span className={styles.value}><Placeholder /></span>
    </td>
    <td>
      <span className={styles.name}>{lineTexts.title}</span>
      <span className={styles.value}><Placeholder /></span>
    </td>
    {showShippingStatus &&
      <td>
        <span className={styles.name}>{lineTexts.lineShippingStatus}</span>
        <span className={styles.value}><Placeholder /></span>
      </td>
    }
    {!isB2CCustomer &&
      <td>
        <span className={styles.name}>
          <span title={lineTexts.shipmentDateTitle}>{lineTexts.shipmentDate}</span>
        </span>
        <span className={styles.value}><Placeholder /></span>
      </td>
    }
    {showPrices &&
      <td>
        <span className={styles.name}>{lineTexts.price}</span>
        <span className={styles.value}><Placeholder /></span>
      </td>
    }
    {showDiscounts &&
      <td>
        <span className={styles.name}>{lineTexts.discount}</span>
        <span className={styles.value}><Placeholder /></span>
      </td>
    }
    <td>
      <span className={styles.name}>
        {isB2CCustomer ? lineTexts.quantity : <span title={lineTexts.quantity}>{lineTexts.qty}</span>}
      </span>
      <span className={styles.value}><Placeholder /></span>
    </td>
    {showUOM &&
      <td>
        <span className={styles.name}>
          <span title={lineTexts.unitOfMeasure}>{lineTexts.uom}</span>
        </span>
        <span className={styles.value}><Placeholder /></span>
      </td>
    }
    {showStock &&
      <td>
        <span className={styles.name}>{lineTexts.stock}</span>
        <span className={styles.value}><Placeholder /></span>
      </td>
    }
    {showExtraColumns &&
      <td>
        <span className={styles.name}>
          <span title={lineTexts.quantityShipped}>{lineTexts.linesShippedQuantity}</span>
        </span>
        <span className={styles.value}><Placeholder /></span>
      </td>
    }
    {showExtraColumns &&
      <td>
        <span className={styles.name}>
          <span title={lineTexts.quantityInvoiced}>{lineTexts.quantityInvoicedTitle}</span>
        </span>
        <span className={styles.value}><Placeholder /></span>
      </td>
    }
    {!isB2CCustomer &&
      <td>
        <span className={styles.name}>
          <span title={lineTexts.outstandingQuantityAlt}>{lineTexts.outstandingQuantity}</span>
        </span>
        <span className={styles.value}><Placeholder /></span>
      </td>
    }
    {showPrices &&
      <td>
        <span className={styles.name}>{lineTexts.totals}</span>
        <span className={styles.value}><Placeholder /></span>
      </td>
    }
  </tr>
);

PreviewLine.propTypes = {
  options: PropTypes.shape({
    isB2CCustomer: PropTypes.bool,
    showUOM: PropTypes.bool,
    showStock: PropTypes.bool,
    showPrices: PropTypes.bool,
    showDiscounts: PropTypes.bool,
    showExtraColumns: PropTypes.bool,
    showShippingStatus: PropTypes.bool,
  }).isRequired,
  lineTexts: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    lineShippingStatus: PropTypes.string,
    shipmentDateTitle: PropTypes.string,
    shipmentDate: PropTypes.string,
    price: PropTypes.string,
    discount: PropTypes.string,
    quantity: PropTypes.string,
    qty: PropTypes.string,
    unitOfMeasure: PropTypes.string,
    uom: PropTypes.string,
    quantityShipped: PropTypes.string,
    linesShippedQuantity: PropTypes.string,
    quantityInvoiced: PropTypes.string,
    quantityInvoicedTitle: PropTypes.string,
    outstandingQuantityAlt: PropTypes.string,
    outstandingQuantity: PropTypes.string,
    totals: PropTypes.string,
    stock: PropTypes.string,
  }).isRequired,
};

export default PreviewLine;
