import { useMemo } from 'react';

export function useIncompleteStepsInfo(steps) {
  return useMemo(
    () => {
      const incompleteSteps = new Set();

      for (const step of steps)
        !step.isCompleted && incompleteSteps.add(step.id);

      return incompleteSteps;
    }, [steps]);
}
