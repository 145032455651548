export default context => value => {
  if (!value)
    return;

  const { types } = context.params;
  const patterns = types.map(type => type.replace('*', '').toLowerCase());

  if (value instanceof File) {
    if (!isValid(value, patterns))
      return context.errorMessage;
  }
  else if (value instanceof FileList) {
    for (const file of value) {
      if (!isValid(file, patterns))
        return context.errorMessage;
    }
  }
};

function isValid(file, patterns) {
  return patterns.some(pattern => pattern[0] === '.'
    ? getExtension(file).toLowerCase() === pattern
    : file.type.toLowerCase().startsWith(pattern));
}

function getExtension(file) {
  return '.' + file.name.split('.').pop();
}