import styles from './ProductSelector.module.scss';
import PropTypes from 'prop-types';
import { useVariantDropdownsControl } from 'components/objects/product/variantDropdowns';
import VariantDropdownRow from './VariantDropdownRow';

const VariantDropdowns = ({ variantComponentGroups, updateVariantId, productId }) => {
  const [
    filteredVariantComponentGroups,
    selection,
    onSelectionChange,
  ] = useVariantDropdownsControl(
    variantComponentGroups,
    updateVariantId,
    productId,
  );

  return (
    <div className={styles.variantDropdowns}>
      {filteredVariantComponentGroups.map(({ id: groupId, components }) => (
        <VariantDropdownRow
          id={groupId}
          key={groupId}
          variantComponents={components}
          onChange={value => onSelectionChange({ ...selection, [groupId]: components.find(c => c.id === value) })}
          value={selection[groupId].id}
        />
      ))}
    </div>
  );
};

VariantDropdowns.propTypes = {
  variantComponentGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      components: PropTypes.arrayOf(
        PropTypes.shape({
          variants: PropTypes.array.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  updateVariantId: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
};

export default VariantDropdowns;
