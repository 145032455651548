import { switchMap, map, catchError } from 'rxjs/operators';
import { merge, throwError, of, Observable } from 'rxjs';
import { ofType, StateObservable } from 'redux-observable';
import { themeLoaded } from './actions';
import { APP_INIT } from 'behavior/app';
import { loadThemeQuery } from './queries';
import { ThemeVersion } from './constants';
import type { AppState } from 'behavior';
import type { StoreDependencies } from 'behavior/types';
import type { AppAction } from 'behavior/app/actions';

const themeEpic = (action$: Observable<AppAction>, _: StateObservable<AppState>, { api }: StoreDependencies) => action$.pipe(
  ofType(APP_INIT),
  switchMap(_ => api.graphApi(loadThemeQuery).pipe(
    map(data => themeLoaded(data.theme)),
  )),
  catchError(e => merge(of(themeLoaded({ version: ThemeVersion.Error })), throwError(e))),
);

export default themeEpic;
