import { LANGUAGES_LOADED, ALTERNATE_URLS_LOADED, URL_LANGUAGE_CHANGED } from './actions';
import { NAVIGATED } from 'behavior/routing';
import { LOCATION_CHANGED } from 'behavior/events';
import { createReducer } from 'utils/redux';

const initialState = {
  languages: [],
  currentLanguage: {
    id: null,
    name: null,
    cultureName: null,
    firstDayOfWeek: null,
  },
  alternateUrls: [],
  alternateUrlsExpired: false,
};

export default createReducer(initialState, {
  [LANGUAGES_LOADED]: onLanguagesLoaded,
  [ALTERNATE_URLS_LOADED]: onAlternateUrlsLoaded,
  [LOCATION_CHANGED]: onLocationChanged,
  [URL_LANGUAGE_CHANGED]: onUrlLanguageChanged,
  [NAVIGATED]: onNavigated,
});

function onLanguagesLoaded(state, action) {
  const languages = action.payload;

  return {
    ...state,
    languages,
    currentLanguage: createCurrentLanguage(state.currentLanguage.id, languages),
  };
}

function onAlternateUrlsLoaded(state, action) {
  return { ...state, alternateUrls: action.payload };
}

function onLocationChanged(state, _action) {
  return { ...state, alternateUrlsExpired: true };
}

function onNavigated(state, _action) {
  if (state.alternateUrlsExpired)
    return { ...state, alternateUrlsExpired: false, alternateUrls: [] };

  return state;
}

function onUrlLanguageChanged(state, action) {
  const id = action.payload;

  return {
    ...state,
    currentLanguage: createCurrentLanguage(id, state.languages),
  };
}

/**
 * Finds a language in an array by the specified language ID.
 * @param {Number} id - ID of the language.
 * @param {Array} languages - languages array.
 * @returns {Object} - found language, or null in case language wasn't found.
 */
function findLanguageById(id, languages) {
  if (!languages)
    return;
  return languages.find(l => l.id === id);
}

function createCurrentLanguage(id, languages) {
  const language = findLanguageById(id, languages);

  return {
    id,
    name: language ? language.twoLetterISOName : null,
    cultureName: language ? language.name : null,
    firstDayOfWeek: language ? language.firstDayOfWeek : null,
  };
}