import styles from '../MainNav.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import MainNav from '../MainNav';
import NavItem from './NavItem';
import { AccordionStateProvider } from './AccordionStateProvider';

const AccordionNav = ({ isExpanded, handleSkipLinkClick }) => (
  <AccordionStateProvider isExpanded={isExpanded}>
    <MainNav NavItemComponent={NavItem} navClass={styles.accordion} handleSkipLinkClick={handleSkipLinkClick} />
  </AccordionStateProvider>
);

AccordionNav.propTypes = {
  isExpanded: PropTypes.bool,
  handleSkipLinkClick: PropTypes.func,
};

export default memo(AccordionNav);