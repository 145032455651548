import { representationPage } from './queries';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';

export default (_data, _state$, { api }) =>
  api.graphApi(representationPage).pipe(
    map(({ pages, profile: { impersonation } }) => {
      const page = pages.represent;

      if (!impersonation || !page)
        return null;

      page.component = PageComponentNames.Represent;
      page.customers = impersonation.customers;
      page.customers.keywords = '';

      return { page };
    }),
  );