import styles from './SalesAgreements.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useOnChange, useCurrentRouteAsBackTo } from 'utils/hooks';
import { RichText, SimpleText, useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { Input, Checkbox } from 'components/primitives/form';
import { LoadMoreButton, SanaButton } from 'components/primitives/buttons';
import { Placeholder } from 'components/primitives/placeholders';
import Items from './Items';

import { requestAgreements, requestAgreement } from 'behavior/pages/salesAgreements';
import { connect } from 'react-redux';

const PAGE_SIZE = 10;
const searchFormName = 'AgreementId';

const Agreements = ({ items, searchParam, activeOnlyParam, dispatch }) => {
  const [activeOnly, setActiveOnly] = useState(activeOnlyParam);
  const searchedIdRef = useRef(searchParam);
  const usedActiveOnlyFilterRef = useRef(activeOnlyParam);
  const inputRef = useRef(null);

  const backTo = useCurrentRouteAsBackTo();
  const agreementBackTo = useMemo(() => ({
    ...backTo,
    routeData: {
      ...backTo.routeData,
      params: {
        ...backTo.routeData.params,
        search: searchedIdRef.current,
        activeOnly: usedActiveOnlyFilterRef.current,
      },
    },
  }), [backTo, searchedIdRef.current, usedActiveOnlyFilterRef.current]);

  useEffect(() => {
    if (searchedIdRef.current)
      inputRef.current.querySelector('input').value = searchedIdRef.current;
  }, []);

  useEffect(() => {
    if (items)
      return;

    const action = searchedIdRef.current
      ? requestAgreement(searchedIdRef.current)
      : requestAgreements(activeOnly, 0, PAGE_SIZE);

    dispatch(action);

    if (!searchedIdRef.current)
      usedActiveOnlyFilterRef.current = activeOnly;
  }, [items]);

  const showLoadNext = useShowLoadButton(items);

  const loadNextBatch = useCallback(index => {
    dispatch(requestAgreements(usedActiveOnlyFilterRef.current, index, PAGE_SIZE));
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    const idInput = e.target.elements && e.target.elements['id'];
    const id = idInput && idInput.value;
    if (id) {
      if (!id.trim()) {
        idInput.value = '';
      } else {
        dispatch(requestAgreement(id));
        searchedIdRef.current = id;

        return;
      }
    } else {
      const searchForm = document.forms[searchFormName];
      const idInput = searchForm && searchForm.elements['id'];
      if (idInput)
        idInput.value = '';
    }

    dispatch(requestAgreements(activeOnly, 0, PAGE_SIZE));
    usedActiveOnlyFilterRef.current = activeOnly;
    searchedIdRef.current = null;
  }

  const [showAllText, showActiveText, searchOnText] = useSanaTexts(['SalesAgreements_ShowAll', 'SalesAgreements_ShowActive', 'Search_SearchedOn_Label']).texts;

  const caption = useMemo(() => searchedIdRef.current && (
    <span className={styles.searchedOn}>{makeSimpleText(searchOnText, [searchedIdRef.current])}</span>
  ), [items, searchOnText]);

  return (
    <>
      <p className={'fr-view ' + styles.intro}><RichText textKey="SalesAgreements_IntroductionText" placeholder={<Placeholder lineCount={4} />} /></p>

      {showActiveText == null
        ? <Placeholder className={styles.formsPlaceholder} />
        : (
          <>
            <form onSubmit={handleSubmit} name={searchFormName}>
              <div className={styles.formRow}>
                <label htmlFor="filterId"><SimpleText textKey="SalesAgreementId" /></label>
                <div className={styles.inputBtnWrapper}>
                  <Input innerRef={inputRef} maxLength="50" type="search" name="id" id="filterId" />
                  <SanaButton textKey="Search" className={btnStyles.btnSmall} />
                </div>
              </div>
            </form>
            <form className={styles.activeOnly} onSubmit={handleSubmit}>
              <div className={styles.formRow}>
                <span className={styles.label}>
                  <label htmlFor="filterActiveOnly"><SimpleText textKey="ActiveOnly" /></label>
                </span>
                <div className={styles.inputBtnWrapper}>
                  <Checkbox id="filterActiveOnly" onChange={() => setActiveOnly(!activeOnly)} checked={activeOnly} />
                  <button className={`${btnStyles.btnBase} ${btnStyles.btnSmall} ${activeOnly ? '' : styles.showAll}`}>
                    {makeSimpleText(activeOnly ? showActiveText : showAllText)}
                  </button>
                </div>
              </div>
            </form>
          </>
        )
      }

      {caption}
      {items ? <Items items={items} backTo={agreementBackTo} /> : null}
      <div className={styles.loadRow}>
        {showLoadNext
          ? (
            <LoadMoreButton textKey="ShowNextNSalesAgreements"
              loadedCount={items.length}
              size={PAGE_SIZE}
              totalCount={300}
              loadNext={loadNextBatch}
            />
          )
          : null
        }
      </div>
    </>
  );
};

Agreements.propTypes = {
  items: PropTypes.array,
  searchParam: PropTypes.string,
  activeOnlyParam: PropTypes.bool,
  dispatch: PropTypes.func,
};

export default connect(({ page }) => ({
  items: page.agreements,
  searchParam: page.search,
  activeOnlyParam: page.activeOnly,
}))(Agreements);

function useShowLoadButton(items) {
  const showLoadButtonRef = useRef();

  useOnChange(() => {
    const state = showLoadButtonRef.current || (showLoadButtonRef.current = { prevLength: 0 });

    // No items, or loaded empty batch, or loaded not complete batch.
    if (!items || !items.length || state.prevLength === items.length || items.length % PAGE_SIZE !== 0) {
      state.prevLength = 0;
      state.show = false;
    } else {
      state.prevLength = items.length;
      state.show = true;
    }
  }, [items]);

  return showLoadButtonRef.current.show;
}
