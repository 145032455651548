import styles from './Lines.module.scss';
import { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { joinClasses } from 'utils/helpers';
import MediaBlock from './MediaBlock';
import ProductInfo from './ProductInfo';
import PriceNode from '../PriceNode';
import ActionLinks from './ActionLinks';
import Discount from './Discount';
import QuantityBox from './QuantityBox';
import ReadonlyQuantityBox from './ReadonlyQuantityBox';
import { routesBuilder } from 'routes';
import DefaultTemplate from './templates/Default';
import MobileTemplate from './templates/Mobile';
import ServiceLine from './ServiceLine';
import UomLabel from './UomLabel';
import BasketLinesContext from './BasketLinesContext';
import { useUpdateContext } from '../updateContext';
import ExtendedTexts from './ExtendedTexts';
import { ProductTrackingContext } from 'components/objects/analytics';
import { EVENT_SOURCES } from 'behavior/analytics';

const ProductLine = ({
  line,
  imageUrl,
  product,
  isVariantLine,
  isLastGroupLine,
  className,
  elementId,
  isSupplementary,
  isVolumeOrder,
}) => {
  const { showImages, showPrice, showUom, currencyInfo, isMobile } = useContext(BasketLinesContext);
  const updateContext = useUpdateContext();

  const { id, url, title: productTitle, stockStatus } = product;
  const { id: lineId, price, subTotal, discount, serviceLines, extendedTexts } = line;
  const uomId = line.uom && line.uom.id;
  const uomDescription = line.uom && line.uom.description;
  const uom = uomId && product.uoms && product.uoms.find(u => u.id === uomId);

  const route = routesBuilder.forProduct.bind(null, id);

  const productActionBlock = showImages && (isMobile || !isVariantLine) && (
    <MediaBlock
      productTitle={productTitle}
      imageUrl={imageUrl}
      productUrl={url}
      route={route}
    />
  );

  const productInfo = isVariantLine
    ? (
      <>
        {showImages && !isMobile && (
          <div className={styles.variantThumbnail}>
            <MediaBlock
              productTitle={productTitle}
              imageUrl={imageUrl}
              route={route}
            />
          </div>
        )}
        <span className={styles.smallTitle}>{line.title}</span>
        <ExtendedTexts texts={extendedTexts} />
      </>
    )
    : (
      <ProductInfo
        productUrl={url}
        route={route}
        productTitle={productTitle}
        productId={id}
        variantTitle={line.title}
        extendedTexts={extendedTexts}
      />
    );

  const actionLinks = !isSupplementary && !isVolumeOrder && (
    <ActionLinks
      productUrl={isVariantLine ? null : url}
      route={isVariantLine ? null : route}
      onDelete={updateContext.delete.bind(updateContext, lineId)}
    />
  );

  const priceNode = showPrice && <PriceNode price={price} currencyInfo={currencyInfo} className={styles.priceNode} />;
  const subTotalNode = showPrice && <PriceNode price={subTotal} currencyInfo={currencyInfo} />;

  const uomData = uom || { id: uomId, description: uomDescription };
  const quantityBox = !isSupplementary && !isVolumeOrder
    ? (
      <QuantityBox
        initialQuantity={line.quantity}
        getCurrentQuantity={updateContext.quantities.get.bind(updateContext.quantities, lineId)}
        uom={uomData}
        id={'qty' + lineId}
        updateQuantity={updateContext.setQuantity.bind(updateContext, lineId, line.quantity)}
      />
    )
    : <ReadonlyQuantityBox quantity={line.quantity} />;
  const uomLabel = showUom && <UomLabel uom={uomData} />;

  const productDiscount = !!discount && <Discount discount={discount} currencyInfo={currencyInfo} />;

  const LineTemplate = isMobile ? MobileTemplate : DefaultTemplate;

  const trackingData = {
    product: { ...product, price, uom: line.uom },
    trackingSource: EVENT_SOURCES.shoppingCartPage,
  };

  return (
    <ProductTrackingContext.Provider value={trackingData}>
      <LineTemplate
        id={elementId}
        className={joinClasses(
          className,
          styles.productLine,
          isVariantLine && styles.variantLine,
          isLastGroupLine && (!serviceLines || !serviceLines.length) && styles.lastInGroup,
        )}
        productActionBlock={productActionBlock}
        productInfo={productInfo}
        quantityBox={quantityBox}
        uomLabel={uomLabel}
        actionLinks={actionLinks}
        priceNode={priceNode}
        productDiscount={productDiscount}
        subTotal={subTotalNode}
        stockStatus={stockStatus}
        quantity={line.quantity}
      />
      {serviceLines && serviceLines.map(({ id, title, price, quantity, subTotal }, index) => (
        <ServiceLine
          key={index}
          id={id}
          title={title}
          price={price}
          quantity={quantity}
          subTotal={subTotal}
          className={joinClasses(
            styles.serviceLine,
            isVariantLine && `${styles.variantLine} ${className}`,
            isLastGroupLine && index === serviceLines.length - 1 && styles.lastInGroup,
          )}
          elementId={`${elementId}_${index}`}
        />
      ))}
    </ProductTrackingContext.Provider>
  );
};

export const productLinePropTypes = {
  line: PropTypes.shape({
    title: PropTypes.string,
    productTitle: PropTypes.string,
    quantity: PropTypes.number,
    uom: PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
    price: PropTypes.number,
    subTotal: PropTypes.number,
    discount: PropTypes.number,
    serviceLines: PropTypes.array,
    extendedTexts: PropTypes.array,
  }).isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string,
    uoms: PropTypes.array,
  }).isRequired,
  isSupplementary: PropTypes.bool,
};

ProductLine.propTypes = {
  ...productLinePropTypes,
  imageUrl: PropTypes.string,
  isVariantLine: PropTypes.bool,
  isLastGroupLine: PropTypes.bool,
  className: PropTypes.string,
  elementId: PropTypes.string,
  isVolumeOrder: PropTypes.bool,
};

export default memo(ProductLine);
