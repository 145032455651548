import styles from '../Order.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { routesBuilder } from 'routes';
import { LinkButton } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';

const ReturnOrderButton = ({ invoice: { id, orderId } }) => (
  <LinkButton
    className={`${btnStyles.btnSmall} ${styles.btnAction} ${styles.btnReturn}`}
    to={routesBuilder.forCreateDocBasedReturnOrder(id, orderId)}
  >
    <SimpleText textKey="ButtonText_ReturnOrder" />
  </LinkButton>
);

ReturnOrderButton.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    orderId: PropTypes.string,
  }).isRequired,
};

export default memo(ReturnOrderButton);