import { map, first, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { createLoadOptions as createLoadOptionsBase } from '../handler';
import { createSearchQuery } from './queries';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { updatePageProducts } from '../reducer';
import { sortOptionsAreEqual } from '../helpers';

export default ({ params, options }, state$, { api }) => {
  const handle = ({ settings }) => {
    if (!params.id)
      return of(null);

    const viewMode = params.viewMode || settings.productList.defaultViewMode;
    const loadOptions = createLoadOptions(params, { ...options, viewMode }, settings);

    const productsOnly = options && options.productsOnly;
    const state = state$.value;

    return api.graphApi(createSearchQuery(productsOnly), {
      id: params.id,
      options: loadOptions,
      loadLargeImages: false,
      loadCategories: state.analytics && state.analytics.isTrackingEnabled,
    }).pipe(
      map(({ catalog: { products: foundProducts, categories: foundCategories } }) => {
        if (!foundProducts)
          return null;

        if (productsOnly) {
          const size = loadOptions.page.size;
          const page = updatePageProducts(state$.value.page, foundProducts.products, options.appendProducts, size);
          page.totalCount = foundProducts.totalCount;

          if (!sortOptionsAreEqual(params.sort, page.selectedSorting))
            page.selectedSorting = params.sort;

          return { page };
        }

        const category = foundCategories[0];
        if (!category)
          return null;

        const { sort, viewMode } = params;
        const {
          showThumbnails,
          defaultSorting,
          defaultViewMode,
          viewModeSwitchEnabled,
          pagingType,
          backgroundColor,
          backgroundImage,
        } = settings.productList;
        const { products, totalCount, facets } = foundProducts;

        facets.facets = facets.facets.filter(f => f.name !== 'Product category');

        const page = {
          id: params.id,
          products,
          totalCount,
          backgroundColor,
          backgroundImage,
          facets,
          viewModeSwitchEnabled,
          showThumbnails,
          pagingType,
          selectedSorting: sort || defaultSorting,
          defaultSorting,
          defaultViewMode,
          selectedViewMode: viewMode || defaultViewMode,
          lastViewedEnabled: true,
          component: PageComponentNames.ProductsWithCategory,
          categoryName: category.name,
        };

        return { page };
      }),
    );
  };

  const state = state$.value;
  if (state.settings.loaded)
    return handle(state);

  return state$.pipe(
    first(({ settings }) => settings.loaded),
    switchMap(handle),
  );
};

function createLoadOptions(params, options, settings) {
  const loadOptions = createLoadOptionsBase(params, options, settings.productList);

  if (!loadOptions.sorting && settings.productList.defaultSorting)
    loadOptions.sorting = [settings.productList.defaultSorting];

  loadOptions.categoryIds = [params.id];

  return loadOptions;
}
