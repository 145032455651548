import { addPageParamToUrl } from 'behavior/pages/helpers';
import { routesBuilder } from 'routes';
import { redirectTo } from 'behavior/routing';

export function getCorrectPageIndex(pageIndex, pageSize, totalCount) {
  if (!pageIndex || pageIndex < 0)
    return 0;

  if (totalCount <= pageIndex * pageSize)
    return Math.floor((totalCount - 1) / pageSize);

  return pageIndex;
}

export function redirectToPage(pageUrl, pageIndex, linesOnly) {
  return redirectTo(addPageParamToUrl(pageUrl, pageIndex + 1), 302, routesBuilder.forBasket(pageIndex, linesOnly));
}