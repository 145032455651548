import { loadCreateProspectPageQuery } from './queries';
import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import { initSystemPageContent } from '../system';
import { getBackTo } from '../helpers';
import { RouteName } from 'routes';

export default (routeData, state$, { api }) => api.graphApi(loadCreateProspectPageQuery).pipe(
  map(({ pages, entityTemplateFields }) => {
    const page = pages.createProspect;
    if (!page)
      return null;

    page.templateFields = entityTemplateFields;

    page.component = PageComponentNames.CreateProspect;

    page.backTo = getBackTo(state$, [
      RouteName.CreateProspect,
    ], routeData.params && routeData.params.language);

    return { page };
  }),
  initSystemPageContent(),
);
