import styles from './TermsAndAgreements.module.scss';
import modalStyles from 'components/objects/modals/Modal.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Modal, useModal } from 'components/objects/modals';
import { SanaButton } from 'components/primitives/buttons';

export const btnId = 'termsBtn';

const Info = ({ linkText, popupHeader, popupBody }) => {
  const { opened, show, hide } = useModal();

  return (
    <>
      <button id={btnId} onClick={show} type="button" className={linkStyles.link}>{linkText}</button>
      <Modal opened={opened} hide={hide} className={styles.popup}>
        <h2 className={`h3 ${modalStyles.title}`}>{popupHeader}</h2>
        {popupBody}
        <SanaButton
          textKey="Close"
          className={`${btnStyles.btnExtraSmall} ${btnStyles.noIcon} ${styles.btnClose}`}
          onClick={hide}
        />
      </Modal>
    </>
  );
};

Info.propTypes = {
  linkText: PropTypes.string,
  popupHeader: PropTypes.string,
  popupBody: PropTypes.arrayOf(PropTypes.node),
};

export default memo(Info);
