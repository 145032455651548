import styles from '../PLP.module.scss';
import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { connectToContext } from 'utils/react';
import { AbilityTo } from 'behavior/user/constants';
import ListContext from './ListContext';
import { UomSelector, useCanViewUom } from 'components/primitives/product';
import { requestCalculatedFields } from 'behavior/pages/volumeOrderList';
import { addProducts } from 'behavior/basket';
import { addToBasket } from './AddToBasketButton';
import {
  QuantityTextBox,
  createQuantityModel,
} from 'components/primitives/product';
import { getNumberDecimalsSeparator } from 'utils/format';
import { useDispatch } from 'react-redux';
import { volumeOrderQuantityChanged } from 'behavior/volumeOrder';

const QuantityBox = ({
  abilities,
  allowUOMSelection,
  updateQuantity,
  product,
  quantities,
  quantityValue,
  addProducts,
  resetQuantities,
  requestCalculatedFields,
  culture,
}) => {
  const { 
    uoms, 
    id: productId, 
    isOrderable, 
    price, 
    level1VolumePrice, 
    level2VolumePrice, 
  } = product;

  const dispatch = useDispatch();

  const canViewUom = useCanViewUom();
  const [uomId, updateUomId] = useState(product.uom && product.uom.id);
  const canOrder = abilities[AbilityTo.OrderProducts];
  const showUomSelector = canViewUom && uomId && uoms && uoms.length > 0;

  const updateUom = useCallback(uomId => {
    updateUomId(uomId);
    const options = { ids: [productId], page: { size: 1, index: 0 }, uomId };
    updateQuantity(productId, uomId, null);
    requestCalculatedFields(options);
  }, [productId, updateQuantity, requestCalculatedFields]);

  const quantityModel = useMemo(() => {
    const uom = uoms && uoms.find(u => u.id === uomId);

    return createQuantityModel(uom, true);
  }, [uomId]);

  const handleQuantityChange = quantity => {
    updateQuantity(productId, uomId, quantity);
    dispatch(volumeOrderQuantityChanged({ productId, quantity, price, level1VolumePrice, level2VolumePrice }));
  };

  const handleEnter = useCallback(({ which, key }) => {
    if (key === 'Enter' || which === 13)
      addToBasket(quantities, resetQuantities, addProducts);
  }, [quantities, resetQuantities, addProducts]);

  const separator = getNumberDecimalsSeparator(culture);

  return (
    <div className={styles.quantityBox}>
      {canOrder && isOrderable && (
        <div className={styles.quantityTextBox}>
          {quantityModel && (
            <QuantityTextBox
              id={`${productId}_qty`}
              quantity={quantityModel}
              value={quantityValue}
              onChange={handleQuantityChange}
              onKeyDown={handleEnter}
              separator={separator}
              allowResetOnStepDecrease
            />
          )}
        </div>
      )}
      {showUomSelector && (
        <div className={styles.uomSelector}>
          <UomSelector
            productId={productId}
            allowUOMSelection={allowUOMSelection}
            uomId={uomId}
            uoms={uoms}
            onUomChange={updateUom}
            isOrderable
          />
        </div>
      )}
    </div>
  );
};

QuantityBox.propTypes = {
  abilities: PropTypes.object.isRequired,
  allowUOMSelection: PropTypes.bool.isRequired,
  updateQuantity: PropTypes.func.isRequired,
  product: PropTypes.shape({
    uoms: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ),
    id: PropTypes.string.isRequired,
    isOrderable: PropTypes.bool.isRequired,
    uom: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  quantities: PropTypes.instanceOf(Map).isRequired,
  quantityValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  addProducts: PropTypes.func.isRequired,
  resetQuantities: PropTypes.func.isRequired,
  requestCalculatedFields: PropTypes.func.isRequired,
  culture: PropTypes.string,
};

const connectedQuantityBox = connect(
  ({ settings, localization }) => ({
    allowUOMSelection: settings.product && settings.product.allowUOMSelection,
    culture: localization.currentLanguage && localization.currentLanguage.cultureName,
  }),
  { requestCalculatedFields, addProducts },
)(QuantityBox);

export default connectToContext([ListContext], ({ quantities, updateQuantity, resetQuantities }, { product }) => {
  const productQuantity = quantities.get(product.id);

  return {
    quantities,
    quantityValue: productQuantity && productQuantity.quantity && productQuantity.quantity.value,
    updateQuantity,
    resetQuantities,
  };
})(connectedQuantityBox);