import { 
  CREATE_INVOICE_ORDER_RESULT_RECEIVED, 
  SUBMIT_INVOICE_ORDER_RESULT_RECEIVED,
  SUBMIT_INVOICE_ORDER,
  PAYMENT_METHOD_EXTRA_DATA_RECEIVED,
} from './actions';
import { createReducer } from 'utils/redux';

export default createReducer(null, {
  [CREATE_INVOICE_ORDER_RESULT_RECEIVED]: onCreateInvoiceOrderResultReceived,
  [SUBMIT_INVOICE_ORDER_RESULT_RECEIVED]: onSubmitInvoiceOrderResultReceived,
  [SUBMIT_INVOICE_ORDER]: onSubmitInvoiceOrder,
  [PAYMENT_METHOD_EXTRA_DATA_RECEIVED]: onPaymentMethodExtraDataReceived,
});

function onCreateInvoiceOrderResultReceived(state, action) {
  return { 
    ...state, 
    createInvoiceOrderResult: action.payload.result,
  };
}

function onSubmitInvoiceOrderResultReceived(state, action) {
  return { 
    ...state, 
    submitInvoiceOrderResult: action.payload.result,
    isInvoiceOrderSubmitting: false,
  };
}

function onSubmitInvoiceOrder(state) {
  return { 
    ...state, 
    submitInvoiceOrderResult: null, 
    isInvoiceOrderSubmitting: true,
  };
}

function onPaymentMethodExtraDataReceived(state, action) {
  const { paymentMethodId, additionalCustomerData, extraPaymentData } = action.payload;

  return {
    ...state,
    additionalCustomerData,
    extraPaymentData: extraPaymentData && { paymentMethodId, ...extraPaymentData },
  };
}