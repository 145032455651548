import styles from '../Order.module.scss';
import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OrderAuthorizationStatus, OrderAuthorizationActionResult } from 'behavior/pages/document';
import { SimpleText, useSanaTexts } from 'components/sanaText';
import { OrderAuthorizers } from 'components/primitives/order';
import ActionButtons from './ActionButtons';
import ProcessedAuthorization from './ProcessedAuthorizationInfo';
import { toasts } from 'behavior/toasts';

const AuthorizationInfo = ({ orderId, info, authorizationActionResultStatus }) => {
  if (!info)
    return null;

  useSanaTexts(textsToPreload);
  useAuthorizationActionResultNotification(authorizationActionResultStatus);

  const { status, authorizers, canAuthorize } = info;
  const orderAuthorizers = <OrderAuthorizers authorizers={authorizers} className={styles.authorizers} />;

  switch (status) {
    case OrderAuthorizationStatus.NotNeeded:
      return (
        <span className={styles.authNotNeeded}>
          <SimpleText textKey="OrderAuthorization_NotNeededExplanation" />
        </span>
      );
    case OrderAuthorizationStatus.Pending:
      return canAuthorize
        ? <ActionButtons orderId={orderId} />
        : orderAuthorizers;
    case OrderAuthorizationStatus.PendingNotSynced:
      return !canAuthorize && orderAuthorizers;
    case OrderAuthorizationStatus.Approved:
    case OrderAuthorizationStatus.Declined:
      return <ProcessedAuthorization orderId={orderId} info={info} />;
    default:
      return null;
  }
};

AuthorizationInfo.propTypes = {
  orderId: PropTypes.string.isRequired,
  info: PropTypes.shape({
    canAuthorize: PropTypes.bool,
    authorizers: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.oneOf(Object.values(OrderAuthorizationStatus)),
  }),
  authorizationActionResultStatus: PropTypes.oneOf(Object.values(OrderAuthorizationActionResult.DetailedStatus)),
};

export default memo(AuthorizationInfo);

function useAuthorizationActionResultNotification(actionResultStatus) {
  useEffect(() => {
    if (!actionResultStatus)
      return;

    let toastClassName;
    let showToast;
    switch (actionResultStatus) {
      case OrderAuthorizationActionResult.DetailedStatus.Approved:
        showToast = toasts.success;
        toastClassName = styles.authApproved;
        break;
      case OrderAuthorizationActionResult.DetailedStatus.Declined:
        showToast = toasts.success;
        toastClassName = styles.authDeclined;
        break;
      case OrderAuthorizationActionResult.DetailedStatus.Cancelled:
        showToast = toasts.success;
        toastClassName = styles.authCancelled;
        break;
      case OrderAuthorizationActionResult.DetailedStatus.AlreadyApproved:
        showToast = toasts.warning;
        toastClassName = styles.authAlreadyApproved;
        break;
      case OrderAuthorizationActionResult.DetailedStatus.AlreadyDeclined:
        showToast = toasts.warning;
        toastClassName = styles.authAlreadyDeclined;
        break;
      default:
        break;
    }

    if (!showToast)
      return;

    showToast(<SimpleText textKey={authorizationResultTexts[actionResultStatus]} />, { className: toastClassName });
  }, [actionResultStatus]);
}

const authorizationResultTexts = {
  [OrderAuthorizationActionResult.DetailedStatus.Approved]: 'OrderAuthorization_ApprovedSuccess',
  [OrderAuthorizationActionResult.DetailedStatus.AlreadyApproved]: 'OrderAuthorization_ApprovedFailure',
  [OrderAuthorizationActionResult.DetailedStatus.Declined]: 'OrderAuthorization_DeclinedSuccess',
  [OrderAuthorizationActionResult.DetailedStatus.AlreadyDeclined]: 'OrderAuthorization_DeclinedFailure',
  [OrderAuthorizationActionResult.DetailedStatus.Cancelled]: 'OrderAuthorization_CancelledSuccess',
};
const textsToPreload = Object.values(authorizationResultTexts);
