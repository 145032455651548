import styles from './Link.module.scss';
import { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Link from './base/Link';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { createRouteData } from './base/helpers';
import { createUrl } from 'behavior/routing';
import { SmallArrowLeftIcon } from 'components/primitives/icons';
import type { LinkTo, SystemLinkTo } from './base/types';
import type { RouteName } from 'routes';

type LinkText = [text: string, title?: string]

type Props = {
  url?: string;
  to: LinkTo;
  texts?: LinkText;
  toTexts?: LinkText;
  supportedRoutes?: Set<RouteName>;
  className?: string;
}

type EmptyBackToProps = Omit<Props, 'to' | 'url'>

type SystemBackToProps = EmptyBackToProps & {
  to: SystemLinkTo;
  url?: string;
}

type BackToProps = EmptyBackToProps & {
  to: LinkTo;
  url: string;
}

const defaultTexts: LinkText = ['ButtonText_Back'];

function BackTo(props: SystemBackToProps): ReactElement;
function BackTo(props: BackToProps): ReactElement;
function BackTo({
  url,
  to,
  texts = defaultTexts,
  toTexts = texts,
  supportedRoutes,
  className = styles.hypBack,
}: Props) {
  const prevRoute = useSelector(state => state.routing.previous);
  const toRouteData = useMemo(() => createRouteData(to), [to]);
  const prevRouteName = prevRoute?.routeData.routeName;

  /* eslint-disable no-mixed-operators */
  const useFallbackRoute = !prevRoute
    || (supportedRoutes && !supportedRoutes.has(prevRouteName!))
    || (!supportedRoutes && prevRouteName !== toRouteData.routeName);
  /* eslint-enable no-mixed-operators */

  const useFallbackTexts = useFallbackRoute || prevRouteName === toRouteData.routeName;

  const { texts: [text, title], loaded } = useSanaTexts<string | null>(useFallbackTexts ? toTexts : texts, makeSimpleText);
  if (!loaded)
    return null;

  const icon = <SmallArrowLeftIcon alt={title} className="back-icon" aria-hidden />;

  if (useFallbackRoute) {
    return (
      <Link to={toRouteData as any} url={url} rel="nofollow" className={className} title={title}>
        {icon}
        {text}
      </Link>
    );
  }

  const { location: prevLocation, routeData: prevRouteData } = prevRoute!;
  const backUrl = url || createUrl(prevLocation);

  return (
    <Link
      url={backUrl}
      to={{
        routeName: prevRouteData.routeName,
        params: toRouteData.params || prevRouteData.params,
        options: toRouteData.options || prevRouteData.options,
      }}
      title={title}
      rel="nofollow"
      className={className}
    >
      {icon}
      {text}
    </Link>
  );
}

export default BackTo;
