import styles from './ProductComparisonRating.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { Link } from 'components/primitives/links';

const ReviewsLink = ({ reviewsTotal, to, url, onClick }) => {
  const reviewsLabelPlaceholder = <Placeholder className={styles.labelPlaceholder} />;

  return (
    <Link onClick={onClick} to={to} url={url} className={styles.gotoReview}>
      {reviewsTotal
        ? <SimpleText textKey="ReviewsReadAmountReviewsLinkText" formatWith={[reviewsTotal]} placeholder={reviewsLabelPlaceholder} />
        : <SimpleText textKey="Product_ReviewsZeroReviewsLabel" placeholder={reviewsLabelPlaceholder} />
      }
    </Link>
  );
};

ReviewsLink.propTypes = {
  reviewsTotal: PropTypes.number.isRequired,
  to: PropTypes.any,
  url: PropTypes.any,
  onClick: PropTypes.func,
};

export default ReviewsLink;