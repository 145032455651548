import { applyMiddleware, createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import { combineEpics } from 'utils/rxjs';
import { createRoutingEpic } from 'behavior/routing/epic';
import reducers from 'behavior/rootReducer';
import rootEpic from 'behavior/rootEpic';
import profilerEpic from 'behavior/tools/profiler/epic';
import { omitNavigationMiddleware } from 'behavior/routing';
import { takeUntil } from 'rxjs/operators';
import { APP_DESTROY } from 'behavior/app';

export default function configureStore(router, dependencies, addonsContainer, initialState) {
  const epicMiddleware = createEpicMiddleware({ dependencies });

  const middleware = [
    addonsContainer.middleware,
    omitNavigationMiddleware,
    epicMiddleware,
  ];

  let devToolsFeatures = {
    pause: true, // start/pause recording of dispatched actions
    lock: false, // lock/unlock dispatching actions and side effects
    persist: false, // persist states on page reloading
    export: true, // export history of actions in a file
    import: undefined, // import history of actions from a file
    jump: false, // jump back and forth (time traveling)
    skip: false, // skip (cancel) actions
    reorder: false, // drag and drop actions in the history list
    dispatch: false, // dispatch custom actions or action creators
    test: true, // generate tests for the selected actions
  };
  // In development, use full set of features
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment) {
    devToolsFeatures = undefined; // All of them are enabled, unless features parameter is specified

    // Required to see changes in state's Map fields.
    require('map.prototype.tojson');
  }
  const composeEnhancers = composeWithDevTools({
    features: devToolsFeatures,
    /*trace: true, traceLimit: 50*/
    // Other options like actionSanitizer, stateSanitizer
  });

  const rootReducer = combineReducers({
    ...reducers,
    addons: addonsContainer.reducer,
  });

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  epicMiddleware.run(completeOnDestroy(combineEpics(
    profilerEpic,
    createRoutingEpic(router),
    rootEpic,
    addonsContainer.epic,
  )));
  return store;
}

function completeOnDestroy(rootEpic) {
  return (action$, ...other) => rootEpic(action$, ...other).pipe(
    takeUntil(action$.ofType(APP_DESTROY)),
  );
}