import styles from '../Checkout.module.scss';
import { memo, useRef, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  CustomerDataStep as CustomerDataStepBase,
  CustomerDataStepBody,
} from '../base/payment';
import { useDispatch } from 'react-redux';
import { saveCustomerData } from 'behavior/pages/checkout';
import CheckoutContext from './CheckoutContext';
import { Steps } from 'behavior/pages/checkout';

const CustomerDataStep = ({ customerDataStep, isCompleted }) => {
  const dispatch = useDispatch();
  const formRef = useRef();

  const {
    setLoading,
    registerStep,
    unregisterStep,
    setStepCompletion,
  } = useContext(CheckoutContext);

  useEffect(() => {
    const validateStep = async () => await formRef.current.validate();

    registerStep(
      Steps.CustomerData,
      styles.customerData,
      [Steps.Address, Steps.Payment, Steps.CustomerData],
      validateStep,
    );

    return () => unregisterStep(Steps.CustomerData);
  }, []);

  useEffect(() => {
    setStepCompletion(Steps.CustomerData, !customerDataStep.errors);
    if (customerDataStep.errors)
      formRef.current.errors = customerDataStep.errors;
  }, [customerDataStep]);

  const onStepSubmit = useCallback(async () => {
    if (!(await formRef.current.validate())) {
      setStepCompletion(Steps.CustomerData, false);
      return;
    }

    setLoading(Steps.ExtraPayment);
    dispatch(saveCustomerData(formRef.current.values));
  }, []);

  return (
    <CustomerDataStepBase
      className={styles.customerData}
      isCompleted={isCompleted}
    >
      <CustomerDataStepBody
        customerDataStep={customerDataStep}
        formRef={formRef}
        onFormSubmit={onStepSubmit}
        submitFormOnBlur
      />
    </CustomerDataStepBase>
  );
};

CustomerDataStep.propTypes = {
  customerDataStep: PropTypes.shape({
    errors: PropTypes.arrayOf(PropTypes.shape({
      fieldName: PropTypes.string,
      message: PropTypes.string.isRequired,
    })),
  }).isRequired,
  isCompleted: PropTypes.bool,
};

export default memo(CustomerDataStep);
