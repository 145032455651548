import styles from './Rating.module.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as solidStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';

const StarsRating = ({ value, max = 5, min = 1 }) => {
  const stars = [];
  const rounded = Math.floor(value * 10) / 10;
  let i;
  for (i = min; i <= rounded; i++)
    stars.push(<FontAwesomeIcon key={i} icon={solidStar} />);

  if (i <= max && i > rounded && rounded > i - 1)
    stars.push(<FontAwesomeIcon key={i++} icon={faStarHalfAlt} />);

  for (; i <= max; i++)
    stars.push(<FontAwesomeIcon key={i} icon={faStar} />);

  const [title] = useSanaTexts(['Rating_Title']).texts;

  return (
    <span className={styles.stars} title={makeSimpleText(title, [value])}>
      {stars}
    </span>
  );
};

StarsRating.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
};

export default StarsRating;