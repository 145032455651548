import styles from './Page.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProductTile from './ProductTile';
import { buildRouteForProductComparison } from '../util';
import { navigateTo } from 'behavior/events';
import { useRoutes } from 'components/primitives/route';
import {
  removeProductFromComparison,
} from 'behavior/productComparison/actions';
import { RowRails, WithSwipeButtons } from './swiper';
import StickyPanel from './StickyPanel';

const ComparisonRow = ({ productsToCompare, removeProduct, navigateTo }) => {
  const removeRoutes = productsToCompare.map(product => {
    const products = productsToCompare.filter(p => p.id.toLocaleUpperCase() !== product.id.toLocaleUpperCase());
    return buildRouteForProductComparison(products);
  });

  const removeUrls = useRoutes(removeRoutes);

  const handleRemove = id => {
    const productToRemove = productsToCompare.find(product => product.id.toLocaleUpperCase() === id.toLocaleUpperCase());
    const removeProductIndex = productsToCompare.indexOf(productToRemove);

    removeProduct(id);
    const route = {
      ...removeRoutes[removeProductIndex],
      options: {
        keepPreviousState: true,
      },
    };
    navigateTo(route, removeUrls[removeProductIndex], { omitScroll: true, replaceHistory: true });
  };

  return (
    <StickyPanel>
      {isSticky => (
        <WithSwipeButtons className={`${styles.tilesRow} ${isSticky ? styles.compact : ''}`}>
          <RowRails items={productsToCompare}>
            {item => <ProductTile product={item} onRemove={handleRemove} compact={isSticky} />}
          </RowRails>
        </WithSwipeButtons>
      )}
    </StickyPanel>
  );
};

ComparisonRow.propTypes = {
  productsToCompare: PropTypes.array,
  removeProduct: PropTypes.func.isRequired,
  navigateTo: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  navigateTo,
  removeProduct: removeProductFromComparison,
};

export default connect(null, mapDispatchToProps)(ComparisonRow);