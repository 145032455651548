import { memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  useProductTrackingOptions,
  createProductStructure,
} from 'utils/structureData';
import { createUomTextKey } from 'components/primitives/product/UomTitle';
import { connectToContext } from 'utils/react';
import ProductContext from './ProductContext';
import renderOnce from 'components/objects/renderOnce';

const Schema = ({ product, calculatedInfo }) => {
  const uom = product
    && product.uom
    && product.uoms
    && product.uoms.find(u => u.id === product.uom.id);
  const uomTitleKey = uom && createUomTextKey(uom.id);

  const options = useProductTrackingOptions(uomTitleKey);

  if (!product || !product.loaded)
    return null;

  const schema = createProductStructure({ ...product, uom, calculatedInfo }, options);

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};

Schema.propTypes = {
  product: PropTypes.shape({
    loaded: PropTypes.bool,
  }),
  calculatedInfo: PropTypes.object,
};

const Memoized = memo(Schema, _ => true);

export default renderOnce(connectToContext([ProductContext], ({
  product,
  calculatedInfo,
}) => ({
  product,
  calculatedInfo,
}))(Memoized));