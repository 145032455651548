import { loadSystemPageQuery } from './queries';
import { pluckAsPage } from 'behavior/pages/helpers';
import { initSystemPageContent } from './helpers';
import { decapitalize } from 'utils/helpers';
import { tap } from 'rxjs/operators';

export default () => {
  let transformedName;

  return ({ routeName, options }, _, { api }) => {
    if (!transformedName)
      transformedName = decapitalize(routeName);

    return api.graphApi(loadSystemPageQuery(transformedName)).pipe(
      pluckAsPage(transformedName, transformedName),
      tap(data => {
        if (data && data.page) {
          data.page.options = options;
          data.page.backTo = options && options.backTo;
        }
      }),
      initSystemPageContent(),
    );
  };
};

