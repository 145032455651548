import styles from './PLP.module.scss';
import PropTypes from 'prop-types';
import { useState, useCallback, useRef } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { SimpleText } from 'components/sanaText';
import Sorting from './Sorting';
import ViewMode from './ViewMode';
import { MobileFacets, MobileFacetsToggler } from './facets';
import { Placeholder } from 'components/primitives/placeholders';
import { useResponsiveBreakpoints } from 'utils/layout';

export const HeaderRow = ({
  totalCount,
  availableFacets,
  hasFacets,
  viewModeSwitchEnabled,
  sortingKey = null,
  selectedSorting,
  sortingEnabled,
  relevanceAvailable,
  selectedViewMode,
}) => {
  const [facetsExpanded, setFacetExpanded] = useState(false);
  const ref = useRef();
  const facetsToggler = useCallback(() => setFacetExpanded(prev => !prev), []);
  const handleResize = useCallback(() => {
    const className = 'facet-order-last';
    if (ref.current.offsetWidth === ref.current.parentElement.offsetWidth)
      ref.current.parentElement.classList.remove(className);
    else
      ref.current.parentElement.classList.add(className);
  }, []);

  const { xs, sm } = useResponsiveBreakpoints();
  const isMobile = xs || sm;

  const counterInsidePlaceholder = <Placeholder className={styles.counterInsidePlaceholder} />;
  const sortingBlock = sortingEnabled && (
    <div className={`text-right ${styles.sorterWrapper}`} ref={ref}>
      {isMobile && !xs && <ReactResizeDetector handleWidth onResize={handleResize} refreshMode="throttle" refreshRate={250} />}
      <Sorting selectedSorting={selectedSorting} relevanceAvailable={relevanceAvailable} key={sortingKey} />
    </div>
  );
  const mobileFacets = isMobile && hasFacets && (
    <>
      <MobileFacetsToggler expanded={facetsExpanded} toggle={facetsToggler} disabled={!availableFacets.length} />
      <MobileFacets expanded={facetsExpanded} className={styles.facetList} />
    </>
  );

  return (
    <div className={styles.panelHeaderRow}>
      <div className={styles.counterInside}>
        {totalCount}
        {' '}
        <span><SimpleText textKey="ProductList_ProductHeader" placeholder={counterInsidePlaceholder} /></span>
      </div>
      {xs && sortingBlock}
      {xs
        ? (
          <div className={styles.modeAndFilters}>
            {/*viewModeSwitchEnabled && <ViewMode selectedViewMode={selectedViewMode} />*/}
            {mobileFacets}
          </div>
        )
        : (
          <>
            {/*viewModeSwitchEnabled && <ViewMode selectedViewMode={selectedViewMode} />*/}
            {mobileFacets}
          </>
        )
      }
      {!xs && sortingBlock}
    </div>
  );
};

HeaderRow.propTypes = {
  totalCount: PropTypes.number,
  availableFacets: PropTypes.array.isRequired,
  hasFacets: PropTypes.bool,
  viewModeSwitchEnabled: PropTypes.bool,
  sortingKey: PropTypes.string,
  selectedSorting: PropTypes.any,
  sortingEnabled: PropTypes.bool.isRequired,
  relevanceAvailable: PropTypes.any,
  selectedViewMode: PropTypes.any,
};
