import { of } from 'rxjs';
import { visualDesignerLoaded, insiteEditorLoaded } from './actions';
import { visualDesignerHeader, insiteEditorHeader } from './constants';
import { routesBuilder } from 'routes';
import { rewriteTo } from 'behavior/routing';

export default (_, state$, { api }) => {
  api.headers.add(visualDesignerHeader, true);

  const isInsiteEditor = window.location.search.includes('insiteEditor=true');
  const isInsiteEditorLoaded = state$.value.visualDesigner.insiteEditor;

  if (isInsiteEditor)
    api.headers.add(insiteEditorHeader, true);

  if (isInsiteEditor || isInsiteEditorLoaded) {
    const rewriteToHome = rewriteTo({
      ...routesBuilder.forHome(),
      params: { language: state$.value.localization.currentLanguage.id },
    });

    return of({
      action$: isInsiteEditorLoaded
        ? of(rewriteToHome)
        : of(insiteEditorLoaded(), rewriteToHome),
    });
  }

  return of({
    action$: of(visualDesignerLoaded()),
    page: { ...state$.value.page },
  });
};
