import styles from './Banner.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from 'components/primitives/links';
import { ResponsiveLazyImage } from 'components/primitives/responsiveImages';

const Banner = ({ model, fullWidth, isPrintMode }) => {

  if (isPrintMode)
    return null;

  if (!model)
    return null;

  const { imagePath, title, subTitle, buttonText, link } = model;
  const showButton = buttonText && link && link.url;

  return (
    <div className={`${styles.banner}${fullWidth ? ` ${styles.noGutters}` : ''}`}>
      <div className={styles.media}>
        {imagePath && <ResponsiveLazyImage src={imagePath} alt={title || ''} />}
      </div>
      <div className={styles.content}>
        <div className={styles.contentContainer}>
          {title && <h1 className={styles.title}>{title}</h1>}
          {subTitle && <h2 className={styles.subtitle}>{subTitle}</h2>}
          {showButton && <LinkButton {...link} className={btnStyles.btnBig}>{buttonText}</LinkButton>}
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  model: PropTypes.shape({
    content: PropTypes.string,
    imagePath: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    buttonText: PropTypes.string,
    link: PropTypes.object,
  }),
  fullWidth: PropTypes.bool,
  isPrintMode: PropTypes.bool,
};

export default memo(Banner);