import styles from './TextArea.module.scss';
import PropTypes from 'prop-types';

const TextArea = ({ className = '', rows = 2, cols = 20, value, ...attributes }) => (
  <div className={`${styles.tar} ${className}`}>
    <textarea rows={rows} cols={cols} value={value === null ? '' : value} {...attributes} />
  </div>
);

TextArea.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.number,
  cols: PropTypes.number,
  value: PropTypes.any,
};

export default TextArea;