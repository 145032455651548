export default function createLogger() {
  type Logger = {
    console: Console;
    formatter: ((value: any) => any) | undefined;
  }

  let self: Logger;

  return {
    init(console: Console, formatter?: (value: any) => any) {
      self = {
        console,
        formatter,
      };
    },
    error(...errors: any[]) {
      checkLoggerInitialized();

      self.console.error(...format(errors));
    },
    warn(...warnings: any[]) {
      checkLoggerInitialized();

      self.console.warn(...format(warnings));
    },
    log(...messages: any[]) {
      checkLoggerInitialized();

      self.console.log(...format(messages));
    },
  };

  function checkLoggerInitialized() {
    if (!self)
      throw new Error('Logger is not initialized. Please use logger.init(console, formatter) method for initialization.');
  }

  function format(args: any[]) {
    if (!self.formatter)
      return args;

    return args.map(self.formatter);
  }
}