import { pluck } from 'rxjs/operators';
import validateAsync from './validateAsync';

export default context => (value, fieldName, formikContext, { api }) => {
  if (!value)
    return;

  const key = fieldName + '_uniqueOrderTemplate';
  const validationHandler = name => api
    .graphApi(`{validate{uniqueOrderTemplate(name:"${name}")}}`)
    .pipe(pluck('validate', 'uniqueOrderTemplate'));

  return validateAsync(value, context.errorMessage, formikContext, key, validationHandler);
};
