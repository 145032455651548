import styles from './LanguageSelector.module.scss';
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import LanguageSelectorContent from './LanguageSelectorContent';
import { VerticalSliding } from 'components/primitives/transitions';
import { useOnChange } from 'utils/hooks';

const MobileLanguageSelector = ({
  selectedLanguage,
  sortedLanguages,
  alternateUrls,
  handleLoadAlternateUrls,
  isLoading,
}) => {
  const [opened, setOpened] = useState(false);

  const handleOpen = useCallback(e => {
    if (e.type === 'keydown') {
      const isTargetKey = e.key === 'Enter' || e.which === 13;

      if (!isTargetKey)
        return;
    }

    handleLoadAlternateUrls();
    setOpened(!opened);
  }, [opened, alternateUrls]);

  const handleClose = useCallback(() => setOpened(false), []);

  const handleExit = useCallback(node => node.classList.add(styles.collapsing), []);
  const handleExited = useCallback(node => node.classList.remove(styles.collapsing), []);

  useOnChange(() => {
    if (!isLoading)
      return;

    handleClose();
  }, [isLoading], false);

  return (
    <div className={styles.mobileContainer}>
      <div
        tabIndex="0"
        role="button"
        disabled={isLoading || null}
        className={styles.mobileOpener}
        onKeyDown={handleOpen}
        onClick={handleOpen}
        aria-controls="mobileLanguageSelector"
      >
        <Header selectedLanguage={selectedLanguage} isMobile />
        <span className={styles.caret}>
          <FontAwesomeIcon icon={faCaretDown} rotation={opened ? 180 : null} />
        </span>
      </div>
      <VerticalSliding
        id="mobileLanguageSelector"
        containerClass={styles.contentHolder}
        expanded={opened}
        onClick={handleClose}
        onExit={handleExit}
        onExited={handleExited}
      >
        <LanguageSelectorContent
          alternateUrls={alternateUrls}
          sortedLanguages={sortedLanguages}
          selectedLanguage={selectedLanguage}
          isMobile
        />
      </VerticalSliding>
    </div>
  );
};

MobileLanguageSelector.propTypes = {
  selectedLanguage: PropTypes.object,
  sortedLanguages: PropTypes.array,
  alternateUrls: PropTypes.array,
  handleLoadAlternateUrls: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default connect(({ isLoading }) => ({ isLoading }))(MobileLanguageSelector);