import styles from '../OrderBox.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PropTypes from 'prop-types';
import { Placeholder } from 'components/primitives/placeholders';
import { SimpleText } from 'components/sanaText';
import QuantityBox from './QuantityBox';
import VariantsButton from 'components/objects/productList/b2b/VariantsButton';
import ViewProductButton from '../b2c/ViewProductButton';

const OrderBox = ({
  product,
  className = '',
  abilities,
  gridMode,
  isVolumeOrder,
}) => {
  const {
    isOrderable,
    hasVariants,
    productConfiguratorInfo,
  } = product;

  let content = null;
  const placeholder = <Placeholder className="placeholder" />;

  if (isOrderable == null) {
    content = placeholder;
  } else if (isOrderable === false) {
    const isSimpleProduct = !hasVariants && !productConfiguratorInfo.isProductConfigurable;
    content = (
      <>
        <span className="msg-not-available">
          <SimpleText textKey="Product_NotAvailable" placeholder={placeholder} />
        </span>
        {isSimpleProduct && <QuantityBox abilities={abilities} product={product} gridMode={gridMode} />}
      </>
    );
  } else if (productConfiguratorInfo.isProductConfigurable) {
    content = (
      <ViewProductButton
        textKey="ConfigureProduct"
        className={`${btnStyles.btnSmall} btn-configure`}
        titleTextKey="ConfigureProduct_ListPage"
        product={product}
        placeholder={placeholder}
      />
    );
  } else if (hasVariants) {
    content = <VariantsButton abilities={abilities} product={product} />;
  } else if (!isVolumeOrder) {
    content = <QuantityBox abilities={abilities} product={product} gridMode={gridMode} />;
  }

  return (
    <div className={`${styles.orderBox} ${className}`}>
      {content}
    </div>
  );
};

OrderBox.propTypes = {
  product: PropTypes.shape({
    isOrderable: PropTypes.bool,
    hasVariants: PropTypes.bool,
    productConfiguratorInfo: PropTypes.object,
  }),
  className: PropTypes.string,
  abilities: PropTypes.object.isRequired,
  gridMode: PropTypes.bool,
  isVolumeOrder: PropTypes.bool,
};

export default OrderBox;
