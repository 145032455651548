export const PAY_ORDER = 'PAY_ORDER/SUBMIT';
export const payOrder = (transactionId, paymentInput, additionalCustomerData, extraPaymentData) => ({
  type: PAY_ORDER,
  payload: { transactionId, paymentInput, additionalCustomerData, extraPaymentData },
});

export const PAY_ORDER_RESULT_RECEIVED = 'PAY_ORDER/RESULT_RECEIVED';
export const receivePayOrderResult = result => ({
  type: PAY_ORDER_RESULT_RECEIVED,
  payload: { result },
});

export const PAYMENT_METHOD_EXTRA_DATA_REQUESTED = 'ORDER_PAYMENT/PAYMENT_METHOD_EXTRA_DATA/REQUESTED';
export const requestPaymentMethodExtraData = (transactionId, paymentMethodId) => ({
  type: PAYMENT_METHOD_EXTRA_DATA_REQUESTED,
  payload: { transactionId, paymentMethodId },
});

export const PAYMENT_METHOD_EXTRA_DATA_RECEIVED = 'ORDER_PAYMENT/ADDITIONAL_CUSTOMER_DATA/RECEIVED';
export const receivePaymentMethodExtraData = (paymentMethodId, additionalCustomerData, extraPaymentData) => ({
  type: PAYMENT_METHOD_EXTRA_DATA_RECEIVED,
  payload: { paymentMethodId, additionalCustomerData, extraPaymentData },
});