import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import { useEffectOnChange } from 'utils/hooks';
import { useCountryField } from '../hooks';
import { FormGroup } from 'components/objects/forms/formGroups';
import { TextBoxField } from 'components/objects/forms/fields';

const StreetNoFormGroup = ({ fieldName, availableCountryIds, ...props }) => {
  const countryField = useCountryField();
  const countryFieldName = countryField?.name;
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const selectedCountryId = countryFieldName ? getIn(values, countryFieldName) : null;

  useEffectOnChange(() => {
    if (isAvailable(selectedCountryId, availableCountryIds))
      return;

    setFieldValue(fieldName, undefined, false);
    setFieldTouched(fieldName, false, false);
  }, [fieldName, selectedCountryId, availableCountryIds]);

  if (!isAvailable(selectedCountryId, availableCountryIds))
    return null;

  return <FormGroup fieldName={fieldName} fieldComponent={TextBoxField} {...props} />;
};

StreetNoFormGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
  availableCountryIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default StreetNoFormGroup;

function isAvailable(selectedCountryId, availableCountryIds) {
  return !availableCountryIds.length || selectedCountryId && availableCountryIds.includes(selectedCountryId);
}