import { timer, Observable } from 'rxjs';
import { bufferWhen, map, exhaustMap, first, share } from 'rxjs/operators';

export default function bufferBatchForLoading<T>(until$: Observable<unknown> | undefined) {
  return (source$: Observable<T>) => {
    const sharedSource$ = source$.pipe(
      share(),
    );

    return sharedSource$.pipe(
      exhaustMap(firstAction => sharedSource$.pipe(
        bufferWhen(() => until$ ? until$ : timer(10)),
        map(buffered => [firstAction, ...buffered]),
        first(),
      )),
    );
  };
}
