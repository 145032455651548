import PropTypes from 'prop-types';
import { useBooleanOptions } from '../../fields/hooks';
import RadioField from './RadioField';
import hoistStatics from 'hoist-non-react-statics';
import validationFieldPropType from './validationFieldPropType';

const BooleanRadioField = hoistStatics(
  ({
    fieldName,
    fieldTitle,
    validation,
    required,
    className,
    disabled,
  }) => {
    const items = useBooleanOptions(fieldName);

    return (
      <RadioField
        fieldName={fieldName}
        fieldTitle={fieldTitle}
        validation={validation}
        required={required}
        items={items}
        className={className}
        disabled={disabled}
      />
    );
  },
  RadioField,
);

BooleanRadioField.propTypes = {
  ...RadioField.propTypes,
  required: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  validation: validationFieldPropType,
};

export default BooleanRadioField;
