import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { SanaButton } from 'components/primitives/buttons';
import { createQuantityModel } from 'components/primitives/product';
import { addProducts } from 'behavior/basket';
import { generateKey } from 'utils/helpers';

const AddProductButton = ({
  productId,
  uom,
  className,
  placeholder,
}) => {
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const { basketModifiedDate, basketUpdatedById } = useSelector(({ basket }) => ({
    basketModifiedDate: basket.modifiedDate,
    basketUpdatedById: basket.updated.updaterId,
  }), shallowEqual);
  const [componentId] = useState(generateKey);

  const addToBasket = useCallback(() => {
    if (disabled)
      return;

    const quantity = createQuantityModel(uom).initial;
    const uomId = uom && uom.id;

    setDisabled(true);
    dispatch(addProducts([{ productId, uomId, quantity }], componentId));
  }, [productId, uom]);

  useEffect(() => {
    if (basketUpdatedById === componentId)
      setDisabled(false);
  }, [basketModifiedDate, basketUpdatedById]);

  return (
    <SanaButton
      className={className}
      textKey="AddToBasket"
      onClick={addToBasket}
      placeholder={placeholder}
      aria-disabled={disabled}
    />
  );
};

AddProductButton.propTypes = {
  productId: PropTypes.string.isRequired,
  uom: PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantityStep: PropTypes.number,
    minimumQuantity: PropTypes.number,
    defaultQuantity: PropTypes.number,
  }),
  className: PropTypes.string,
  placeholder: PropTypes.node,
};

export default AddProductButton;