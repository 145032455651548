import styles from '../BasketFooter.module.scss';
import PropTypes from 'prop-types';
import TotalsLine from './TotalsLine';
import ShippingCostLabel from '../../ShippingCostLabel';

const ShippingLine = ({ shippingCost, labelOnly }) => {
  if (!shippingCost || !shippingCost.price)
    return null;

  const shippingLabel = <ShippingCostLabel labelOnly={labelOnly} />;
  return (
    <TotalsLine
      className={styles.shippingCost}
      label={shippingLabel}
      value={shippingCost.price}
    />
  );
};

ShippingLine.propTypes = {
  shippingCost: PropTypes.shape({ price: PropTypes.number.isRequired }),
  labelOnly: PropTypes.bool,
};

export default ShippingLine;
