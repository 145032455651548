import styles from '../Basket.module.scss';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useConfirmation from 'components/objects/confirmation';
import Message from './ExpirationMessage';
import { reloadBasketPage } from 'behavior/basket';

export function useExpirationMessage(pendingChanges, resetPendingChanges, modelExpired, modifiedDate, isB2BPreset) {
  const dispatch = useDispatch();
  const { showConfirmation, clearConfirmation, confirmationModal } = useConfirmation(dispatch);

  useEffect(() => {
    if (!modelExpired)
      return;

    const update = () => {
      dispatch(reloadBasketPage(!isB2BPreset));
      if (pendingChanges)
        resetPendingChanges();
    };
    showConfirmation(
      update,
      'PageExpired',
      <Message modifiedDate={modifiedDate} pendingChanges={pendingChanges} />,
      {
        okText: 'ButtonText_Refresh',
      },
      undefined,
      styles.expirationMsgPopup,
    );

    return clearConfirmation;
  }, [modelExpired && modifiedDate]);

  return confirmationModal;
}