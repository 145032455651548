import styles from './Spinner.module.scss';
import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';

const Spinner = forwardRef(({ className = '' }, ref) => (
  <span ref={ref} className={`${styles.spinner} ${className}`} />
));

Spinner.propTypes = {
  className: PropTypes.string,
};

export default memo(Spinner);