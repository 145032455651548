import { normalizeBounds, strip } from './helpers';

const defaultUom = {
  quantityStep: 1,
  maximumQuantity: 99999,
};

export const createQuantityModel = (uom, allowEmpty) => {
  const {
    quantityStep,
    minimumQuantity,
    maximumQuantity,
    defaultQuantity,
  } = uom || defaultUom;

  const step = quantityStep || defaultUom.quantityStep;
  const minimum = minimumQuantity && minimumQuantity > step
    ? minimumQuantity
    : step;
  const maximum = maximumQuantity || defaultUom.maximumQuantity;

  let initial = normalizeBounds(defaultQuantity || minimum, minimum, maximum);
  initial = strip(Math.round(initial / step) * step);

  return { step, minimum, maximum, initial, allowEmpty };
};