import { USER_EDIT_PROFILE } from './actions';
import { editProfileMutation } from './queries';
import { switchMap, mergeMap, startWith } from 'rxjs/operators';
import { of } from 'rxjs';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { unlockForm, FormLockKeys } from 'behavior/pages';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import { createUserData } from 'behavior/user/helpers';
import { userProfileUpdated } from 'behavior/user';
import { ofType } from 'redux-observable';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { RouteName } from 'routes';

const setLoading = setLoadingIndicator();
const unsetLoading = unsetLoadingIndicator();

export default function editProfileEpic(action$, state$, { api, logger }) {
  return action$.pipe(
    ofType(USER_EDIT_PROFILE),
    switchMap(action => api.graphApi(editProfileMutation, { input: action.payload }).pipe(
      mergeMap(({ viewer }) => {
        const user = createUserData(viewer, true);
        let backTo = state$.value.page.backTo;
        if (backTo?.routeData.routeName !== RouteName.Checkout)
          backTo = undefined;

        return of(
          userProfileUpdated(user),
          backTo
            ? navigateTo(backTo.routeData, backTo.url)
            : navigateTo(routesBuilder.forMyAccount()),
        );
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(userProfileUpdated({ invalidInput: true }), unlockForm(FormLockKeys.EditProfile), unsetLoading)),
      retryWithToast(action$, logger, _ => of(userProfileUpdated({}), unlockForm(FormLockKeys.EditProfile), unsetLoading)),
      startWith(setLoading),
    )),
  );
}
