import { merge, pipe } from 'rxjs';
import { ignoreElements, pluck, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
  ANALYTICS_PRODUCT_CLICKED,
  ANALYTICS_PRODUCT_DETAILS_VIEWED,
  ANALYTICS_PRODUCT_LIST_VIEWED,
  ANALYTICS_PRODUCTS_ADDED_TO_BASKET,
  ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET,
  ANALYTICS_CHECKOUT,
  ANALYTICS_CHECKOUT_OPTION,
  ANALYTICS_PURCHASE,
} from './actions';
import { pushToDataLayer } from './dataLayer';
import {
  createProductClickPayload,
  createProductDetailsViewPayload,
  createProductListViewPayload,
  createProductsAddToBasketPayload,
  createProductsRemoveFromBasketPayload,
  createCheckoutPayload,
  createCheckoutOptionPayload,
  createPurchasePayload,
} from './payload';

const push = (state$, type, createPayload) => pipe(
  ofType(type),
  pluck('payload'),
  map(payload => extendData(payload, state$)),
  pushToDataLayer(state$, createPayload),
  ignoreElements(),
);

export default (action$, state$, _) => {
  return merge(
    action$.pipe(
      push(state$, ANALYTICS_PRODUCT_CLICKED, createProductClickPayload),
    ),
    action$.pipe(
      push(state$, ANALYTICS_PRODUCT_DETAILS_VIEWED, createProductDetailsViewPayload),
    ),
    action$.pipe(
      push(state$, ANALYTICS_PRODUCT_LIST_VIEWED, createProductListViewPayload),
    ),
    action$.pipe(
      push(state$, ANALYTICS_PRODUCTS_ADDED_TO_BASKET, createProductsAddToBasketPayload),
    ),
    action$.pipe(
      push(state$, ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET, createProductsRemoveFromBasketPayload),
    ),
    action$.pipe(
      push(state$, ANALYTICS_CHECKOUT, createCheckoutPayload),
    ),
    action$.pipe(
      push(state$, ANALYTICS_CHECKOUT_OPTION, createCheckoutOptionPayload),
    ),
    action$.pipe(
      push(state$, ANALYTICS_PURCHASE, createPurchasePayload),
    ),
  );
};

export const extendData = (data, state$) => {
  if (!state$.value.user)
    return data;

  return {
    ...data,
    customerType: state$.value.user.customerType,
    currencyCode: state$.value.user.currencyId,
  };
};
