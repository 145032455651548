import { loadEditProfilePageQuery } from './queries';
import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import { getBackTo } from '../helpers';
import { RouteName } from 'routes';
import { initSystemPageContent } from '../system';

export default (routeData, state$, { api }) => {
  return api.graphApi(loadEditProfilePageQuery).pipe(
    map(({ pages, entityTemplateFields, viewer }) => {
      const page = pages.editProfile;
      if (!page)
        return null;

      page.templateFields = viewer.customerData && initializeTemplateFields(entityTemplateFields, viewer.customerData);
      page.component = PageComponentNames.EditProfile;

      page.backTo = getBackTo(state$, [
        RouteName.EditProfile,
      ], routeData.params && routeData.params.language);

      return { page };
    }),
    initSystemPageContent(),
  );
};

const initializeTemplateFields = (templateFields, customerData) => (
  templateFields.reduce((initialized, field) => {
    if (isEmptyReadOnlyField(field, customerData[field.name]))
      return initialized;

    if (field.displayValue !== undefined) {
      initialized.push({
        ...field,
        displayValue: customerData[field.name],
      });
      return initialized;
    }

    initialized.push({
      ...field,
      defaultValue: customerData[field.name],
    });

    return initialized;
  }, [])
);

const isEmptyReadOnlyField = (field, fieldName) => {
  return field.type === 'ReadOnlyTemplateField' && !fieldName;
};
