import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CustomerDataStepForm } from 'components/objects/checkout/base/payment';
import { RichText } from 'components/sanaText';

const AdditionalCustomerDataForm = ({ additionalCustomerData, formRef }) => {
  useEffect(() => {
    if (!additionalCustomerData)
      formRef.current = null;
  }, [additionalCustomerData]);

  if (!additionalCustomerData)
    return null;

  return (
    <>
      <h2>
        <RichText textKey="PleaseCheckInformationForPaymentProvider" />
      </h2>
      <CustomerDataStepForm customerDataStep={additionalCustomerData} formRef={formRef} />
    </>
  );
};

AdditionalCustomerDataForm.propTypes = {
  additionalCustomerData: PropTypes.object,
  formRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
};

export default memo(AdditionalCustomerDataForm);
