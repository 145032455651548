import { memo } from 'react';
import PropTypes from 'prop-types';
import defaultComponents from './defaultComponents';
import AddonContentBlock from './AddonContentBlock';
import StyleWrapper from './StyleWrapper';
import { ContentHorizontalAlignment } from './constants';

const ContentBlock = props => {
  const {
    packId,
    name: componentName,
    horizontalAlignment,
    border,
    spacing,
    minHeight,
    stretchHeight,
    ...componentProps
  } = props;

  const wrapperProps = {
    border,
    spacing,
    minHeight,
    horizontalAlignment,
    stretchHeight,
  };

  const sharedStyleProps = {
    horizontalAlignment,
  };

  if (packId)
    return (
      <StyleWrapper {...wrapperProps}>
        <AddonContentBlock
          addonId={packId}
          componentName={componentName}
          styleWrapperProps={sharedStyleProps}
          {...componentProps}
        />
      </StyleWrapper>
    );

  const Component = defaultComponents[componentName];
  if (!Component)
    return null;

  return (
    <StyleWrapper {...wrapperProps}>
      <Component sharedStyleProps={sharedStyleProps} {...componentProps} />
    </StyleWrapper>
  );
};

ContentBlock.propTypes = {
  packId: PropTypes.string,
  name: PropTypes.string.isRequired,
  border: PropTypes.shape({
    width: PropTypes.string,
    style: PropTypes.string,
    color: PropTypes.string,
    radius: PropTypes.string,
  }).isRequired,
  spacing: PropTypes.shape({
    margin: PropTypes.string,
    padding: PropTypes.string,
  }),
  minHeight: PropTypes.shape({
    mobile: PropTypes.string,
    tablet: PropTypes.string,
    desktop: PropTypes.string,
  }).isRequired,
  horizontalAlignment: PropTypes.oneOf(Object.keys(ContentHorizontalAlignment)),
  stretchHeight: PropTypes.bool,
};

export default memo(ContentBlock);
