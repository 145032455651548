import styles from '../Paging.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PropTypes from 'prop-types';

const ArrowLink = ({ index, title, className = '', rel, lastIndex, getUrl, onClick }) => {
  const disabled = index > lastIndex || index < 0;
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href={disabled ? null : getUrl(index)}
      title={title}
      className={`${btnStyles.btnBase} ${styles.arrow} ${className}`}
      rel={rel}
      onClick={disabled ? null : onClick.bind(this, index)}
      aria-disabled={disabled || null}
    >
      <span className="visually-hidden">{title}</span>
    </a>
  );
};

ArrowLink.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  rel: PropTypes.string,
  lastIndex: PropTypes.number.isRequired,
  getUrl: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ArrowLink;