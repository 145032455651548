import styles from './Header.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';

const CustomerServicePage = ({ customerServicePage }) => {
  if (customerServicePage == null)
    return null;

  return (
    <Link to={customerServicePage.to} url={customerServicePage.url} className={styles.customerServiceLink}>
      <SimpleText textKey="CustomerService" />
    </Link>
  );
};

CustomerServicePage.propTypes = {
  customerServicePage: PropTypes.shape({
    to: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
  }),
};

export default connect(
  ({ settings: { customerServicePage } }) => ({ customerServicePage }),
)(CustomerServicePage);