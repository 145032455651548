import type { LocalStorage } from './types';

function createServerStorage(): LocalStorage {
  const storage = new Map();
  return {
    getItem(key) {
      return storage.get(key);
    },
    setItem(key, value) {
      storage.set(key, value);
    },
    removeItem(key) {
      storage.delete(key);
    },
  };
}

export default createServerStorage;
