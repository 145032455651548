import { APPLIED_AGREEMENT_RECEIVED } from './actions';

import { createReducer } from 'utils/redux';

export default createReducer({}, {
  [APPLIED_AGREEMENT_RECEIVED]: salesAgreementsReducer,
});

function salesAgreementsReducer(state, action) {
  return { ...state, appliedSalesAgreementId: action.payload.salesAgreementId };
}