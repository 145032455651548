import { Steps } from './constants';
import { trackCheckoutOption } from 'behavior/analytics/actions';

const stepsNames = Object.values(Steps);

export const getStepNumber = step => stepsNames.indexOf(step) + 1;

export const trackStepOption = (step, info) => {
  switch (step) {
    case Steps.Address:
      return trackCheckoutOption(getStepNumber(step), info.shippingAddress?.shippingOption);
    case Steps.Shipping:
      const shippingMethod = getMethod(info.shippingMethods, info.shippingMethodId);
      return shippingMethod && trackCheckoutOption(getStepNumber(step), shippingMethod);
    case Steps.Payment:
      const paymentMethod = getMethod(info.paymentMethods, info.paymentMethodId);
      return paymentMethod && trackCheckoutOption(getStepNumber(step), paymentMethod);
    default:
      return null;
  }
};

export const getMethod = (methods, methodId) => {
  const method = methods &&
  methods.find(method => method.id === methodId);
  return method && method.name;
};