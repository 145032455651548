import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import { TextBoxField as TextBoxFieldCore } from '../../fields';
import validationFieldPropType from './validationFieldPropType';

const TextBoxField = hoistStatics(
  ({
    fieldName,
    fieldTitle,
    validation,
    required,
    type = 'text',
    className,
    disabled,
    maxLength,
    placeholder,
  }) => (
      <TextBoxFieldCore
        fieldName={fieldName}
        fieldTitle={fieldTitle}
        validation={validation}
        required={required}
        type={type}
        className={className}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
      />
    ),
  TextBoxFieldCore,
);

TextBoxField.propTypes = {
  ...TextBoxFieldCore.propTypes,
  required: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  validation: validationFieldPropType,
};

export default TextBoxField;
