import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ShopAccountTypes } from 'behavior/user';
import LoginInfoAnonymous from './LoginInfoAnonymous';
import LoginInfoCustomer from './LoginInfoCustomer';
import { LoginInfoImpersonated, LoginInfoSalesAgent } from './salesAgent';

const LoginInfoItems = ({ isAuthenticated, isImpersonating, shopAccountType, logoutDelay }) => {
  if (!isAuthenticated)
    return <LoginInfoAnonymous />;

  if (shopAccountType !== ShopAccountTypes.SalesAgent)
    return <LoginInfoCustomer logoutDelay={logoutDelay} />;

  if (isImpersonating)
    return <LoginInfoImpersonated logoutDelay={logoutDelay} />;

  return <LoginInfoSalesAgent logoutDelay={logoutDelay} />;
};

LoginInfoItems.propTypes = {
  isAuthenticated: PropTypes.bool,
  isImpersonating: PropTypes.bool,
  shopAccountType: PropTypes.string,
  logoutDelay: PropTypes.number,
};

export default connect(
  ({ user: { isAuthenticated, isImpersonating, shopAccountType } }) => ({ isAuthenticated, isImpersonating, shopAccountType }),
)(LoginInfoItems);