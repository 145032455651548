import type { ReactNode } from 'react';
import AddonContext from './AddonContext';
import type { Registry } from './registry';

type Props = {
  registry: Registry;
  children: ReactNode;
};

const AddonProvider = ({ registry, children }: Props) => (
  <AddonContext.Provider value={{ registry }}>
    {children}
  </AddonContext.Provider>
);

export default AddonProvider;
