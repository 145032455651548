import styles from './ProductComparisonRating.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import RatingDisplay from './RatingDisplay';
import { useProductRoute } from 'components/objects/productComparison/hooks';

const RatingCell = ({ product: { id, reviews, url } }) => {
  const route = useProductRoute(id);
  return (
    <div className={styles.fieldValue}>
      <RatingDisplay reviews={reviews} to={route} url={url} />
    </div>
  );
};

RatingCell.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    reviews: PropTypes.object,
    url: PropTypes.string,
  }).isRequired,
};

export default memo(RatingCell, (prev, next) => prev.product.id === next.product.id);