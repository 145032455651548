import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from './hooks';
import { makeRichText } from 'utils/render';

const RichText = ({
  textKey,
  children,
  formatWith,
  placeholder = null,
  disableInsiteEditor = false,
}) => {
  const options = [{ key: textKey, fallback: children }];
  options.disableInsiteEditor = disableInsiteEditor;

  const { texts: [text], loaded } = useSanaTexts(options);

  if (!loaded)
    return placeholder;

  return makeRichText(text, formatWith);
};

RichText.propTypes = {
  textKey: PropTypes.string.isRequired,
  formatWith: PropTypes.array,
  children: PropTypes.node,
  placeholder: PropTypes.node,
  disableInsiteEditor: PropTypes.bool,
};

export default memo(RichText);