import { memoize } from './helpers';
import { arrayToObject } from 'utils/helpers';
import type { DefaultRootState } from 'react-redux';
import type { AddonAppContext } from '../types';

export type SectionName = keyof AddonAppContext;

const builders = {
  language: memoize((state: DefaultRootState) => {
    const { id, cultureName } = state.localization.currentLanguage;
    return { id, cultureName };
  }),
  user: memoize((state: DefaultRootState) => {
    const { isAuthenticated, currencyId } = state.user;
    return { isAuthenticated, currencyId };
  }),
};

const allSections = Object.keys(builders) as SectionName[];

export const makeAppContext = (state: DefaultRootState, sections?: SectionName[]): AddonAppContext => {
  return arrayToObject(
    sections || allSections,
    s => s,
    s => s in builders ? builders[s](state) : undefined,
  ) as AddonAppContext;
};
