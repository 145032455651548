import styles from './SubAccount.module.scss';
import modalStyles from 'components/objects/modals/Modal.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { SimpleText, RichText } from 'components/sanaText';
import { Modal } from 'components/objects/modals';
import { SanaButton } from 'components/primitives/buttons';

const SubAccountRemovalFailureModal = ({ opened, hide, removalInfo }) => {
  if (!removalInfo)
    return null;

  const { dependentAccounts, authorizationsCount } = removalInfo;
  return (
    <Modal hide={hide} opened={opened} className={styles.failurePopup}>
      <h2 className={`h3 ${modalStyles.title}`}>
        <SimpleText textKey="SubAccounts_CannotRemoveSubAccountMessageTitle" />
      </h2>
      {dependentAccounts && (
        <>
          <div>
            <RichText textKey="SubAccounts_CantRemoveAuthorizerRole_DependenciesFound" formatWith={[dependentAccounts.length]} />
          </div>
          <ul className={styles.failureModalDependencies}>
            {dependentAccounts.map(({ name }, index) => <li key={index}>{name}</li>)}
          </ul>
        </>
      )}
      {!!authorizationsCount && (
        <div>
          <RichText textKey="SubAccounts_CantRemoveAuthorizerRole_AuthorizationsFound" formatWith={[authorizationsCount]} />
        </div>
      )}
      <div className={styles.failureModalFooter}>
        <SanaButton textKey="Ok" className={`${btnStyles.btn} ${btnStyles.btnExtraSmall} ${btnStyles.noIcon}`} onClick={hide} />
      </div>
    </Modal>
  );
};

SubAccountRemovalFailureModal.propTypes = {
  opened: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  removalInfo: PropTypes.shape({
    dependentAccounts: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
    authorizationsCount: PropTypes.number,
  }),
};

export default memo(SubAccountRemovalFailureModal);
