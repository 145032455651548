import { memo, useEffect } from 'react';
import { renderHTML } from 'utils/render';
import { useDispatch } from 'react-redux';
import { initializeVolumeOrder } from 'behavior/volumeOrder';
import { useSimpleTexts } from 'components/sanaText';
import { getFormatPrice } from 'utils/format';
import { useCurrencyInfo } from 'utils/hooks';
import PropTypes from 'prop-types';

const VolumeOrderSummary = ({ 
  lowerThreshold, 
  upperThreshold, 
  currentPriceLevel, 
  toNextPriceLevel,
  orderValue,
  totalDiscount,
  totalDiscountPercent,
  rrpOrderValue,
}) => {

  const dispatch = useDispatch();
  const currencyInfo = useCurrencyInfo();
  const formatPrice = currencyInfo && getFormatPrice(currencyInfo);
  const formatAsPrice = (value, ci) => {
    return formatPrice(value, ci).replace(' ', '');
  };


  useEffect(() => {
    dispatch(initializeVolumeOrder());
  }, []);

  const [
    volumeOrderSummaryTextTemplate,
    volumeOrderSummaryCurrentOrderValueTemplate,
    volumeOrderSummaryNextPricingLevelTemplate,
    volumeOrderSummaryCurrentPricingLevelTemplate,
    volumeOrderTotalDiscount,
    volumeOrderTotalDiscountPercent,
    rrpOrderValueTemplate,
   ] = useSimpleTexts([
     'VolumeOrders_Summary_SummaryText',
     'VolumeOrders_Summary_CurrentOrderValue_Template',
     'VolumeOrders_Summary_NextPricingLevel_Template',
     'VolumeOrders_Summary_CurrentPricingLevel_Template',
     'VolumeOrder_TotalDiscount',
     'VolumeOrder_TotalDiscountPercent',
     'VolumeOrders_Summary_RRPOrderValue_Template',
    ]).texts;

  const volumeOrderSummaryTextValue = volumeOrderSummaryTextTemplate
    ?.replace('[LOWERTHRESHOLD]', formatAsPrice(lowerThreshold, currencyInfo))
    .replace('[UPPERTHRESHOLD]', formatAsPrice(upperThreshold, currencyInfo));

  const currentOrderDiscountedText = `${volumeOrderSummaryCurrentOrderValueTemplate} ${formatAsPrice(orderValue, currencyInfo)}`;
  const currentOrderRRPText = `${rrpOrderValueTemplate} ${formatAsPrice(rrpOrderValue, currencyInfo)}`;

  const currentPriceLevelText = `${volumeOrderSummaryCurrentPricingLevelTemplate} ${currentPriceLevel}`;

  const nextPriceLevelText = `${volumeOrderSummaryNextPricingLevelTemplate} ${formatAsPrice(toNextPriceLevel, currencyInfo)}`;

  const totalDiscountText = `${volumeOrderTotalDiscount}: ${formatAsPrice(totalDiscount, currencyInfo)}`;
  const totalDiscountPercentText = `${volumeOrderTotalDiscountPercent}: ${totalDiscountPercent.toFixed(2)}`;

  return (
    <div className="volumeOrderSummary">
      <span>{renderHTML(volumeOrderSummaryTextValue)}</span>
      <div>
        {renderHTML(currentOrderRRPText)}
      </div>
      {currentPriceLevel!=='' && <div>
        {renderHTML(currentOrderDiscountedText)}
      </div>}
      {currentPriceLevel!=='' && 
        <div>
          {renderHTML(currentPriceLevelText)}
        </div>}
      {toNextPriceLevel!==0 &&
        <div>
          {renderHTML(nextPriceLevelText)}
        </div>}
      {currentPriceLevel!=='' &&
        <div>
          <span>{renderHTML(totalDiscountText)}</span>
        </div>}
      {currentPriceLevel!=='' &&
        <div>
          <span>{renderHTML(totalDiscountPercentText)}</span>
        </div>}
    </div>
  );
};

VolumeOrderSummary.propTypes = {
  lowerThreshold: PropTypes.number,
  upperThreshold: PropTypes.number,
  currentPriceLevel: PropTypes.string,
  toNextPriceLevel: PropTypes.number,
  orderValue: PropTypes.number,
  totalDiscount: PropTypes.number,
  totalDiscountPercent: PropTypes.number,
  rrpOrderValue: PropTypes.number,
};

const memoizedVolumeOrderSummary = memo(VolumeOrderSummary);

export default memoizedVolumeOrderSummary;