import { createReducer } from 'utils/redux';
import { ANALYTICS_TRACKERS_INITIALIZE } from './actions';
import { trackerFactory } from './trackers';

const initialState = {
  isTrackingEnabled: false,
};

export default createReducer(initialState, {
  [ANALYTICS_TRACKERS_INITIALIZE]: onTrackersInitialize,
});

function onTrackersInitialize(state, action) {
  if (state.isTrackingEnabled)
    return state;

  const { payload } = action;

  if (!payload)
    return state;

  const trackers = trackerFactory(payload);
  if (!trackers || !trackers.length)
    return state;

  return {
    ...state,
    isTrackingEnabled: true,
    trackers,
  };
}