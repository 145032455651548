import styles from './Reviews.module.scss';
import pdpStyles from '../Details.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import { HashRoutingContext } from 'components/hash';
import { useSanaButton } from 'components/primitives/buttons';
import { Placeholder } from 'components/primitives/placeholders';

const AddReviewBtn = ({ navigateTo }) => {
  const { text, loaded } = useSanaButton('WriteReview');
  if (!loaded)
    return <Placeholder className={`${pdpStyles.reviewsBtnPlaceholder} ${styles.reviewsBtn}`} />;

  const onClick = e => {
    e.preventDefault();
    navigateTo('#reviews_add', { extraTopOffset: 60, time: 400 });
  };

  return (
    <a href="#reviews_add"
      onClick={onClick}
      className={`${linkStyles.link} ${styles.reviewsBtn}`}
    >
      {text}
    </a>
  );
};

AddReviewBtn.propTypes = {
  navigateTo: PropTypes.func.isRequired,
};

export default connectToContext(
  [HashRoutingContext],
  ({ navigateTo }) => ({ navigateTo }),
)(memo(AddReviewBtn));