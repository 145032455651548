import { useSelector, useDispatch } from 'react-redux';
import { WarningAlert } from 'components/primitives/alerts';
import { SimpleText } from 'components/sanaText';
import { SanaButton } from 'components/primitives/buttons';
import { Modal, useModal } from 'components/objects/modals';
import modalStyles from 'components/objects/modals/Modal.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { clearBasket } from 'behavior/basket/actions';
import { initializeVolumeOrder } from 'behavior/volumeOrder';

const VolumeOrderInProgressWarning = () => {
  const { isVolumeOrder }  = useSelector(s => s.basket);
  const { opened, show, hide } = useModal();
  const dispatch = useDispatch();

  if (!isVolumeOrder)
  {
    return null;
  }

  const handleCancelVolumeOrderClick = () => {
    show();
  };

  const handleCancelConfirmClick = () => {
    dispatch(clearBasket(true));
    dispatch(initializeVolumeOrder());
  };

  return (
    <>
      <WarningAlert>
        <SimpleText textKey="VolumeOrderInProgressWarning" />
        <SanaButton textKey="CancelVolumeOrder" onClick={handleCancelVolumeOrderClick} />
      </WarningAlert> 
      <Modal
        opened={opened}
        hide={hide}
        className="cancel-volume-order-modal"
      >
        <h2 className={`h3 ${modalStyles.title}`} className="cancel-volume-order-title">
          <SimpleText textKey="CancelVolumeOrderConfirmationTitle" />
        </h2>
        <div className={modalStyles.content} className="cancel-volume-order-content">
          <SimpleText textKey="CancelVolumeOrderConfirmation" />
        </div>
        <div className="cancel-volume-order-footer">
          <SanaButton textKey="Cancel" className={`${btnStyles.btn} ${btnStyles.noIcon}`} onClick={hide} />
          <SanaButton textKey="OK" className={`${btnStyles.btn} ${btnStyles.noIcon}`} onClick={handleCancelConfirmClick} />
        </div>
      </Modal>
    </>
  );

};

export default VolumeOrderInProgressWarning;