import PropTypes from 'prop-types';

const SuggestionsContainer = ({ containerProps, children }) => (
  <div {...containerProps}>
    {children}
  </div>
);

SuggestionsContainer.propTypes = {
  containerProps: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default SuggestionsContainer;
