import { createElement } from 'react';

import BooleanDropdownField from '../fields/BooleanDropdownField';
import BooleanRadioField from '../fields/BooleanRadioField';
import CheckboxField from '../fields/CheckboxField';
import DateDropdownField from '../fields/DateDropdownField';
import DatePickerField from '../fields/DatePickerField';
import DecimalField from '../fields/DecimalField';
import DropdownField from '../fields/DropdownField';
import IntegerField from '../fields/IntegerField';
import RadioField from '../fields/RadioField';
import TextAreaField from '../fields/TextAreaField';
import TextBoxField from '../fields/TextBoxField';
import GenderField from './fields/GenderField';
import ZipCodeField from './fields/ZipCodeField';

export default function (field, validation) {
  const props = {
    fieldName: field.name,
    fieldTitle: field.title,
    required: field.isRequired,
    validation,
  };

  switch (field.type) {
    case 'Text':
    case 'String':
      props.maxLength = field.maxLength;
      return createElement(TextBoxField, props);
    case 'Password':
      props.type = 'password';
      props.maxLength = field.maxLength;
      return createElement(TextBoxField, props);
    case 'MultilineText':
      props.maxLength = field.maxLength;
      return createElement(TextAreaField, props);
    case 'Date':
      return createElement(DatePickerField, props);
    case 'DateDropdown':
      return createElement(DateDropdownField, props);
    case 'Boolean':
      return createElement(CheckboxField, props);
    case 'BooleanDropdown':
      return createElement(BooleanDropdownField, props);
    case 'BooleanRadio':
      return createElement(BooleanRadioField, props);
    case 'Int32':
      return createElement(IntegerField, props);
    case 'Int64':
    case 'Decimal':
    case 'Double':
    case 'Single':
      return createElement(DecimalField, props);
    case 'Enum':
    case 'DropDown':
      props.items = convertEnumMembers(field.enumMembers);
      return createElement(DropdownField, props);
    case 'RadioButtons':
      props.items = convertEnumMembers(field.enumMembers);
      return createElement(RadioField, props);
    case 'Gender':
      return createElement(GenderField, props);
    case 'Country':
      props.items = convertCountries(field.additionalData.countries);
      return createElement(DropdownField, props);
    case 'ZipCode':
      props.countryField = field.additionalData.countryField;
      props.perCountryPatterns = field.additionalData.perCountryPatterns;
      return createElement(ZipCodeField, props);
    default:
      return null;
  }
}

function convertEnumMembers(enumMembers) {
  return enumMembers.map(({ title: name, value }) => ({ name, value }));
}

function convertCountries(countries) {
  return countries.map(({ id: value, name }) => ({ name, value }));
}
