import type { AppState } from 'behavior';

export default function getLanguageSegment(state: AppState): string | null | undefined {
  const location = state.routing.location;
  if (!location)
    return;

  const cultureName = state.localization.currentLanguage.cultureName;
  if (!cultureName)
    return;

  const languageSegment = '/' + cultureName.toLowerCase();
  if (location.pathname.startsWith(languageSegment))
    return languageSegment;

  return null;
}
