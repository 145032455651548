import styles from 'components/objects/forms/SanaForm.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';

const ButtonRow = ({ textKey = 'Submit', btnClass = '' }) => {
  return (
    <div className={`${styles.formRow} ${styles.rowActions}`}>
      <SanaButton
        textKey={textKey}
        type="submit"
        className={`${btnStyles.btnBig} ${styles.btn} ${btnClass}`}
      />
    </div>
  );
};

ButtonRow.propTypes = {
  textKey: PropTypes.string,
  btnClass: PropTypes.string,
};

export default memo(ButtonRow);