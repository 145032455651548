import isAbsoluteUrl from './isAbsoluteUrl';

export default function createUrl(pathAndQuery: string | null | undefined, baseUrl?: string)
  : string | null {

  if (pathAndQuery == null)
    return null;

  if (!pathAndQuery.length)
    return baseUrl ? formatBaseUrl(baseUrl) + '/' : null;

  if (isAbsoluteUrl(pathAndQuery))
    return pathAndQuery;

  const formattedPath = formatPath(pathAndQuery);
  if (!baseUrl)
    return formattedPath;

  const formattedBaseUrl = isAbsoluteUrl(baseUrl)
    ? formatBaseUrl(baseUrl)
    : formatPath(formatBaseUrl(baseUrl));

  return formattedBaseUrl + formattedPath;
}

function formatBaseUrl(base: string) {
  if (base[base.length - 1] === '/')
    return base.substring(0, base.length - 1);

  return base;
}

function formatPath(path: string) {
  if (path[0] === '/')
    return path;

  return '/' + path;
}
