export const DOCUMENTS_REQUESTED = 'DOCUMENTS_REQUESTED';
export const requestDocuments = (options, documentType) => { 
  return {
    type: DOCUMENTS_REQUESTED,
    payload: { options, documentType },
  };
};

export const DOCUMENTS_RECEIVED = 'DOCUMENTS_RECEIVED';
export const documentsReceived = (documents, page) => ({
  type: DOCUMENTS_RECEIVED,
  payload: { documents, page },
});