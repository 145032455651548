export const PRODUCT_COMPARISON_ADD_PRODUCT = 'PRODUCT_COMPARISON_ADD_PRODUCT';
export const addProductToComparison = ({ id, title, image, url }) => ({
  type: PRODUCT_COMPARISON_ADD_PRODUCT,
  payload: { id, title, image, url },
});

export const PRODUCT_COMPARISON_REMOVE_PRODUCT = 'PRODUCT_COMPARISON_REMOVE_PRODUCT';
export const removeProductFromComparison = id => ({
  type: PRODUCT_COMPARISON_REMOVE_PRODUCT,
  payload: { id },
});

export const PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS = 'PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS';
export const removeAllProductsFromComparison = () => ({
  type: PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS,
});

export const PRODUCT_COMPARISON_REQUEST_INITIALIZATION = 'PRODUCT_COMPARISON_REQUEST_INITIALIZATION';
export const requestProductComparisonInitialization = () => ({
  type: PRODUCT_COMPARISON_REQUEST_INITIALIZATION,
});

export const PRODUCT_COMPARISON_INITIALIZE = 'PRODUCT_COMPARISON_INITIALIZE';
export const initializeProductComparison = productsToCompare => ({
  type: PRODUCT_COMPARISON_INITIALIZE,
  payload: { productsToCompare },
});
