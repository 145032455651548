import { useRef, DependencyList } from 'react';
import { isEqual } from 'lodash';

type EqualityFn = (oldDeps: DependencyList, newDeps: DependencyList) => boolean;

const initialDeps: DependencyList = [];

export default function useComputedValue<T>(computeValue: (...args: DependencyList) => T, deps: DependencyList, equalityFn: EqualityFn = isEqual): T {
  const memoizedComputedValueRef = useRef<T>();
  const memoizedDepsRef = useRef<DependencyList>(initialDeps);

  const initialRun = memoizedDepsRef.current === initialDeps;
  if (!initialRun && equalityFn(deps, memoizedDepsRef.current))
    return memoizedComputedValueRef.current!;

  memoizedDepsRef.current = deps;

  return memoizedComputedValueRef.current = computeValue();
}
