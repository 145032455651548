import { of } from 'rxjs';
import { setPreviewToken } from './helpers';
import { reloadUser } from 'behavior/user';

export default ({ params: { token, routeName, routeData } }, state$, { api }) => {
  if (!token)
    return of(null);

  if (state$.value.page.forward)
    return of({ forwardTo: { routeName, params: { ...routeData, previewToken: token } } });

  setPreviewToken(api, token);

  return of({ page: { forward: true }, action$: of(reloadUser()) });
};