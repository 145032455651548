import { merge } from 'rxjs';
import { switchMap, mapTo } from 'rxjs/operators';
import { subscribeMutation, unsubscribeMutation } from './queries';
import { NEWSLETTER_SUBSCRIBE, NEWSLETTER_UNSUBSCRIBE, updateNewsletterSubscription } from './actions';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';

export default function (action$, _, { api, logger }) {
  const subscribe$ = action$.ofType(NEWSLETTER_SUBSCRIBE).pipe(
    switchMap(({ payload: { input, updatedById } }) => api.graphApi(subscribeMutation, { input }).pipe(
      mapTo(updateNewsletterSubscription(updatedById, input.email, true)),
      catchApiErrorWithToast(),
      retryWithToast(action$, logger),
    )),
  );

  const unsubscribe$ = action$.ofType(NEWSLETTER_UNSUBSCRIBE).pipe(
    switchMap(({ payload: { input, updatedById } }) => api.graphApi(unsubscribeMutation, { input }).pipe(
      mapTo(updateNewsletterSubscription(updatedById, input.email, false)),
      catchApiErrorWithToast(),
      retryWithToast(action$, logger),
    )),
  );

  return merge(subscribe$, unsubscribe$);
}