export default context => value => {
  if (!value || !value.length)
    return;

  const { pattern } = context.params;
  const match = new RegExp(pattern).exec(value);
  if (match && (match.index === 0) && (match[0].length === value.length))
    return;

  return context.errorMessage;
};