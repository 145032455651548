import styles from '../../Order.module.scss';
import PropTypes from 'prop-types';

const ProductHeaderRow = ({
  id,
  title,
  columnsAmount,
  cancelled,
  lineTexts,
}) => (
    <tr className={`${styles.productLine} ${cancelled ? styles.cancelled : ''}`}>
      <td aria-hidden={!cancelled} className="visually-hidden">
        {cancelled && lineTexts.lineStatusCancelled}
      </td>
      <td className={styles.lineId}>{id}</td>
      <td className={styles.lineTitle}>{title}</td>
      <td aria-hidden colSpan={columnsAmount - 2} />{/* ID and title cells*/}
    </tr>
  );

ProductHeaderRow.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  columnsAmount: PropTypes.number.isRequired,
  cancelled: PropTypes.bool,
  lineTexts: PropTypes.shape({
    lineStatusCancelled: PropTypes.string,
  }),
};

export default ProductHeaderRow;
