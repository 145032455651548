import { REGISTRATION_PROCESSED, templateFieldsReceived } from './actions';
import { exhaustMap, filter, map } from 'rxjs/operators';
import { loadEntityTemplateFieldsQuery } from './queries';
import { ofType } from 'redux-observable';

export default function registrationEpic(action$, _state$, { api }) {
  return action$.pipe(
    ofType(REGISTRATION_PROCESSED),
    filter(action => action.payload.invalidInput),
    exhaustMap(_ => api.graphApi(loadEntityTemplateFieldsQuery).pipe(
      map(({ entityTemplateFields }) => templateFieldsReceived(entityTemplateFields)),
    )),
  );
}