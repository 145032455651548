import styles from './Input.module.scss';
import PropTypes from 'prop-types';

const Input = ({ type, className = '', innerRef = null, value, ...attributes }) => (
  <div className={`${styles.tbx} ${className}`} ref={innerRef} role="presentation">
    <input type={type} value={value === null ? '' : value} {...attributes} />
  </div>
);

Input.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  innerRef: PropTypes.object,
  value: PropTypes.any,
};

export default Input;