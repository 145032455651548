import { getIn } from 'formik';
import { formatText } from 'utils/formatting';

export default context => (value, _fieldName, formikContext) => {
  const { fieldTitle, countryFieldName, perCountryPatterns } = context.params;

  if (!value || !perCountryPatterns || !perCountryPatterns.length)
    return;

  let countryId, perCountryValidator;
  if (countryFieldName != null)
    countryId = getIn(formikContext.values, countryFieldName);

  if (countryId != null)
    perCountryValidator = perCountryPatterns.find(v => v.countryId === countryId);
  else if (perCountryPatterns.length > 0 && perCountryPatterns[0].countryId == null)
    perCountryValidator = perCountryPatterns[0];

  if (perCountryValidator == null)
    return;

  const patternEntries = perCountryValidator.patterns;
  for (const { pattern } of patternEntries) {
    if (new RegExp(pattern).test(value))
      return;
  }

  return formatText(context.errorMessage, [
    fieldTitle,
    patternEntries.map(e => e.mask).join(', '),
  ]);
};