import { of, merge, Subject, EMPTY } from 'rxjs';
import { mergeMap, map, pluck, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { loadTextsQuery } from './queries';
import { arrayToObject } from 'utils/helpers';
import { ADMINTEXTS_REQUESTED, textsLoaded } from './actions';
import { retryWithToast } from 'behavior/errorHandling';
import { bufferBatchForLoading } from 'utils/rxjs';

function textsEpic(action$, _state$, { api, logger, completePendingActions$ }) {
  const usedKeys = new Set();

  const getTexts = keys => {
    if (!keys.length)
      return EMPTY;

    const errorHandler = retryWithToast(action$, logger, EMPTY);

    return api.graphApi(loadTextsQuery, { keys }).pipe(
      map(data => textsLoaded(arrayToObject(data.adminTexts, s => s.key, s => s.value))),
      errorHandler,
    );
  };

  const pushText$ = new Subject();
  textsEpic.pushText = (key, text) => pushText$.next(textsLoaded({ [key]: text }));

  const load$ = action$.pipe(
    ofType(ADMINTEXTS_REQUESTED),
    bufferBatchForLoading(completePendingActions$),
    mergeMap(actions => {
      const keys = [];

      for (const action of actions)
        for (const key of action.payload) {
          if (usedKeys.has(key))
            continue;

          keys.push(key);
          usedKeys.add(key);
        }

      return getTexts(keys);
    }),
  );

  return merge(pushText$, load$);
}

export default textsEpic;

export function requestAdminText(key, state$, { api }) {
  const existingText = state$.value.adminTexts[key];
  if (existingText)
    return of(existingText);

  return api.graphApi(loadTextsQuery, { keys: [key] }).pipe(
    pluck('adminTexts', '0', 'value'),
    tap(text => textsEpic.pushText(key, text)),
  );
}