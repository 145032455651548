import createSystemRenderer from 'components/objects/systemPages/createSystemRenderer';
import { CheckoutProcessNames } from 'behavior/pages/checkout';
import OrderCancelled from './OrderCancelled';

export default page => {
  const { transaction: { checkoutProcessName } } = page;

  const headerTextKey = checkoutProcessName === CheckoutProcessNames.Invoice
    ? 'InvoiceCancelled_Header'
    : checkoutProcessName === CheckoutProcessNames.Promotion
      ? 'QuotePaymentCancelled_Header'
      : 'OrderCancelled_Header';

  return createSystemRenderer(headerTextKey, OrderCancelled)(page);
};