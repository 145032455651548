import { useEffect, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Form from './Form';
import SanaFormButton from './SanaFormButton';
import { useServices } from 'utils/services';
import { useSelector } from 'react-redux';

const EMPTY_VALUES = {};
const SanaForm = ({
  onLoad,
  children,
  onSubmit,
  initialValues = EMPTY_VALUES,
  initialStatus,
  shouldReset,
  validateOnChange = true,
  validateOnBlur = true,
  name,
  onBlur,
  validate = null,
  formikRef,
  ...attributes
}) => {
  const localRef = useRef();
  if (!formikRef)
    formikRef = localRef;

  useEffect(() => {
    if (onLoad)
      onLoad();
  }, []);

  useEffect(() => {
    const formik = formikRef.current;
    if (formik && shouldReset) {
      if (initialValues !== EMPTY_VALUES)
        formik.resetForm({ values: initialValues });
      else
        // reuse values from formik to prevent extra renders on initializing fields
        formik.resetForm({ values: formik.initialValues });
    }
  }, [shouldReset]);

  // reset on initial values changed
  useEffect(() => {
    const formik = formikRef.current;
    if (formik && initialValues !== EMPTY_VALUES) {
      if (!formik.dirty && formik.initialValues !== initialValues) {
        formik.resetForm({ values: initialValues });
      }
    }
  }, [initialValues]);

  const { api } = useServices();

  const currencySeparator = useSelector(currencySeparatorSelector, firstTruthyComparer);

  const formikInitialStatus = useMemo(() => {
    return {
      ...initialStatus,
      api,
      formName: name || '',
      currencySeparator,
    };
  }, [name, initialStatus]);

  const submitProxy = useCallback(data => onSubmit && onSubmit(data, formikRef.current), [onSubmit]);

  return (
    <Formik
      onSubmit={submitProxy}
      initialValues={initialValues}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
      initialStatus={formikInitialStatus}
      validate={validate}
    >
      {formik => {
        formikRef.current = formik;
        if (onBlur)
          attributes.onBlur = e => onBlur(e, formik);

        return (
          <Form noValidate="novalidate" name={name} {...attributes}>
            {children}
          </Form>
        );
      }}
    </Formik>
  );
};

SanaForm.propTypes = {
  onLoad: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  initialValues: PropTypes.object,
  initialStatus: PropTypes.object,
  shouldReset: PropTypes.any,
  validateOnChange: PropTypes.bool,
  validateOnBlur: PropTypes.bool,
  validate: PropTypes.func,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
};

SanaForm.SubmitRow = SanaFormButton;

export default SanaForm;

function currencySeparatorSelector(state) {
  const format = state.settings.currency;
  return format ? format.separator : undefined;
}

function firstTruthyComparer(next, prev) {
  if (prev == null)
    return next == null;

  // Do not re-render after first non-null value has been selected.
  return true;
}
