export const VOLUME_ORDER_QUANTITY_CHANGED = 'VOLUME_ORDER_QUANTITY_CHANGED';
export const volumeOrderQuantityChanged = q => ({
  type: VOLUME_ORDER_QUANTITY_CHANGED,
  payload: q,
});

export const VOLUME_ORDER_INITIALIZE = 'VOLUME_ORDER_INITIALIZE';
export const initializeVolumeOrder = () => ({
  type: VOLUME_ORDER_INITIALIZE,
  payload: {},
});