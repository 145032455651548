import styles from '../Details.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import ProductContext from '../ProductContext';
import { connectToContext } from 'utils/react';
import VolumePrices from './VolumePrices';
import { Modal, useModal } from 'components/objects/modals';
import { Placeholder } from 'components/primitives/placeholders';
import { requestVolumePrices } from 'behavior/pages/product';
import { useCanViewUom } from 'components/primitives/product';

const Button = ({
  showCompleteList,
  volumePrices,
  uoms,
  productId,
  uomId,
  variantId,
  price,
  requestVolumePrices,
}) => {
  const canViewUom = useCanViewUom();
  const { opened, show, hide, toggle } = useModal();
  const opening = useRef(false);

  const volumePricesUom = canViewUom ? uomId : null;

  const onLoadVolumePrices = () => {
    if (volumePrices && volumePrices.variantId === variantId && volumePrices.uomId === volumePricesUom)
      show();
    else {
      opening.current = true;
      requestVolumePrices(productId, variantId, volumePricesUom);
    }
  };

  useEffect(() => {
    if (volumePrices && opening.current) {
      opening.current = false;
      toggle();
    }
  }, [volumePrices]);

  const isVolumePricesHypDisabled = !!volumePrices && !volumePrices.prices;
  const volumeDiscountPlaceholder = <Placeholder className={styles.volumeDiscountPlaceholder} />;

  return (
    <>
      <button className={`${styles.hypVolumePrices} ${linkStyles.arrowed} ${linkStyles.link} ${isVolumePricesHypDisabled ? 'disabled' : ''}`}
        disabled={isVolumePricesHypDisabled}
        onClick={onLoadVolumePrices}
      >
        <SimpleText textKey="ViewVolumeDiscount" placeholder={volumeDiscountPlaceholder} />
      </button>
      <Modal opened={opened} hide={hide} key={productId} className={styles.volPricesPopup}>
        <VolumePrices
          volumePrices={volumePrices}
          canViewUom={canViewUom}
          uoms={uoms}
          defaultPrice={price}
          forAllVariants={showCompleteList}
        />
      </Modal>
    </>
  );
};

Button.propTypes = {
  showCompleteList: PropTypes.bool,
  volumePrices: PropTypes.object,
  uoms: PropTypes.array,
  productId: PropTypes.string,
  uomId: PropTypes.string,
  variantId: PropTypes.string,
  price: PropTypes.number,
  requestVolumePrices: PropTypes.func.isRequired,
};

const Connected = connect(
  ({ page: { volumePrices } }) => ({ volumePrices }),
  { requestVolumePrices },
)(Button);

export default connectToContext(
  [ProductContext],
  ({
    product: {
      uoms,
      id,
    },
    uomId,
    variantId,
    calculatedInfo: { price },
  }, { showCompleteList, canOrderProducts }) => ({
    productId: id,
    uoms,
    uomId: showCompleteList || !canOrderProducts ? null : uomId,
    variantId,
    price,
  }),
)(Connected);