import { memo } from 'react';
import { DocumentType } from 'behavior/documents';
import { routesBuilder } from 'routes';
import Documents, { selectPropsFromPage } from '../Documents';
import QuoteList from './QuoteList';

const textKeys = {
  introduction: 'QuotesHistory_IntroductionText',
  listHeader: 'RecentQuotes',
  loadMore: 'ShowNextNQuotes',
  noItems: 'NoQuoteHistory',
};

const quotesRoute = routesBuilder.forQuotes();

const Quotes = memo(props => (
  <Documents
    loadMoreRoute={quotesRoute}
    textKeys={textKeys}
    documentType={DocumentType.Quote}
    {...props}
  >
    <QuoteList documents={props.documents} />
  </Documents>
));

Quotes.selectPropsFromPage = selectPropsFromPage;

export default Quotes;
