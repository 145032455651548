import { Children } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

let ContentBlocksSortableList;
let ColumnToolbar;

const VdColumnContentWrapper = ({ children, columnId, isVisualDesigner }) => {
  if (!isVisualDesigner)
    return children;

  if (!ContentBlocksSortableList)
    ContentBlocksSortableList = loadable(() => import(/*webpackChunkName:"vd"*/'../sortableLists/ContentBlocksSortableList'));

  if (!ColumnToolbar)
    ColumnToolbar = loadable(() => import(/*webpackChunkName:"vd"*/'./columnToolbar/ColumnToolbar'));

  const childrenLength = Children.count(children);

  return (
    <>
      <ColumnToolbar isEmptyColumn={childrenLength === 0} columnId={columnId} />
      <ContentBlocksSortableList columnId={columnId}>{children}</ContentBlocksSortableList>
    </>
  );
};

VdColumnContentWrapper.propTypes = {
  children: PropTypes.node,
  isVisualDesigner: PropTypes.bool,
  columnId: PropTypes.string.isRequired,
};

export default connect(
  ({ visualDesigner }) => ({ isVisualDesigner: visualDesigner.initialized }),
)(VdColumnContentWrapper);