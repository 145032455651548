import htmlPackage from 'components/objects/htmlBlock/package';
import headerPackage from 'components/objects/headerBlock/package';
import bannerPackage from 'components/objects/banner/package';
import uspPackage from 'components/objects/usp/package';
import imageTilesPackage from 'components/objects/imageTiles/package';
import vdColumnTemplatePackage from 'components/visualDesigner/package';
import childMenuItemsPackage from 'components/objects/childMenuItems/package';
import productSetPackage from 'components/objects/productSet/package';
import imagePackage from 'components/objects/imageBlock/package';
import searchBarPackage from 'components/objects/searchBarBlock/package';
import spacerPackage from 'components/objects/spacerBlock/package';

const defaultComponents = {
  ...htmlPackage,
  ...headerPackage,
  ...bannerPackage,
  ...uspPackage,
  ...imageTilesPackage,
  ...vdColumnTemplatePackage,
  ...childMenuItemsPackage,
  ...productSetPackage,
  ...imagePackage,
  ...searchBarPackage,
  ...spacerPackage,
};

export default defaultComponents;