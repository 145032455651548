import type { AppState } from 'behavior';
import { useMemo } from 'react';
import useComputedValue from './useComputedValue';
import useLoadEffect from './useLoadEffect';
import { loadTexts } from 'behavior/adminTexts';
import { createSelector } from 'reselect';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

type TextValue = string | null | undefined;

type AdminTexts = {
  texts: TextValue[];
  loaded: boolean;
};

export default function useAdminTexts(keys: string[]): AdminTexts {
  const dispatch = useDispatch();

  const selectTexts = useComputedValue(() => makeTextsSelector(keys), keys, shallowEqual);
  const adminTexts = useSelector(selectTexts);

  useLoadEffect(() => {
    const keysToLoad: string[] = [];

    for (const text of adminTexts) {
      if (text.value === undefined)
        keysToLoad.push(text.key);
    }

    if (keysToLoad.length)
      dispatch(loadTexts(keysToLoad));
  });

  return useMemo(() => {
    let loaded = true;
    const textValues: TextValue[] = [];

    for (const { value } of adminTexts) {
      const isUndefined = value === undefined;
      if (isUndefined)
        loaded = false;

      textValues.push(isUndefined ? null : value);
    }

    return { texts: textValues, loaded };
  }, [adminTexts]);
}

const selectTexts = (adminTexts: AppState['adminTexts'], keys: string[]) => {
  return keys.map(key => {
    if (!key)
      return { key: null, value: null };

    return {
      key,
      value: adminTexts[key],
    };
  });
};

function makeTextsSelector(keys: string[]) {
  return createSelector(
    (state: AppState) => state.adminTexts,
    adminTexts => selectTexts(adminTexts, keys),
  );
}
