import type { ReactNode, ReactText } from 'react';
const formatRegex = /\{(\d+)\}/g;

export const splitToFragments = (text: string, args: ReactNode[]): ReactNode[] => {
  if (!text || !args.length)
    return [text];

  const parts = text.split(formatRegex);
  if (parts.length === 1)
    return [text];

  const fragments: ReactNode[] = [];
  for (let i = 0; i < parts.length; i += 2) {
    if (parts[i])
      fragments.push(parts[i]);

    if (i === parts.length - 1)
      break;

    const argsIndex = parseInt(parts[i + 1]);
    if (args[argsIndex] == null)
      continue;

    fragments.push(args[argsIndex]);
  }

  return fragments;
};

export const formatText = (text: string | null | undefined, args: ReactText[]) => {
  if (!text || !args.length)
    return text;

  const fragments = splitToFragments(text, args);
  if (!fragments)
    return text;

  return fragments.join('');
};
