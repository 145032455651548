import PropTypes from 'prop-types';
import { NavigationItemPropTypes, LinkPropTypes } from '../PropTypes';
import { ImagePosition } from 'behavior/navigation';

const GeneralMainNaigationItemPropTypes = {
  ...NavigationItemPropTypes,
  id: PropTypes.string.isRequired,
};

export const MainNavItemPropTypes = PropTypes.shape(GeneralMainNaigationItemPropTypes).isRequired;

export const MainNavImagePropTypes = PropTypes.shape({
  path: PropTypes.string,
  keepOriginalSize: PropTypes.bool,
  position: PropTypes.oneOf([
    ImagePosition.Inline,
    ImagePosition.RightBottom,
  ]),
  link: LinkPropTypes,
});

export const MainNavTopLevelItemPropTypes = PropTypes.shape({
  ...GeneralMainNaigationItemPropTypes,
  image: MainNavImagePropTypes,
}).isRequired;

export const SublistParamsPropTypes = PropTypes.shape({
  startOffset: PropTypes.number,
  direction: PropTypes.string,
});
