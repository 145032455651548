import styles from '../Checkout.module.scss';
import { memo, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { saveGuest } from 'behavior/pages/checkout';
import { extractEscapedTemplateFields, validateForm } from 'components/objects/forms';
import StepButtons from './StepButtons';
import { AddressStep as AddressStepSection, GuestAddressStepBody } from '../base/address';
import CheckoutContext from './CheckoutContext';
import scrollIntoView from 'scroll-into-view';

const GuestAddressStep = ({
  info: {
    profileFields,
    shippingTemplateFields,
    profileTemplateFields,
    shippingAddress,
  },
  isActive,
  isCompleted,
}) => {
  const { nextStep } = useContext(CheckoutContext);
  const profileFormikRef = useRef();
  const shippingFormikRef = useRef();

  const dispatch = useDispatch();

  const onStepSubmit = async () => {
    const formValid = await validateForm(profileFormikRef);

    const withShippingAddress = !!shippingFormikRef.current,
      shippingFormValid = !withShippingAddress || (await validateForm(shippingFormikRef));

    if (!formValid || !shippingFormValid) {
      const invalidElement = document.querySelector(`.${styles.activeStep} [aria-invalid=true]`);

      if (invalidElement)
        scrollIntoView(invalidElement, { time: 300 }, invalidElement.focus && (() => invalidElement.focus()));

      return;
    }

    const formData = extractEscapedTemplateFields(profileFormikRef.current.values, profileTemplateFields);
    const shippingFormData = withShippingAddress ? extractEscapedTemplateFields(shippingFormikRef.current.values, shippingTemplateFields) : undefined;
    dispatch(saveGuest(formData, shippingFormData));
    nextStep();
  };

  return (
    <AddressStepSection
      asLink={!isActive}
      className={`${styles.address} ${isActive ? styles.activeStep : ''}`}
      isGuest
      isCompleted={isCompleted}
    >
      {isActive &&
        <>
          <GuestAddressStepBody
            profileFields={profileFields}
            profileTemplateFields={profileTemplateFields}
            shippingFields={shippingAddress && shippingAddress.address?.fields}
            shippingTemplateFields={shippingTemplateFields}
            onAddressFormSubmit={onStepSubmit}
            profileFormikRef={profileFormikRef}
            shippingFormikRef={shippingFormikRef}
          />
          <StepButtons onStepSubmit={onStepSubmit} />
        </>
      }
    </AddressStepSection>
  );
};

GuestAddressStep.propTypes = {
  info: PropTypes.shape({
    profileFields: PropTypes.object,
    shippingTemplateFields: PropTypes.array,
    profileTemplateFields: PropTypes.array,
    shippingAddress: PropTypes.shape({
      address: PropTypes.object,
    }),
  }).isRequired,
  isActive: PropTypes.bool,
  isCompleted: PropTypes.bool,
};

export default memo(GuestAddressStep);
