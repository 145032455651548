import { useMemo } from 'react';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import { useAbilities } from 'components/objects/user';

const abilityKeys = [
  AbilityTo.ViewPrices,
  AbilityTo.OrderProducts,
  AbilityTo.ViewStock,
  AbilityTo.ViewUnitOfMeasure,
];

export function useProductListAbilities() {
  const { abilities } = useAbilities(abilityKeys);

  return useMemo(() => abilityKeys.reduce((seed, key, index) => {
    seed[key] = abilities[index] === AbilityState.Available;
    return seed;
  }, {}), abilities);
}