import { memo } from 'react';
import PropTypes from 'prop-types';
import { makeRichText } from 'utils/render';
import { connectToContext } from 'utils/react';
import ProductContext from './ProductContext';
import { showMatrix } from './showMatrix';

const RetailOffers = ({ isOrderable, variantId: selectedVariantId, product, matrixPreset }) => {
  const { retailOffers } = product;
  if (!isOrderable || !retailOffers || !retailOffers.length)
    return null;

  const showAllOffers = matrixPreset && showMatrix(product);

  return (
    <div className="retail-offers">
      {retailOffers.map(({ description, variantId }, index) => {
        if (!showAllOffers && variantId && variantId !== selectedVariantId)
          return null;

        return (
          <div key={index}>
            {makeRichText(description)}
          </div>
        );
      })}
    </div>
  );
};

RetailOffers.propTypes = {
  isOrderable: PropTypes.bool,
  product: PropTypes.shape({
    retailOffers: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string.isRequired,
      variantId: PropTypes.string,
    })),
  }),
  variantId: PropTypes.string,
  matrixPreset: PropTypes.bool,
};

export default connectToContext(
  [ProductContext],
  ({ product, calculatedInfo, variantId }) => ({
    isOrderable: calculatedInfo && calculatedInfo.isOrderable,
    product,
    variantId,
  }),
)(memo(RetailOffers));
