import styles from './DisabledSearchBox.module.scss';
import searchStyles from './Search.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { SearchButtonIcon } from 'components/primitives/icons';
import { RichText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';

const DisabledSearchBox = ({ className = '' }) => {
  const loginLink = (
    <Link to={routesBuilder.forLogin}>
      <RichText textKey="DisabledSearchBar_Login" />
    </Link>
  );

  return (
    <div className={`${searchStyles.searchBox} ${className}`}>
      <div className={searchStyles.container}>
        <form className={styles.fakeForm}>
          <div className={styles.fakeInput}>
            <RichText textKey="SearchBarBlockDisabledDescription" formatWith={[loginLink]} />
          </div>
          <button className={searchStyles.submit} disabled>
            <SearchButtonIcon className={searchStyles.searchIcon} />
          </button>
        </form>
      </div>
    </div>
  );
};

DisabledSearchBox.propTypes = {
  className: PropTypes.string,
};

export default memo(DisabledSearchBox);
