import basketStyles from './Basket.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import inputStyles from 'components/primitives/form/Input.module.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useOnChange } from 'utils/hooks';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { useUpdateContext } from './updateContext';

const TextKeys = [
  'ButtonText_Submit',
  'DiscountCode',
  'EnterDiscountCode',
  'Delete',
  'PromotionDiscount',
  'DeleteDiscountCode',
];

const PromotionControl = ({ line, modifiedDate, showTitle }) => {
  const [expanded, setExpanded] = useState(false);

  useOnChange(() => {
    if (line)
      return;

    setExpanded(false);
  }, [modifiedDate], false);

  const { submit } = useUpdateContext();

  const { texts: [
    submitText,
    placeholderText,
    expandBtnText,
    deleteText,
    promotionDiscountText,
    deleteBtnText,
  ], loaded } =
    useSanaTexts(TextKeys, makeSimpleText);

  if (!loaded)
    return null;

  if (line) {
    const deleteTitle = line.code ? (deleteText || '') + ' ' + line.code : null;
    return (
      <div className={basketStyles.delPromotion}>
        {showTitle && <span>{line.title + ' '}</span>}
        <button
          className={`${linkStyles.link} ${basketStyles.btnDelete}`}
          title={deleteTitle}
          onClick={() => void (submit(null, ''), setExpanded(false))}
        >
          <span aria-hidden>− </span>
          <span>
            {showTitle
              ? deleteBtnText
              : deleteText + ' ' + (line.title || promotionDiscountText)
            }
          </span>
        </button>
      </div>
    );
  }

  if (expanded) {
    const onSubmit = e => {
      e.preventDefault();
      const code = e.target.elements['code'].value;
      if (code)
        submit(e, code);
    };

    return (
      <form onSubmit={onSubmit} className={basketStyles.promotionForm}>
        <div className={`${inputStyles.tbx} ${basketStyles.inputWrapper}`}>
          <input name="code" maxLength="20" placeholder={placeholderText} data-required autoFocus />
        </div>
        <button type="submit" className={`${btnStyles.btn} ${btnStyles.btnSmall} ${btnStyles.noIcon}`}>
          <span className="btn-cnt">
            {submitText}
          </span>
        </button>
      </form>
    );
  }

  return (
    <div>
      <button type="button" className={linkStyles.link + ' ' + basketStyles.addCoupon} onClick={setExpanded}>
        {expandBtnText}
      </button>
    </div>
  );
};

PromotionControl.propTypes = {
  line: PropTypes.shape({
    code: PropTypes.string,
    title: PropTypes.string.isRequired,
  }),
  modifiedDate: PropTypes.number,
  showTitle: PropTypes.bool,
};

export default connect(({ basket: { modifiedDate, model } }) => ({
  line: model.discount && model.discount.promotion,
  modifiedDate,
}))(PromotionControl);