import { pluck } from 'rxjs/operators';
import validateAsync from './validateAsync';

export default context => (value, fieldName, formikContext, { api }) => {
  // Shortest valid email address is 3 characters long.
  // https://stackoverflow.com/questions/1423195/what-is-the-actual-minimum-length-of-an-email-address-as-defined-by-the-ietf
  if (!value || value.length < 3)
    return;

  if (value === formikContext.initialValues[fieldName] || value === formikContext.initialStatus?.defaultValues?.[fieldName])
    return;

  const key = fieldName + '_uniqueEmail';
  const validationHandler = email => api
    .graphApi(`query IsEmailUnique{validate{uniqueEmail(email:"${email}")}}`)
    .pipe(pluck('validate', 'uniqueEmail'));

  return validateAsync(value, context.errorMessage, formikContext, key, validationHandler);
};
