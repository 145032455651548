import Field, { fieldPropTypes } from '../Field';
import { TextArea } from 'components/primitives/form';

const TextAreaField = ({ fieldName, fieldTitle, validation, ...attributes }) => (
  <Field
    fieldName={fieldName}
    fieldTitle={fieldTitle}
    validation={validation}
    as={TextArea}
    {...attributes}
  />
);

TextAreaField.propTypes = fieldPropTypes;

export default TextAreaField;