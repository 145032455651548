import { SimpleText, RichText } from 'components/sanaText';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

const Offline = ({ shopEmailAddress }) => {
  const formatWith = [
    <a href={`mailto:${shopEmailAddress}`}><SimpleText textKey="ContactUs" /></a>,
  ];

  return (
    <RichText textKey="ConnectionOfflinePage_ErrorText" formatWith={formatWith} formatAsHtml />
  );
};

Offline.propTypes = {
  shopEmailAddress: PropTypes.string,
};

export default connect(
  ({ settings: { shopEmailAddress } }) => ({
    shopEmailAddress,
  }),
)(Offline);