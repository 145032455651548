import styles from './NumberSwitcher.module.scss';
import { memo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const NumberSwitcher = ({ number = 0 }) => {
  const ref = useRef(null);
  const numberNext = useRef(0);
  const numberPrevious = useRef(0);

  if (numberNext.current !== number) {
    numberPrevious.current = numberNext.current > 0 ? numberNext.current : number;
    numberNext.current = number;
  }

  useEffect(() => {
    const switcherElement = ref.current;
    if (!switcherElement)
      return;

    let timeout;
    requestAnimationFrame(() => {
      switcherElement.classList.remove(styles.finalState);
      switcherElement.classList.add(styles.numSwitchAnimation);

      timeout = setTimeout(() => {
        switcherElement.classList.remove(styles.numSwitchAnimation);
        switcherElement.classList.add(styles.finalState);
      }, styles.animationDuration);
    });

    return () => clearTimeout(timeout);
  }, [number]);

  const numSwitchClasses = `${styles.numSwitch} ${numberNext.current === numberPrevious.current ? styles.noAnimation : ''}`;

  return (
    <span
      ref={ref}
      className={numSwitchClasses}
      data-invert={numberPrevious.current > numberNext.current ? '' : null}
    >
      <span className={styles.numSwitchPrev}>{numberPrevious.current}</span>
      <span className={styles.numSwitchNext}>{numberNext.current}</span>
    </span>
  );
};

NumberSwitcher.propTypes = {
  number: PropTypes.number,
};

export default memo(NumberSwitcher);