import type { Cookies } from './types';
import { parse, serialize } from 'cookie';

interface BrowserCookies extends Cookies {
  getValues(): Record<string, string>;
}

export function createBrowserCookies(): BrowserCookies {
  return {
    getValues() {
      return parse(document.cookie);
    },
    get(name) {
      return parse(document.cookie)[name];
    },
    set(name, value, options) {
      document.cookie = serialize(name, value, { ...options, httpOnly: false });
    },
  };
}
