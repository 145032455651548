export const SANATEXTS_REQUESTED = 'SANATEXTS_REQUESTED';
export const loadSanaTexts = (keys: string[]) => ({
  type: SANATEXTS_REQUESTED,
  payload: keys,
});

export const SANATEXTS_LOADED = 'SANATEXTS_LOADED';
export const sanaTextsLoaded = (sanaTexts: { key: string; description: string | null }) => ({
  type: SANATEXTS_LOADED,
  payload: sanaTexts,
});
