  export const getAvailableDate = (ss, q=-1) => {
    if (ss===undefined) {
      return undefined;
    }

    const [headStatus, ...others] = ss;

    if (headStatus===undefined)
    {
      return undefined;
    }

    if (headStatus.stockLevel>=q) {
      return headStatus.leadDate;
    }

    const theOthers = others
      .filter(x => x.name!=='Overdue')
      .map(x => ({ ...x, date: dateFromShortDate(x.name)}))
      .sort((a,b) => a.date-b.date);

    const theDate = theOthers
      .filter((s, i) => {
        const soFar = theOthers.slice(0,i+1).map(o => o.stockLevel).reduce((a,b) => a+b);
        return soFar>=q;
      });

    if (theDate.length) {
      const [firstDate] = theDate.slice(0,1);
      return (firstDate.name==='Today' || firstDate.name==='Overdue') ? 
        firstDate.name : 
        dateFromShortDate(firstDate.name).toLocaleDateString();
    }

    return undefined;
  };

export const dateFromShortDate = ds => {
    const monthOffset=0;
    const dayOffset=1;
    const dateSplit = ds.split('/');
    const month = dateSplit[monthOffset]-1;

    return new Date('20' + dateSplit[2], month, dateSplit[dayOffset]);
  };

export const dateFromLocaleString = ls => { 
    const monthOffset=1;
    const dayOffset=0;
    const dateSplit = ls.split('/');
    const month = dateSplit[monthOffset]-1;

    return new Date(dateSplit[2], month, dateSplit[dayOffset]);
};

