import { NAVIGATED, NAVIGATING, NAVIGATION_REQUESTED, INIT } from './actions';
import { areLocationsEqual } from './helpers';
import { createReducer } from 'utils/redux';

const initialState = {
  location: undefined,
  previous: undefined, // { location: Location, routeData: RouteData }
  routeData: undefined,
  navigatingTo: null,
};

export default createReducer(initialState, {
  [INIT]: onInitialize,
  [NAVIGATED]: onNavigated,
  [NAVIGATION_REQUESTED]: onNavigationRequested,
  [NAVIGATING]: onNavigating,
});

function onInitialize(state, action) {
  const { location } = action.payload;
  return { ...state, location };
}

function onNavigated(state, action) {
  const { location: newLocation, routeData: newRouteData } = action.payload;

  const { canonicalUrl, options, ...routeData } = newRouteData || {};

  return {
    ...state,
    navigatingTo: null,
    canonicalUrl,
    routeData,
    location: newLocation,
    previous: getPrevious(state, newLocation),
  };
}

function onNavigationRequested(state, action) {
  if (!action.payload.routeData)
    return state.navigatingTo ? { ...state, navigatingTo: null } : state;
  return {
    ...state,
    navigatingTo: {
      location: action.payload.location,
      routeData: action.payload.routeData,
    },
  };
}

function onNavigating(state, action) {
  return {
    ...state,
    navigatingTo: action.payload,
  };
}

function getPrevious(state, newLocation) {
  const { location: currentLocation, routeData: currentRouteData } = state;
  if (!currentLocation || !currentRouteData)
    return;

  const previous = areLocationsEqual(currentLocation, newLocation)
    ? state.previous
    : {
      location: currentLocation,
      routeData: currentRouteData,
    };
  return previous;
}
