import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DomElement } from 'components/primitives/dom';

const Injection = ({ htmlInjections }) => {
  if (!htmlInjections || !htmlInjections.bodyStart)
    return null;

  return <DomElement content={htmlInjections.bodyStart} />;
};

Injection.propTypes = {
  htmlInjections: PropTypes.shape({
    bodyStart: PropTypes.string,
  }),
};

export default connect(({ settings: { htmlInjections } }) => ({ htmlInjections }))(Injection);