import { useRef } from 'react';
import PropTypes from 'prop-types';
import SanaForm from '../SanaForm';
import { initFormRef } from './helpers';

const Form = ({
  name,
  initialValues,
  className,
  children,
  formRef,
  onSubmit,
  onBlur,
  validate,
}) => {
  const formikRef = useRef();

  if (formRef && !formRef.current)
    initFormRef(formRef, formikRef);

  const submitProxy = excludeFormikFromParams(onSubmit);
  const blurProxy = excludeFormikFromParams(onBlur);

  return (
    <SanaForm
      name={name}
      initialValues={initialValues}
      className={className}
      onSubmit={submitProxy}
      onBlur={blurProxy}
      validate={validate}
      formikRef={formikRef}
    >
      {children}
    </SanaForm>
  );
};

Form.propTypes = {
  name: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  formRef: PropTypes.shape({ current: PropTypes.any }),
  onSubmit: PropTypes.func,
  onBlur: PropTypes.func,
  validate: PropTypes.func,
};

export default Form;

function excludeFormikFromParams(func) {
  if (!func)
    return func;

  return (param, _formik) => func(param);
}
