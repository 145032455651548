import styles from './Modal.module.scss';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { CrossBigIcon } from 'components/primitives/icons';
import { useSimpleTexts } from 'components/sanaText';

const CloseButton = forwardRef(({ hide, closeBtnTitle }, ref) => {
  const [defaultTitle] = useSimpleTexts([!closeBtnTitle && 'ButtonAltText_Close']).texts;

  return (
    <div className={styles.closeBtnWrapper} ref={ref}>
      <button onClick={hide} className={styles.closeBtn} title={closeBtnTitle || defaultTitle}>
        <CrossBigIcon aria-hidden />
      </button>
    </div>
  );
});

CloseButton.propTypes = {
  hide: PropTypes.func,
  closeBtnTitle: PropTypes.string,
};

export default CloseButton;
