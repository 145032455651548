import styles from './Summary.module.scss';
import { memo } from 'react';
import Spinner from 'components/primitives/spinner/Spinner';
import { SimpleText } from 'components/sanaText';

const LoadingIndicator = () => (
  <div className={styles.loader}>
    <Spinner className={styles.indicator} />
    <div className={styles.loaderTitle}><SimpleText textKey="BasketSummary_AddingProduct" /></div>
  </div>
);

export default memo(LoadingIndicator);
