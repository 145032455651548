import styles from '../PLP.module.scss';
import PropTypes from 'prop-types';
import ProductContext from '../ProductContext';
import Thumbnail from '../Thumbnail';
import Description from '../Description';
import OrderBox from './OrderBox';
import { ProductPrice } from 'components/primitives/product';
import ProductComparisonSelector from '../ProductComparisonSelector';
import { useResponsiveBreakpoints } from 'utils/layout';

const ProductLine = ({ product, gridMode, showThumbnail, index }) => {
  const { xs } = useResponsiveBreakpoints();
  const gridOrSmallScreen = gridMode || xs;

  return (
    <ProductContext.Provider value={product}>
      <div className={styles.item}>
        <div className={styles.tile} role="listitem">
          <i className="visually-hidden">{index + 1}</i>
          {(showThumbnail || gridMode) && <Thumbnail gridMode={gridMode} />}
          <div className={styles.productInfoWrapper}>
            <div className={styles.productInfo}>
              <Description />
              <div className={styles.productAction}>
                {product.price != null && (
                  <div className={styles.prices}>
                    <ProductPrice salesPrice={product.price} basePrice={product.listPrice} />
                  </div>
                )}
                {gridOrSmallScreen && <ProductComparisonSelector className={styles.productComparisonSelector} />}
                <OrderBox product={product} className={styles.orderBox} />
              </div>
            </div>
            {!gridOrSmallScreen && <ProductComparisonSelector className={styles.productComparisonSelector} />}
          </div>
        </div>
      </div>
    </ProductContext.Provider>
  );
};

ProductLine.propTypes = {
  product: PropTypes.object.isRequired,
  gridMode: PropTypes.bool,
  showThumbnail: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

export default ProductLine;