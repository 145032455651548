import styles from '../checkout/Checkout.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import OrderPaymentFormsContainer from './OrderPaymentFormsContainer';
import { DangerAlert, InfoAlert } from 'components/primitives/alerts';
import { RichText } from 'components/sanaText';
import { FailureOrderPaymentFetchingReason, FailureOrderPaymentReason } from 'behavior/pages/orderPayment';

const OrderPayment = ({
  transactionId,
  order,
  paymentMethods,
  failureFetching,
  payOrderResult,
  additionalCustomerData,
  extraPaymentData,
}) => {
  const orderPaymentInfo = useMemo(() => !failureFetching && ({
    transactionId,
    order,
    paymentMethods,
    additionalCustomerData,
    extraPaymentData,
  }), [failureFetching, transactionId, order, paymentMethods, additionalCustomerData, extraPaymentData]);

  const failureFetchingReasonMessageKey = failureFetching && selectFailureFetchingMessageKey(failureFetching.reason);
  const failureOrderPaymentReasonMessageKey = payOrderResult?.error && selectFailureOrderPaymentMessageKey(payOrderResult.error.reason);

  return (
    <Row>
      <Col xs={12} lg={8}>
        {failureFetchingReasonMessageKey &&
          <InfoAlert role="alert" scrollOnAppear>
          <RichText textKey={failureFetchingReasonMessageKey} formatWith={[failureFetching.orderId]} />
          </InfoAlert>
        }
        {failureOrderPaymentReasonMessageKey &&
          <DangerAlert role="alert" scrollOnAppear>
            <RichText textKey={failureOrderPaymentReasonMessageKey} formatWith={[payOrderResult.error.orderId]} />
          </DangerAlert>
        }
        {orderPaymentInfo &&
          <OrderPaymentFormsContainer orderPaymentInfo={orderPaymentInfo} />
        }
      </Col>
      <Col xs={12} lg={4} className={styles.customText}>
        <RichText textKey="PaymentAndServicesInfo" withPlaceholder />
      </Col>
    </Row>
  );
};

OrderPayment.propTypes = {
  transactionId: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  additionalCustomerData: PropTypes.object,
  extraPaymentData: PropTypes.object,
  failureFetching: PropTypes.shape({
    reason: PropTypes.string.isRequired,
    orderId: PropTypes.string,
  }),
  payOrderResult: PropTypes.shape({
    error: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        reason: PropTypes.string.isRequired,
        orderId: PropTypes.string,
      }),
    ]),
  }),
};

function selectFailureFetchingMessageKey(failureFetchingReason) {
  switch (failureFetchingReason) {
    case FailureOrderPaymentFetchingReason.OrderAlreadyPaid:
      return 'Payment_AlreadyPayed';
    case FailureOrderPaymentFetchingReason.QuoteAlreadyPaid:
      return 'Payment_QuoteAlreadyPayed';
    case FailureOrderPaymentFetchingReason.QuoteCannotBePromoted:
      return 'Payment_QuoteCanNotBePromoted';
    default:
      throw new Error(`Unexpected order payment fetching failure reason: ${failureFetchingReason}.`);
  }
}

function selectFailureOrderPaymentMessageKey(failureFetchingReason) {
  switch (failureFetchingReason) {
    case FailureOrderPaymentReason.OrderAlreadyPaid:
      return 'Payment_AlreadyPayed';
    case FailureOrderPaymentReason.QuoteAlreadyPaid:
      return 'Payment_QuoteAlreadyPayed';
    case FailureOrderPaymentReason.QuoteCannotBePromoted:
      return 'Payment_QuoteCanNotBePromoted';
    default:
      return 'Payment_CannotBePlaced';
  }
}

const Memoized = memo(OrderPayment);
Memoized.selectPropsFromPage = ({
  transactionId,
  order,
  paymentMethods,
  failureFetching,
  payOrderResult,
  additionalCustomerData,
  extraPaymentData,
}) => ({
    transactionId,
    order,
    paymentMethods,
    failureFetching,
    payOrderResult,
    additionalCustomerData,
    extraPaymentData,
  });

export default Memoized;
