import { ofType } from 'redux-observable';
import { merge, BehaviorSubject, identity } from 'rxjs';
import { mergeMap, filter, first, tap, ignoreElements, takeUntil, share } from 'rxjs/operators';
import { NAVIGATED } from 'behavior/routing';

import createAddressEpic from './epic.address';
import createPaymentEpic from './epic.payment';
import createAdditionalInfoEpic from './epic.additionalInfo';
import createLinesEpic from './epic.lines';
import createShippingEpic from './epic.shipping';
import createSubmitEpic from './epic.submit';

function checkoutEpic(action$, state$, deps) {
  const isIdleSubj = new BehaviorSubject(true);

  const addressEpic = createAddressEpic(waitForSubmit);
  const paymentEpic = createPaymentEpic(waitForSubmit);
  const additionalInfoEpic = createAdditionalInfoEpic(waitForSubmit);
  const linesEpic = createLinesEpic();
  const shippingEpic = createShippingEpic(waitForSubmit);
  const submitEpic = createSubmitEpic(isIdleSubj);

  const navigated$ = action$.pipe(ofType(NAVIGATED), share());
  const resetIsIdle$ = navigated$.pipe(
    tap(_ => isIdleSubj.value || setTimeout(() => isIdleSubj.next(true))),
    ignoreElements(),
  );

  return merge(
    addressEpic(action$, state$, deps),
    paymentEpic(action$, state$, deps),
    additionalInfoEpic(action$, state$, deps),
    linesEpic(action$, state$, deps),
    submitEpic(action$, state$, deps),
    shippingEpic(action$, state$, deps),
    resetIsIdle$,
  );

  function waitForSubmit(createObservable) {
    if (isIdleSubj.value)
      return createObservable();

    return isIdleSubj.pipe(
      filter(identity),
      first(),
      mergeMap(createObservable),
      takeUntil(navigated$),
    );
  }
}

export default checkoutEpic;
