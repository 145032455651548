import styles from './Alert.module.scss';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import scrollIntoView from 'scroll-into-view';

export const alertPropTypes = {
  icon: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  role: PropTypes.string,
  scrollOnAppear: PropTypes.bool,
};

const Alert = ({
  icon: Icon,
  children,
  scrollOnAppear,
  className = styles.info,
  role = null,
  ...props
}) => {
  const alertRef = useRef();

  useEffect(() => {
    if (!scrollOnAppear)
      return;

    scrollIntoView(alertRef.current);
  }, []);

  return (
    <div className={`${styles.block} ${className}`} role={role} ref={alertRef} {...props}>
      {Icon &&
        <div className={styles.iconWrapper} role="presentation">
          <Icon className={styles.icon} />
        </div>
      }
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};

Alert.propTypes = alertPropTypes;

export default Alert;
