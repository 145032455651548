export const UPDATE_PRODUCT_CALCULATED_FIELDS = 'PRODUCT/UPDATE/CALCULATED_FIELDS';
export const updateCalculatedFields = options => ({
  type: UPDATE_PRODUCT_CALCULATED_FIELDS,
  payload: { options },
});

export const PRODUCT_CALCULATED_FIELDS_LOADED = 'PRODUCT/CALCULATED_FIELDS/LOADED';
export const productCalculatedFieldsLoaded = product => ({
  type: PRODUCT_CALCULATED_FIELDS_LOADED,
  payload: product,
});

export const REVIEWS_REQUESTED = 'PRODUCT/REVIEWS/REQUESTED';
export const requestReviews = (productId, pageIndex) => ({
  type: REVIEWS_REQUESTED,
  payload: { productId, pageIndex },
});

export const REVIEWS_RECEIVED = 'PRODUCT/REVIEWS/RECEIVED';
export const reviewsReceived = reviews => ({
  type: REVIEWS_RECEIVED,
  payload: reviews,
});

export const REVIEW_SUBMITTED = 'PRODUCT/REVIEW/SUBMITTED';
export const sendReview = data => ({ type: REVIEW_SUBMITTED, payload: data });

export const REVIEW_PROCESSED = 'PRODUCT/REVIEW/PROCESSED';
export const reviewProcessed = success => ({
  type: REVIEW_PROCESSED,
  payload: success,
});

export const VOLUME_PRICES_REQUESTED = 'VOLUME_PRICES/REQUESTED';
export const requestVolumePrices = (productId, variantId, uomId) => ({
  type: VOLUME_PRICES_REQUESTED,
  payload: { productId, variantId, uomId },
});

export const VOLUME_PRICES_RECEIVED = 'VOLUME_PRICES/RECEIVED';
export const volumePriceReceived = volumePrices => ({
  type: VOLUME_PRICES_RECEIVED,
  payload: volumePrices,
});