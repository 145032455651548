import PropTypes from 'prop-types';

const ConditionalWrap = ({ condition, wrapWith, children }) => {
  return condition ? wrapWith(children) : children;
};

ConditionalWrap.propTypes = {
  condition: PropTypes.bool,
  wrapWith: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ConditionalWrap;