import { createReducer } from 'utils/redux';
import {
  WISH_LIST_PRODUCT_REMOVED,
} from 'behavior/wishList/actions';

export default createReducer(null, {
  [WISH_LIST_PRODUCT_REMOVED]: onProductRemoved,
});

function onProductRemoved(state, action) {
  const { productId, uomId, variantId } = action.payload;
  const { productLines } = state;

  const lineToRemove = productLines.find(line => {
    if (variantId || !uomId)
      return line.product.id === productId;

    return line.product.id === productId && line.uom && line.uom.id === uomId;
  });

  if (!lineToRemove || (variantId && !lineToRemove.subLines))
    return state;

  if (!variantId) {
    return {
      ...state,
      productLines: productLines.filter(line => line.id !== lineToRemove.id),
    };
  }

  const sublineToRemove = lineToRemove.subLines.find(subLine => {
    if (!uomId)
      return subLine.variationId === variantId;

    return subLine.variationId === variantId && subLine.uom && subLine.uom.id === uomId;
  });

  if (!sublineToRemove)
    return state;

  if (lineToRemove.subLines.length === 1)
    return {
      ...state,
      productLines: productLines.filter(line => line.id !== lineToRemove.id),
    };

  return {
    ...state,
    productLines: productLines.map(line => {
      if (line.id !== lineToRemove.id)
        return line;

      return {
        ...line,
        subLines: lineToRemove.subLines.filter(subLine => subLine.id !== sublineToRemove.id),
      };
    }),
  };
}
