import { createReducer } from 'utils/redux';
import { 
  VOLUME_ORDER_QUANTITY_CHANGED,
  VOLUME_ORDER_INITIALIZE,
 } from './actions';

const initialState = {
  quantities: [],
};

const onQuantityChanged = (state,action) => {

  const { payload } = action;

  const filteredQuantities = state.quantities.filter(q => q.productId!==payload.productId);

  return { 
    ...state,
    quantities: [...filteredQuantities, payload],
  };
};

const onInitailizeVolumeOrder = () => {
  return initialState;
};

export default createReducer(initialState, {
  [VOLUME_ORDER_QUANTITY_CHANGED]: onQuantityChanged,
  [VOLUME_ORDER_INITIALIZE]: onInitailizeVolumeOrder,
});
