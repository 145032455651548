export const SEARCH_PRODUCT_SUGGESTIONS = 'PRODUCT/SEARCH/SUGGESTION';
export const searchProductsSuggestions = keywords => ({
  type: SEARCH_PRODUCT_SUGGESTIONS,
  payload: { keywords },
});

export const SEARCH_PRODUCT_SUGGESTIONS_COMPLETED = 'PRODUCT/SEARCH/SUGGESTION/COMPLETED';
export const productsSearchSuggestionsCompleted = products => ({
  type: SEARCH_PRODUCT_SUGGESTIONS_COMPLETED,
  payload: products,
});

export const SEARCH_PRODUCT_SUGGESTIONS_CLEARED = 'PRODUCT/SEARCH/SUGGESTION/CLEARED';
export const clearProductsSuggestions = _ => ({
  type: SEARCH_PRODUCT_SUGGESTIONS_CLEARED,
});