export class SwiperShape {
  constructor() {
    /* All values will be calculated there during emitting DOM events and shared between them.
       The proper work of swiper based on using columns grid such like bootstrap grid and it's widths of columns
       in percentage.
     */
    this.rowsArray = [];
    this.baseColWidth = 25; /* value in percentage */
    this.swiping = false;
    this.scrolling = false;
    this.columnsLength = 0;
    this.availableCols = 0;
    this.maxRightSwipe = 0;
    this.shift = 0;
    this.currentDeltaX = 0;
    this.currentShift = 0;
    this.currentSlide = 0;
    this.startX = 0;
    this.startY = 0;
  }

  get isSwipingPossible() {
    const [row] = this.rowsArray;
    return this.rowsArray.length && row.offsetWidth !== row.scrollWidth;
  }

  get maxSwipePosition() {
    return this.columnsLength - this.availableCols;
  }

  registerRow = row => {
    if (row && !this.rowsArray.includes(row)) {
      this.rowsArray.push(row);
      this.rowsArray.length === 1 && this.refreshSwipeData();
      this.shift && (row.style.transform = `translate3d(${this.shift}%, 0, 0)`);
    }
  };

  unRegisterRow = row => {
    if (row && this.rowsArray.includes(row))
      this.rowsArray = this.rowsArray.filter(item => item !== row);
  };

  setCssTranslate = val => {
    this.rowsArray.forEach(el => {
      el.style.transform = `translate3d(${val}%, 0, 0)`;
    });
  };

  updateBaseColWidth = () => {
    const [firstRow] = this.rowsArray;

    if (!firstRow)
      return;

    const rowWidth = firstRow.offsetWidth;
    const itemWidth = firstRow.firstElementChild.offsetWidth;
    this.baseColWidth = (itemWidth / rowWidth) * 100;
  };

  refreshSwipeData = () => {
    if (!this.rowsArray.length)
      return;

    this.columnsLength = this.rowsArray[0].children.length;
    this.availableCols = Math.floor((100 / this.baseColWidth).toPrecision(2));
  };

  swipe(currentSlide, currentShift) {
    this.currentSlide -= currentSlide;
    this.currentShift += currentShift;
    this.swiping = true;
    this.swipeEnd();
  }

  swipePrevious() {
    this.swipe(-1, this.currentSlide < 0 ? 0 : this.baseColWidth);
  }

  swipeNext() {
    this.swipe(1, this.currentSlide > this.maxSwipePosition ? 0 : -this.baseColWidth);
  }

  swipeStart = event => {
    this.rowsArray.forEach(el => {
      el.style.transition = '';
    });

    if (this.swiping)
      return;

    this.refreshSwipeData();

    let touches = null;
    if (event.touches !== undefined)
      touches = event.touches[0];

    this.updateBaseColWidth();

    this.swiping = true;
    this.scrolling = false;
    this.maxRightSwipe = parseFloat(-this.baseColWidth * this.maxSwipePosition - this.baseColWidth);
    this.startX = touches !== null ? touches.pageX : event.clientX;
    this.startY = touches !== null ? touches.pageY : event.clientY;
  };

  swipeMove = (event, swiperBody) => {
    if (!this.swiping || (event.touches && event.touches.length !== 1))
      return;

    const touches = event.touches !== undefined ? event.touches[0] : null;
    const curX = touches !== null ? touches.pageX : event.clientX;
    const curY = touches !== null ? touches.pageY : event.clientY;
    const containerWidth = swiperBody.offsetWidth;
    const horizontalSwipeLength = curX - this.startX;
    const verticalSwipeLength = curY - this.startY;
    const deltaX = Math.round(horizontalSwipeLength * 100) / containerWidth;
    const r = Math.atan2(verticalSwipeLength, horizontalSwipeLength);
    let swipeAngle = Math.round(r * 180 / Math.PI);

    if (swipeAngle < 0) {
      swipeAngle = 360 - Math.abs(swipeAngle);
    }

    /* The "swipeAngle" value there is needed for determining the direction of user's cursor moving and as result
       to decide what user are doing now, either scrolling page horizontally or scrolling the slider vertically.
       (swipeAngle >= 225 && swipeAngle <= 315) - is left direction
       (swipeAngle <= 135 && swipeAngle >= 35) - is right direction
    */
    if ((swipeAngle >= 225 && swipeAngle <= 315) || (swipeAngle <= 135 && swipeAngle >= 35)) {
      this.scrolling = true;
      return;
    } else if (event.cancelable) {
      event.preventDefault();
    }

    let tpmShift = 0;
    if (Math.abs(this.shift) !== deltaX && !this.scrolling) {
      tpmShift = this.shift + deltaX;

      if (tpmShift > this.baseColWidth) {
        tpmShift = this.baseColWidth;
      } else if (tpmShift < this.maxRightSwipe) {
        tpmShift = this.maxRightSwipe;
      }

      this.setCssTranslate(tpmShift);
      this.currentShift = tpmShift;
      this.currentDeltaX = deltaX;
    }
  };

  swipeEnd = () => {
    if (!this.swiping)
      return;

    this.swiping = false;
    this.scrolling = false;

    const prevSlide = this.currentSlide;
    const nextSlide = this.currentShift / -this.baseColWidth;
    const accuracyNum = Math.abs(nextSlide % 1);
    const direction = nextSlide > prevSlide;
    // eslint-disable-next-line no-mixed-operators
    this.currentSlide = accuracyNum > 0.2 && direction || accuracyNum > 0.8 && !direction ? Math.ceil(nextSlide) : Math.floor(nextSlide);

    if (this.currentSlide < 0) {
      this.currentSlide = 0;
    } else if (this.currentSlide > this.maxSwipePosition) {
      this.currentSlide = this.maxSwipePosition;
    }

    this.shift = this.currentSlide * -this.baseColWidth;
    this.currentShift = this.shift;

    this.rowsArray.forEach(el => {
      el.style.transition = 'transform 500ms ease';
    });
    this.setCssTranslate(this.shift);
  };

  swipeClick = event => {
    if (this.currentDeltaX && Math.abs(this.currentDeltaX) >= 1.5) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
    }
  };

  handleResizeRefresh = () => {
    this.shift = 0;
    this.currentShift = 0;
    this.currentSlide = 0;
    this.updateBaseColWidth();

    this.setCssTranslate(0);
    this.refreshSwipeData();
  };
}
