import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect as formikConnect } from 'formik';
import scrollIntoView from 'scroll-into-view';
import { getScrollState } from 'scroll-lock';

const Form = ({
  formik: {
    handleSubmit,
    handleReset,
    isValid,
    submitCount,
    isSubmitting,
  },
  name,
  focus,
  ...attributes
}) => {
  const formRef = useRef(null);

  useEffect(() => {
    if (!focus)
      return;

    const id = setTimeout(() => {
      const element = formRef.current.elements[0];

      if (element) {
        element.focus();
        if (element.select)
          element.select();
      }
    }, 150);

    return () => clearTimeout(id);
  }, []);

  useEffect(() => {
    if (!submitCount || isValid || isSubmitting || !formRef.current)
      return;

    const invalidFormControl = formRef.current.querySelector('[aria-invalid]:not([aria-invalid="false"])');

    if (!invalidFormControl)
      return;

    const scrollState = getScrollState();

    scrollIntoView(
      invalidFormControl,
      {
        time: 200,
        isScrollable: (target, defaultIsScrollable) => {
          if (!scrollState && target === window)
            return false;

          return defaultIsScrollable(target);
        },
      },
      () => invalidFormControl.focus(),
    );
  }, [isSubmitting]);

  return (
    <form
      name={name}
      ref={formRef}
      onReset={handleReset}
      onSubmit={handleSubmit}
      {...attributes}
    />
  );
};

Form.propTypes = {
  formik: PropTypes.shape({
    handleSubmit: PropTypes.func,
    handleReset: PropTypes.func,
    isValid: PropTypes.bool,
    submitCount: PropTypes.number,
    isSubmitting: PropTypes.bool,
  }),
  name: PropTypes.string,
  focus: PropTypes.bool,
};

export default formikConnect(Form);
