import styles from '../OrderResult.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { CheckoutProcessNames } from 'behavior/pages/checkout';
import { useSimpleTexts } from 'components/sanaText';
import { checkoutProcessNamePropType } from '../propTypes';
import { logout } from 'behavior/user';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { Placeholder } from 'components/primitives/placeholders';
import { Helmet } from 'react-helmet';
import { usePageTitle } from 'components/objects/hooks';
import { useSelector } from 'react-redux';

const OrderFailed = ({ checkoutProcessName }) => {
  const dispatch = useDispatch();
  const isInvoiceCheckout = checkoutProcessName === CheckoutProcessNames.Invoice;
  const isAuthenticated = useSelector(s => s.user.isAuthenticated);

  let descriptionTextKey, linkTextKey, linkRoute, linkClass;

  if (isInvoiceCheckout) {
    descriptionTextKey = 'InvoicePaymentFailed_Description';
    linkTextKey = 'GotoInvoicesPage';
    linkRoute = routesBuilder.forInvoices;
    linkClass = styles.invoicesLink;
  } else {
    descriptionTextKey = 'OrderFailed_Description';
    linkTextKey = 'GotoBasketPage';
    linkRoute = routesBuilder.forBasket;
    linkClass = styles.basketLink;
  }

  const [texts, loaded] = useOrderFailedTexts(descriptionTextKey, linkTextKey);
  const pageTitle = usePageTitle();

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <p className={styles.description}>
        {texts.description}
      </p>
      {loaded
        ? (
          <ul className={styles.actionsList}>
            <li>
              <Link
                className={`${linkStyles.arrowed} ${linkClass}`}
                to={linkRoute}
              >
                {texts.link}
              </Link>
            </li>
            <li>
              <Link
                className={`${linkStyles.arrowed} ${styles.homeLink}`}
                to={routesBuilder.forHome}
              >
                {texts.homePage}
              </Link>
            </li>
            {isAuthenticated &&
              <li>
                <button
                  className={`${linkStyles.link} ${linkStyles.arrowed} ${styles.btnLogout}`}
                  onClick={() => dispatch(logout())}
                >
                  {texts.logout}
                </button>
              </li>
            }
          </ul>
        )
        : (
          <ul className={styles.actionsList}>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
          </ul>
        )
      }
    </>
  );
};

OrderFailed.propTypes = {
  checkoutProcessName: checkoutProcessNamePropType.isRequired,
};

const Memoized = memo(OrderFailed);
Memoized.selectPropsFromPage = ({ transaction }) => transaction;

export default Memoized;

function useOrderFailedTexts(descriptionTextKey, linkTextKey) {
  const {
    texts: [
      description,
      link,
      homePage,
      logout,
    ],
    loaded,
  } = useSimpleTexts([
    descriptionTextKey,
    linkTextKey,
    'GoToHomePage',
    'SubmitLogoutLinkText',
  ]);

  return [
    {
      description,
      link,
      homePage,
      logout,
    },
    loaded,
  ];
}
