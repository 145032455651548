import createSystemRenderer from 'components/objects/systemPages/createSystemRenderer';
import { CheckoutProcessNames } from 'behavior/pages/checkout';
import OrderSubmit from './OrderSubmit';

export default page => {
  const { transaction: { checkoutProcessName } } = page;

  const headerTextKey = checkoutProcessName === CheckoutProcessNames.Quote || checkoutProcessName === CheckoutProcessNames.EditQuote
    ? 'SubmitQuote_QuoteProcessedSuccessfully'
    : checkoutProcessName === CheckoutProcessNames.Invoice
      ? 'SubmitInvoice_ProcessedSuccessfully'
      : 'SubmitOrder_OrderProcessedSuccessfully';

  return createSystemRenderer(headerTextKey, OrderSubmit)(page);
};
