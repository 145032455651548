import type { ClientRoute } from './types';

const routeWithIdSegment = (path: string, segmentName = 'id', segmentToString?: (segment: unknown) => string): ClientRoute => {
  return {
    getVirtualPath(params) {
      if (!params || params[segmentName] == null)
        throw new Error(`Can't build URL for the page. '${segmentName}' route value was not specified.`);

      const segmentValue = params[segmentName];
      return path + (segmentToString ? segmentToString(segmentValue) : segmentValue);
    },
  };
};

export default routeWithIdSegment;
