import styles from './Faq.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import FaqPageItem from './FaqPageItem';
import { UseSanaTexts } from 'components/sanaText';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';

const FaqPage = ({ faqItems }) => (
  <UseSanaTexts options={['Aria_FAQ']} dontWait>
    {([ariaFAQText]) => (
      <div
        role="list"
        aria-label={stripHtmlTagsAndFormatting(ariaFAQText)}
        itemScope
        itemType="http://schema.org/FAQPage"
        className={styles.accordions}
      >
        {faqItems && faqItems.map((item, index) => <FaqPageItem key={index} item={item} level={0} />)}
      </div>
    )}
  </UseSanaTexts>
);

FaqPage.propTypes = {
  faqItems: PropTypes.array,
};

export default memo(FaqPage);