import PropTypes from 'prop-types';
import { Row, Col } from 'components/primitives/grid';
import { SimpleText } from 'components/sanaText';
import SubAccountForm from './form';

const Details = ({ subAccount, options, authorizers }) => {
  return (
    <>
      <h1>
        <SimpleText textKey={subAccount ? 'EditSubAccount' : 'CreateNewSubAccount'} />
      </h1>
      <Row>
        <Col md={9}>
          <SubAccountForm account={subAccount} options={options} authorizers={authorizers} />
        </Col>
      </Row>
    </>
  );
};

Details.propTypes = {
  subAccount: PropTypes.object,
  options: PropTypes.object,
};

Details.selectPropsFromPage = function ({ subAccount, options, authorizers }) {
  return { subAccount, options, authorizers };
};

export default Details;