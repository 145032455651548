import { useContext } from 'react';
import ProductContext from '../ProductContext';
import ViewBomButton from './Button';

const Components = () => {
  const { product, variantId } = useContext(ProductContext);

  if (!product.hasBomComponents)
    return null;

  const { bomComponents, variants } = product;

  return (
    <ViewBomButton
      key={product.id}
      variantId={variantId}
      bomComponents={bomComponents}
      variants={variants}
    />
  );
};

export default Components;
