import { memo } from 'react';
import { DocumentType } from 'behavior/documents';
import { routesBuilder } from 'routes';
import Documents, { selectPropsFromPage } from '../Documents';
import BulkOrdersList from './OrdersBulkList';
import { useSelector } from 'react-redux';
import { InfoAlert } from 'components/primitives/alerts';
import { toLocaleDate } from 'utils/format';
import { useCultureName } from 'utils/hooks';

const textKeys = {
  introduction: 'OrderHistory_IntroductionText',
  listHeader: 'RecentOrders',
  loadMore: 'ShowNextNOrders',
  noItems: 'Orders_NoOrderHistory',
};

const bulkOrdersRoute = routesBuilder.forBulkOrders();
const BulkOrders = memo(props => {

  const cultureName = useCultureName();
  const { activeBulkOrder } = useSelector(s => s.page);
  const { committedAmount, endDate, expectedShipmentDate, bulkType } = activeBulkOrder ?? { };

  const isAmountBulkOrder = bulkType === 'Amount';

  return (
    <>
      {activeBulkOrder && 
        <InfoAlert>
          {isAmountBulkOrder && <span>{`At the proposed rate of business, we estimate that you'll spend £${committedAmount} by ${toLocaleDate(endDate, cultureName)}, so we can ship it on ${toLocaleDate(expectedShipmentDate, cultureName)}.`}</span>}
          {!isAmountBulkOrder && <span>{`At the proposed rate of business, we estimate that you'll purchase ${committedAmount}M³ by ${toLocaleDate(endDate, cultureName)}, so we can ship it on ${toLocaleDate(expectedShipmentDate, cultureName)}.`}</span>}
        </InfoAlert>
      }
      <Documents
        loadMoreRoute={bulkOrdersRoute}
        textKeys={textKeys}
        documentType={DocumentType.OrderBulk}
        {...props}
      >
        <BulkOrdersList documents={props.documents} />
      </Documents>
    </>
  );
});

BulkOrders.selectPropsFromPage = selectPropsFromPage;

export default BulkOrders;
