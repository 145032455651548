import { memo } from 'react';
import { useLoadEffect } from 'utils/hooks';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { requestCalculatedFields } from 'behavior/pages/productList';

const CalculatedFieldsLoader = ({ products, children }) => {
  const dispatch = useDispatch();

  useLoadEffect(() => {
    const productsToUpdate = products.filter(p => !p.calculated);
    if (!productsToUpdate.length)
      return;

    const options = { ids: productsToUpdate.map(p => p.id), page: { size: productsToUpdate.length, index: 0 } };
    dispatch(requestCalculatedFields(options));
  }, [products]);

  return children;
};

CalculatedFieldsLoader.propTypes = {
  products: PropTypes.array.isRequired,
  children: PropTypes.node,
};

export default memo(CalculatedFieldsLoader);