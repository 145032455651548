export const PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_STARTED = 'PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_STARTED';
export const startNewProductConfiguration = (input, updatedById) => ({
  type: PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_STARTED,
  payload: { input, updatedById },
});

export const PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_RECEIVED = 'PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_RECEIVED';
export const receiveNewProductConfiguration = ({ configurationId, configuratorUrl }) => ({
  type: PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_RECEIVED,
  payload: { configurationId, configuratorUrl },
});

export const PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_FAILED  = 'PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_FAILED ';
export const newProductConfigurationFailed = updatedById => ({
  type: PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_FAILED ,
  payload: updatedById,
});

export const PRODUCT_CONFIGURATOR_MESSAGE_RECEIVED = 'PRODUCT_CONFIGURATOR_MESSAGE_RECEIVED';
export const receiveProductConfiguratorMessage = message => ({
  type: PRODUCT_CONFIGURATOR_MESSAGE_RECEIVED,
  payload: { message },
});

export const PRODUCT_CONFIGURATOR_ADD_TO_BASKET_TRIGGERED = 'PRODUCT_CONFIGURATOR_ADD_TO_BASKET_TRIGGERED';
export const triggerAddProductConfigurationToBasket = configurationId => ({
  type: PRODUCT_CONFIGURATOR_ADD_TO_BASKET_TRIGGERED,
  payload: { configurationId },
});

export const PRODUCT_CONFIGURATOR_ADD_TO_BASKET_COMPLETED = 'PRODUCT_CONFIGURATOR_ADD_TO_BASKET_COMPLETED';
export const completeAddProductConfigurationToBasket = () => ({
  type: PRODUCT_CONFIGURATOR_ADD_TO_BASKET_COMPLETED,
});

export const PRODUCT_CONFIGURATOR_REDIRECT_TO_BASKET_TRIGGERED = 'PRODUCT_CONFIGURATOR_REDIRECT_TO_BASKET_TRIGGERED';
export const triggerRedirectToBasket = () => ({
  type: PRODUCT_CONFIGURATOR_REDIRECT_TO_BASKET_TRIGGERED,
});

export const PRODUCT_CONFIGURATOR_FINISHED = 'PRODUCT_CONFIGURATOR_FINISHED';
export const finishProductConfigurator = () => ({
  type: PRODUCT_CONFIGURATOR_FINISHED,
});