type Values<T> = T[keyof T];
type Locale = string;

export const DayOfWeekIndexes = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
} as const;

export type DayOfWeek = Values<typeof DayOfWeekIndexes>;
export type CalendarType = 'Arabic' | 'Hebrew' | 'ISO_8601' | 'US';
export type PeriodType = 'century' | 'decade' | 'year' | 'month' | 'day';

export type CalendarView = Exclude<PeriodType, 'day'>;
export type ValueType = Exclude<PeriodType, 'century'>;

export const CalendarTypeLocalesMap: { [K in CalendarType]: readonly Locale[]} = {
  US: [
    'en-CA',
    'en-US',
    'es-AR',
    'es-BO',
    'es-CL',
    'es-CO',
    'es-CR',
    'es-DO',
    'es-EC',
    'es-GT',
    'es-HN',
    'es-MX',
    'es-NI',
    'es-PA',
    'es-PE',
    'es-PR',
    'es-SV',
    'es-VE',
    'pt-BR',
  ],
  Arabic: [
    // ar-LB, ar-MA intentionally not included.
    'ar',
    'ar-AE',
    'ar-BH',
    'ar-DZ',
    'ar-EG',
    'ar-IQ',
    'ar-JO',
    'ar-KW',
    'ar-LY',
    'ar-OM',
    'ar-QA',
    'ar-SA',
    'ar-SD',
    'ar-SY',
    'ar-YE',
    'dv',
    'dv-MV',
    'ps',
    'ps-AR',
  ],
  Hebrew: [
    'he',
    'he-IL',
  ],
  ISO_8601: [],
} as const;

export const ViewValueMap: { [K in CalendarView]: ValueType } = {
  century: 'decade',
  decade: 'year',
  year: 'month',
  month: 'day',
} as const;

export const СalendarViews: readonly CalendarView[] = ['century', 'decade', 'year', 'month'];
