import styles from '../../Checkout.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { Steps } from 'behavior/pages/checkout';
import { toUrlHash } from 'utils/url';
import StepDoneMark from '../StepDoneMark';

const ShippingMethodStep = ({
  className,
  isQuote,
  isPromotion,
  isCompleted,
  asLink,
  children,
}) => {
  const headerText = (
    <SimpleText
      textKey={`${isQuote ? 'Quote' : ''}CheckoutStep_ShippingMethods`}
    />
  );

  return (
    <section className={className}>
      <div className={styles.header}>
        <h2>
          {asLink
            ? (
              <Link
                to={isPromotion ? routesBuilder.forQuotePromotion(Steps.Shipping) : routesBuilder.forCheckout(isQuote, Steps.Shipping)}
                url={toUrlHash(Steps.Shipping)}
              >
                {headerText}
              </Link>
            ) : headerText
          }
        </h2>
        {isCompleted && <StepDoneMark />}
      </div>
      {children &&
        <div className={styles.body}>
          {children}
        </div>
      }
    </section>
  );
};

ShippingMethodStep.propTypes = {
  className: PropTypes.string,
  isQuote: PropTypes.bool,
  isPromotion: PropTypes.bool,
  isCompleted: PropTypes.bool,
  asLink: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default ShippingMethodStep;
