import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import { IntegerField as IntegerFieldCore } from '../../fields';
import validationFieldPropType from './validationFieldPropType';

const IntegerField = hoistStatics(
  ({
    fieldName,
    fieldTitle,
    validation,
    required,
    className,
    disabled,
    placeholder,
    maxLength,
  }) => (
      <IntegerFieldCore
        fieldName={fieldName}
        fieldTitle={fieldTitle}
        validation={validation}
        required={required}
        className={className}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={maxLength}
      />
    ),
  IntegerFieldCore,
);

IntegerField.propTypes = {
  ...IntegerFieldCore.propTypes,
  required: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  validation: validationFieldPropType,
  maxLength: PropTypes.number,
};

export default IntegerField;
