import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { routesBuilder } from 'routes';
import { useCurrentRouteAsBackTo } from 'utils/hooks';

export const useProductRoute = productId => {
  const backTo = useCurrentRouteAsBackTo();
  const pageBackTo = useSelector(state => state.page.backTo);

  const productBackTo = {
    ...backTo,
    routeData: {
      ...backTo.routeData,
      options: {
        backTo: pageBackTo,
      },
    },
  };

  return useCallback(() => routesBuilder.forProduct(productId, productBackTo), [productId, pageBackTo, backTo]);
};
