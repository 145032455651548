import { InfoAlert } from 'components/primitives/alerts';
import { RichText } from 'components/sanaText';

const AvailabilityDatesWarningMessage = () => {

  return (
    <InfoAlert>
      <RichText textKey="AvailablilityDateWarning" />
    </InfoAlert>
  );

};

export default AvailabilityDatesWarningMessage;