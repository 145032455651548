import { stripHtmlTagsAndFormatting } from '../helpers';
import { createUrl } from '../url';
import type { TrackingOptions } from './types';

type Node = {
  title: string | null | undefined;
  link: { url: string | null | undefined } | null;
}

export default function create(nodes: Node[], options: TrackingOptions) {
  if (!nodes.length)
    return null;

  const { origin } = options;

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListOrder: 'Ascending',
    itemListElement: nodes.map((node, i) => {
      const url = node.link && node.link.url;
      return {
        '@type': 'ListItem',
        position: i + 1,
        name: stripHtmlTagsAndFormatting(node.title),
        item: createUrl(url, origin),
      };
    }),
  };

  return schema;
}
