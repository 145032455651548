export function filterLines(lines, search, pageIndex, pageSize) {
  const keywords = search && search.keywords && search.keywords.toUpperCase();
  const productIdsSet = new Set((search && search.ids) || []);
  if (search && search.ids)
    for (const id of search.ids)
      productIdsSet.add(id.toUpperCase());

  const skip = pageIndex * pageSize;
  const result = [];
  let i = 0;

  for (const line of lines) {
    if (shouldInclude(line)) {
      if (i++ < skip) {
        continue;
      }

      if (result.length < pageSize)
        result.push(line);
    }
  }

  result.totalCount = i;
  return result;

  function shouldInclude({ id, product, category }) {
    if (!keywords)
      return true;

    if (id.toUpperCase().startsWith(keywords))
      return true;

    if (product) {
      const productId = product.id.toUpperCase();
      if (productIdsSet.has(productId) || productId.includes(keywords))
        return true;
      if (product.exists && product.title && product.title.toUpperCase().includes(keywords))
        return true;
    }
    if (category && (category.id.toUpperCase().includes(keywords) || category.name.toUpperCase().includes(keywords))) {
      return true;
    }

    return false;
  }
}