import styles from './Header.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSanaTexts } from 'components/sanaText';
import { renderHTML } from 'utils/render';
import { PhoneIcon } from 'components/primitives/icons';

const TopInfo = ({ shopPhoneNumber = '' }) => {
  const [overriddenTxt, callUsTxt] = useSanaTexts(['HeaderInfoMessage', 'CallUsIfHaveQuestions']).texts;
  if (!overriddenTxt && !callUsTxt)
    return null;

  return (
    <span className={styles.topInfo}>
      {overriddenTxt != null
        ? renderHTML(transformText(overriddenTxt, shopPhoneNumber))
        : (
          <>
            <PhoneIcon className={styles.phoneIcon} aria-hidden />
            {renderHTML(transformText(callUsTxt, shopPhoneNumber))}
          </>
        )}
    </span>
  );
};

TopInfo.propTypes = {
  shopPhoneNumber: PropTypes.string,
};

export default connect(
  ({ settings: { shopPhoneNumber } }) => ({ shopPhoneNumber }),
)(TopInfo);

function transformText(text, shopPhoneNumber) {
  return text.replace('[PHONE]', shopPhoneNumber);
}
