import styles from '../../Checkout.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import formStyles from 'components/objects/forms/SanaForm.module.scss';
import { memo, useMemo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { Input } from 'components/primitives/form';
import { useOnChange } from 'utils/hooks';
import debounce from 'lodash/debounce';
import { renderHTML, makeSimpleText } from 'utils/render';
import { stripHtmlTags } from 'utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import scrollIntoView from 'scroll-into-view';
import { Placeholder } from 'components/primitives/placeholders';
import { Button } from 'components/primitives/buttons';
import { SearchIcon } from 'components/primitives/icons';

const DEFAULT_FILTER = { q: '', size: 5 };

const AddressSelection = ({ addresses, currentId, onSelection, showValidation }) => {

  const [texts, textsLoaded] = useAddressSelectionTexts();
  const inputRef = useRef();
  const [filter, setFilter] = useState(DEFAULT_FILTER);

  const sorted = useMemo(() => {
    const index = addresses.findIndex(a => a.id === currentId);
    if (index <= 0)
      return addresses;

    const selectedAddress = addresses[index];
    const newAddresses = [selectedAddress, ...addresses];
    newAddresses.splice(index + 1, 1);

    return newAddresses;
  }, [addresses]);

  useOnChange(() => setFilter(f => ({ q: f.q, size: DEFAULT_FILTER.size }), false), [sorted]);

  const [filtered, hasMore] = useMemo(() => {
    if (!filter.q)
      return [sorted.slice(0, filter.size), sorted.length > filter.size];

    const result = [];
    const q = filter.q.toLowerCase();
    for (const addr of sorted) {
      const value = addr.formatted.toLowerCase();
      if (value.includes(q) && stripHtmlTags(value).includes(q)) {
        if (result.length < filter.size)
          result.push(addr);
        else
          return [result, true];
      }
    }

    return [result, false];
  }, [filter]);

  const searchRef = useRef();
  if (!searchRef.current) {
    const debounced = debounce(q => setFilter({ q, size: DEFAULT_FILTER.size }), 300);
    searchRef.current = e => debounced(e.target.value);
  }

  const handleButtonClick = e => {
    const id = e.currentTarget.value;
    onSelection(id);
  };

  useEffect(
    () => void (showValidation && scrollIntoView(document.querySelector(`.${styles.validation}`), { time: 200 })),
    [showValidation],
  );

  const isMoreThanOne = sorted.length > 1;

  return (
    <div className={styles.addresses}>
      {isMoreThanOne &&
        <div className={styles.search}>
          <Input type="text"
            placeholder={texts.inputPlaceholder}
            onChange={searchRef.current}
            onKeyDown={handleEsc}
            innerRef={inputRef}
          />
          <span className={styles.iconWrapper}>
            <SearchIcon aria-hidden />
          </span>
        </div>
      }
      {showValidation &&
        <div className={`${formStyles.validation} ${styles.validation}`} role="alert">
          <FontAwesomeIcon icon={faTimesCircle} />
          {texts.validationMsg}
        </div>
      }
      <ul className={!isMoreThanOne ? styles.single : null}>
        {filtered.map((address, index) => {
          const current = currentId === address.id;

          return (
            <li key={address.id} aria-current={current}>
              <span>
                <i className="visually-hidden">{index + 1}</i>
                {renderHTML(address.formatted)}
              </span>
              {isMoreThanOne &&
                <div className={styles.select}>
                  {textsLoaded
                    ? (
                      <Button
                        value={address.id}
                        onClick={current ? null : handleButtonClick}
                        aria-disabled={current}
                        tabIndex={current ? -1 : null}
                        className={`${btnStyles.btn} ${btnStyles.btnSmall} ${btnStyles.noIcon}`}
                      >
                        {current ? texts.btnTextSelected : texts.btnTextSelect}
                      </Button>
                    )
                    : <Placeholder className={styles.selectBtnPlaceholder} />
                  }
                </div>
              }
            </li>
          );
        })}
      </ul>
      {hasMore && (
        textsLoaded
          ? (
            <Button
              onClick={() => setFilter({ q: filter.q, size: filter.size + DEFAULT_FILTER.size })}
              className={`${btnStyles.btn} ${btnStyles.btnSmall} ${btnStyles.noIcon} ${styles.loadMore}`}
            >
              {texts.btnTextShowNextNAddresses}
            </Button>
          )
          : <Placeholder className={styles.showNextBtnPlaceholder} />
      )}
    </div>
  );

  function handleEsc(e) {
    if (e.which === 27 || e.key === 'Escape') {
      e.preventDefault();
      e.target.value = '';
      searchRef.current(e);
    }
  }
};

AddressSelection.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    formatted: PropTypes.string.isRequired,
  }).isRequired),
  currentId: PropTypes.string,
  onSelection: PropTypes.func.isRequired,
  showValidation: PropTypes.bool,
};

export default memo(AddressSelection);

function useAddressSelectionTexts() {
  const { texts: [
    inputPlaceholder,
    btnTextSelect,
    btnTextSelected,
    btnTextShowNextNAddresses,
    validationMsg,

  ], loaded } = useSanaTexts([
    'OrderAddress_Watermark',
    'ButtonText_Select',
    'ButtonText_Selected',
    'ButtonText_ShowNextNAddresses',
    'OrderAddress_SelectionValidationMessage',
  ]);

  return [
    {
      inputPlaceholder: makeSimpleText(inputPlaceholder),
      btnTextSelect: makeSimpleText(btnTextSelect),
      btnTextSelected: makeSimpleText(btnTextSelected),
      btnTextShowNextNAddresses: makeSimpleText(btnTextShowNextNAddresses, [DEFAULT_FILTER.size]),
      validationMsg: makeSimpleText(validationMsg),
    },
    loaded,
  ];
}