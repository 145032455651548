import { Container } from 'components/primitives/grid';
import { BackToContainer } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';
import { PageContent } from 'components/objects/pages';

function createSystemRenderer(titleTextKey, Component, backTo) {
  return page => {
    const header = page.content && page.content.header;
    const footer = page.content && page.content.footer;

    const props = Component.selectPropsFromPage
      ? Component.selectPropsFromPage(page)
      : undefined;

    return (
      <>
        {backTo && <BackToContainer options={backTo} />}
        {titleTextKey &&
          <Container>
            <h1><SimpleText textKey={titleTextKey} /></h1>
          </Container>
        }
        <PageContent content={header} position="header" />
        <Container>
          <Component {...props} />
        </Container>
        <PageContent content={footer} position="footer" />
      </>
    );
  };
}

export default createSystemRenderer;
