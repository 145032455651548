import {
  loadCalculatedFieldsQuery,
  loadVariantsCalculatedFieldsQuery,
  loadProductsGeneralInfoQuery,
  loadVariantsGeneralInfoQuery,
} from './queries';
import {
  PRODUCTLIST_CALCULATED_FIELDS_REQUESTED,
  PRODUCTLIST_PRODUCTS_GENERALINFO_REQUESTED,
  PRODUCTLIST_VARIANTS_GENERALINFO_REQUESTED,
  productsUpdated,
  productsGeneralInfoLoaded,
  requestCalculatedFields,
} from 'behavior/pages/productList/actions';
import {
  map,
  takeUntil,
  mergeMap,
  switchMap,
  pluck,
  startWith,
  withLatestFrom,
  filter,
} from 'rxjs/operators';
import { LOCATION_CHANGED } from 'behavior/events';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { parseQuery } from 'utils/url';
import { merge, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { shallowEqual } from 'react-redux';

export default (action$, state$, { api, logger, scope }) => {
  const locationChanged$ = action$.pipe(
    ofType(LOCATION_CHANGED),
    withLatestFrom(state$, isLocationChanged),
    filter(Boolean),
  );

  const calculatedFieldsLoad$ = action$.pipe(
    ofType(PRODUCTLIST_CALCULATED_FIELDS_REQUESTED),
    mergeMap(({ payload: { options, variantsOnly } }) =>
      api.graphApi(selectCalculatedFieldsQuery(variantsOnly), { options }).pipe(
        map(data => productsUpdated(data.catalog.products.products)),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
      )),
  );

  const productsGeneralInfoLoad$ = action$.pipe(
    ofType(PRODUCTLIST_PRODUCTS_GENERALINFO_REQUESTED),
    withLatestFrom(state$),
    switchMap(([action, state]) => api.graphApi(loadProductsGeneralInfoQuery, {
      id: action.payload.listPageId,
      options: action.payload.options,
      loadLargeImages: scope === 'SERVER',
      loadCategories: state.analytics && state.analytics.isTrackingEnabled,
    }, { retries: 0 })
      .pipe(
        pluck('pages', 'productList', 'products', 'products'),
        mergeMap(products => of(
          productsGeneralInfoLoaded(
            products,
            action.payload.appendProducts,
            action.payload.options.page.size,
          ),
          unsetLoadingIndicator(),
        )),
        retryWithToast(action$, logger),
        startWith(setLoadingIndicator()),
      ),
    ),
  );

  const variantsGeneralFieldsLoad$ = action$.pipe(
    ofType(PRODUCTLIST_VARIANTS_GENERALINFO_REQUESTED),
    mergeMap(action => api.graphApi(loadVariantsGeneralInfoQuery, action.payload).pipe(
      mergeMap(data =>
        of(
          productsUpdated(data.catalog.products.products),
          requestCalculatedFields(action.payload.options, true),
        )),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );

  return merge(calculatedFieldsLoad$, productsGeneralInfoLoad$, variantsGeneralFieldsLoad$);
};

function isLocationChanged(_action, state) {
  const { routing: { location, navigatingTo } } = state;
  if (!location)
    return true;

  const navigatingLocation = navigatingTo.location;
  if (location.pathname !== navigatingLocation.pathname)
    return true;

  const query = parseQuery(location.search);
  const navigatingQuery = parseQuery(navigatingLocation.search);
  delete query.count;
  delete navigatingQuery.count;

  return !shallowEqual(query, navigatingQuery);
}

function selectCalculatedFieldsQuery(variantsOnly) {
  return variantsOnly
    ? loadVariantsCalculatedFieldsQuery
    : loadCalculatedFieldsQuery;
}
