import styles from './Details.module.scss';
import { Container } from 'components/primitives/grid';
import Details from './Details';
import PageBreadcrumb from 'components/objects/pageBreadcrumb';
import BackLink from './BackLink';

function productPageRenderer({ preset, middleContent, footerContent }) {
  return (
    <>
      <Container>
        <PageBreadcrumb backLinkComponent={BackLink} className={styles.nav} />
      </Container>
      <Details preset={preset} middleContent={middleContent} footerContent={footerContent} />
    </>
  );
}

export default {
  productDetails: productPageRenderer,
};