import { CUSTOMERS_RECEIVED } from './actions';

export default function (state, action) {
  if (action.type === CUSTOMERS_RECEIVED) {
    const newCustomers = action.payload.customers;
    newCustomers.keywords = action.payload.keywords;

    if (action.payload.keywords === state.customers.keywords) {
      newCustomers.list = state.customers.list.concat(newCustomers.list);
    }

    return {
      ...state,
      customers: newCustomers,
    };
  }
  return state;
}