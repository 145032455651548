import { filterExistingImages, iEquals } from 'utils/helpers';
import { Steps, ShippingAddressOption } from './constants';
import { selectAddress } from './actions';
import { requestAbility } from 'behavior/user/epic';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import { DocumentType } from 'behavior/documents';

export function filterImages(checkoutInfo) {
  if (checkoutInfo && checkoutInfo.productLines && checkoutInfo.productLines.list)
    for (const line of checkoutInfo.productLines.list)
      if (line.product && line.product.images)
        line.product.images = filterExistingImages(line.product.images);

  return checkoutInfo;
}

const stepsNames = Object.values(Steps);
export const getStepByUrlHash = hash => {
  if (hash && (hash = hash.substr(1)))
    return stepsNames.find(stepName => iEquals(stepName, hash));
};

export function updateShippingAddressIfNeeded(checkoutInfo, state$, dependencies) {
  let shippingAddress = checkoutInfo.shippingAddress;
  if (shippingAddress && (shippingAddress.shippingOption || !shippingAddress.address))
    return of(undefined);

  if (!shippingAddress)
    checkoutInfo.shippingAddress = shippingAddress = {};

  if (!checkoutInfo.billingAddress)
    return of(shipToExisting(shippingAddress, checkoutInfo.shippingAddresses));

  return requestAbility(AbilityTo.ShipToBillingAddress, state$, dependencies).pipe(
    map(shipToBilling => {
      if (shipToBilling === AbilityState.Available) {
        shippingAddress.address = checkoutInfo.billingAddress;
        shippingAddress.shippingOption = ShippingAddressOption.Billing;
        return selectAddress();
      }

      return shipToExisting(shippingAddress, checkoutInfo.shippingAddresses);
    }),
  );
}

function shipToExisting(shippingAddress, shippingAddresses) {
  if (!shippingAddresses?.length)
    return;

  shippingAddress.address = shippingAddresses[0];
  shippingAddress.shippingOption = ShippingAddressOption.Existing;
  return selectAddress(shippingAddress.address.id);
}

export function adjustShippingMethodData(info) {
  if (!info)
    return;

  info.shippingMethodId = info.shippingMethod?.id;

  delete info.shippingMethod;
}

export function adjustPaymentMethodData(info) {
  info.paymentMethodId = info.paymentMethod?.info?.id;
  info.recheckPaymentMethod = !info.paymentMethodId && !!info.paymentMethod;

  info.extraPaymentStep = info.paymentMethod?.extraPaymentCheckoutStep;
  info.customerDataStep = info.paymentMethod?.additionalCustomerDataStep;

  delete info.paymentMethod;
}

export function adjustGuestProfileData(info) {
  if (info.guestProfile) {
    info.billingAddress = info.guestProfile.billingAddress;
    info.profileFields = info.guestProfile.fields;
    info.email = info.guestProfile.email;
  }
  delete info.guestProfile;
}

export function adjustCheckoutAddresses(checkoutInfo, viewer) {
  const { customer: { billingAddress, shippingAddresses } } = viewer;
  checkoutInfo.billingAddress = billingAddress;
  checkoutInfo.shippingAddresses = shippingAddresses;
}

export function navigateOnIncorrect(checkoutInfo) {
  if (checkoutInfo && checkoutInfo.quote)
    return navigateTo(routesBuilder.forDocument(checkoutInfo.quote.id, DocumentType.Quote), checkoutInfo.quote.url);

  return navigateTo(routesBuilder.forBasket());
}