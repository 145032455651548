import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { invoicePaymentPageQuery } from './queries';
import { initSystemPageContent } from 'behavior/pages/system';

export default (routeData, _, { api }) => {
  const { params: { orderId } } = routeData;

  return api.graphApi(invoicePaymentPageQuery, { orderId }).pipe(
    map(({ 
      pages: { invoicePayment: invoicePaymentPage },
      invoicePayment: invoicePaymentInfo,
    }) => {
      if (!invoicePaymentInfo)
        return null;

      const { order, paymentMethods: { list: paymentMethods } } = invoicePaymentInfo;

      return {
        page: {
          component: PageComponentNames.InvoicePayment,
          order,
          paymentMethods: paymentMethods.map(({ info }) => info),
          ...invoicePaymentPage,
        },
      };
    }),
    initSystemPageContent(),
  );
};