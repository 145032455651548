import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { takeUntil, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { LOCATION_CHANGED } from 'behavior/events';
import { documentReceived, DOCUMENT_LINES_REQUESTED } from '../actions';
import { documentLinesQuery } from '../queries';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { getDocumentField } from 'behavior/documents';

export default function (action$, _state$, dependencies) {
  const { api, logger } = dependencies;
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();

  const locationChanged$ = action$.pipe(
    ofType(LOCATION_CHANGED),
  );

  const loadLines$ = action$.pipe(
    ofType(DOCUMENT_LINES_REQUESTED),
    switchMap(({ payload: { id, documentType, orderId } }) =>
      api.graphApi(documentLinesQuery(documentType), { id, orderId }).pipe(
        mergeMap(({ documents }) =>
          of(
            documentReceived(documents[getDocumentField(documentType)].byId, documentType, true),
            unsetLoading),
        ),
        takeUntil(locationChanged$),
        retryWithToast(action$, logger),
        startWith(setLoading),
      ),
    ),
  );

  return loadLines$;
}
