import type { AddonPackageInfo } from './types';

export const ADDONS_METADATA_REQUESTED = 'ADDONS/METADATA/REQUESTED' as const;
export const requestAddonsMetadata = () => ({
  type: ADDONS_METADATA_REQUESTED,
});

export const ADDONS_METADATA_LOADED = 'ADDONS/METADATA/LOADED' as const;
export const addonsMetadataLoaded = (metadata: AddonPackageInfo[]) => ({
  type: ADDONS_METADATA_LOADED,
  payload: metadata,
});

export type MetadataActions = ReturnType<
  | typeof requestAddonsMetadata
  | typeof addonsMetadataLoaded
>