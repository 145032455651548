import type { Cookies } from '../cookies';
import type { CookieSerializeOptions } from 'cookie';

export type ValueStorageOptions = {
  cookies: Cookies;
  cookieName: string;
  sameSite?: true | false | 'lax' | 'strict' | 'none';
  maxAge?: number;
}

export function createValueStorage({ cookies, cookieName, sameSite = 'strict', maxAge }: ValueStorageOptions) {
  let currentValue: string | null | undefined = undefined;

  return {
    saveValue(value: string | null) {
      if (!value)
        value = '';

      currentValue = value;
      const options: CookieSerializeOptions = {
        sameSite,
        maxAge,
        path: '/',
      };
      if (sameSite === 'none')
        options.secure = true;

      cookies.set(cookieName, currentValue, options);
    },
    getValue() : string | null {
      if (currentValue === undefined)
        currentValue = cookies.get(cookieName) || null;
      return currentValue;
    },
  };
}
