import styles from '../Order.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { edit } from 'behavior/pages/document';
import { EditResult } from 'behavior/documents';
import useConfirmation from 'components/objects/confirmation';
import { RichText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';

const EditButton = ({ document, documentNotifications }) => {
  const { showConfirmation, clearConfirmation, confirmationModal } = useConfirmation();
  const [editOrderPending, setEditOrderPending] = useState(false);

  const dispatch = useDispatch();
  const editOrderResult = useSelector(s => s.page.editResult);

  const handleClick = forceClear => {
    setEditOrderPending(true);
    documentNotifications.hideOrderNotifications();
    dispatch(edit(document.id, forceClear));
  };

  useEffect(() => {
    if (editOrderResult == null || editOrderResult === EditResult.Success)
      return;

    setEditOrderPending(false);
  }, [editOrderResult]);

  useEffect(() => {
    if (!editOrderResult)
      return;

    if (editOrderResult !== EditResult.Success)
      setEditOrderPending(false);

    clearConfirmation();

    switch (editOrderResult) {
      case EditResult.BasketNotEmpty:
        showConfirmation(
          handleClick.bind(null, true),
          'Info',
          <RichText
            textKey="ProductsFromCurrentBasketWillBeRemoved"
            placeholder={<Placeholder className={styles.confirmationTextPlaceholder} />}
          />,
          {
            okText: 'ButtonText_Continue',
          },
          undefined,
          styles.basketNotEmptyPopup,
        );
        break;

      case EditResult.NoDocument:
        documentNotifications.orderNotFound();
        break;

      default:
        return;
    }
  }, [editOrderResult]);

  return (
    <>
      <SanaButton
        className={`${btnStyles.btnSmall} ${styles.btnAction} ${styles.btnEdit}`}
        textKey="Edit"
        onClick={editOrderPending ? null : handleClick.bind(null, false)}
      />
      {confirmationModal}
    </>
  );
};

EditButton.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  documentNotifications: PropTypes.shape({
    orderNotFound: PropTypes.func.isRequired,
    hideOrderNotifications: PropTypes.func.isRequired,
  }).isRequired,
};

export default EditButton;
