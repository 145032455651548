import { FORGOT_PASSWORD_RECEIVED } from './actions';

export default function forgotPasswordReducer(state, action){
  if (action.type === FORGOT_PASSWORD_RECEIVED) {
    return {
      ...state,
      forgotSucceededAt: Date.now(),
    };
  }
  return state;
}