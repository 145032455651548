import { useSelector } from 'react-redux';
import { useServices } from '../services';
import { ProductTrackingOptions, TrackingOptions } from './types';

export const useProductTrackingOptions = (uomTitleKey?: string): ProductTrackingOptions => {
  const { context: { origin } } = useServices();

  return useSelector(({
    user: { currencyId },
    settings: { product },
    sanaTexts,
  }) => ({
    origin,
    currencyId,
    noImageLarge: product && product.noImage.large,
    uomTitle: uomTitleKey && sanaTexts[uomTitleKey],
  }),
    _ => true,
  );
};

export const useTrackingOptions = (): TrackingOptions => {
  const { context: { origin } } = useServices();
  return { origin };
};
