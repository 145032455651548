import styles from './Lines.module.scss';
import { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import MediaBlock from './MediaBlock';
import ProductInfo from './ProductInfo';
import PriceNode from '../PriceNode';
import ActionLinks from './ActionLinks';
import Discount from './Discount';
import QuantityBox from './QuantityBox';
import { routesBuilder } from 'routes';
import DefaultTemplate from './templates/Default';
import MobileTemplate from './templates/Mobile';
import ServiceLine from './ServiceLine';
import UomLabel from './UomLabel';
import BasketLinesContext from './BasketLinesContext';
import { useUpdateContext } from '../updateContext';
import { ProductTrackingContext } from 'components/objects/analytics';
import { EVENT_SOURCES } from 'behavior/analytics';

const ConfiguredProductLine = ({ line, configuration }) => {
  const { showImages, showPrice, currencyInfo, isMobile } = useContext(BasketLinesContext);
  const updateContext = useUpdateContext();

  const product = configuration.products[0];

  const { id: productId, title: productTitle, variantTitle, url, image, description } = product;
  const { id: lineId, quantity, price, subTotal, discount, serviceLines, extendedTexts, uom } = line;
  const uomId = uom && uom.id;
  const uomDescription = uom && uom.description;
  const route = routesBuilder.forProduct.bind(null, productId);
  const imageUrl = image && image.small;

  const productActionBlock = showImages && (
    <MediaBlock
      productTitle={productTitle}
      imageUrl={imageUrl}
      productUrl={url}
      route={route}
    />
  );

  const productInfo = (
    <ProductInfo
      productUrl={url}
      route={route}
      productTitle={productTitle}
      productId={productId}
      variantTitle={variantTitle}
      extendedTexts={extendedTexts}
      description={description}
    />
  );

  const actionLinks = (
    <ActionLinks
      productUrl={url}
      route={route}
      onDelete={updateContext.delete.bind(updateContext, lineId)}
    />
  );

  const priceNode = showPrice && <PriceNode price={price} currencyInfo={currencyInfo} className={styles.priceNode} />;
  const subTotalNode = showPrice && <PriceNode price={subTotal} currencyInfo={currencyInfo} />;

  const uomData = { id: uomId, description: uomDescription };
  const quantityBox = (
    <QuantityBox
      initialQuantity={quantity}
      getCurrentQuantity={updateContext.quantities.get.bind(updateContext.quantities, lineId)}
      uom={uomData}
      id={'qty' + lineId}
      updateQuantity={updateContext.setQuantity.bind(updateContext, lineId, quantity)}
    />
  );
  const uomLabel = <UomLabel uom={uomData} />;

  const productDiscount = !!discount && <Discount discount={discount} currencyInfo={currencyInfo} />;

  const LineTemplate = isMobile ? MobileTemplate : DefaultTemplate;

    const trackingData = { 
      product: { ...product, price, uom },
      trackingSource: EVENT_SOURCES.shoppingCartPage,
    };

  return (
    <ProductTrackingContext.Provider value={trackingData}>
      <LineTemplate
        productActionBlock={productActionBlock}
        productInfo={productInfo}
        quantityBox={quantityBox}
        uomLabel={uomLabel}
        actionLinks={actionLinks}
        priceNode={priceNode}
        productDiscount={productDiscount}
        subTotal={subTotalNode}
      />
      {serviceLines && serviceLines.map((serviceLine, index) => (
        <ServiceLine
          key={index}
          {...serviceLine}
          className={styles.serviceLine}
          elementId={`${index}`}
        />
      ))}
    </ProductTrackingContext.Provider>
  );
};

ConfiguredProductLine.propTypes = {
  line: PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantity: PropTypes.number,
    uom: PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
    price: PropTypes.number,
    subTotal: PropTypes.number,
    discount: PropTypes.number,
    serviceLines: PropTypes.array,
    extendedTexts: PropTypes.array,
  }).isRequired,
  configuration: PropTypes.shape({
    id: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      variantTitle: PropTypes.string,
      url: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        small: PropTypes.string,
      }),
    })),
  }).isRequired,
};

export default memo(ConfiguredProductLine);