import { LASTVIEWED_PRODUCTS_PAGE_RECEIVED } from './actions';
import { VIEWER_CHANGED } from 'behavior/events';
import { deleteProductCalculatedInfo } from 'behavior/products/product';
import { createReducer } from 'utils/redux';

export default createReducer(null, {
  [LASTVIEWED_PRODUCTS_PAGE_RECEIVED]: onProductsReceived,
  [VIEWER_CHANGED]: onViewerChanged,
});

function onProductsReceived(state, action) {
  const products = action.payload;
  const lastViewed = state.lastViewed;

  if (lastViewed && lastViewed.length)
    return {
      ...state,
      lastViewed: lastViewed.map(product => {
        const calculated = products.find(({ id }) => id === product.id);
        return { ...product, ...calculated };
      }),
    };

  return { ...state, lastViewed: products };
}

function onViewerChanged(state, _action) {
  if (!state.lastViewed)
    return state;

  const lastViewed = state.lastViewed.map(deleteProductCalculatedInfo);
  return { ...state, lastViewed };
}
