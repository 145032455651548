import { createContext } from 'react';

const UpdateContext = createContext({
  quantities: null,
  setQuantity: null,
  delete: null,
  writeOnlySubmit: null,
  submit: null,
});

export default UpdateContext;