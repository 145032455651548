import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import DateOnly from 'date-only';
import { toLongLocaleDate } from 'utils/format';
import { useCultureName } from 'utils/hooks';
import { getIn, useFormikContext } from 'formik';

const ReadonlyDateField = ({ fieldName }) => {
  const formik = useFormikContext();
  const culture = useCultureName();
  const value = getIn(formik.values, fieldName);

  return useMemo(() => {
    const date = DateOnly.parse(value);
    return toLongLocaleDate(date, culture);
  }, [value]);
};

ReadonlyDateField.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default memo(ReadonlyDateField);
