import { fromEventPattern, asyncScheduler, EMPTY, merge } from 'rxjs';
import { throttleTime, shareReplay, map, startWith, filter } from 'rxjs/operators';
import { DEFAULT_BREAKPOINT } from './constants';

/**
 * Checks if Intersection Observer API is available in current environment.
 * @returns {boolean} - Status flag indicating if Intersection Observer API is available.
 */
export function isIntersectionObserverAvailable() {
  return (
    typeof window !== 'undefined' &&
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window
  );
}

/**
 * @typedef {Object} PageScrollValues
 * @property {number} x number of pixels that page content is scrolled vertically.
 * @property {number} y number of pixels that page content is scrolled horizontally.
 */
/**
 * Gets number of pixels that page content is scrolled vertically and horizontally.
 * @returns {PageScrollValues} object with number of pixels that page content is scrolled vertically and horizontally.
 */
export const getPageScroll = () => {
  if (typeof window === 'undefined')
    return { x: 0, y: 0 };

  return {
    x: window.scrollX || window.pageXOffset,
    y: window.scrollY || window.pageYOffset,
  };
};

export const getBreakpointPath = matchedBreakpoint => `/images/${matchedBreakpoint}/`;

/**
 * Creates observable which emits matched image breakpoint value depending on current browser screen resolution.
 * @param {number[]} breakpoints Array containing breakpoint values.
 * @returns {Observable} Breakpoints observable.
 */
export function getBreakpointsObservable(breakpoints) {
  if (typeof window === 'undefined' || typeof window.matchMedia !== 'function')
    return EMPTY;

  const querySources = [];
  const queries = getBreakpointsQueries(breakpoints);

  for (const mediaQuery of queries) {
    querySources.push(
      fromEventPattern(
        addMediaQueryHandler(mediaQuery),
        removeMediaQueryHandler(mediaQuery),
      ).pipe(
        startWith(mediaQuery),
        throttleTime(20, asyncScheduler, { leading: false, trailing: true }),
        filter(({ matches }) => matches),
        map(({ target, breakpoint }) => breakpoint || target.breakpoint),
      ),
    );
  }

  return merge(...querySources).pipe(
    shareReplay(1),
  );
}

/**
 * Creates array of media query objects from passed image breakpoint values.
 * @param {number[]} breakpoints Array containing breakpoint values.
 * @returns {MediaQueryList[]} Array of objects which store information on a generated from breakpoint media query.
 */
function getBreakpointsQueries(breakpoints) {
  const { matchMedia } = window;
  return breakpoints.reduce((acc, breakpoint, index, arr) => {
    if (index === 0) {
      const mediaQueryList = matchMedia(`screen and (max-width: ${breakpoint}px)`);
      mediaQueryList.breakpoint = breakpoint;
      acc.push(mediaQueryList);
      return acc;
    }

    if (index === arr.length - 1) {
      let mediaQueryList = matchMedia(`screen and (min-width: ${arr[index - 1] + 1}px) and (max-width: ${breakpoint}px)`);
      mediaQueryList.breakpoint = breakpoint;
      acc.push(mediaQueryList);
      mediaQueryList = matchMedia(`screen and (min-width: ${breakpoint + 1}px)`);
      mediaQueryList.breakpoint = DEFAULT_BREAKPOINT;
      acc.push(mediaQueryList);
      return acc;
    }

    const mediaQueryList = matchMedia(`screen and (min-width: ${arr[index - 1] + 1}px) and (max-width: ${breakpoint}px)`);
    mediaQueryList.breakpoint = breakpoint;
    acc.push(mediaQueryList);
    return acc;
  }, []);
}

/**
 * Creates function which adds handler function for MediaQueryListener that will run a custom callback
 * function in response to the media query status changing
 * @param {MediaQueryList} mediaQueryList Object which stores information on a media query.
 * @returns {function} Add MediaQueryListener handler function.
 */
function addMediaQueryHandler(mediaQueryList) {
  return handler => mediaQueryList.addListener(handler);
}

/**
 * Creates function which removes handler function for MediaQueryListener
 * @param {MediaQueryList} mediaQueryList Object which stores information on a media query.
 * @returns {function} Remove MediaQueryListener handler function.
 */
function removeMediaQueryHandler(mediaQueryList) {
  return handler => mediaQueryList.removeListener(handler);
}