import styles from '../Order.module.scss';
import tableStyles from 'components/primitives/table/Table.module.scss';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { OrderProp } from './OrderProp';
import OrderDateProp from './OrderDateProp';
import ShippingStatus from '../../documents/ShippingStatus';
import TrackingProp from './TrackingProp';
import SalesAgreementLink from './SalesAgreementLink';
import { connect } from 'react-redux';
import { makeSimpleText } from 'utils/render';
import { abilitiesPropType } from 'behavior/user';
import { AbilityTo } from 'behavior/user/constants';
import { useHasAbilities } from 'components/objects/user';
import { DocumentType } from 'behavior/documents';
import { documentTypePropType } from '../propTypes';

const LeftColumn = ({ document, documentType, showShippingStatus, isB2BCustomer }) => {
  const [
    orderNumberText,
    documentNumberText,
    shippingStatusText,
    shippingMethodText,
    pickupLocationText,
    trackingLinkText,
    shipmentDateText,
    locationCodeText,
    locationTitleText,
    requestedDeliveryDateText,
    promisedDeliveryDateText,
    salesPersonText,
    contactText,
    referenceNumberText,
    salesAgreementText,
    estimatedDeliveryDate
  ] = useLeftColumnTexts();

  const {
    documentId,
    orderId,
    shipping: { tracking, methodName: shippingMethodName },
    pickupLocation,
    shipmentDate,
    requestedDeliveryDate,
    promisedDeliveryDate,
    salesPerson,
    contact,
    referenceNo,
    salesAgreementId,
    bulkStatus,
    expectedShipmentDate,
  } = document;

  const [canUseSalesAgreements] = useHasAbilities(AbilityTo.UseSalesAgreements);

  const showSalesAgreementLink = isB2BCustomer && !!salesAgreementId && canUseSalesAgreements;
  const showOrderId = documentType !== DocumentType.Order && documentType !== DocumentType.Quote;

  const isBulkOrder = documentType === DocumentType.OrderBulk;
  const isOrder = documentType === DocumentType.Order;

  const shipDateDocumentType = documentType !== DocumentType.Invoice && 
    documentType !== DocumentType.ReturnOrder && documentType !== DocumentType.CreditNote
    && !isBulkOrder;

  return (
    <div className={styles.column}>
      <table className={`${tableStyles.table} ${tableStyles.nameValue} ${styles.table}`}>
        <tbody>
          {showOrderId && <OrderProp name={documentNumberText} value={documentId} className={styles.documentNumber} />}
          <OrderProp name={orderNumberText} value={showOrderId ? orderId : documentId} className={styles.orderNumber} />
          {showShippingStatus && !isBulkOrder && 
            <OrderProp name={shippingStatusText} value={<ShippingStatus document={document} />} className={styles.shippingStatus} />
          }
          {showShippingStatus && !isBulkOrder &&
            <OrderProp name={shippingStatusText} value={bulkStatus} className={styles.shippingStatus} />
          }
          {!!shippingMethodName && <OrderProp name={shippingMethodText} value={shippingMethodName} className={styles.shippingMethod} />}
          {pickupLocation && <OrderProp name={pickupLocationText} value={pickupLocation.address || null} className={styles.pickupLocation} />}
          <TrackingProp name={trackingLinkText} tracking={tracking} />
          {isB2BCustomer && shipDateDocumentType && 
            <OrderDateProp 
              name={isOrder ? estimatedDeliveryDate : shipmentDateText} 
              date={shipmentDate} 
              className={styles.shipmentDate} 
            />
          }
          {isB2BCustomer && isBulkOrder && <OrderDateProp name={shipmentDateText} date={expectedShipmentDate} className={styles.shipmentDate} />}
          {isB2BCustomer && pickupLocation && (
            <OrderProp
              name={pickupLocation.title ? locationTitleText : locationCodeText}
              value={pickupLocation.title || pickupLocation.code || null}
              className={styles.locationCode}
            />
          )}
          {requestedDeliveryDate != null &&
            <OrderDateProp name={requestedDeliveryDateText} date={requestedDeliveryDate} className={styles.requestedDeliveryDate} />
          }
          {isB2BCustomer && promisedDeliveryDate != null &&
            <OrderDateProp name={promisedDeliveryDateText} date={promisedDeliveryDate || null} className={styles.promisedDeliveryDate} />
          }
          {isB2BCustomer && salesPerson && <OrderProp name={salesPersonText} value={salesPerson.name || salesPerson.id} className={styles.salesPersonCode} />}
          {!isBulkOrder && isB2BCustomer && (
            <OrderProp
              name={contactText}
              value={contact ? (contact.name || contact.id) : ''}
              className={styles.sellToContact}
            />
          )}
          {!!referenceNo && <OrderProp name={referenceNumberText} value={referenceNo} className={styles.referenceNumber} />}
          {showSalesAgreementLink && <SalesAgreementLink agreementId={salesAgreementId} text={salesAgreementText} />}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = ({ settings: { documents } }) => ({
  showShippingStatus: !!documents && documents.showShippingStatus,
});

export default connect(mapStateToProps)(LeftColumn);

LeftColumn.propTypes = {
  isB2BCustomer: PropTypes.bool.isRequired,
  showShippingStatus: PropTypes.bool.isRequired,
  document: PropTypes.shape({
    documentId: PropTypes.string.isRequired,
    orderId: PropTypes.string,
    shipping: PropTypes.shape({
      tracking: PropTypes.object.isRequired,
      methodName: PropTypes.string,
    }).isRequired,
    pickupLocation: PropTypes.shape({
      address: PropTypes.string,
      title: PropTypes.string,
      code: PropTypes.string,
    }),
    shipmentDate: PropTypes.string,
    requestedDeliveryDate: PropTypes.string,
    promisedDeliveryDate: PropTypes.string,
    salesPerson: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
    contact: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
    referenceNo: PropTypes.string,
    salesAgreementId: PropTypes.string,
    comments: PropTypes.string,
  }).isRequired,
  documentType: documentTypePropType.isRequired,
  abilities: abilitiesPropType,
};

function useLeftColumnTexts() {
  return useSanaTexts([
    'OrderNumber',
    'DocumentNo',
    'ShippingStatus',
    'OrderDetail_ShippingMethod',
    'OrderDetail_PickupLocation',
    'OrderDetail_ShippingTrackingLink',
    'ShipmentDate',
    'OrderDetail_LocationCode',
    'OrderDetail_LocationTitle',
    'RequestedDeliveryDate',
    'OrderDetail_PromisedDeliveryDate',
    'OrderDetail_SalesPersonCode',
    'OrderDetail_SellToContact',
    'ReferenceNumber',
    'SalesAgreementId',
    'OrderDetail_EstimatedDeliveryDate',
  ], makeSimpleText).texts;
}
