import styles from './BasketB2C.module.scss';
import basketStyles from '../Basket.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { connect } from 'react-redux';
import { Row } from 'components/primitives/grid';
import { clearBasket } from 'behavior/basket';
import { UseSanaButton } from 'components/primitives/buttons';
import { Placeholder } from 'components/primitives/placeholders';
import BasketContent from '../BasketContent';
import EmptyBasket from '../Empty';
import Footer from '../footer';
import NonOrderable from '../nonOrderable';
import Actions from './Actions';
import RecalculationSuggestion from '../RecalculationSuggestion';
import { useUpdateContext } from '../updateContext';
import { basketModelPropTypes } from '../PropTypes';
import EditOrderInfo from '../EditOrderInfo';
import CreditLimitMessage from '../CreditLimitMessage';
import { TrashCanIcon, CartRefreshIcon } from 'components/primitives/icons';

const BasketB2C = ({ model, dispatch }) => {
  if (model == null)
    return null;

  const { submit } = useUpdateContext();

  const {
    productLines: { totalCount },
    nonOrderableLines,
    page,
    isBlocked,
  } = model;

  const nonOrderable = <NonOrderable nonOrderableLines={nonOrderableLines} />;

  if (!totalCount) {
    return (
      <>
        {nonOrderable}
        <EmptyBasket />
        <Row crossAxisAlign="center" className={styles.actionsFooter}>
          <Actions isEmpty editDocumentType={model.editDocumentType} />
        </Row>
      </>
    );
  }

  const basketActionsPlaceholder = <Placeholder className={styles.basketActionsPlaceholder} />;
  const editMsg = model.editDocumentId ? <EditOrderInfo documentId={model.editDocumentId} /> : null;

  return (
    <>
      {editMsg}
      <Row crossAxisAlign="center" className={styles.actionsHeader}>
        <Actions
          page={page}
          totalCount={totalCount}
          editDocumentType={model.editDocumentType}
          isBlocked={isBlocked}
        />
      </Row>
      {nonOrderable}
      <CreditLimitMessage creditLimit={model.creditLimit} />
      <RecalculationSuggestion />
      <BasketContent productLines={model.productLines} serviceLines={model.serviceLines} />
      <div className={`${styles.basketActions} ${basketStyles.buttons}`}>
        <UseSanaButton textKey="ClearBasket" placeholder={basketActionsPlaceholder}>
          {text => (
            <button
              className={`${linkStyles.link} ${basketStyles.clearBtn}`}
              type="button"
              onClick={dispatch.bind(null, clearBasket())}
            >
              <TrashCanIcon className={styles.icon} aria-hidden />
              {text}
            </button>
          )}
        </UseSanaButton>
        <UseSanaButton textKey="UpdateBasket" placeholder={basketActionsPlaceholder}>
          {text => (
            <button
              className={`${linkStyles.link} ${basketStyles.recalculateBtn}`}
              type="button"
              onClick={submit}
            >
              <CartRefreshIcon className={styles.icon} aria-hidden />
              {text}
            </button>
          )}
        </UseSanaButton>
      </div>
      <div className={styles.footerWrapper}>
        <Footer {...Footer.selectBasketProps(model)} />
      </div>
      <Row crossAxisAlign="center" className={styles.actionsFooter}>
        <Actions
          page={page}
          totalCount={totalCount}
          showPagingTotal
          editDocumentType={model.editDocumentType}
          isBlocked={isBlocked}
        />
      </Row>
    </>
  );
};

BasketB2C.propTypes = basketModelPropTypes;

export default connect(
  ({ basket: { model } }) => ({ model }),
)(BasketB2C);
