import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import Tooltip from 'components/primitives/tooltip';
import { makeSimpleText } from 'utils/render';

const ShippingCostLabel = ({ labelOnly } ) => {
  const [title, message] =
    useSanaTexts(['ShippingCosts', 'Basket_ShippingCostsTooltipBody'], makeSimpleText).texts;

  if (labelOnly)
    return title;

  return (
    <Tooltip id="shippingCosts" title={title} body={message}>
      <span aria-hidden>{title}</span>
    </Tooltip>
  );
};

ShippingCostLabel.propTypes = {
  labelOnly: PropTypes.bool,
};

export default ShippingCostLabel;
