import styles from './ProductSelector.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/primitives/spinner/Spinner';

const LoadingIndicator = ({ text }) => (
  <div className={styles.loader}>
    <div className={styles.spinner}><Spinner /></div>
    {text}
  </div>
);

LoadingIndicator.propTypes = {
  text: PropTypes.string,
};

export default memo(LoadingIndicator);