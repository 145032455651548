import styles from './ClosedStore.module.scss';
import { memo } from 'react';
import { Container } from 'components/primitives/grid';
import { Copyright } from 'components/primitives/copyright';

export default memo(() => (
  <footer id="footer">
    <Container className={styles.footer}>
      <Copyright />
    </Container>
  </footer>
));