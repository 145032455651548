import styles from '../Order.module.scss';
import modalStyles from 'components/objects/modals/Modal.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PropTypes from 'prop-types';
import { Modal } from 'components/objects/modals';
import { SanaForm } from 'components/objects/forms';
import { TextAreaField } from 'components/objects/forms/fields';
import { SanaButton } from 'components/primitives/buttons';
import { SimpleText } from 'components/sanaText';

const DeclineAuthorizationModal = ({ opened, hide, handleDecline }) => {
  return (
    <Modal opened={opened} hide={hide} className={styles.declineAuthorizationPopup}>
      <h2 className={`h3 ${modalStyles.title}`}><SimpleText textKey="OrderAuthorization_Feedback" /></h2>
      <SanaForm
        name="DeclineOrderConfirmation"
        onSubmit={handleDecline}
      >
        <TextAreaField
          fieldName="feedbackMessage"
          maxLength={1000}
          autoFocus
        />
        <div className={styles.declineAuthorizationActions}>
          <SanaButton
            type="submit"
            textKey="Decline"
            className={`${btnStyles.btn} ${btnStyles.btnExtraSmall} ${btnStyles.noIcon} ${btnStyles.btnAction}`}
          />
        </div>
      </SanaForm>
    </Modal>
  );
};

DeclineAuthorizationModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  handleDecline: PropTypes.func.isRequired,
};

export default DeclineAuthorizationModal;
