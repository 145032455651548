import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import { initSystemPageContent } from '../system';
import { loadCreateReturnOrderPageQuery } from './queries';

export default (_routeData, _state$, { api }) => api.graphApi(loadCreateReturnOrderPageQuery).pipe(
  map(({ pages, settings: { documents: { returnOrder } } }) => {
    const page = pages.createDocFreeReturnOrder;
    if (!page)
      return null;

    page.products = {};
    page.returnReasons = returnOrder.reasons;
    page.fileUploadSettings = returnOrder.attachments;

    page.component = PageComponentNames.CreateDocFreeReturnOrder;

    return { page };
  }),
  initSystemPageContent(),
);
