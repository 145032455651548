import { Subject, asyncScheduler } from 'rxjs';
import { share, observeOn } from 'rxjs/operators';
import { normalizeAddonId } from './helpers';
import type { AddonExports } from './types';

type Entry = {
  hash: string | null;
  addon: AddonExports | null;
};

type RegistryEvent = Entry & {
  id: string;
};

export type Registry = ReturnType<typeof createRegistry>;

export default function createRegistry() {
  const entries = new Map<string, Entry>();
  const events = new Subject<RegistryEvent>();
  const updates = events.pipe(
    observeOn(asyncScheduler),
    share(),
  );

  return {
    get(id: string, hash: string | null | undefined) {
      const entry = entries.get(normalizeAddonId(id));
      if (hash === undefined)
        return entry && entry.addon;

      return entry && entry.hash === hash ? entry.addon : undefined;
    },
    getEntries() {
      return [...entries.entries()]
        .map(([id, { hash, addon }]) => ({ id, hash, addon }));
    },
    add(id: string, hash: string | null, addon: AddonExports | null) {
      const addonId = normalizeAddonId(id);

      entries.set(addonId, { hash, addon });
      events.next({ id: addonId, hash, addon });
    },
    get updates() {
      return updates;
    },
    getIds() {
      return [...entries.keys()];
    },
  };
}
