import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import { DatePickerField as DatePickerFieldCore } from '../../fields';
import validationFieldPropType from './validationFieldPropType';

const DatePickerField = hoistStatics(
  ({
    fieldName,
    fieldTitle,
    validation,
    required,
    className,
    minDate,
    maxDate,
  }) => (
      <DatePickerFieldCore
        fieldName={fieldName}
        fieldTitle={fieldTitle}
        validation={validation}
        required={required}
        className={className}
        minDate={minDate}
        maxDate={maxDate}
      />
    ),
  DatePickerFieldCore,
);

DatePickerField.propTypes = {
  ...DatePickerFieldCore.propTypes,
  required: PropTypes.bool,
  className: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  validation: validationFieldPropType,
};

export default DatePickerField;
