function useVerticalScrolling(tableWrapperRef, stickyHeaderWrapperRef) {
  let verticalScrollPos = 0;

  return (styles, force) => {
    const verticalScroll = tableWrapperRef.current.scrollTop;

    if (verticalScroll === verticalScrollPos && !force)
      return;

    const stickyHeaderColTable = stickyHeaderWrapperRef.current.querySelector(`.${styles.stickyCol} table`);
    const stickyHeaderRow = stickyHeaderWrapperRef.current.querySelector(`.${styles.stickyRow}`);
    const cornerCell = stickyHeaderWrapperRef.current.querySelector(`.${styles.cornerCell}`);
    //vertical scrolling
    verticalScrollPos = verticalScroll;
    const cornerCellHeight = tableWrapperRef.current.querySelector('th:first-child').getBoundingClientRect().height;
    stickyHeaderColTable.style.top = -verticalScroll + cornerCellHeight + 'px';

    if (verticalScroll > 0) {
      cornerCell.classList.add(styles.vertical);
      stickyHeaderRow.classList.add(styles.vertical);
    } else {
      cornerCell.classList.remove(styles.vertical);
      stickyHeaderRow.classList.remove(styles.vertical);
    }
  };
}

function useHorizontalScrolling(tableWrapperRef, stickyHeaderWrapperRef) {
  let horizontalScrollPos = 0;

  return (styles, force) => {
    const horizontalScroll = tableWrapperRef.current.scrollLeft;

    if (horizontalScroll === horizontalScrollPos && !force)
      return;

    const stickyHeaderCol = stickyHeaderWrapperRef.current.querySelector(`.${styles.stickyCol}`);
    const stickyHeaderRow = stickyHeaderWrapperRef.current.querySelector(`.${styles.stickyRow}`);
    const cornerCell = stickyHeaderWrapperRef.current.querySelector(`.${styles.cornerCell}`);
    const stickyHeaderRowTable = stickyHeaderRow.querySelector('table');
    //horizontal scrolling
    horizontalScrollPos = horizontalScroll;
    stickyHeaderRowTable.style.left = -horizontalScroll + 'px';
    if (horizontalScroll > 0) {
      cornerCell.classList.add(styles.horizontal);
      stickyHeaderCol.classList.add(styles.horizontal);
    }
    else {
      cornerCell.classList.remove(styles.horizontal);
      stickyHeaderCol.classList.remove(styles.horizontal);
    }
  };
}

export function useStickyHeadersScrolling(tableWrapperRef, stickyHeaderWrapperRef) {
  const scrollHorizontal = useHorizontalScrolling(tableWrapperRef, stickyHeaderWrapperRef);
  const scrollVertical = useVerticalScrolling(tableWrapperRef, stickyHeaderWrapperRef);

  return (styles, force, hasRowHeaders) => {
    if (!tableWrapperRef.current)
      return;

    scrollVertical(styles, force);
    if (hasRowHeaders)
      scrollHorizontal(styles, force);
  };
}