import { useContext, useMemo } from 'react';
import { EntityTemplateFieldsContext } from './Context';

export function useCountryField() {
  const fields = useContext(EntityTemplateFieldsContext);

  return useMemo(() => {
    if (fields == null)
      return null;

    return fields.find(f => f.editor === 'COUNTRY' || f.editor === 'COUNTRY_READ_ONLY');
  }, [fields]);
}
