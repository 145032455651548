import styles from './Spacer.module.scss';
import PropTypes from 'prop-types';

const SpacerBlock = ({ isDesignerMode }) => <div className={isDesignerMode ? styles.body : null} />;

SpacerBlock.propTypes = {
  isDesignerMode: PropTypes.bool,
};

export default SpacerBlock;
