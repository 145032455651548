import { first, pluck, map, startWith } from 'rxjs/operators';

export default (routeData, state$, _dependencies) => state$.pipe(
  startWith(state$.value),
  pluck('settings'),
  first(s => s.loaded),
  map(s => {
    const homePage = s.homePage?.to;
    if (!homePage)
      return null;

    return {
      forwardTo: {
        ...homePage,
        params: { ...homePage.params, ...routeData.params },
      },
    };
  }),
);
