import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSimpleTexts } from 'components/sanaText';
import { usePageTitle } from 'components/objects/hooks';
import ForgotPasswordForm from 'components/objects/forgotPassword/ForgotPasswordForm';
import { useVerticalOverflowFix } from 'utils/hooks';
import { BackTo } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { RichText } from 'components/sanaText';
import ClosedStoreLayout from '../ClosedStoreLayout';

const ClosedShopForgotPassword = ({ email }) => {
  const backTexts = ['ButtonText_BackToLogin', 'ButtonText_Login'];
  const [title] = useSimpleTexts(['ForgotPassword']).texts;
  const pageTitle = usePageTitle(title);

  useVerticalOverflowFix();

  return (
    <ClosedStoreLayout pageTitle={pageTitle}>
      <div>
        <BackTo to={routesBuilder.forLogin} texts={backTexts} />
      </div>
      <div>
        <h1>
          {title}
        </h1>
        <RichText textKey="ForgotPassword_Intro" />
      </div>
      <ForgotPasswordForm email={email} />
    </ClosedStoreLayout>
  );
};

ClosedShopForgotPassword.propTypes = {
  email: PropTypes.string,
};

const Memoized = memo(ClosedShopForgotPassword);

Memoized.selectPropsFromPage = ({ options }) => options;

export default Memoized;