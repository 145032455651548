import styles from './Basket.module.scss';
import { memo } from 'react';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';

const BasketTitle = () => (
  <h1 className={styles.title}>
    <SimpleText textKey="ShoppingBasket_Header" placeholder={<Placeholder className={styles.titlePlaceholder} />} />
  </h1>
);

export default memo(BasketTitle);