import styles from '../Orders.module.scss';
import PropTypes from 'prop-types';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { connect } from 'react-redux';
import { CustomerTypes } from 'behavior/user';
import { SimpleText } from 'components/sanaText';
import { Table } from 'components/primitives/table';
import { toLocaleDate } from 'utils/format';
import ShippingStatus from '../ShippingStatus';
import ShippingTracking from '../ShippingTracking';
import { FormattedPrice } from 'components/primitives/price';
import ViewDetailsLink from '../ViewDetailsLink';
import { DocumentType } from 'behavior/documents';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';

const BulkOrdersList = ({
  documents,
  showShippingStatus,
  isB2CCustomer,
  culture,
  previewDocumentsCount,
}) => {
  const [showPrices] = useHasAbilities(AbilityTo.ViewPrices);
  const isPreview = useIsPreview();
  
  return (
    <Table className={styles.ordersList}>
      <thead>
        <tr>
          <th id="headerId" className={styles.documentIdCell}><SimpleText textKey="OrderNumber" /></th>
          <th id="headerDate" className={styles.dateCell}><SimpleText textKey="OrderDate" /></th>
          <th id="headerAddress" className={styles.billToCell} data-wt-type={isB2CCustomer ? 'ShipToName' : 'BillToName'}>
            <SimpleText textKey={isB2CCustomer
              ? 'OrderDetail_ShipToName'
              : 'OrderDetail_BillToName'}
            />
          </th>
          {showPrices && <th id="headerTotal" className={styles.priceCell}><SimpleText textKey="Total" /></th>}
          {showShippingStatus && <th id="headerStatus" className={styles.statusCell}><SimpleText textKey="ShippingStatus" /></th>}
          {isB2CCustomer && <th id="headerTracking" className={styles.trackingCell}><SimpleText textKey="ShippingTracking" /></th>}
          <th className={styles.viewMoreCell} />
        </tr>
      </thead>
      <tbody>
        {!isPreview
          ? documents.map((order, index) => {
            const {
              id,
              documentId,
              documentDate,
              currency,
              url,
              billingAddress,
              bulkStatus,
              shipping: { tracking, address: shippingAddress },
              totals: { totalExcludingTax },
            } = order;

            const address = isB2CCustomer
              ? shippingAddress
                ? shippingAddress.name
                : null
              : billingAddress
                ? billingAddress.name
                : null;

            return (
              <tr key={index}>
                <th scope="row" aria-labelledby="headerId">{documentId}</th>
                <td aria-labelledby="headerDate">{toLocaleDate(documentDate, culture)}</td>
                <td aria-labelledby="headerAddress">{address}</td>
                {showPrices &&
                  <td aria-labelledby="headerTotal" className={styles.total}>
                    <FormattedPrice price={totalExcludingTax} currencyInfo={currency} />
                  </td>
                }
                {showShippingStatus &&
                  <td aria-labelledby="headerStatus">{bulkStatus}</td>
                }
                {isB2CCustomer &&
                  <td aria-labelledby="headerTracking" className={styles.trackingCell}><ShippingTracking tracking={tracking} /></td>
                }
                <td><ViewDetailsLink url={url} id={id} documentType={DocumentType.OrderBulk} /></td>
              </tr>
            );
          })
          : Array.from(Array(previewDocumentsCount || 5)).map((_, index) => (
            <tr key={index}>
              <th scope="row"><Placeholder /></th>
              <td><Placeholder /></td>
              <td><Placeholder /></td>
              {showPrices && <td className={styles.total}><Placeholder /></td>}
              {showShippingStatus && <td><Placeholder /></td>}
              {isB2CCustomer && <td className={styles.trackingCell}><Placeholder /></td>}
              <td><Placeholder /></td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
};

BulkOrdersList.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,
    documentDate: PropTypes.string.isRequired,
    currency: PropTypes.object,
    url: PropTypes.string,
    billingAddress: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    shipping: PropTypes.shape({
      tracking: PropTypes.object,
      address: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }).isRequired,
    totals: PropTypes.shape({
      totalPrice: PropTypes.number,
    }).isRequired,
  })),
  showShippingStatus: PropTypes.bool,
  isB2CCustomer: PropTypes.bool.isRequired,
  culture: PropTypes.string,
  previewDocumentsCount: PropTypes.number,
};

const mapStateToProps = ({
  settings: { documents },
  user: { customerType },
  localization: { currentLanguage },
}) => ({
  showShippingStatus: documents && documents.showShippingStatus,
  isB2CCustomer: customerType === CustomerTypes.B2C,
  culture: currentLanguage && currentLanguage.cultureName,
});

export default connect(mapStateToProps)(BulkOrdersList);
