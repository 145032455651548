import { SUB_ACCOUNT_SUBMITTED, SUB_ACCOUNT_REMOVE, subAccountRemovalResultRecieved } from './actions';
import { switchMap, mapTo, mergeMap, map } from 'rxjs/operators';
import { saveSubAccountQuery, removeSubAccountQuery } from './queries';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import { merge, of } from 'rxjs';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { handleError, ERROR_PRIORITIES } from 'utils/rxjs';
import { unlockForm, FormLockKeys } from 'behavior/pages';
import { ofType } from 'redux-observable';

export default function (action$, _state$, dependencies) {
  const { api, logger } = dependencies;
  const subAccountsRoute = routesBuilder.forSubAccounts();

  const savingSubAccount$ = action$.pipe(
    ofType(SUB_ACCOUNT_SUBMITTED),
    switchMap(action => api.graphApi(saveSubAccountQuery, { sub: action.payload }, { retries: 0 }).pipe(
      mapTo(navigateTo(subAccountsRoute)),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(FormLockKeys.SubAccount))),
      retryWithToast(action$, logger, _ => of(unlockForm(FormLockKeys.SubAccount))),
    )),
  );

  const removingSubAccount$ = action$.pipe(
    ofType(SUB_ACCOUNT_REMOVE),
    mergeMap(action => api.graphApi(removeSubAccountQuery, { id: action.payload }, { retries: 0 }).pipe(
      map(data => subAccountRemovalResultRecieved({ ...data.profile.subAccounts.remove.results[0], subAccountId: action.payload })),
      handleError(ERROR_PRIORITIES.HIGH, 'profile'),
    )),
  );

  return merge(savingSubAccount$, removingSubAccount$);
}
