import btnStyles from 'components/primitives/buttons/Button.module.scss';
import styles from './WishList.module.scss';
import { memo } from 'react';
import { SanaButton } from 'components/primitives/buttons';
import { Placeholder } from 'components/primitives/placeholders';
import { SimpleText } from 'components/sanaText';
import PropTypes from 'prop-types';

const AddToBasketBlock = ({ className, addToBasket, isOrderable }) => {
  const placeholder = <Placeholder className="placeholder" />;

  if (!isOrderable)
    return (
      <span className="msg-not-available">
        <SimpleText textKey="Product_NotAvailable" placeholder={placeholder} />
      </span>
    );

  return addToBasket
    ? (
      <div className={`${className} ${styles.orderBox}`} role="article">
        <SanaButton className={`${btnStyles.btnAction} ${btnStyles.btnSmall} btn-action`}
          textKey="AddToBasket"
          onClick={addToBasket}
          placeholder={placeholder}
        />
      </div>
    )
    : null;
};

AddToBasketBlock.propTypes = {
  className: PropTypes.string,
  addToBasket: PropTypes.func,
  isOrderable: PropTypes.bool,
};

export default memo(AddToBasketBlock);