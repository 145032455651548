import styles from './Heading.module.scss';
import PropTypes from 'prop-types';

const Heading = ({ as, className = '', children }) => (
  <div className={`${as ? `${styles.heading} ${as} ` : ''}${className}`}>
    {children}
  </div>
);

Heading.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Heading;