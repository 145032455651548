import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { SanaButton, UseSanaButton } from 'components/primitives/buttons';
import { Table } from 'components/primitives/table';
import { Modal, useModal } from 'components/objects/modals';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import modalStyles from 'components/objects/modals/Modal.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import TieredPriceRow from './TieredPriceRow';

const VolumeOrderLink = ({ product }) => {

  const { opened, show, hide } = useModal();
  const { tieredPrices } = product;

  const handleButtonClick = () => {
    show();
  };

  return (
    <div className={'volume-order-link-container'}>

      <UseSanaButton textKey="VolumePricesAvailable">
        {text => (
          <button
            className={`${linkStyles.link}`}
            onClick={handleButtonClick}
            type="button"
          >
            {text}
          </button>
        )}
      </UseSanaButton>

      <Modal opened={opened} hide={hide} className={'volume-order-link-modal'}>
        <h2 className={`h3 ${modalStyles.title}`}>
          <SimpleText textKey="VolumePriceReductions" />
        </h2>
        <div className={modalStyles.content}>
          <Table className="volume-order-link-table">
            <thead>
              <tr>
                <th><SimpleText textKey="PriceList" /></th>
                <th><SimpleText textKey="UnitPrice" /></th>
                <th><SimpleText textKey="Link" /></th>
              </tr>
            </thead>
            <tbody>
              {tieredPrices.map((tp, i) => (
                <React.Fragment key={i}>
                  <TieredPriceRow priceList={tp.level1PriceDescription} price={tp.level1Price} url={tp.pageUrl} pageId={tp.pageId} />
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
        <SanaButton
          textKey="Close"
          className={`${btnStyles.btnExtraSmall} ${btnStyles.noIcon}`}
          onClick={hide}
        />
      </Modal>
    </div>
  );
};

VolumeOrderLink.propTypes = {
  product: PropTypes.object.isRequired,
};

export default memo(VolumeOrderLink);