import { memo } from 'react';
import { DocumentType } from 'behavior/documents';
import { routesBuilder } from 'routes';
import Documents, { selectPropsFromPage } from '../Documents';
import ShipmentsList from './ShipmentsList';

const textKeys = {
  introduction: 'ShipmentsHistory_IntroductionText',
  listHeader: 'RecentShipments',
  loadMore: 'ShowNextNOrders',
  noItems: 'Orders_NoOrderHistory',
};

const shipmentsRoute = routesBuilder.forShipments();

const Shipments = memo(props => (
  <Documents
    loadMoreRoute={shipmentsRoute}
    textKeys={textKeys}
    documentType={DocumentType.Shipment}
    {...props}
  >
    <ShipmentsList documents={props.documents} />
  </Documents>
));

Shipments.selectPropsFromPage = selectPropsFromPage;

export default Shipments;
