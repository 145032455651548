import {
  ADDON_LOADED,
  EVENT_ACTION_PREFIX,
  AddonActions,
  isWrappedAddonAction,
  WrappedAddonAction,
} from './actions';
import { Action, combineReducers } from 'redux';
import { normalizeAddonId } from './helpers';
import metadataReducer from './metadata/reducer';
import type { Registry } from './registry';
import type { AddonExports, AddonsState, AddonsScopesState } from './types';

const INIT_ACTION = { type: '@@INIT' as const };

const scopesReducer = (addonRegistry: Registry) => (state: AddonsScopesState = {}, action: AddonActions): AddonsScopesState => {
  if (action.type === ADDON_LOADED) {
    const { id, hash, addon } = action.payload;
    addonRegistry.add(id, hash, addon);
    return reduceAddonState(state, INIT_ACTION, id, addon);
  }

  if (isWrappedAddonAction(action)) {
    const { addonId, addonHash, action: addonAction } = action.payload;

    if (addonId === '*') {
      return addonRegistry.getEntries().reduce((state, { id, addon }) =>
        reduceAddonState(state, addonAction, id, addon), state);
    }
    else {
      const addon = addonRegistry.get(addonId, addonHash);
      return addon ? reduceAddonState(state, addonAction, addonId, addon) : state;
    }
  }
  else if (action.type.startsWith(EVENT_ACTION_PREFIX)) {
    return addonRegistry.getEntries().reduce((state, { id, addon }) =>
      reduceAddonState(state, action, id, addon), state);
  }

  return state;
};

export default (addonRegistry: Registry) => {
  const reducer = combineReducers({
    metadata: metadataReducer,
    scopes: scopesReducer(addonRegistry),
  });

  return (state = {} as AddonsState, action: Action): AddonsState => {
    if (isWrappedAddonAction(action)) {
      const metadata = state.metadata && state.metadata[action.payload.addonId];
      const actionWithAddonHash: WrappedAddonAction = {
        ...action,
        payload: {
          ...action.payload,
          addonHash: metadata && metadata.bundle ? metadata.bundle.hash : undefined,
        },
      };
      return reducer(state, actionWithAddonHash as Action);
    }
    else {
      return reducer(state, action);
    }
  };
};

function reduceAddonState(state: AddonsScopesState, action: Action, addonId: string, addon: AddonExports | null): AddonsScopesState {
  if (!addon || !addon.reducer)
    return state;

  const addonState = state[normalizeAddonId(addonId)];
  const newAddonState = addon.reducer(addonState, action);

  if (addonState === newAddonState)
    return state;

  return { ...state, [normalizeAddonId(addonId)]: newAddonState };
}
