import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PageTemplate from './PageTemplate';
import CalculatedFieldsLoader from 'components/objects/volumeOrderList/page/CalculatedFieldsLoader';

const PageContainer = ({ page, Page }) => {

  const PRICING_LEVEL_1 = 'Level 1';
  const PRICING_LEVEL_2 = 'Level 2';

  const [currentPriceLevel, setCurrentPriceLevel] = useState('');
  const [toNextPriceLevel, setToNextPriceLevel] = useState(0);
  const [orderValue, setOrderValue] = useState(0);
  const [baseOrderValue, setBaseOrderValue] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalDiscountPercent ,setTotalDiscountPercent] = useState(0);
  const [loading, setLoading] = useState(true);

  const { quantities } = useSelector(s => s.volumeOrders);

  const reInitializeState = () => {
    setCurrentPriceLevel('');
    setOrderValue(0);
    setBaseOrderValue(0);
    setTotalDiscount(0);
    setTotalDiscountPercent(0);
    setLoading(false);
  };

  useEffect(() => {
    if (quantities.length===0) {
      setToNextPriceLevel(lowerThreshold);
      reInitializeState();
      return;
    }

    let priceLevel = '';
    let tnl = 0;

    const newBaseValue = quantities.map(q => {
      const { quantity: { value }, price } = q;
      return value*price;
    }).reduce((a, cv) => a+cv);

    setBaseOrderValue(newBaseValue);

    if (newBaseValue < lowerThreshold) {
      priceLevel = '';
      tnl = lowerThreshold-newBaseValue;
    } else if (newBaseValue < upperThreshold) {
      priceLevel = PRICING_LEVEL_1;
      tnl = upperThreshold-newBaseValue;
    } else {
      priceLevel = PRICING_LEVEL_2;
      tnl = 0;
    }

    const newOrderValue = quantities.map(q => {
      return calcLinePrice(q, priceLevel);
    }).reduce((a, cv) => a+cv);
  
    setOrderValue(newOrderValue);

    const newTotalDiscount = newBaseValue-newOrderValue;
    const newTotalDiscountPercent = Math.abs(((newOrderValue/newBaseValue)*100)-100);

    setTotalDiscount(newTotalDiscount);
    setTotalDiscountPercent(newTotalDiscountPercent);

    setCurrentPriceLevel(priceLevel);
    setToNextPriceLevel(tnl);

    setLoading(false);

  }, [quantities]);
  
  const { lowerThreshold, upperThreshold, hasBasket } = page;

  const calcLinePrice = (q, priceLevel) => {
    const { quantity: { value }, price, level1VolumePrice, level2VolumePrice } = q;

    const multiplier = priceLevel==='' ?
      price :
      priceLevel===PRICING_LEVEL_1 ?
        level1VolumePrice :
        level2VolumePrice;

    return value * multiplier;
  };

  if (loading) {
    return null;
  }

  return (
    <PageTemplate {...PageTemplate.selectProps(page)} 
      currentPriceLevel={currentPriceLevel} 
      toNextPriceLevel={toNextPriceLevel}
      orderValue={orderValue}
      totalDiscount={totalDiscount}
      totalDiscountPercent={totalDiscountPercent}
      rrpOrderValue={baseOrderValue}
    >
      <CalculatedFieldsLoader products={page.products} pageId={page.id}>
        <Page {...Page.selectProps(page)} 
          calcLinePrice={calcLinePrice} 
          currentPriceLevel={currentPriceLevel} 
          baseOrderValue={baseOrderValue} 
          lowerThreshold={lowerThreshold} 
          hasBasket={hasBasket}
        />
      </CalculatedFieldsLoader>
    </PageTemplate>
  );
  
};

export default PageContainer;
