const zwj = '\u200D'; // zero width joiner
const zwnj = '\u200C'; // zero width non joiner
const zws = '\u200B'; // zero width space

const INSITE_EDITOR_END_SEQUENCE = `${zws}${zws}${zws}`;

export const isSanaTextStart = text => {
  if (!text || text.length === 0)
    return false;

  return text[0] === zwj || text[0] === zwnj;
};

export const isSanaTextEnd = text => {
  return text.indexOf(INSITE_EDITOR_END_SEQUENCE) !== -1;
};

export const encodeIndexIntoText = (text, sanaTextIndex) => {
  if (!text)
    return text;

  const binaryString = sanaTextIndex.toString(2);
  const code = binaryString.split('').reduce(
    (acc, value) => acc + (value === '1' ? zwj : zwnj),
    '',
  );

  return code + text + INSITE_EDITOR_END_SEQUENCE;
};

export const decodeIndexFromText = text => {
  if (!text || text.length === 0)
    return { text, hasIndex: false };

  if (!isSanaTextStart(text))
    return { text, hasIndex: false };

  if (text.slice(-INSITE_EDITOR_END_SEQUENCE.length) === INSITE_EDITOR_END_SEQUENCE)
    text = text.slice(0, -INSITE_EDITOR_END_SEQUENCE.length);

  let codeLength = 0;
  let binaryString = '';
  do {
    if (text[codeLength] === zwj)
      binaryString += '1';
    else if (text[codeLength] === zwnj)
      binaryString += '0';
    else
      break;

    codeLength++;
  } while (true);

  const textValue = text.substring(codeLength);
  return { text: textValue, hasIndex: true, index: parseInt(binaryString, 2) };
};

export const removeInsiteEditorEncoding = text => {
  if (isSanaTextStart(text))
    return decodeIndexFromText(text).text;

  return text;
};