import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSanaTexts } from 'components/sanaText';
import { preload as banner } from './ErrorBanner';
import { preload as retryToast } from './ErrorToastWithRetry';
import { preload as reloadToast } from './ErrorToastWithReload';

const Preload = ({ shouldPreload }) => {
  if (!shouldPreload)
    return null;

  useSanaTexts(banner.texts.concat(retryToast.texts, reloadToast.texts));

  return null;
};

Preload.propTypes = {
  shouldPreload: PropTypes.bool,
};

export default connect(({ localization: { currentLanguage: { id } } }) => ({ shouldPreload: !!id }))(Preload);