import { createUrl } from '../url';
import type { ProductTrackingOptions } from './types';

type ProductImage = {
  small?: string | null;
  medium?: string | null;
  large?: string | null;
}

export interface ProductWithImages {
  image?: ProductImage | null;
  images?: ProductImage[] | null;
}

export default function (product: ProductWithImages, options: ProductTrackingOptions) {
  const { origin, noImageLarge } = options;
  const { image, images } = product;
  const result: Array<string> = [];

  const addImage = ({ small, medium, large }: ProductImage): void => {
    const image = large || medium || small;
    const url = createUrl(image, origin);
    if (url)
      result.push(url);
  };

  if (images && images.length)
    images.forEach(addImage);

  if (!result.length && image)
    addImage(image);

  if (!result.length) {
    const noImageUrl = createUrl(noImageLarge, origin);
    if (noImageUrl)
      result.push(noImageUrl);
  }

  return { image: result };
}
