import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { isWidgetAllowed } from 'behavior/productComparison';

import { useIsMobileViewport } from 'utils/hooks';
import { useOnChange } from 'utils/hooks';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';

import WidgetContent from './WidgetContent';

import {
  requestProductComparisonInitialization,
  removeAllProductsFromComparison,
} from 'behavior/productComparison/actions';

import {
  setWidgetCollapsedState,
  getWidgetCollapsedState,
} from 'behavior/productComparison/util';

const Widget = ({ state, requestInitialization, removeAllProducts }) => {
  useEffect(() => {
    if (!state)
      requestInitialization();
  }, [state]);
  const isMobile = useIsMobileViewport();

  const checkTouched = () => {
    if (typeof localStorage === 'undefined')
      return {};

    const storedCollapsedState = getWidgetCollapsedState(localStorage);
    return {
      touched: !!storedCollapsedState,
      storedCollapsedState,
    };
  };

  const isCollapsed = () => {
    const { touched, storedCollapsedState } = checkTouched();
    return touched
      ? storedCollapsedState === 'true'
      : isMobile;
  };

  const [collapsed, setCollapsed] = useState(isCollapsed);

  const handleWidgetCollapse = () => {
    const newValue = !isCollapsed();
    setWidgetCollapsedState(localStorage, newValue);
    setCollapsed(newValue);
  };

  useOnChange(() => {
    const { touched } = checkTouched();
    if (!touched)
      setCollapsed(isMobile);
  }, [isMobile], false);

  const productsToCompare = state && state.products;
  if (!productsToCompare || !productsToCompare.length)
    return null;

  return (
    <WidgetContent
      products={productsToCompare}
      isCollapsed={collapsed}
      handleChangeCollapsedState={handleWidgetCollapse}
      handleRemoveAllProducts={() => removeAllProducts()}
    />
  );
};

Widget.propTypes = {
  state: PropTypes.shape({
    products: PropTypes.array,
  }),
  requestInitialization: PropTypes.func.isRequired,
  removeAllProducts: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  state: state.productComparison,
});

const mapDispatchToProps = {
  requestInitialization: requestProductComparisonInitialization,
  removeAllProducts: removeAllProductsFromComparison,
};

const ConnectedWidget = connect(mapStateToProps, mapDispatchToProps)(Widget);

export default () => {
  const isAllowed = useSelector(state => {
    const routeData = state.routing.routeData;
    return routeData ? isWidgetAllowed(routeData.routeName, routeData.params) : false;
  });

  const [canCompareProducts] = useHasAbilities(AbilityTo.CompareProducts);

  return isAllowed && canCompareProducts && <ConnectedWidget />;
};
