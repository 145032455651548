import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText, makeRichText } from 'utils/render';
import { ProductSelector } from 'components/objects/productSelector';
import { EVENT_SOURCES } from 'behavior/analytics';

const ReturnOrderProductSearch = ({ onProductAdd }) => {
  const texts = useProductSearchTexts();

  return (
    <ProductSelector
      texts={texts}
      onProductAdd={onProductAdd}
      allowAddUnorderableProduct
      productClickTrackingSource={EVENT_SOURCES.returnOrder}
    />
  );
};

ReturnOrderProductSearch.propTypes = {
  onProductAdd: PropTypes.func.isRequired,
};

export default memo(ReturnOrderProductSearch);

function useProductSearchTexts() {
  const texts = useSanaTexts([
    'Search_SearchPrefix',
    'ButtonText_Add',
    'Loading',
    'ReturnOrder_ProductNotFound',
    'ProductCannotBeOrderedAtThisTime',
  ]).texts;

  return useMemo(() => {
    const [
      searchBoxPlaceholder,
      addBtn,
      loadingText,
      productNotFoundText,
      productCannotBeOrderedMessage,
    ] = texts;

    return {
      searchBoxPlaceholder: makeSimpleText(searchBoxPlaceholder),
      addBtn: makeSimpleText(addBtn),
      loadingText: makeSimpleText(loadingText),
      productNotFoundText: makeRichText(productNotFoundText),
      productCannotBeOrderedMessage: makeRichText(productCannotBeOrderedMessage),
    };
  }, [texts]);
}