import styles from '../OrderResult.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useSimpleTexts } from 'components/sanaText';
import { logout } from 'behavior/user';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { Placeholder } from 'components/primitives/placeholders';
import { AbilityTo } from 'behavior/user/constants';
import { useHasAbilities } from 'components/objects/user';
import { DocumentType } from 'behavior/documents';
import { formatText } from 'utils/formatting';
import { Helmet } from 'react-helmet';
import { usePageTitle } from 'components/objects/hooks';
import { useSelector } from 'react-redux';

const PaymentError = ({ id: transactionId, documentId }) => {
  const dispatch = useDispatch();
  const [canSeeOrder] = useHasAbilities(AbilityTo.ViewOrders);
  const [texts, loaded] = usePaymentErrorTexts();
  const pageTitle = usePageTitle();
  const isAuthenticated = useSelector(s => s.user.isAuthenticated);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <p className={styles.description}>
        {formatText(texts.description, [transactionId, documentId])}
      </p>
      {loaded
        ? (
          <ul className={styles.actionsList}>
            <li>
              <Link
                className={`${linkStyles.arrowed} ${styles.contactUsLink}`}
                to={routesBuilder.forContactUs}
              >
                {texts.contactUs}
              </Link>
            </li>
            {canSeeOrder && (
              <li>
                <Link
                  className={`${linkStyles.arrowed} ${styles.orderLink}`}
                  to={routesBuilder.forDocument(documentId, DocumentType.Order)}
                  options={{ backTo: { routeData: routesBuilder.forPaymentError(transactionId) } }}
                >
                  {texts.orderLink}
                </Link>
              </li>
            )}
            <li>
              <Link
                className={`${linkStyles.arrowed} ${styles.homeLink}`}
                to={routesBuilder.forHome}
              >
                {texts.homePage}
              </Link>
            </li>
            {isAuthenticated &&
              <li>
                <button
                  className={`${linkStyles.link} ${linkStyles.arrowed} ${styles.btnLogout}`}
                  onClick={() => dispatch(logout())}
                >
                  {texts.logout}
                </button>
              </li>
            }
          </ul>
        )
        : (
          <ul className={styles.actionsList}>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
          </ul>
        )
      }
    </>
  );
};

PaymentError.propTypes = {
  id: PropTypes.string.isRequired,
  documentId: PropTypes.string.isRequired,
};

const Memoized = memo(PaymentError);
Memoized.selectPropsFromPage = ({ transaction }) => transaction;

export default Memoized;

function usePaymentErrorTexts() {
  const {
    texts: [
      description,
      contactUs,
      orderLink,
      homePage,
      logout,
    ],
    loaded,
  } = useSimpleTexts([
    'OrderPaymentError_Description',
    'ContactUs',
    'GotoOrderPage',
    'GoToHomePage',
    'SubmitLogoutLinkText',
  ]);

  return [
    {
      description,
      contactUs,
      orderLink,
      homePage,
      logout,
    },
    loaded,
  ];
}
