import { createContext } from 'react';
import { noop } from 'rxjs';

const CheckoutContext = createContext({
  registerStep: noop,
  unregisterStep: noop,
  setLoading: noop,
  submit: noop,
  setStepCompletion: noop,
});

export default CheckoutContext;
