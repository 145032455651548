import styles from './Lines.module.scss';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import PriceNode from '../PriceNode';
import QuantityBox from './ReadonlyQuantityBox';
import MobileTemplate from './templates/Mobile';
import DefaultTemplate from './templates/Default';
import BasketLinesContext from './BasketLinesContext';
import { useUpdateContext } from '../updateContext';

const ServiceLine = ({
  id,
  title,
  price,
  quantity,
  subTotal,
  className,
  isGeneral,
  elementId,
}) => {
  const { isB2BPreset } = useUpdateContext();
  const { showImages, showPrice, showUom, currencyInfo, isMobile } = useContext(BasketLinesContext);

  const lineInfo = (
    <>
      <span className={isB2BPreset && !isGeneral ? styles.smallTitle : styles.largeTitle}>{title}</span>
      {id && (
        <div className={styles.productId}>
          <SimpleText textKey="General_Product_Id_Label" /> {id}
        </div>
      )}
    </>
  );

  const priceNode = showPrice && (
    <PriceNode price={price} currencyInfo={currencyInfo} />
  );

  const subTotalNode = showPrice && (
    <PriceNode price={subTotal} currencyInfo={currencyInfo} />
  );

  const quantityBox = <QuantityBox quantity={quantity} />;

  const LineTemplate = isMobile ? MobileTemplate : DefaultTemplate;
  return (
    <LineTemplate
      id={elementId}
      className={className}
      productActionBlock={showImages}
      productInfo={lineInfo}
      priceNode={priceNode}
      quantityBox={quantityBox}
      subTotal={subTotalNode}
      uomLabel={showUom}
    />
  );
};

ServiceLine.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  quantity: PropTypes.number,
  price: PropTypes.number,
  subTotal: PropTypes.number,
  className: PropTypes.string,
  isGeneral: PropTypes.bool,
  elementId: PropTypes.string,
};

export default ServiceLine;