import styles from '../Checkout.module.scss';
import { useEffect, useContext, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AddressStep as AddressStepSection, AddressStepBody } from '../base/address';
import CheckoutContext from './CheckoutContext';
import { validateForm } from 'components/objects/forms';
import { ShippingAddressOption, Steps } from 'behavior/pages/checkout';
import { selectAddress, saveAddress } from 'behavior/pages/checkout/actions';
import { extractEscapedTemplateFields } from '../../forms';
import scrollIntoView from 'scroll-into-view';
import { addressStepPropTypes } from '../propTypes';
import { useOnChange } from 'utils/hooks';

const AddressStep = ({ info, isCompleted }) => {
  const {
    shippingAddresses,
    billingAddress,
    shippingAddress,
    shippingTemplateFields,
    isQuote,
  } = info;

  const { setStepCompletion } = useContext(CheckoutContext);

  const formikRef = useRef();
  formikRef.current = null;

  const { setLoading, registerStep } = useContext(CheckoutContext);
  const { address: selectedShipping, shippingOption: selectedShippingOption } = shippingAddress;

  const lastSelectedRef = useRef();
  const lastCustomFieldsRef = useRef();

  const shippingOptionState = useState(selectedShippingOption);
  const [shippingOption, setShippingOption] = shippingOptionState;
  useOnChange(() => setShippingOption(selectedShippingOption), [selectedShippingOption], false);

  if (selectedShippingOption === ShippingAddressOption.Existing)
    lastSelectedRef.current = selectedShipping && selectedShipping.id;

  if (selectedShippingOption === ShippingAddressOption.Custom)
    lastCustomFieldsRef.current = selectedShipping && selectedShipping.fields;

  const shippingOptionRef = useRef();
  shippingOptionRef.current = shippingOption;
  const [showSelectionValidation, setShowSelectionValidation] = useState();
  useEffect(() => {
    const validateStep = async () => {
      if (shippingOptionRef.current === ShippingAddressOption.Existing) {
        if (!lastSelectedRef.current) {
          setShowSelectionValidation(true);
          scrollIntoView(document.querySelector(`.${styles.validation}`), { time: 200 });
          return false;
        }
      }

      if (shippingOptionRef.current === ShippingAddressOption.Custom)
        return await validateForm(formikRef);

      return true;
    };

    registerStep(Steps.Address, styles.address, [Steps.Address], validateStep);
  }, []);

  useEffect(() => {
    if (shippingOption === ShippingAddressOption.Billing)
      setStepCompletion(Steps.Address, true);
    else if (shippingOption === ShippingAddressOption.Existing) {
      setStepCompletion(Steps.Address, !!lastSelectedRef.current);
    }
    else {
      setStepCompletion(Steps.Address, !!lastCustomFieldsRef.current);
    }
  }, [shippingOption, selectedShippingOption]);

  const dispatch = useDispatch();

  const onOptionChange = option => {
    setShowSelectionValidation(false);

    if (option === selectedShippingOption)
      return;

    if (option === ShippingAddressOption.Billing) {
      setLoading(Steps.Address);
      dispatch(selectAddress());
    } else if (option === ShippingAddressOption.Existing) {
      if (lastSelectedRef.current || shippingAddresses.length === 1) {
        setLoading(Steps.Address);
        dispatch(selectAddress(lastSelectedRef.current || shippingAddresses[0].id));
      }
    } else if (lastCustomFieldsRef.current) {
      setLoading(Steps.Address);
      dispatch(saveAddress(lastCustomFieldsRef.current));
    }
  };

  const onAddressSelection = id => {
    setShowSelectionValidation(false);
    dispatch(selectAddress(id));
    setLoading(Steps.Address);
  };

  const onAddressFormSubmit = (formData, formik) => {
    if (formik.dirty) {
      setLoading(Steps.Address);
      dispatch(saveAddress(extractEscapedTemplateFields(formData, shippingTemplateFields)));
    }
  };

  const onAddressFormValidate = valid => {
    setStepCompletion(Steps.Address, valid);
  };

  return (
    <AddressStepSection
      className={styles.address}
      isQuote={isQuote}
      isCompleted={isCompleted}
    >
      <AddressStepBody
        shippingOptionState={shippingOptionState}
        selectedAddressId={lastSelectedRef.current}
        shippingAddresses={shippingAddresses}
        billingAddress={billingAddress}
        templateFields={shippingTemplateFields}
        customFields={lastCustomFieldsRef.current}
        onOptionChange={onOptionChange}
        onAddressSelection={onAddressSelection}
        onAddressFormSubmit={onAddressFormSubmit}
        onAddressFormValidate={onAddressFormValidate}
        submitFormOnBlur
        formikRef={formikRef}
        showLoading
        showSelectionValidation={showSelectionValidation}
      />
    </AddressStepSection>
  );
};

AddressStep.propTypes = addressStepPropTypes;

export default AddressStep;
