import { createLocation as createHistoryLocation } from 'history';
import type { Location } from './types';

export function areLocationsEqual(l1: Location | null | undefined, l2: Location | null | undefined, ignoreHash = false): boolean {
  return l1 === l2 || (
    l1 && l2 ? (
      (l1.pathname || '') === (l2.pathname || '') &&
      (l1.search || '') === (l2.search || '') &&
      (ignoreHash || (l1.hash || '') === (l2.hash || ''))
    ) : false
  );
}

export function createLocation(url: string): Location {
  const { pathname, search, hash } = createHistoryLocation(url);
  return { pathname, search, hash };
}

export function createUrl(location: Location): string {
  const { pathname, search, hash } = location;
  return pathname + search + hash;
}
