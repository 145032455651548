import type { StateObservable } from 'redux-observable';
import type { Observable } from 'rxjs';
import type { AppState } from 'behavior';
import type { StoreDependencies } from 'behavior/types';
import { map } from 'rxjs/operators';
import { notifyChanged } from './actions';

export default function connectionEpic(_action$: Observable<unknown>, _state$: StateObservable<AppState>, { api }: StoreDependencies) {
  return api.connection$.pipe(
    map(notifyChanged),
  );
}