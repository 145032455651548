import { loadPasswordStrengthValidator } from 'utils/validation';

export default context => value => {
  if (!value)
    return;

  const { minimumScore, userInputs } = context.params;

  return new Promise(resolve => {
    loadPasswordStrengthValidator().then(validatePasswordStrength => {
      resolve(validatePasswordStrength(value, userInputs).score >= minimumScore ? undefined : context.errorMessage);
    });
  });
};