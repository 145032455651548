import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';

import {
  tap,
  pluck,
  switchMap,
  map,
  ignoreElements,
  withLatestFrom,
} from 'rxjs/operators';

import {
  PRODUCT_COMPARISON_ADD_PRODUCT,
  PRODUCT_COMPARISON_REMOVE_PRODUCT,
  PRODUCT_COMPARISON_REQUEST_INITIALIZATION,
  PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS,
  initializeProductComparison,
} from './actions';

import {
  addProductToStorage,
  removeProductFromStorage,
  getProductsFromStorage,
  removeAllProductsFromStorage,
} from './util';

import { productsToCompareRequest } from './queries';

const productComparisonEpic = (action$, state$, { api, localStorage }) => {
  const requestProductsToCompareAction$ = action$.pipe(
    ofType(PRODUCT_COMPARISON_REQUEST_INITIALIZATION),
    withLatestFrom(state$),
    map(([_, { settings, analytics }]) => [getProductsFromStorage(localStorage, settings), analytics]),
    switchMap(
      ([ ids, analytics ]) => ids && ids.length > 0
        ? loadProducts(api, ids, analytics)
        : of([]),
    ),
    map(initializeProductComparison),
  );

  const addProductToComparisonAction$ = action$.pipe(
    ofType(PRODUCT_COMPARISON_ADD_PRODUCT),
    pluck('payload', 'id'),
    withLatestFrom(state$),
    tap(([id, { settings }]) => addProductToStorage(localStorage, settings, id)),
    ignoreElements(),
  );

  const removeProductFromComparisonAction$ = action$.pipe(
    ofType(PRODUCT_COMPARISON_REMOVE_PRODUCT),
    pluck('payload', 'id'),
    withLatestFrom(state$),
    tap(([id, { settings }]) => removeProductFromStorage(localStorage, settings, id)),
    ignoreElements(),
  );

  const removeAllProductsFromComparisonAction$ = action$.pipe(
    ofType(PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS),
    tap(_ => removeAllProductsFromStorage(localStorage)),
    ignoreElements(),
  );

  return merge(
    requestProductsToCompareAction$,
    addProductToComparisonAction$,
    removeProductFromComparisonAction$,
    removeAllProductsFromComparisonAction$,
  );
};

export default productComparisonEpic;

function sort(products, ids) {
  if (!products || !products.length)
    return [];
  return ids.map(id => products.find(p => p.id.toLocaleUpperCase() === id.toLocaleUpperCase())).filter(Boolean);
}

function loadProducts(api, ids, analytics) {
  const variables = {
    options: {
      ids,
        page: {
        size: ids.length,
      },
    },
    loadCategories: analytics && analytics.isTrackingEnabled,
  };
  return api.graphApi(productsToCompareRequest, variables).pipe(
    pluck('catalog', 'products', 'products'),
    map(products => sort(products, ids)),
  );
}