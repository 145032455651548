import styles from './DocBasedReturnOrder.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSimpleTexts } from 'components/sanaText';
import { FormGroup } from 'components/objects/forms';
import { DropdownField, TextAreaField } from 'components/objects/forms/fields';
import { Button } from 'components/primitives/buttons';
import QuantityBoxField from './QuantityBoxField';
import { TrashCanButtonIcon } from 'components/primitives/icons';

const ReturnOrderSubline = ({ name, index, invoiceLine, returnReasons, onDelete }) => {
  const [
    quantityTitle,
    returnReasonTitle,
    commentTitle,
    deleteButtonTitle,
  ] = useSimpleTexts([
    'ReturnRequest_ReturnQuantity',
    'ReturnRequest_Reason',
    'OrderComments',
    'ButtonAltText_Delete',
  ]).texts;

  return (
    <>
      <div className={styles.relativeRow}>
        <Button
          title={deleteButtonTitle}
          type="button"
          className={`${btnStyles.btnBase} ${btnStyles.noIcon} ${styles.btnDelete}`}
          onClick={() => onDelete(index)}
        >
          <TrashCanButtonIcon className={styles.icon} aria-hidden />
        </Button>
        <FormGroup
          fieldName={`${name}.quantity`}
          fieldTitle={quantityTitle}
          fieldComponent={QuantityBoxField}
          uom={invoiceLine.uom}
        />
      </div>
      {returnReasons.length !== 0 && (
        <FormGroup
          fieldName={`${name}.reasonId`}
          fieldTitle={returnReasonTitle}
          fieldComponent={DropdownField}
          items={returnReasons}
          placeholderTextKey="ReturnRequest_SelectReasonPlaceholder"
          required
          validation={{ required: true }}
          className={styles.reasonField}
        />
      )}
      <FormGroup
        fieldName={`${name}.comment`}
        fieldTitle={commentTitle}
        fieldComponent={TextAreaField}
        maxLength={900}
      />
    </>
  );
};

ReturnOrderSubline.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  invoiceLine: PropTypes.shape({
    uom: PropTypes.object,
  }).isRequired,
  returnReasons: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default memo(ReturnOrderSubline);