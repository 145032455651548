import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useGraphApi } from 'utils/hooks';
import { getCommandExecutionMutation } from './queries';

export default function useExtraCheckoutStepCommand() {
  const apiCall = useGraphApi();
  const isPromotion = useSelector(s => !!s.page.info.quote);

  return useCallback(
    args => apiCall(
      getCommandExecutionMutation(isPromotion),
      { input: { arguments: args } },
      ({ checkout }) => checkout?.paymentMethod.extraCheckoutStep?.executeCommand?.result,
    ).toPromise(),
    [],
  );
}