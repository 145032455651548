interface Sku {
  price?: number | null;
  listPrice?: number | null;
  isOrderable?: boolean;
  inventory?: number | null;
}

interface Product extends Sku {
  variants?: Sku[];
}

export function deleteProductCalculatedInfo<T extends Product>(product: T): T {
  const result = deleteSkuCalculatedInfo(product);

  if (result.variants)
    result.variants = result.variants.map(deleteSkuCalculatedInfo);

  return result;
}

function deleteSkuCalculatedInfo<T extends Sku>(sku: T): T {
  const result: T = { ...sku };
  delete result.price;
  delete result.listPrice;
  delete result.isOrderable;
  delete result.inventory;

  return result;
}
