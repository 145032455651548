import styles from './CookieBar.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { storage as cookiebarStorage } from 'behavior/cookiebar';
import { useSanaTexts } from 'components/sanaText';
import { Button } from 'components/primitives/buttons';
import { Link } from 'components/primitives/links';
import { makeRichText, makeSimpleText } from 'utils/render';
import { CrossBigIcon } from 'components/primitives/icons';

const CookieBar = ({ link }) => {
  const [closed, setClosed] = useState(true);
  const ref = useRef();
  const focused = useRef();
  const { texts: [cookiebar, ...otherTexts], loaded } = useSanaTexts([
    'Cookiebar_Text',
    'ButtonText_Cookiebar_Agree',
    'ButtonText_Cookiebar_AdditionalInformation',
    'ButtonAltText_Close',
  ]);
  const cookiebarText = makeRichText(cookiebar);
  const [
    btnAgreeText,
    btnAddInfoText,
    btnCloseAltText,
  ] = otherTexts.map(makeSimpleText);

  const hideComponent = () => setClosed(true);

  useEffect(() => setClosed(false), []);

  useEffect(() => {
    if (closed || !ref.current || focused.current || !loaded)
      return;

    focused.current = true;

    const element = ref.current.querySelector('button,a');
    if (element) {
      element.focus();
    }
  }, [closed, loaded]);

  if (closed || !cookiebarStorage.shouldBeShown()) {
    ref.current = null;
    return null;
  }

  const handleClick = () => {
    cookiebarStorage.rememberAccepted();
    hideComponent();
  };

  return (
    <div className={styles.cookiebar} ref={ref} data-scroll-lock-fill-gap role="banner" style={loaded ? null : { display: 'none' }}>
      {/* data-scroll-lock-fill-gap - fills gap on element on right side with padding (default) when body scroll disabled */}
      <div className={styles.content} role="dialog" aria-describedby="cookiebar_text">
        <div className={styles.description} id="cookiebar_text">
          {cookiebarText}
        </div>
        <div className={styles.holder}>
          <div className={styles.btnWrapper}>
            <Button
              className={`${btnStyles.btn} ${btnStyles.btnExtraSmall} ${btnStyles.noIcon} ${btnStyles.btnAction} ${styles.btnAcceptPolicy}`}
              onClick={handleClick}
            >
              {btnAgreeText}
            </Button>
          </div>
          {link && link.url && (
            <div className={styles.btnWrapper}>
              <Link
                {...link}
                className={`${btnStyles.btn} ${btnStyles.btnExtraSmall} ${btnStyles.noIcon} ${styles.btnMoreInfo}`}
              >
                <span className="btn-cnt">{btnAddInfoText}</span>
              </Link>
            </div>
          )}
        </div>
        <Button className={styles.btnClose} onClick={hideComponent} title={btnCloseAltText}>
          <CrossBigIcon aria-hidden />
        </Button>
      </div>
    </div>
  );
};

CookieBar.propTypes = {
  link: PropTypes.object,
};

export default connect(
  ({ settings: { cookiebar: { link } } }) => ({ link }),
)(CookieBar);
