import { useMatchMediaContext } from '../hooks';

type MatchMediaContext = {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
}

export default function (): MatchMediaContext {
  const { xs, sm, md, lg, xl } = useMatchMediaContext();
  return { xs, sm, md, lg, xl };
}