import styles from '../OrderBox.module.scss';
import matrixStyles from 'components/objects/product/variantsMatrix/VariantsMatrix.module.scss';
import { useRef } from 'react';
import PropTypes from 'prop-types';
import { AbilityTo } from 'behavior/user/constants';
import { SimpleText } from 'components/sanaText';
import { OfflinePriceWarning, UomSelector, useCanViewUom } from 'components/primitives/product';
import { SanaButton } from 'components/primitives/buttons';
import { Placeholder } from 'components/primitives/placeholders';
import VariantsMatrix from 'components/objects/product/variantsMatrix/VariantsMatrix';
import { useOnChange } from 'utils/hooks';
import { connect } from 'react-redux';
import { focusById } from 'utils/dom';

const VariantsMatrixForm = ({
  quantities,
  abilities,
  product,
  uomId,
  updateUomId,
  onQuantitiesSubmit,
  allowUOMSelection,
}) => {
  const canViewUom = useCanViewUom();

  const { uoms, title, id: productId } = product;
  const showUomSelector = canViewUom && uomId && uoms && uoms.length;
  const quantitiesRef = useRef();

  useOnChange(() => {
    const productQuantities = quantities && quantities.get(uomId);
    quantitiesRef.current = productQuantities
      ? new Map(productQuantities)
      : new Map();
  }, [uomId, quantities]);

  const onQuantityChanged = (variantId, quantity) => {
    if (quantity.value)
      quantitiesRef.current.set(variantId, quantity);
    else
      quantitiesRef.current.delete(variantId, quantity);
  };

  const handleSubmit = e => {
    e.preventDefault();

    for (const [variantId, quantity] of quantitiesRef.current) {
      if (!quantity.isValid) {
        const id = `${productId}_${variantId}`;
        document.getElementById(id).focus();
        return;
      }
    }

    onQuantitiesSubmit(quantitiesRef.current);
  };

  const uomTitlePlaceholder = <Placeholder className={matrixStyles.uomTitlePlaceholder} />;

  return (
    <form onSubmit={handleSubmit} className={styles.matrixForm}>
      <div className={matrixStyles.header}>
        <h2 className={matrixStyles.productTitle}>{title}</h2>
        <div className={matrixStyles.productId}>
          <span className={matrixStyles.idName}><SimpleText textKey="General_Product_Id" /></span>
          <span className={matrixStyles.idValue}>{productId}</span>
        </div>
      </div>
      <OfflinePriceWarning />
      {showUomSelector && (
        <div className={matrixStyles.uomWrapper}>
          {/* onClick is used to simulate default label behavior. */}
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
          <label className={matrixStyles.uomTitle} onClick={focusById.bind(null, productId + '_UOM')}>
            <SimpleText textKey="UOM_Label" placeholder={uomTitlePlaceholder} />
          </label>
          <div className={matrixStyles.uomSelector}>
            <UomSelector
              productId={productId}
              allowUOMSelection={allowUOMSelection}
              uomId={uomId}
              uoms={uoms}
              onUomChange={updateUomId}
              isOrderable
            />
          </div>
        </div>
      )}
      {product.variantComponentGroups &&
        <VariantsMatrix
          key={uomId}
          product={product}
          initialQuantities={quantitiesRef.current}
          abilities={abilities}
          onQuantityChanged={onQuantityChanged}
        />
      }
      <div className={`${matrixStyles.footer} ${styles.matrixFooter}`}>
        <SanaButton textKey="Ok" />
      </div>
    </form>
  );
};

VariantsMatrixForm.propTypes = {
  abilities: PropTypes.shape({
    [AbilityTo.OrderProducts]: PropTypes.bool.isRequired,
  }).isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  uomId: PropTypes.string,
  updateUomId: PropTypes.func.isRequired,
  quantities: PropTypes.instanceOf(Map),
  onQuantitiesSubmit: PropTypes.func.isRequired,
  allowUOMSelection: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ settings: { product } }) => ({
  allowUOMSelection: product && product.allowUOMSelection,
});

export default connect(mapStateToProps)(VariantsMatrixForm);
