import styles from './PageTemplate.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'components/primitives/grid';
import HeaderContent from './HeaderContent';
import { PageContent } from 'components/objects/pages';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { Facets, FacetsProvider, facetUrlBuilder } from '../facets';
import { useIsMobileViewport } from 'utils/hooks';
import { plpId } from '../constants';
import { VolumeOrderSummary } from '../summary';

const PageTemplate = ({ 
  facets, 
  id, 
  isEmpty, 
  headerContent, 
  footerContent, 
  children, 
  lowerThreshold, 
  upperThreshold, 
  thresholdType,
  currentPriceLevel,
  toNextPriceLevel,
  orderValue,
  calcLinePrice,
  totalDiscount,
  totalDiscountPercent,
  rrpOrderValue,
 }) => {
  const isMobile = useIsMobileViewport();
  const { texts: [leftColumnProductList], loaded } = useSanaTexts(['LeftColumn_ProductList'], makeSimpleText);
  const shouldHideLeftColumn = isEmpty && loaded && !leftColumnProductList;

  const content = (
    <Container className={`${styles.plp} ${shouldHideLeftColumn ? styles.hideLeftColumn : ''}`}>
      <Row>
        <Col xs={12} md={9} id={plpId} className={styles.productList}>
          {children}
        </Col>
        {isMobile
          ? <Col>{leftColumnProductList}</Col>
          : (
            <Col md={{ size: 3, order: 'first' }} className={styles.leftColumn}>
              <VolumeOrderSummary 
                lowerThreshold={lowerThreshold} 
                upperThreshold={upperThreshold} 
                thresholdType={thresholdType} 
                currentPriceLevel={currentPriceLevel}
                toNextPriceLevel={toNextPriceLevel}
                orderValue={orderValue}
                totalDiscount={totalDiscount} 
                totalDiscountPercent={totalDiscountPercent} 
                rrpOrderValue={rrpOrderValue}
              />
              <Facets key={id} />
              <div>{leftColumnProductList}</div>
            </Col>
          )}
      </Row>
    </Container>
  );
  const renderFacets = !!facets && facets.facets.length > 0;

  return (
    <>
      {renderFacets
        ? (
          <FacetsProvider facets={facets} urlBuilder={facetUrlBuilder}>
            <HeaderContent headerContent={headerContent} />
            {content}
          </FacetsProvider>
        )
        : (
          <>
            <HeaderContent headerContent={headerContent} />
            {content}
          </>
        )}
      <PageContent content={footerContent} position="footer" />
    </>
  );
};

PageTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  facets: PropTypes.object,
  headerContent: PropTypes.any,
  footerContent: PropTypes.array,
  id: PropTypes.string,
  isEmpty: PropTypes.bool,
  rrpOrderValue: PropTypes.numbr
};

const memoizedPageTemplate = memo(PageTemplate);

memoizedPageTemplate.selectProps = (({
  facets,
  id,
  headerContent,
  footerContent,
  products,
  lowerThreshold,
  upperThreshold,
  thresholdType,
}) => ({
  facets,
  id,
  headerContent,
  footerContent,
  isEmpty: !products.length,
  lowerThreshold,
  upperThreshold,
  thresholdType,
})
);

export default memoizedPageTemplate;