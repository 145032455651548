export const PROFILER_TIMING_RECEIVED = 'PROFILER_TIMING_RECEIVED';
export const receiveTiming = timing => ({
  type: PROFILER_TIMING_RECEIVED,
  payload: timing,
});

export const PROFILER_REQUEST_CLOSE = 'PROFILER_REQUEST_CLOSE';
export const closeProfiler = () => ({
  type: PROFILER_REQUEST_CLOSE,
});

export const PROFILER_CLOSED = 'PROFILER_CLOSED';
export const profilerClosed = () => ({
  type: PROFILER_CLOSED,
});