import { useMemo } from 'react';
import { useStickyHeadersScrolling } from './useStickyHeadersScrolling';
import { useSetStickyHeaders } from './useSetStickyHeaders';

export default function (
  tableRef,
  tableWrapperRef,
  stickyHeaderWrapperRef,
  resetClassName,
) {
  return useMemo(() => {
    return [
      useSetStickyHeaders(tableRef, tableWrapperRef, stickyHeaderWrapperRef, resetClassName),
      useStickyHeadersScrolling(tableWrapperRef, stickyHeaderWrapperRef),
    ];
  }, []);
}