import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import { useCountryField } from '../hooks';
import { TextBoxField } from '../../fields';

const CountryDependentTextBoxField = ({
  fieldName,
  perCountryPatterns,
  validation,
  ...attributes
}) => {
  const countryField = useCountryField();
  const countryFieldName = countryField && countryField.name;
  validation = { ...validation, countryDependentRegex: { perCountryPatterns, countryFieldName } };

  const { values, validateField } = useFormikContext();
  const countryId = countryFieldName ? getIn(values, countryFieldName) : null;
  useEffect(() => {
    validateField(fieldName);
  }, [countryId]);

  return (
    <TextBoxField
      fieldName={fieldName}
      validation={validation}
      {...attributes}
    />
  );
};

CountryDependentTextBoxField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  perCountryPatterns: PropTypes.array,
  validation: PropTypes.object,
};

export default CountryDependentTextBoxField;