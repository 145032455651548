import BroadcastChannel from 'broadcast-channel';
import { fromEvent } from 'rxjs';
import { share } from 'rxjs/operators';
import type { Action } from 'redux';
import { BroadcastFactory } from './types';

export const createBroadcast: BroadcastFactory = () => {
  const channel = new BroadcastChannel<Action>('action', { webWorkerSupport: false });
  const action$ = fromEvent(channel, 'message').pipe(share());
  return {
    action$,
    dispatch(action: Action) {
      channel.postMessage(action);
    },
  };
};