import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { rewriteTo } from 'behavior/routing';

export const useLoadMore = route => {
  const dispatch = useDispatch();

  return useCallback((index, filter) => {
    const routeData = {
      ...route,
      params: { index, filter },
      options: { onlyItems: true },
    };

    dispatch(rewriteTo(routeData, true));
  }, [route]);
};