import PropTypes from 'prop-types';
import { Placeholder } from 'components/primitives/placeholders';

const PreviewLine = ({
  options: {
    isB2CCustomer,
    showUOM,
    showStock,
    showPrices,
    showDiscounts,
    showExtraColumns,
    showShippingStatus,
  },
}) => (
  <tr>
    <td className="visually-hidden" />
    <td><Placeholder /></td>
    <td><Placeholder /></td>
    {showShippingStatus && <td><Placeholder /></td>}
    {!isB2CCustomer &&<td><Placeholder /></td>}
    {showPrices && <td><Placeholder /></td>}
    {showDiscounts && <td><Placeholder /></td>}
    <td><Placeholder /></td>
    {showUOM && <td><Placeholder /></td>}
    {showStock && <td><Placeholder /></td>}
    {showExtraColumns && <td><Placeholder /></td>}
    {showExtraColumns && <td><Placeholder /></td>}
    {!isB2CCustomer && <td><Placeholder /></td>}
    {showPrices && <td><Placeholder /></td>}
  </tr>
);

PreviewLine.propTypes = {
  options: PropTypes.shape({
    isB2CCustomer: PropTypes.bool,
    showUOM: PropTypes.bool,
    showStock: PropTypes.bool,
    showPrices: PropTypes.bool,
    showDiscounts: PropTypes.bool,
    showExtraColumns: PropTypes.bool,
    showShippingStatus: PropTypes.bool,
  }).isRequired,
};

export default PreviewLine;
