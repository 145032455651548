import { memo } from 'react';
import PropTypes from 'prop-types';
import { useAddon } from 'utils/addons';
import Spinner from 'components/primitives/spinner/Spinner';

const AddonContentBlock = ({ addonId, componentName, ...componentProps }) => {
  const { exports } = useAddon(addonId);
  if (exports === undefined)
    return <Spinner />;

  const Component = exports && exports.contentBlocks && exports.contentBlocks[componentName];
  if (!Component)
    return null;

  return (<Component {...componentProps} />);
};

AddonContentBlock.propTypes = {
  addonId: PropTypes.string.isRequired,
  componentName: PropTypes.string.isRequired,
};

export default memo(AddonContentBlock);