import { ofType } from 'redux-observable';
import { PASSWORD_RESET_REQUESTED, notifyResetResult } from './actions';
import { exhaustMap, map, pluck } from 'rxjs/operators';
import { resetPasswordMutation } from './queries';
import { catchApiErrorWithToast } from 'behavior/errorHandling';

export default function resetPasswordEpic(action$, state$, { api }) {
  return action$.pipe(
    ofType(PASSWORD_RESET_REQUESTED),
    exhaustMap(({ payload }) => api.graphApi(resetPasswordMutation, { input: payload }).pipe(
      pluck('profile', 'resetPassword', 'changed'),
      catchApiErrorWithToast(),
      map(notifyResetResult),
    )),
  );
}