import styles from 'components/objects/product/Details.module.scss';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { connect } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { useProductContext } from './hooks';
import { QuantityTextBox, UomSelector, useCanViewUom } from 'components/primitives/product';
import { Placeholder } from 'components/primitives/placeholders';
import QuantityContext from './QuantityContext';
import { getNumberDecimalsSeparator } from 'utils/format';

const QuantityBox = ({ culture, disabled }) => {
  const { product, calculatedInfo: { isOrderable }, uomId } = useProductContext();
  const { uoms, id } = product || {};

  const canViewUom = useCanViewUom();
  const { quantityModel, updateQuantity, quantity } = useContext(QuantityContext);

  if (!product)
    return null;

  const showUnitOfMeasure = canViewUom && uomId && uoms
    && (isOrderable || uoms.length > 1);
  
  if (!isOrderable && !showUnitOfMeasure)
    return null;

  const quantityTextPlaceholder = <Placeholder className={styles.quantityTextPlaceholder} />;
  const separator = getNumberDecimalsSeparator(culture);

  return (
    <Row className={styles.row} crossAxisAlign="center">
      <Col xs={12} sm={3} md={12} lg={4} className={styles.quantityBoxCol}>
        <label className={styles.ellipsis} htmlFor={quantityModel ? 'quantity_Input' : null}>
          <SimpleText textKey="Quantity" placeholder={quantityTextPlaceholder} />
        </label>
      </Col>
      {(quantityModel || showUnitOfMeasure) &&
        <Col xs={12} sm={9} md={12} lg={8} className={`${styles.quantityBoxCol} ${styles.actionsCol}`}>
          {quantityModel &&
            <QuantityTextBox
              className={styles.quantity}
              quantity={quantityModel}
              value={quantity && quantity.value}
              onChange={updateQuantity}
              separator={separator}
              disabled={disabled}
            />
          }
          {showUnitOfMeasure &&
            <UomSelector
              className={styles.limitedWidth}
              productId={id}
              uomId={uomId}
              uoms={uoms}
              isOrderable={isOrderable}
            />
          }
        </Col>
      }
    </Row>
  );
};

QuantityBox.propTypes = {
  culture: PropTypes.string,
  disabled: PropTypes.bool,
};

export default connect(
  ({ localization }) => ({
    culture: localization.currentLanguage && localization.currentLanguage.cultureName,
  }),
)(QuantityBox);