import styles from './RelatedProduct.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { ProductThumbnail } from 'components/primitives/product';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';

const RelatedProduct = ({ product: { url, routeData, image, title }, noImageUrl }) => (
  <WithProductClickTracking>
    {handleClick => (
      <Link onClick={handleClick} url={url} to={routeData} className={styles.tile}>
        <figure>
          <ProductThumbnail className={styles.thumbnailImg} src={image && image.medium} noImageSrc={noImageUrl} title={title} />
          <figcaption className={styles.title}>{title}</figcaption>
        </figure>
      </Link>
    )}
  </WithProductClickTracking>
);

RelatedProduct.propTypes = {
  product: PropTypes.shape({
    url: PropTypes.string.isRequired,
    routeData: PropTypes.object.isRequired,
    image: PropTypes.shape({
      medium: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
  }).isRequired,
  noImageUrl: PropTypes.string,
};

export default memo(RelatedProduct);
