import styles from './Breadcrumb.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import { UseSanaTexts } from 'components/sanaText';
import { useRoutes } from 'components/primitives/route';
import { RouteName, routesBuilder } from 'routes';
import Schema from './Schema';
import { makeSimpleText } from 'utils/render';
import { SmallArrowRightIcon } from 'components/primitives/icons';

const homeRoute = routesBuilder.forHome();

const Breadcrumb = ({ items, className = '', backLink = null }) => {
  const [homePath] = useRoutes([homeRoute]);

  if (items.length === 0) {
    return backLink
      ? <div className={styles.back}>{backLink}</div>
      : null;
  }

  const addHomeLink = !isHomePage(items[0]);
  const links = items.map((item, index) => (
    <li key={index}>
      <SmallArrowRightIcon className={styles.icon} aria-hidden />
      <Link {...item.link}>
        {item.title}
      </Link>
    </li>
  ));

  return (
    <div className={`${styles.container} ${className} ${!backLink ? styles.noBack : ''} d-print-none`}>
      {backLink &&
        <div className={styles.back}>
          {backLink}
          <ins role="presentation" />
        </div>
      }
      <nav className={styles.nav}>
        <ul>
          {addHomeLink
            ? (
              <UseSanaTexts options={['BreadCrumb_StartingNode']} props={{ links, homePath, items }} dontWait>
                {renderWithHomeLink}
              </UseSanaTexts>
            )
            : (
              <>
                <Schema nodes={links} />
                {links}
              </>
            )
          }
        </ul>
      </nav>
    </div>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.object,
    title: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  className: PropTypes.string,
  backLink: PropTypes.node,
};

export default memo(Breadcrumb);

function renderWithHomeLink(texts, { links, homePath, items }) {
  const homeRoute = routesBuilder.forHome();
  const homeLink = texts[0]
    ? <li><Link url={homePath} to={homeRoute}>{makeSimpleText(texts[0])}</Link></li>
    : null;
  return (
    <>
      <Schema nodes={items} homeLink={{ title: texts[0], link: { url: homePath, to: homeRoute } }} />
      {homeLink}
      {links}
    </>
  );
}

function isHomePage({ link }, homePath) {
  if (!link)
    return false;

  if (link.to)
    return link.to.routeName === RouteName.Home;

  return link.url === homePath;
}
