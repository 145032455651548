import { combineEpics } from 'utils/rxjs';
import { of, merge, identity, EMPTY } from 'rxjs';
import {
  map, pluck, tap, switchMap,
  takeUntil, filter,
  ignoreElements,
  mergeMap,
  first,
  exhaustMap,
  mapTo,
  withLatestFrom,
  switchMapTo,
  delay,
  catchError,
} from 'rxjs/operators';
import {
  NAVIGATION_REQUESTED, navigationRequested,
  STATUS_CODE_RESOLVED,
  GO_BACK,
  NAVIGATE_TO_PREVIOUS,
  RELOAD_LOCATION,
  REWRITE_TO,
  NAVIGATING,
  NAVIGATED,
  redirectTo, startNavigation, initialize, reloadLocation,
} from './actions';
import { NAVIGATION_REQUESTED as NAVIGATION_REQUESTED_EVENT } from 'behavior/events';
import { locationChanged } from 'behavior/events';
import { APP_INIT, OFFLINE_MODE_CHANGED, OfflineModeSupport } from 'behavior/app';
import { areLocationsEqual, createLocation, createUrl } from './helpers';
import { resolveQuery } from './queries';
import {
  trailingMiddleware,
  languageMiddleware,
  canonicalMiddleware,
} from './middlewares';
import { requestRoute } from 'behavior/route';
import { RouteName, routesBuilder } from 'routes';
import { ShopAccountTypes } from 'behavior/user/constants';
import { logout } from 'behavior/user';
import { visibility$ } from 'utils/rxjs/eventsObservables';
import { ofType } from 'redux-observable';
import { getBackTo } from 'behavior/pages/helpers';

export function createRoutingEpic(router, navigationMiddleware = [
  languageMiddleware,
  trailingMiddleware,
  canonicalMiddleware,
]) {
  const historyChangeEpic = action$ => {
    const onInit$ = action$.pipe(
      ofType(APP_INIT),
      first(),
      mergeMap(_ => of(initialize(router.location), navigationRequested(router.location))),
    );
    const navigating$ = action$.pipe(
      ofType(NAVIGATION_REQUESTED),
      map(({ payload: { location } }) => location),
    );
    const navigated$ = action$.pipe(
      ofType(NAVIGATED),
      map(({ payload: { location } }) => location),
    );

    const onHistoryChange$ = router.locationObservable.pipe(
      withLatestFrom(merge(of(router.location), navigating$, navigated$)),
      filter(([newLocation, currentLocation]) => !areLocationsEqual(newLocation, currentLocation)),
      map(([location, _]) => navigationRequested(location)),
    );
    return merge(onInit$, onHistoryChange$);
  };

  const navigationEpic = (action$, state$, { api, ...dependencies }) => action$.pipe(
    ofType(NAVIGATION_REQUESTED),
    pluck('payload'),
    filter(({ location, statusCode }) => router.onNavigating(location, statusCode)),
    switchMap(({ location, routeData }) => {
      if (routeData && routeData.routeName) {
        if (!routeData.params?.language) {
          routeData = {
            ...routeData,
            params: { ...routeData.params, language: state$.value.localization.currentLanguage.id },
          };
        }

        return of(startNavigation(location, routeData));
      }
      else {
        return api.graphApi(resolveQuery, {
          path: location.pathname + location.search,
        }).pipe(
          map(({ routing }) => {
            for (const middleware of navigationMiddleware) {
              const action = middleware(routing.route, location, dependencies);

              if (action)
                return action;
            }

            return startNavigation(location, routing.route);
          }),
          catchError(e => {
            dependencies.logger.error(e);
            return of(startNavigation(location, { routeName: RouteName.Error }));
          }),
          takeUntil(action$.pipe(ofType(NAVIGATING))),
        );
      }
    }),
  );

  const navigationEventEpic = (action$, state$, dependencies) => {
    const requestNavigation = (routeData, url, omitScroll, replaceHistory) => {
      const location = createLocation(url);
      if (omitScroll)
        location.omitScroll = omitScroll;

      return navigationRequested(location, undefined, routeData, replaceHistory);
    };

    return action$.pipe(
      ofType(NAVIGATION_REQUESTED_EVENT),
      pluck('payload'),
      switchMap(({ to, url, omitScroll, replaceHistory }) => {
        if (url || !to) {
          if (url[0] === '#') {
            const location = state$.value.routing.location;
            url = location.pathname + location.search + url;
          }

          return of(requestNavigation(to, url, omitScroll, replaceHistory));
        }

        return requestRoute(to, state$, dependencies).pipe(
          map(path => requestNavigation(to, path, omitScroll, replaceHistory)),
        );
      }),
    );
  };

  const navigationCompletedEpic = (action$, state$) => action$.pipe(
    ofType(NAVIGATING),
    map(({ payload: { location } }) => location),
    filter(newLocation => !areLocationsEqual(newLocation, state$.value.routing.location)),
    mapTo(locationChanged()),
  );

  const syncWithHistoryEpic = action$ => action$.pipe(
    ofType(NAVIGATION_REQUESTED),
    pluck('payload'),
    filter(({ location }) => !areLocationsEqual(location, router.location)),
    tap(({ location, replaceHistory }) => replaceHistory ? router.replace(location) : router.push(location)),
    ignoreElements(),
  );

  const statusCodeEpic = action$ => action$.pipe(
    ofType(STATUS_CODE_RESOLVED),
    tap(({ payload: { statusCode } }) => router.onStatusCodeResolved(statusCode)),
    ignoreElements(),
  );

  const goBackEpic = action$ => action$.pipe(
    ofType(GO_BACK),
    tap(() => router.goBack()),
    ignoreElements(),
  );

  const navigateToPreviousEpic = (action$, state$, dependencies) => action$.pipe(
    ofType(NAVIGATE_TO_PREVIOUS),
    mergeMap(({ payload }) => {
      const backTo = getBackTo(state$, payload.ignoredRouteNames);
      if (backTo && backTo.url)
        return of({ routeData: backTo.routeData, location: createLocation(backTo.url) });

      const prev = state$.value.routing.previous;
      if (prev && !payload.ignoredRouteNames.includes(prev.routeData.routeName))
        return of(prev);

      const routeData = payload.fallbackRoute || routesBuilder.forHome();
      const toLocation = path => ({ routeData, location: createLocation(path) });
      return map(toLocation)(requestRoute(routeData, state$, dependencies));
    }),
    filter(Boolean),
    map(data => navigationRequested(data.location, 200, data.routeData)),
  );

  const reloadLocationEpic = (action$, state$) => action$.pipe(
    ofType(RELOAD_LOCATION),
    map(_ => startNavigation(state$.value.routing.location, state$.value.routing.routeData)),
  );

  const rewriteToEpic = (action$, state$) => action$.pipe(
    ofType(REWRITE_TO),
    pluck('payload'),
    map(({ routeData, omitScroll }) => {
      const location = state$.value.routing.location;
      if (omitScroll)
        return startNavigation({ ...location, omitScroll }, routeData);

      return startNavigation(location, routeData);
    }),
  );

  const authRedirectsEpic = (action$, state$, dependencies) => {
    const redirect = user => {
      const routing = state$.value.routing;
      const { location: currentLocation, routeData: currentRoute } = routing.navigatingTo || routing;

      let route;
      if (!user.isAuthenticated)
        route = routesBuilder.forLogin();
      else if (user.shopAccountType === ShopAccountTypes.SalesAgent && !user.isImpersonating)
        route = routesBuilder.forRepresent();
      else
        return of(logout()); // User is still authenticated on client, but on server the session has been invalidated.

      const isServer = dependencies.scope === 'SERVER';
      // On client side 401 status will replace current history record, 302 - adds new record.
      // So use 401 for redirects to login/represent during client navigation to avoid loop on going back via browser button
      // and to be consistent with SSR redirect in number of history records.
      const statusCode = isServer || !routing.navigatingTo ? 302 : 401;

      const backUrl = createUrl(currentLocation);
      const redirectRoute = {
        ...route,
        options: { backTo: { url: backUrl, routeData: currentRoute } },
      };

      return requestRoute(route, state$, dependencies)
        .pipe(map(path => {
          const redirectUrl = isServer
            ? `${path}?backurl=${backUrl}`
            : path;
          return redirectTo(redirectUrl, statusCode, redirectRoute);
        }));
    };

    return merge(
      action$.pipe(
        ofType(STATUS_CODE_RESOLVED),
        filter(action => action.payload.statusCode === 401),
      ),
      dependencies.api.errors$.pipe(
        filter(e => e.status && e.status === 401),
      ),
    ).pipe(
      exhaustMap(_ => {
        const user = state$.value.user;
        if (user.initialized)
          return redirect(user);

        return state$.pipe(
          first(({ user }) => user.initialized),
          exhaustMap(({ user }) => redirect(user)),
        );
      }),
    );
  };

  const authSynchronizationReloadEpic = (action$, _state$, { api }) => {
    if (!api.authChanges$)
      return EMPTY;

    return api.authChanges$.pipe(
      switchMapTo(visibility$.pipe(
        first(identity),
        delay(50),
        mapTo(reloadLocation()),
        takeUntil(action$.pipe(ofType(NAVIGATING, NAVIGATED))),
      )),
    );
  };

  const serviceUnavailableEpic = action$ => action$.pipe(
    ofType(OFFLINE_MODE_CHANGED),
    first(),
    filter(({ payload: { offlineMode, offlineModeSupport } }) =>
      offlineMode && offlineModeSupport === OfflineModeSupport.Disabled),
    tap(_ => router.onStatusCodeResolved(503)),
    ignoreElements(),
  );

  return combineEpics(
    serviceUnavailableEpic,
    historyChangeEpic,
    authRedirectsEpic,
    navigationEventEpic,
    navigationEpic,
    syncWithHistoryEpic,
    statusCodeEpic,
    goBackEpic,
    navigateToPreviousEpic,
    reloadLocationEpic,
    rewriteToEpic,
    navigationCompletedEpic,
    authSynchronizationReloadEpic,
  );
}
