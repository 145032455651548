import styles from './ContactUs.module.scss';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SanaForm, FormGroup, useCaptchaOnSubmit, CaptchaFormGroup } from 'components/objects/forms/';
import { TextBoxField, EmailAddressField, PhoneNumberField, TextAreaField } from 'components/objects/forms/fields';
import { ValidationTexts } from 'components/objects/forms/validation';
import { CustomerTypes } from 'behavior/user/constants';
import { sendFeedback } from 'behavior/feedback';
import { RichText, useSimpleTexts } from 'components/sanaText';
import { FormLockKeys } from 'behavior/pages';
import { toasts } from 'behavior/toasts';
import { DangerAlert } from 'components/primitives/alerts';

const ContactUsForm = ({
  isAuthenticated,
  email,
  name,
  customerName,
  phone,
  customerType,
  successStatus,
  sendFeedback,
}) => {
  const [formSubmitFailed, setFormSubmitFailed] = useState(false);

  const { onSubmit, captchaRef } = useCaptchaOnSubmit(formData => {
    setFormSubmitFailed(false);
    sendFeedback(formData);
  }, FormLockKeys.ContactUs, []);

  useEffect(() => {
    if (successStatus == null)
      return;

    if (successStatus) {
      setFormSubmitFailed(false);
      toasts.success(<RichText textKey="ContactForm_MessageSendSuccessfully" disableInsiteEditor />, { className: styles.toast });
    } else {
      setFormSubmitFailed(true);
    }
  }, [successStatus]);

  let initialValues = {};
  if (isAuthenticated) {
    const isB2c = CustomerTypes.B2C === customerType;
    initialValues = {
      companyName: isB2c ? '' : customerName,
      name,
      email,
      phoneNumber: phone,
    };
  }

  const [
    companyNameTitle,
    nameTitle,
    emailTitle,
    phoneTitle,
    commentsTitle,
    commentsErrorMsg,
  ] = useSimpleTexts([
    'CompanyName',
    'Name',
    'EmailAddress',
    'PhoneNumber',
    'Comments',
    ValidationTexts.RequiredPlural,
  ]).texts;

  return (
    <SanaForm
      name="ContactUs"
      onSubmit={onSubmit}
      initialValues={initialValues}
      shouldReset={successStatus}
    >
      {formSubmitFailed &&
        <DangerAlert role="alert" scrollOnAppear>
          <RichText textKey="ContactForm_MessageSendUnsuccessfully" />
        </DangerAlert>
      }
      <FormGroup
        fieldName="companyName"
        fieldTitle={companyNameTitle}
        fieldComponent={TextBoxField}
        maxLength={100}
        validation={{
          readableName: true,
        }}
      />
      <FormGroup
        fieldName="name"
        fieldTitle={nameTitle}
        fieldComponent={TextBoxField}
        maxLength={100}
        required
        validation={{
          required: true,
          readableName: true,
        }}
      />
      <FormGroup
        fieldName="email"
        fieldTitle={emailTitle}
        fieldComponent={EmailAddressField}
        maxLength={80}
        required
        validation={{
          required: true,
        }}
      />
      <FormGroup
        fieldName="phoneNumber"
        fieldTitle={phoneTitle}
        fieldComponent={PhoneNumberField}
        maxLength={100}
      />
      <FormGroup
        fieldName="comments"
        fieldTitle={commentsTitle}
        fieldComponent={TextAreaField}
        maxLength={1000}
        required
        validation={{
          required: { message: commentsErrorMsg },
        }}
      />
      <CaptchaFormGroup ref={captchaRef} />
      <SanaForm.SubmitRow textKey="ContactSubmit" />
    </SanaForm>
  );
};

ContactUsForm.propTypes = {
  isAuthenticated: PropTypes.bool,
  email: PropTypes.string,
  name: PropTypes.string,
  customerName: PropTypes.string,
  phone: PropTypes.string,
  customerType: PropTypes.string,
  sendFeedback: PropTypes.func.isRequired,
  successStatus: PropTypes.bool,
};

export default connect(mapStateToProps, { sendFeedback })(ContactUsForm);

function mapStateToProps({ user, page }) {
  const { isAuthenticated } = user;
  const { successStatus } = page;

  if (!isAuthenticated)
    return { isAuthenticated, successStatus };

  const { name, email, customer, customerType } = user;
  const { name: customerName = '', phone = '' } = customer || {};

  return {
    isAuthenticated,
    email,
    name,
    customerName,
    phone,
    customerType,
    successStatus,
  };
}
