import { ofType } from 'redux-observable';
import { merge, of, throwError, concat, from } from 'rxjs';
import { switchMap, map, mapTo, filter, pluck, catchError, startWith } from 'rxjs/operators';
import { combineEpics } from 'utils/rxjs';
import { windowMessage$ } from 'utils/rxjs/eventsObservables';
import { isValidConfiguratorWindowMessage, convertToString } from './util';
import {
  PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_STARTED,
  PRODUCT_CONFIGURATOR_MESSAGE_RECEIVED,
  PRODUCT_CONFIGURATOR_ADD_TO_BASKET_TRIGGERED,
  PRODUCT_CONFIGURATOR_ADD_TO_BASKET_COMPLETED,
  PRODUCT_CONFIGURATOR_REDIRECT_TO_BASKET_TRIGGERED,
  receiveNewProductConfiguration,
  newProductConfigurationFailed,
  receiveProductConfiguratorMessage,
  finishProductConfigurator,
  triggerAddProductConfigurationToBasket,
  completeAddProductConfigurationToBasket,
  triggerRedirectToBasket,
} from './actions';
import {
  startNewProductConfiguration,
  saveProductConfiguration,
  addProductConfigurationToBasket,
} from './queries';
import { basketChangeStarted, basketChangeCompleted, navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import { trackAddToBasket } from 'behavior/analytics';
import { getConfigurationResultTrackingData } from 'behavior/basket/util';

export const productConfiguratorEpic = (action$, state$, { api }) => {
  const startAction$ = action$.pipe(
    ofType(PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_STARTED),
    pluck('payload'),
    switchMap(({ input, updatedById }) => api.graphApi(startNewProductConfiguration, { input }).pipe(
      pluck('productConfiguration', 'create'),
      map(receiveNewProductConfiguration),
      catchError(e => concat(of(newProductConfigurationFailed(updatedById)))),
    ),
    ),
  );

  const messageReceivedAction$ = action$.pipe(
    ofType(PRODUCT_CONFIGURATOR_MESSAGE_RECEIVED),
    pluck('payload', 'message', 'data'),
    map(msg => ({
      configurationId: state$.value.productConfigurator.configurationId,
      message: convertToString(msg),
    })),
    switchMap(
      input => api.graphApi(saveProductConfiguration, { input }).pipe(
        switchMap(_ => of(finishProductConfigurator(), triggerAddProductConfigurationToBasket(input.configurationId))),
        catchError(e => concat(of(finishProductConfigurator()), throwError(e))),
      ),
    ),
  );

  const addToBasketAction$ = action$.pipe(
    ofType(PRODUCT_CONFIGURATOR_ADD_TO_BASKET_TRIGGERED),
    switchMap(
      action => api.graphApi(addProductConfigurationToBasket, action.payload).pipe(
        pluck('productConfiguration', 'addToBasket'),
        switchMap(getAddToBasketActions),
        catchError(e => concat(of(basketChangeCompleted()), throwError(e))),
        startWith(basketChangeStarted()),
      ),
    ),
  );

  const redirectToBasketAction$ = action$.pipe(
    ofType(PRODUCT_CONFIGURATOR_ADD_TO_BASKET_COMPLETED),
    filter(_ => state$.value.settings.basket.redirectOnAdd),
    mapTo(triggerRedirectToBasket()),
  );

  return merge(startAction$, messageReceivedAction$, addToBasketAction$, redirectToBasketAction$);
};

const redirectedToBasketEpic = action$ => {
  const basketRoute = routesBuilder.forBasket();

  return action$.pipe(
    ofType(PRODUCT_CONFIGURATOR_REDIRECT_TO_BASKET_TRIGGERED),
    mapTo(navigateTo(basketRoute)),
  );
};

const productConfiguratorWindowMessagesEpic = (_, state$) => {
  return windowMessage$.pipe(
    filter(msg => isValidConfiguratorWindowMessage(msg, state$.value)),
    map(receiveProductConfiguratorMessage),
  );
};

export default combineEpics(productConfiguratorEpic, productConfiguratorWindowMessagesEpic, redirectedToBasketEpic);

function getAddToBasketActions(configurationResult) {
  const actions = [
    basketChangeCompleted(configurationResult.products.length),
    completeAddProductConfigurationToBasket(),
  ];
  const addedProducts = getConfigurationResultTrackingData(configurationResult);
  if (addedProducts && addedProducts.length) {
    actions.push(trackAddToBasket(addedProducts));
  }

  return from(actions);
}