import styles from './DocFreeReturnOrder.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useSimpleTexts, RichText } from 'components/sanaText';
import { SanaForm, FormGroup, useFormLockerOnSubmit } from 'components/objects/forms';
import { DropdownField, TextAreaField, FileField } from 'components/objects/forms/fields';
import { FormLockKeys, unlockForm } from 'behavior/pages';
import { createReturnOrder } from 'behavior/pages/createDocFreeReturnOrder';
import { toasts } from 'behavior/toasts';
import { DangerAlert } from 'components/primitives/alerts';
import { ContactStoreLink } from 'components/primitives/links';
import ReturnOrderLinesFieldArray from './ReturnOrderLinesFieldArray';
import { useHandlerLockerInPreview } from 'components/objects/preview';

const DocFreeReturnOrderForm = ({ setProductAddCallback }) => {
  const dispatch = useDispatch();
  const { returnReasons, fileUploadSettings, isFailed } = useSelector(({ page }) => ({
    returnReasons: page.returnReasons.forOrder,
    fileUploadSettings: page.fileUploadSettings,
    isFailed: page.isFailed,
  }), shallowEqual);
  const [
    returnReasonTitle,
    commentTitle,
    fileAttachmentsTitle,
    fileAcceptedTypesErrorText,
  ] = useSimpleTexts([
    'ReturnRequest_Reason',
    'OrderComments',
    'ReturnRequest_FileAttachments',
    'FileUploader_AcceptValidation',
  ]).texts;
  const orderReturnReasons = useMemo(() => returnReasons.map(({ id, name }) => ({ value: id, name })), [returnReasons]);

  const onSubmit = useFormLockerOnSubmit(({ files, ...input }) => {
    if (!input.lines?.length) {
      toasts.info(<RichText textKey="ReturnRequest_NoProductsSelected" />);
      dispatch(unlockForm(FormLockKeys.CreateDocFreeReturnOrder));
      return;
    }

    dispatch(createReturnOrder({ ...input, fileNames: Array.from(files).map(f => f.name) }, files));
  }, FormLockKeys.CreateDocFreeReturnOrder, []);

  return (
    <SanaForm
      name="CreateDocFreeReturnOrder"
      onSubmit={useHandlerLockerInPreview(onSubmit)}
      shouldReset={isFailed}
    >
      {isFailed && (
        <DangerAlert role="alert" scrollOnAppear>
          <RichText
            textKey="ReturnRequest_OrderFailed"
            formatWith={[<ContactStoreLink linkTextKey="ReturnRequest_OrderFailed_ContactUs" />]}
          />
        </DangerAlert>
      )}
      <ReturnOrderLinesFieldArray name="lines" setProductAddCallback={setProductAddCallback} />
      {orderReturnReasons.length !== 0 && (
        <FormGroup
          fieldName="reasonId"
          fieldTitle={returnReasonTitle}
          fieldComponent={DropdownField}
          items={orderReturnReasons}
          placeholderTextKey="ReturnRequest_SelectReasonPlaceholder"
          required
          validation={{ required: true }}
          className={styles.reasonField}
        />
      )}
      <FormGroup
        fieldName="comment"
        fieldTitle={commentTitle}
        fieldComponent={TextAreaField}
        maxLength={2000}
      />
      <FormGroup
        fieldName="files"
        fieldTitle={fileAttachmentsTitle}
        fieldComponent={FileField}
        multiple={fileUploadSettings.allowMultiple}
        validation={{
          fileAcceptedTypes: { types: fileUploadSettings.acceptedTypes, message: fileAcceptedTypesErrorText },
          fileNameMaxLength: { max: fileUploadSettings.maxNameLength },
          filesMaxSize: { max: fileUploadSettings.maxSize },
        }}
      />
      <SanaForm.SubmitRow textKey="CreateReturnOrder" />
    </SanaForm>
  );
};

DocFreeReturnOrderForm.propTypes = {
  setProductAddCallback: PropTypes.func.isRequired,
};

export default memo(DocFreeReturnOrderForm);