import { memo } from 'react';
import PropTypes from 'prop-types';
import { DangerAlert, WarningAlert } from 'components/primitives/alerts';
import { useCurrencyInfo } from 'utils/hooks';
import { formatAsPrice } from 'utils/format';
import { AbilityTo } from 'behavior/user/constants';
import { useHasAbilities } from '../user';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText } from 'utils/render';
import { CreditLimitValidationResult as Results } from './constants';

const CreditLimitMessage = ({ creditLimit, className }) => {
  if (!creditLimit)
    return null;

  const [canSeePrices] = useHasAbilities(AbilityTo.ViewPrices);
  const currencyInfo = useCurrencyInfo();
  const { loaded, texts: [
    exceededByText,
    exceededText,
    additionalText,
  ] } = useSanaTexts([
    'CreditLimitExceededBy',
    'CreditLimitExceeded',
    creditLimit.validationResult === Results.ForceOnlinePayment
      ? 'CreditLimitExceeded_PayOnline'
      : creditLimit.validationResult === Results.Error
        ? 'CreditLimitExceeded_PayYourInvoices_Error'
        : 'CreditLimitExceeded_PayYourInvoices',
  ]);

  if (creditLimit.validationResult === Results.Valid || !loaded)
    return null;

  const AlertComponent = creditLimit.validationResult === Results.Error
    ? DangerAlert
    : WarningAlert;

  const text = canSeePrices && creditLimit.exceededAmount
    ? makeRichText(exceededByText, [formatAsPrice(creditLimit.exceededAmount, currencyInfo)])
    : makeRichText(exceededText);

  return <AlertComponent className={className}>{text} {makeRichText(additionalText)}</AlertComponent>;
};

CreditLimitMessage.propTypes = {
  creditLimit: PropTypes.shape({
    validationResult: PropTypes.oneOf(Object.values(Results)).isRequired,
    exceededAmount: PropTypes.number,
  }),
  className: PropTypes.string,
};

export default memo(CreditLimitMessage);
