import { createElement } from 'react';
import PropTypes from 'prop-types';
import components from './components';
import { Placeholder } from 'components/primitives/placeholders';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText } from 'utils/render';

const PromotionalText = ({ textKey, withPlaceholder, disableInsiteEditor }) => {
  const options = [textKey];
  options.disableInsiteEditor = disableInsiteEditor;

  const { texts: [text], loaded } = useSanaTexts(options, makeRichText);
  if (!loaded) {
    return withPlaceholder ? <Placeholder /> : null;
  }

  return text || createElement(components[textKey]);
};

PromotionalText.propTypes = {
  textKey: PropTypes.string.isRequired,
  withPlaceholder: PropTypes.bool,
  disableInsiteEditor: PropTypes.bool,
};

export default PromotionalText;
