import { memo } from 'react';
import { useSimpleTexts } from 'components/sanaText';
import { usePageTitle } from 'components/objects/hooks';
import ResetPassword from 'components/objects/resetPassword';
import ClosedStoreLayout from '../ClosedStoreLayout';

const ClosedStoreResetPassword = () => {
  const [title] = useSimpleTexts(['ChangePassword']).texts;
  const pageTitle = usePageTitle(title);

  return (
    <ClosedStoreLayout pageTitle={pageTitle}>
      <ResetPassword />
    </ClosedStoreLayout>
  );
};

export default memo(ClosedStoreResetPassword);
