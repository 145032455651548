import styles from './ProductComparisonPage.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { Checkbox } from 'components/primitives/form';
import { Row, Col } from 'components/primitives/grid';
import RemoveAllProductsLink from './RemoveAllProductsLink';

const ActionSection = ({ onShowOnlyDifferenceChanged }) => (
  <Row noGutters mainAxisAlign="between" className={styles.actionsSection}>
    <Col>
      <Checkbox onChange={onShowOnlyDifferenceChanged} id="onlyDiff" />
      <label className={styles.checkboxLabel} htmlFor="onlyDiff">
        <SimpleText textKey="ProductCompare_OnlyShowDifferences" />
      </label>
    </Col>
    <Col xs="auto">
      <RemoveAllProductsLink />
    </Col>
  </Row>
);

ActionSection.propTypes = {
  onShowOnlyDifferenceChanged: PropTypes.func.isRequired,
};

export default ActionSection;