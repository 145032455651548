import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText } from 'utils/render';

const UnitOfMeasure = ({ id, description, placeholder = null }) => {
  const options = [{
    key: `UnitOfMeasure_${id || 'Empty'}`,
    fallback: description || id,
  }];
  options.disableInsiteEditor = true;

  const { texts: [text], loaded } = useSanaTexts(options);

  if (!loaded)
    return placeholder;

  return makeRichText(text || description || id);
};

UnitOfMeasure.propTypes = {
  id: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.node,
};

export default UnitOfMeasure;
