import styles from './Link.module.scss';
import { useSimpleTexts } from 'components/sanaText';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Placeholder } from 'components/primitives/placeholders';

const ContactStoreLink = ({ shopEmailAddress, linkTextKey }) => {
  const { texts: [contactLink], loaded } = useSimpleTexts([linkTextKey]);

  if (!loaded)
    return <Placeholder className={styles.contactPlaceholder} />;

  return (
    <a href={`mailto:${shopEmailAddress}`}>{contactLink}</a>
  );
};

ContactStoreLink.propTypes = {
  shopEmailAddress: PropTypes.string,
  linkTextKey: PropTypes.string.isRequired,
};

export default connect(
  ({ settings: { shopEmailAddress } }) => ({
    shopEmailAddress,
  }),
)(ContactStoreLink);
