import { useMemo, useEffect } from 'react';
import { generateKey } from '../helpers';

type IFrameData = {
  id: string;
  intervalId?: number;
};

export default function useDownload() {
  const [downloadingIframes, download] = useMemo(() => {

    const downloadingIframes: Map<string, IFrameData> = new Map();

    return [downloadingIframes, download];

    function download(src: string, onDownloaded: () => void, onFailed: () => void) {
      let iframeData = downloadingIframes.get(src) as IFrameData;
      const key = generateKey();
      if (iframeData) {
        clearInterval(iframeData.intervalId);

        const oldIframe = document.getElementById(iframeData.id);
        oldIframe && document.body.removeChild(oldIframe);
        iframeData.id = key;
      } else {
        iframeData = { id: key };
        downloadingIframes.set(src, iframeData);
      }

      const iframe = document.createElement('iframe');
      iframe.id = iframeData.id;
      iframe.style.visibility = 'hidden';
      iframe.src = src + (src.indexOf('?') === -1 ? '?' : '&') + 'download=' + iframeData.id;
      document.body.appendChild(iframe);

      const cookieName = 'download' + iframeData.id;

      iframeData.intervalId = window.setInterval(() => {
        if (document.cookie.toLowerCase().indexOf(cookieName) > -1) {
          window.clearInterval(iframeData.intervalId);

          const isFailed = withHtmlOrFailed(iframe);

          if (isFailed)
            onFailed();
          else
            onDownloaded();
        }

        if (withHtmlOrFailed(iframe)) {
          window.clearInterval(iframeData.intervalId);
          onFailed();
        }
      }, 150);
    }
  }, []);

  useEffect(() => () => {
    for (const iframeData of downloadingIframes.values()) {
      clearInterval(iframeData.intervalId);

      const oldIframe = document.getElementById(iframeData.id);
      oldIframe && document.body.removeChild(oldIframe);
    }
  }, []);

  return download;
}

interface DOMExceptionWithIESpecificFields extends DOMException {
  number: number;
}

function withHtmlOrFailed(iframe: HTMLIFrameElement) {
  try {
    const iframeDoc = iframe.contentWindow?.document || iframe.contentDocument;
    return !!(iframeDoc?.body?.innerHTML);
  }
  catch (e) {
    // Typescript does not support annotations on the catch variable, applied solution from https://github.com/Microsoft/TypeScript/issues/20024.
    const err = e as DOMExceptionWithIESpecificFields;
    // IE & Chromium throws a permission denied error in case of 404/503 statuses.
    if (err && (err.number === -2146828218 || err.code === 18)) {
      return true;
    } else {
      throw err;
    }
  }
}
