import styles from './Error.module.scss';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { isBrowser } from 'utils/detections';

const ErrorButton = ({ id, className = '' }) => {
  const [ariaCopy, copied] = useSanaTexts(['Aria_Copy', 'Copied']).texts;
  const copy = ErrorButton.canCopy && navigator.clipboard && (e => {
    navigator.clipboard.writeText(id);
    const status = e.currentTarget.querySelector('.' + styles.copied);
    status.style.display = 'inline';
    setTimeout(() => status.style.display = 'none', 3500);
  });

  return (
    <button onClick={copy} className={styles.copy + ' ' + className} aria-label={ariaCopy + ': ' + id} aria-live="assertive">
      {id}
      <FontAwesomeIcon icon={faCopy} />
      <span className={styles.copied}>{copied}</span>
    </button>
  );
};

ErrorButton.canCopy = isBrowser && navigator.clipboard;

ErrorButton.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ErrorButton;
