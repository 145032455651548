import contentPage from 'components/objects/content/page';
import productPage from 'components/objects/product/page';
import productListPage from 'components/objects/productList/page';
import searchPage from 'components/objects/productList/search';
import plpWithCategory from 'components/objects/productList/withCategory';
import faqPage from 'components/objects/faq/page';
import newsPage from 'components/objects/news/page';
import basketPage from 'components/objects/basket/page';
import systemPages from 'components/objects/systemPages';
import error from 'components/objects/error/page';
import offline from 'components/objects/offlineMode/page';
import closedStoreLogin from 'components/objects/closedStore/login/page';
import productComparisonPage from 'components/objects/productComparison/page';
import closedStoreForgotPassword from 'components/objects/closedStore/forgotPassword/page';
import closedStoreResetPassword from 'components/objects/closedStore/resetPassword/page';
import wishListPage from 'components/objects/wishList/page';
import volumeOrderListPage from 'components/objects/volumeOrderList/page';

const defaultPageRenderers = {
  ...contentPage,
  ...productPage,
  ...productListPage,
  ...searchPage,
  ...plpWithCategory,
  ...faqPage,
  ...newsPage,
  ...systemPages,
  ...error,
  ...basketPage,
  ...offline,
  ...productComparisonPage,
  ...closedStoreLogin,
  ...closedStoreForgotPassword,
  ...closedStoreResetPassword,
  ...wishListPage,
  ...volumeOrderListPage
};

export default defaultPageRenderers;