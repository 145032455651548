import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import Tooltip from 'components/primitives/tooltip';
import { FormattedPrice } from 'components/primitives/price';
import { makeSimpleText } from 'utils/render';

const PrepaymentTooltip = ({ isQuote, totals, currency }) => {
  const { texts: [title, body, label], loaded } = useSanaTexts([
    'OrderOverview_AdditionalInformation',
    'OrderOverview_PrepaymentDescriptionAltText',
    'OrderOverview_PrepaymentDescription' + (isQuote ? '_Quote' : ''),
  ]);

  if (!loaded)
    return null;

  return (
    <Tooltip
      id="prepayment"
      title={makeSimpleText(title)}
      body={makeSimpleText(body)}
    >
      {makeSimpleText(label, [<FormattedPrice price={totals.prepayment} currencyInfo={currency} />], true)}
    </Tooltip>
  );
};

PrepaymentTooltip.propTypes = {
  isQuote: PropTypes.bool,
  totals: PropTypes.shape({
    prepayment: PropTypes.number,
  }).isRequired,
  currency: PropTypes.object.isRequired,
};

export default PrepaymentTooltip;
