import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Search } from '../search';
import { generateKey } from 'utils/helpers';

const SearchBarBlock = ({ model, isDesignerMode }) => {
  if (!model)
    return null;

  const [searchBarId, setSearchBarId] = useState('');
  useEffect(() => setSearchBarId(generateKey()), []);

  return <Search initialModel={model} id={searchBarId} designMode={isDesignerMode} />;
};

SearchBarBlock.propTypes = {
  model: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  isDesignerMode: PropTypes.bool,
};

export default memo(SearchBarBlock);
