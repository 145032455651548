import { ignoreElements, map, mergeMap, switchMap, takeUntil, tap, pluck, withLatestFrom } from 'rxjs/operators';
import { merge, of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  LASTVIEWED_PRODUCTS_CLEARED,
  LASTVIEWED_PRODUCTS_REQUESTED,
  lastViewedProductsReceived,
  PRODUCT_VIEWED,
} from './actions';
import { loadCalculatedFieldsQuery, productsQuery } from './queries';
import { VIEWER_CHANGED } from 'behavior/events';
import { createApiVariables, sortProducts } from './helpers';
import { storageKey } from 'behavior/products/lastViewedTracking';

export default (action$, state$, { api, localStorage }) => {
  const trackViewedProduct$ = action$.pipe(
    ofType(PRODUCT_VIEWED),
    tap(({ payload: id }) => {
      const products = localStorage.getItem(storageKey) || [];
      localStorage.setItem(storageKey, [id, ...products.filter(productId => productId !== id).slice(0, 24)]);
    }),
    ignoreElements(),
  );

  const clearViewedProducts$ = action$.pipe(
    ofType(LASTVIEWED_PRODUCTS_CLEARED),
    tap(_ => localStorage.setItem(storageKey, [])),
    ignoreElements(),
  );

  const reset$ = action$.pipe(
    ofType(
      LASTVIEWED_PRODUCTS_CLEARED,
      VIEWER_CHANGED,
    ),
  );

  const requestProducts$ = action$.pipe(
    ofType(LASTVIEWED_PRODUCTS_REQUESTED),
    pluck('payload'),
    withLatestFrom(state$),
    map(([payload, { analytics }]) => ({
      ...payload,
      loadCategories: analytics && analytics.isTrackingEnabled,
    })),
    switchMap(({ skipCurrent, count, loadCategories }) => {
      const variables = createApiVariables(localStorage, count, skipCurrent);
      if (!variables)
        return of(lastViewedProductsReceived([]));

      variables.loadCategories = loadCategories;

      return api.graphApi(productsQuery, variables, { retries: 0 }).pipe(
        pluck('catalog', 'products', 'products'),
        mergeMap(products => {
          const sortedProducts = sortProducts(products, variables);

          return merge(
            of(lastViewedProductsReceived(sortedProducts)),
            api.graphApi(loadCalculatedFieldsQuery, variables).pipe(
              pluck('catalog', 'products', 'products'),
              map(products => lastViewedProductsReceived(products, true)),
            ),
          );
        }),
        takeUntil(reset$),
      );
    },
    ),
  );

  return merge(trackViewedProduct$, clearViewedProducts$, requestProducts$);
};
