import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import { RadioField as RadioFieldCore } from '../../fields';
import validationFieldPropType from './validationFieldPropType';

const RadioField = hoistStatics(
  ({
    fieldName,
    fieldTitle,
    validation,
    required,
    items,
    className,
    disabled,
  }) => (
      <RadioFieldCore
        fieldName={fieldName}
        fieldTitle={fieldTitle}
        validation={validation}
        required={required}
        items={items}
        className={className}
        disabled={disabled}
      />
    ),
  RadioFieldCore,
);

RadioField.propTypes = {
  ...RadioFieldCore.propTypes,
  required: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.node,
    value: PropTypes.any,
  })),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  validation: validationFieldPropType,
};

export default RadioField;
