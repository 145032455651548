import { ADMINTEXTS_LOADED } from './actions';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADMINTEXTS_LOADED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}