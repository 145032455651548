import { orderTemplatesPageQuery } from './queries';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { initSystemPageContent } from '../system';
import { orderTemplatesReceived } from './actions';
import { loadSystemPageQuery } from '../system/queries';
import { generateKey } from 'utils/helpers';

export default ({ params }, _state$, { api }) => {
  if (params?.previewToken) {
    return api.graphApi(loadSystemPageQuery('orderTemplates')).pipe(
      map(({ pages: { orderTemplates: page } }) => {
        if (!page)
          return null;
  
        page.component = PageComponentNames.OrderTemplates;
        const orderTemplates = Array.from(Array(5)).map(() => ({ id: generateKey(), lines: [] }));
        
        return { page, action$: of(orderTemplatesReceived({ orderTemplates })) };
      }),
      initSystemPageContent(),
    );
  }

  return api.graphApi(orderTemplatesPageQuery).pipe(
    map(({ pages: { orderTemplates: page }, orderTemplates }) => {
      if (!page)
        return null;

      page.component = PageComponentNames.OrderTemplates;

      return { page, action$: of(orderTemplatesReceived({ orderTemplates })) };
    }),
    initSystemPageContent(),
  );
};
