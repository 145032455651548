import { combineEpics } from 'utils/rxjs';
import { EMPTY, of, identity } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  BASKET_RECEIVED, BASKET_SUMMARY_RECEIVED,
  broadcastBasket, BASKET_BROADCAST, basketArrived,
  basketUpdated, basketSummaryReceived,
} from '../actions';
import { BASKET_CHANGE_STARTED, BASKET_CHANGE_COMPLETED } from 'behavior/events';
import {
  ignoreElements, first, filter, takeUntil,
  tap, map, mergeMap, switchMap, delayWhen, switchMapTo, pluck,
} from 'rxjs/operators';
import { Updaters } from '../constants';
import { visibility$ } from 'utils/rxjs/eventsObservables';
import { basketChangeCompleted } from 'behavior/events';
import { skipIfPreview } from 'behavior/preview';

export default combineEpics(notifyBasketChangesEpic, syncBasketChangesEpic);

function syncBasketChangesEpic(_action$, state$, { broadcast }) {
  const delayUntilPageVisible = (() => {
    const visible$ = visibility$.pipe(first(identity));
    return delayWhen(_ => visible$);
  })();

  const distinctOnLastModified = (() => {
    let lastModified;
    return filter(d => {
      const value = +d.modifiedDate;
      return lastModified === value
        ? false
        : lastModified = value;
    });
  })();

  const dataToActions = data => {
    const state = state$.value,
      modifiedDate = data.modifiedDate;

    if (state.basket.modifiedDate && state.basket.modifiedDate >= modifiedDate)
      return EMPTY;

    const updatedAction = basketUpdated(Updaters.Sync, modifiedDate);
    const completedAction = basketChangeCompleted(0);

    // eslint-disable-next-line eqeqeq
    if (data.language != getLanguage(state))
      return of(updatedAction, completedAction);

    const { summary, basket } = data;
    let action;
    if (summary) {
      summary.modifiedDate = modifiedDate;
      action = basketSummaryReceived(summary);
    } else if (basket) {
      const stateBasket = state.basket.model;
      if (!stateBasket || (basket.page && stateBasket.page && stateBasket.page.index === basket.page.index)) {
        basket.modifiedDate = modifiedDate;
        action = basketArrived(basket);
      }
    }

    return action
      ? of(action, updatedAction, completedAction)
      : of(updatedAction, completedAction);
  };

  return broadcast.action$.pipe(
    ofType(BASKET_BROADCAST),
    skipIfPreview(state$),
    switchMap(action => of(action.payload).pipe(
      delayUntilPageVisible,
      distinctOnLastModified,
      mergeMap(dataToActions),
    )),
  );
}

function notifyBasketChangesEpic(action$, state$, { broadcast }) {
  const reset$ = action$.pipe(ofType(BASKET_CHANGE_STARTED));

  let lastModified;
  const distinctOnLastModified = filter(([, state]) => {
    const value = state.basket.modifiedDate;
    return lastModified === value
      ? false
      : lastModified = value;
  });

  return action$.pipe(
    ofType(BASKET_CHANGE_COMPLETED),
    pluck('payload', 'updatedLinesAmount'),
    filter(updatedLinesAmount => updatedLinesAmount || state$.value.basket.updated.updaterId === Updaters.Basket),
    switchMapTo(action$.pipe(
      ofType(BASKET_RECEIVED, BASKET_SUMMARY_RECEIVED),
      first(),
      map(action => [action.type, state$.value]),
      distinctOnLastModified,
      tap(([type, state]) => {
        const basketState = state.basket;
        const data = {
          language: getLanguage(state),
          modifiedDate: basketState.modifiedDate,
        };

        if (type === BASKET_RECEIVED)
          data.basket = basketState.model;
        else
          data.summary = basketState.summary;

        broadcast.dispatch(broadcastBasket(data));
      }),
      ignoreElements(),
      takeUntil(reset$),
    )),
  );
}

function getLanguage({ localization }) {
  return localization && localization.currentLanguage && localization.currentLanguage.id;
}
