import styles from './Alert.module.scss';
import { SuccessIcon } from 'components/primitives/icons';
import Alert, { alertPropTypes } from './Alert';

const SuccessAlert = ({
  children,
  className = '',
  scrollOnAppear,
  role,
  ...props
}) => (
  <Alert
    className={styles.success + ' ' + className}
    icon={SuccessIcon}
    scrollOnAppear={scrollOnAppear}
    role={role}
    {...props}
  >
    {children}
  </Alert>
);

SuccessAlert.propTypes = alertPropTypes;

export default SuccessAlert;