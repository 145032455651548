import { ofType } from 'redux-observable';
import { merge } from 'rxjs';
import { tap, map, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { receiveTiming, PROFILER_REQUEST_CLOSE, closeProfiler, profilerClosed } from './actions';
import { toolDisableMutation } from '../queries';
import { getTimingHightLights } from './helpers';

const ToolName = 'Profiler'; /*:Tool*/

export default function (action$, _, { api, toolsStorage }) {
  const track$ = api.extensions$.pipe(
    map(extensions => extensions && extensions.profiler),
    distinctUntilChanged(),
    filter(profiler => !profiler || !profiler.root.isTrivial),
    map(profiler => {
      if (!profiler)
        return closeProfiler();

      const [isSlow, hasERPRequest] = getTimingHightLights(profiler);

      if (isSlow)
        profiler.isSlow = true;

      if (hasERPRequest)
        profiler.hasERPRequest = true;

      return receiveTiming(profiler);
    }),
  );

  const close$ = action$.pipe(
    ofType(PROFILER_REQUEST_CLOSE),
    filter(_ => toolsStorage.toolEnabled(ToolName)),
    switchMap(_ => api.graphApi(toolDisableMutation, { name: ToolName }, { useCookies: true }).pipe(
      tap(_ => toolsStorage.disableTool(ToolName)),
      map(_ => profilerClosed()),
    )),
  );

  return merge(track$, close$);
}
