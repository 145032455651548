import { memo } from 'react';
import { ContactStoreLink } from 'components/primitives/links';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText } from 'utils/render';

const TemporaryNotAvailable = () => {
  const { texts: [errorText], loaded } = useSanaTexts([
    'ConnectionOffline_TemporaryNotAvailable_ErrorText',
    'ConnectionOffline_TemporaryNotAvailable_Contact',
  ]);

  if (!loaded)
    return null;

  const formatWith = [
    <ContactStoreLink linkTextKey="ConnectionOffline_TemporaryNotAvailable_Contact" />,
  ];

  return makeRichText(errorText, formatWith);
};

export default memo(TemporaryNotAvailable);