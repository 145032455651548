import { combineEpics, StateObservable } from 'redux-observable';

import * as libraries from '../../../adk/webstore/_libraries.json';
import * as elements from '../../../adk/webstore/elements';
import * as forms from '../../../adk/webstore/forms';
import * as events from '../../../adk/webstore/events';
import * as routes from '../../../adk/webstore/routes';
import * as utils from '../../../adk/webstore/utils';
import * as constants from '../../../adk/webstore/constants';
import { useAppContext, getAppContext } from './context';
import texts from './texts/proxy';
import createAddonRegistry from './registry';
import addonCreatorFactory from './creator';
import createReducer from './reducer';
import createEpic from './epic';
import preloadAddons from './preload';
import createMiddleware from './middleware';

import metadataEpic from './metadata/epic';

import type { AddonEpicServices, AddonLoader, StateWithAddons } from './types';
import type { DefaultRootState } from 'react-redux';
import type { Store } from 'redux';
import type { Api } from 'utils/api';

const sharedModules = { elements, forms, events, routes, utils, texts, constants, context: { useAppContext } };

export function createAddonsContainer(addonLoader: AddonLoader, epicServices: { api: Api }) {
  const registry = createAddonRegistry();
  const reducer = createReducer(registry);
  const addonCreator = addonCreatorFactory(libraries as Record<string, any>, sharedModules);
  const epicDepsFactory = (_addonId: string, state$: StateObservable<DefaultRootState>): AddonEpicServices => {
    return {
      api: {
        graphApi: epicServices.api.graphApi,
        fetch: epicServices.api.fetch,
        trackObservable: epicServices.api.trackObservable,
      },
      appContext: {
        getCurrent: () => getAppContext(state$.value),
      },
    };
  };
  const epic = combineEpics(
    createEpic(registry, addonLoader, addonCreator, epicDepsFactory),
    metadataEpic,
  );
  const middleware = createMiddleware();
  const preload = (store: Store<StateWithAddons>, addonIds: string[]) => preloadAddons(store, registry, epic, addonIds);
  return { registry, reducer, epic, middleware, preload };
}
