import formStyles from 'components/objects/forms/SanaForm.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import styles from '../SubAccount.module.scss';
import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import scrollIntoView from 'scroll-into-view';
import { SanaButton } from 'components/primitives/buttons';
import { ERROR_ID } from './GeneralErrorMessage';

const SubAccountFormSubmitRow = () => {
  const { general: generalError } = useFormikContext().errors;

  const onClick = useCallback(() => {
    if (generalError)
      scrollIntoView(document.getElementById(ERROR_ID));
  }, [generalError]);

  return (
    <div className={`${formStyles.formRow} ${formStyles.rowActions}`}>
      <SanaButton
        textKey="Save"
        type="submit"
        className={`${btnStyles.btnBig} ${formStyles.btn} ${styles.btnSave}`}
        onClick={onClick}
      />
    </div>
  );
};

export default SubAccountFormSubmitRow;