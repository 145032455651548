import { RouteName } from 'routes';

const allowedRoutes = new Set([
  RouteName.ProductDetails,
  RouteName.LastViewedProducts,
  RouteName.ProductList,
  RouteName.Search,
  RouteName.ProductsWithCategory,
  RouteName.VolumeOrderList,
]);

export function isWidgetAllowed(routeName, params) {
  return allowedRoutes.has(routeName) && params?.previewToken == null;
}
