export const ADMIN_IMPERSONATION_STOP = 'ADMIN_IMPERSONATION_STOP';
export const stop = isLogout => ({
  type: ADMIN_IMPERSONATION_STOP,
  payload: { isLogout },
});

export const ADMIN_IMPERSONATION_STOPPED = 'ADMIN_IMPERSONATION_STOPPED';
export const notifyStopped = isLogout => ({
  type: ADMIN_IMPERSONATION_STOPPED,
  payload: { isLogout },
});
