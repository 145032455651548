import { CONNECTION_CHANGED } from './actions';
import type { ConnectionAction } from './actions';
import type { Connection } from 'utils/api';

const initialState: Connection = {
  onLine: true,
  accessible: true,
};
export default function connectionReducer(state = initialState, action: ConnectionAction) {
  if (action.type === CONNECTION_CHANGED) {
    return action.payload;
  }
  return state;
}