export const VOLUMEORDERLIST_PRODUCTS_GENERALINFO_REQUESTED = 'VOLUMEORDERLIST_PRODUCTS_GENERALINFO_REQUESTED';
export const requestProductsGeneralInfo = (listPageId, options, appendProducts = false) => ({
  type: VOLUMEORDERLIST_PRODUCTS_GENERALINFO_REQUESTED,
  payload: { listPageId, options, appendProducts },
});

export const VOLUMEORDERLIST_PRODUCTS_GENERALINFO_LOADED = 'VOLUMEORDERLIST_PRODUCTS_GENERALINFO_LOADED';
export const productsGeneralInfoLoaded = (products, appendProducts, pageSize) => ({
  type: VOLUMEORDERLIST_PRODUCTS_GENERALINFO_LOADED,
  payload: {
    products,
    appendProducts,
    pageSize,
  },
});

export const VOLUMEORDERLIST_CALCULATED_FIELDS_REQUESTED = 'VOLUMEORDERLIST_CALCULATED_FIELDS_REQUESTED';
export const requestCalculatedFields = (options, variantsOnly) => ({
  type: VOLUMEORDERLIST_CALCULATED_FIELDS_REQUESTED,
  payload: { options, variantsOnly },
});

export const VOLUMEORDERLIST_PRODUCTS_UPDATED = 'VOLUMEORDERLIST_PRODUCTS_UPDATED';
export const productsUpdated = products => ({
  type: VOLUMEORDERLIST_PRODUCTS_UPDATED,
  payload: products,
});

export const VOLUMEORDERLIST_VARIANTS_GENERALINFO_REQUESTED = 'VOLUMEORDERLIST_VARIANTS_GENERALINFO_REQUESTED';
export const requestVariantsGeneralInfo = options => ({
  type: VOLUMEORDERLIST_VARIANTS_GENERALINFO_REQUESTED,
  payload: { options },
});