/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types';

const iconPropTypes = {
  className: PropTypes.string,
};

export const ScrollToTopIcon = ({ className }) => (
  <svg aria-hidden height="15.70429" width="24.017151" viewBox="0 0 24.017151 15.70429" version="1.1" className={className}>
    <path className="icon-shadow" d="m 2.83,15.70429 9.17,-9.17 9.17,9.17 2.83,-2.83 -12,-12 -12,12 z" />
    <path className="icon-general" d="m 2.847151,14.83 9.17,-9.17 9.17,9.17 2.83,-2.83 -12,-12 -12,12 z" />
  </svg>
);

ScrollToTopIcon.propTypes = iconPropTypes;

export const StickyAddToCartIcon = ({ className }) => (
  <svg aria-hidden xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1.322 1.322" width="26" height="26" className={className}>
    <path className="icon-shadow" d="M1.264.559c-.196.432-.26.432-.298.432H.592A.25.25 0 0 1 .335.787 46.843 46.843 0 0 1 .24.297C.23.247.208.212.12.212a.072.072 0 0 1-.07-.074c0-.04.032-.073.07-.073.143 0 .234.073.258.206l.093.486c.015.078.08.088.12.088h.356c.038-.043.125-.208.189-.348A.07.07 0 0 1 1.23.462c.036.017.05.06.034.097zm-.72.481a.12.12 0 0 0-.117.122.12.12 0 0 0 .117.122.12.12 0 0 0 .117-.122.12.12 0 0 0-.117-.122zm.422 0a.12.12 0 0 0-.117.122.12.12 0 0 0 .117.122.12.12 0 0 0 .117-.122.12.12 0 0 0-.117-.122zM.739.642c.005.004.01.006.017.006A.025.025 0 0 0 .772.642 1.79 1.79 0 0 0 .974.401a.028.028 0 0 0 0-.028.026.026 0 0 0-.022-.014H.848S.838.149.832.139C.822.125.782.117.755.117.729.115.689.125.679.14a2.71 2.71 0 0 0-.015.219H.558a.026.026 0 0 0-.023.014.028.028 0 0 0 0 .028c.07.108.2.238.204.241z" />
    <path className="icon-general" d="M1.264.535c-.196.432-.26.432-.298.432H.592A.25.25 0 0 1 .335.763 46.843 46.843 0 0 1 .24.273C.23.223.208.188.12.188a.072.072 0 0 1-.07-.074c0-.04.032-.073.07-.073.143 0 .234.073.258.206l.093.486c.015.078.08.088.12.088h.356c.038-.043.125-.208.189-.348A.07.07 0 0 1 1.23.438c.036.017.05.06.034.097zm-.72.481a.12.12 0 0 0-.117.122.12.12 0 0 0 .117.122.12.12 0 0 0 .117-.122.12.12 0 0 0-.117-.122zm.422 0a.12.12 0 0 0-.117.122.12.12 0 0 0 .117.122.12.12 0 0 0 .117-.122.12.12 0 0 0-.117-.122zM.739.618c.005.004.01.006.017.006A.025.025 0 0 0 .772.618a1.79 1.79 0 0 0 .202-.24.028.028 0 0 0 0-.029.026.026 0 0 0-.022-.014H.848S.838.125.832.115C.822.101.782.093.755.093.729.091.689.101.679.116a2.71 2.71 0 0 0-.015.22H.558a.026.026 0 0 0-.023.013.028.028 0 0 0 0 .028c.07.108.2.238.204.241z" />
  </svg>
);

StickyAddToCartIcon.propTypes = iconPropTypes;
