import { default as Checkout } from './Checkout';
import createSystemRenderer from 'components/objects/systemPages/createSystemRenderer';
import { routesBuilder } from 'routes';
import { DocumentType } from 'behavior/documents';

function checkoutRenderer(page) {
  const titleTextKey = page.info.quote
    ? 'AcceptQuote'
    : page.info.isQuote
      ? 'Checkout_RequestQuote'
      : 'Checkout_SecureCheckout';

  const backTo = page.info.quote
    ? {
      texts: ['BackToQuote'],
      url: page.info.quote.url,
      route: routesBuilder.forDocument(page.info.quote.id, DocumentType.Quote),
    }
    : null;

  return createSystemRenderer(titleTextKey, Checkout, backTo)(page);
}

export default checkoutRenderer;
