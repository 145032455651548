import styles from './ImageBlock.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { ImageScalings, ImageBehaviors } from './constants';
import Image from './Image';
import ImageWithLink from './ImageWithLink';
import ImageWithPreview from './ImageWithPreview';

const ScalingOptionMap = {
  [ImageScalings.ByWidth]: styles.scaleByWidth,
  [ImageScalings.ByHeight]: styles.scaleByHeight,
  [ImageScalings.DoNotScale]: styles.doNotScale,
};

const AlignmentOptionMap = {
  LEFT: styles.left,
  RIGHT: styles.right,
  CENTER: styles.center,
  JUSTIFY: styles.justify,
};

const ImageBlock = ({ model, sharedStyleProps: { horizontalAlignment } }) => {
  if (!model)
    return null;

  const {
    url,
    altText,
    style,
    scaling = ImageScalings.ByWidth,
    behavior,
    link,
  } = model;

  if (!url || url === '')
    return null;

  const imageProps = {
    src: url,
    alt: altText || null,
    title: altText || null,
    className: styles[style.toLowerCase()],
    wrapperClassName: `${ScalingOptionMap[scaling]} ${AlignmentOptionMap[horizontalAlignment]}`,
  };

  if (behavior === ImageBehaviors.UseLink)
    return <ImageWithLink link={link} {...imageProps} />;

  if (behavior === ImageBehaviors.OpenInOverlay)
    return <ImageWithPreview {...imageProps} />;

  return <Image {...imageProps} />;
};

ImageBlock.propTypes = {
  model: PropTypes.shape({
    url: PropTypes.string,
    altText: PropTypes.string,
    style: PropTypes.string,
    scaling: PropTypes.oneOf(Object.values(ImageScalings)),
    behavior: PropTypes.oneOf(Object.values(ImageBehaviors)),
    link: PropTypes.shape({
      url: PropTypes.string,
      target: PropTypes.string,
    }),
  }),
  sharedStyleProps: PropTypes.shape({
    horizontalAlignment: PropTypes.string,
  }),
};

export default memo(ImageBlock);