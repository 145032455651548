import { Col, Container, Row } from 'components/primitives/grid';
import { SimpleText } from 'components/sanaText';
import { PageContent } from 'components/objects/pages';
import Sidebar from './Sidebar';
import { useResponsiveBreakpoints } from 'utils/layout';
import { BackToContainer } from 'components/primitives/links';
import { usePrintMode } from 'utils/hooks';

function createProfileRenderer(titleTextKey, Component, options) {
  return page => {
    const header = page.content && page.content.header;
    const footer = page.content && page.content.footer;
    const props = Component.selectPropsFromPage ? Component.selectPropsFromPage(page) : undefined;
    const hasHeaderBlocks = header && header.length > 0;
    const { lg, xl } = useResponsiveBreakpoints();
    const marginTop = lg || xl ? '5.6em' : '1em';
    const isPrintMode = usePrintMode();
    const sidebarTopMargin = options.sidebarTopMargin || (titleTextKey ? 0 : marginTop);

    return (
      <>
        {!isPrintMode && <BackToContainer options={options.backTo} pageBackTo={page.backTo} />}
        {titleTextKey &&
          <Container>
            <Row>
              <Col lg={!hasHeaderBlocks ? { offset: 3 } : null} xl={!hasHeaderBlocks ? { offset: 2 } : null}>
                <h1><SimpleText textKey={titleTextKey} /></h1>
              </Col>
            </Row>
          </Container>
        }
        <PageContent content={header} position="header" />
        <Container>
          <Row>
            {!isPrintMode &&
              <Col xs={12} lg={3} xl={2}>
                <Sidebar linkKey={options.linkKey} styles={sidebarTopMargin ? { marginTop: sidebarTopMargin } : null} />
              </Col>
            }
            <Col xs={12} lg={isPrintMode ? null : 9} xl={isPrintMode ? null : 10}>
              <Component {...props} />
            </Col>
          </Row>
        </Container>
        <PageContent content={footer} position="footer" />
      </>
    );
  };
}

export default createProfileRenderer;