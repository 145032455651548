import styles from '../Order.module.scss';
import PropTypes from 'prop-types';
import { renderHTML } from 'utils/render';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';

export const OrderProp = ({ name, value, asHTML = false, className = null }) => {
  const isPreview = useIsPreview();

  if (value == null && !isPreview)
    return null;

  return (
    <tr className={className}>
      <th scope="row" className={styles.name}>{(name != null) ? renderHTML(name) : <Placeholder />}</th>
      <td className={styles.value}>{isPreview ? <Placeholder /> : asHTML ? renderHTML(value) : value}</td>
    </tr>
  );
};

OrderProp.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  asHTML: PropTypes.bool,
  className: PropTypes.string,
};
