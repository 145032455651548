import PropTypes from 'prop-types';
import { SanaLinkButton } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import { useProductRouteBuilder } from '../hooks';

const ViewProductButton = ({
  textKey,
  titleTextKey,
  product,
  className,
  placeholder,
  withoutBackTo,
}) => {
  const { id, url } = product;
  const routeBuilder = useProductRouteBuilder(id, withoutBackTo);

  return (
    <WithProductClickTracking>
      {handleClick => (
        <SanaLinkButton className={className}
          url={url}
          to={routeBuilder}
          textKey={textKey}
          titleTextKey={titleTextKey}
          placeholder={placeholder}
          onClick={handleClick}
        />
      )}
    </WithProductClickTracking>
  );
};

ViewProductButton.propTypes = {
  textKey: PropTypes.string.isRequired,
  defaultText: PropTypes.string,
  titleTextKey: PropTypes.string,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
  placeholder: PropTypes.node,
  withoutBackTo: PropTypes.bool,
};

export default ViewProductButton;