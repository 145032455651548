import styles from '../Checkout.module.scss';
import { memo, useRef, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SanaForm, FormGroup } from 'components/objects/forms';
import { HiddenSubmitButton } from 'components/primitives/form';
import { TextBoxField, TextAreaField, DatePickerField } from 'components/objects/forms/fields';
import { SimpleText, useSanaTexts, UseSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { isVisible, FieldVisibility } from './constants';
import { Checkbox, Input } from 'components/primitives/form';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';

const AdditionalInfoForm = ({
  formikRef,
  initialValues,
  commentVisibility,
  deliveryDateVisibility,
  referenceNoVisibility,
  onSubmit,
  onBlur,
  submitOnBlur,
  minDeliveryDate,
  maxDeliveryDate,
  hasBulkOrder,
  expectedShipmentDate,
  isVolumeOrder,
}) => {
  const [shippedTogether, setShippedTogether] = useState(!hasBulkOrder && !isVolumeOrder);
  const [showStock] = useHasAbilities(AbilityTo.ViewStock);


  useEffect(() => {
   setShippedTogether(!hasBulkOrder && !isVolumeOrder); 
  }, [hasBulkOrder, isVolumeOrder]);

  const hours = Math.abs(minDeliveryDate.getTimezoneOffset())/60;
  minDeliveryDate.setHours(hours,0,0,0);

  const isReferenceVisible = isVisible(referenceNoVisibility),
    isCommentVisible = isVisible(commentVisibility),
    isDateVisible = isVisible(deliveryDateVisibility);

  const { texts: [referenceNoLbl, commentsLbl, dateLbl], loaded } = useSanaTexts([
    isReferenceVisible && 'ReferenceNumber',
    isCommentVisible && 'Comments',
    isDateVisible && 'RequestedDeliveryDate',
  ], makeSimpleText);
  const prevValuesRef = useRef();

  const dateFromShortDate = ds => {
    const dateSplit = ds.split('/');
    const month = dateSplit[1]-1;

    return new Date(dateSplit[2], month, dateSplit[0]);
  };

  const newInitialValues = useMemo(() => {
    const result = { 
      ...initialValues,
      deliveryDate: hasBulkOrder ? dateFromShortDate(expectedShipmentDate) : minDeliveryDate.toISOString(),
     };

    if (!isReferenceVisible)
      delete result.referenceNo;
    if (!isCommentVisible)
      delete result.comment;
    if (!isDateVisible) {
      delete result.deliveryDate;
    }

    return result;
  }, [initialValues, isReferenceVisible, isCommentVisible, isDateVisible, hasBulkOrder]);

  if (!loaded)
    return DatePickerField.textKeys ? <UseSanaTexts options={DatePickerField.textKeys} /> : null;

  const handleSubmit = values => {
    prevValuesRef.current = values;
    onSubmit(values);
  };

  const onFormBlur = submitOnBlur
    ? (e, formik) => {
      onBlur && onBlur();
      if (!formik.dirty || !formik.isValid || e.currentTarget.contains(e.relatedTarget || document.activeElement))
        return;

      if (prevValuesRef.current === formik.values)
        return;

      handleSubmit(formik.values);
    }
    : null;

  const onShippedTogetherChange = () => {
    setShippedTogether(!shippedTogether);
  };

  return (
    <SanaForm
      name="additional"
      formikRef={formikRef}
      onBlur={onFormBlur}
      onSubmit={handleSubmit}
      initialValues={newInitialValues}
      className={styles.additional}
    >
      {isReferenceVisible &&
        <FormGroup
          fieldName="referenceNo"
          fieldTitle={referenceNoLbl}
          fieldComponent={TextBoxField}
          maxLength={20}
          required={referenceNoVisibility === FieldVisibility.Required}
          validation={{ required: referenceNoVisibility === FieldVisibility.Required }}
        />
      }
      {isCommentVisible &&
        <FormGroup
          fieldName="comment"
          fieldTitle={commentsLbl}
          fieldComponent={TextAreaField}
          maxLength={2000}
        />
      }
      {!hasBulkOrder && !isVolumeOrder &&
        <div className="SanaForm_control form-row-control SanaForm_form-row">
          <div className="SanaForm_label form-row-label">
            <label htmlFor="guestSingleAddress"><SimpleText textKey="AdditionalInfoShippedTogetherLabel" /></label>
          </div>
          <Checkbox id="guestSingleAddress" checked={shippedTogether} onChange={onShippedTogetherChange} />
        </div>
      }

      {(isDateVisible && shippedTogether && showStock) && 
        <FormGroup
          fieldName="deliveryDate"
          fieldTitle={dateLbl}
          fieldComponent={DatePickerField}
          minDate={minDeliveryDate}
          maxDate={maxDeliveryDate}
          required={shippedTogether}
          validation={{ required: shippedTogether }}
        />
      }

      {hasBulkOrder && !isVolumeOrder &&
        <div className="SanaForm_control form-row-control SanaForm_form-row">
          <div className="SanaForm_label form-row-label">
            <label htmlFor="expectedDeliveryDate"><SimpleText textKey="ExpectedDeliveryDate" /></label>
          </div>
          <Input id="expectedDeliveryDate" readOnly value={expectedShipmentDate} />
        </div>
      }

      {isVolumeOrder &&
        <div className="SanaForm_control form-row-control SanaForm_form-row">
          <div className="SanaForm_label form-row-label">
            <label htmlFor="expectedDeliveryDate"><SimpleText textKey="ExpectedDeliveryDate" /></label>
          </div>
          <Input id="expectedDeliveryDate" readOnly value={minDeliveryDate.toLocaleDateString()} />
        </div>
      }

      {!shippedTogether && !isVolumeOrder &&
        <div className="SanaForm_control form-row-control SanaForm_form-row">
          <div className="SanaForm_label form-row-label" />
          <SimpleText textKey="AdditionalInfoShippedTogetherText" />
      </div>
      }
      <HiddenSubmitButton />
    </SanaForm>
  );
};
AdditionalInfoForm.propTypes = {
  formikRef: PropTypes.object,
  initialValues: PropTypes.object,
  commentVisibility: PropTypes.oneOf(Object.values(FieldVisibility)),
  deliveryDateVisibility: PropTypes.oneOf(Object.values(FieldVisibility)),
  referenceNoVisibility: PropTypes.oneOf(Object.values(FieldVisibility)),
  onSubmit: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  submitOnBlur: PropTypes.bool,
  minDeliveryDate: PropTypes.any,
  maxDeliveryDate: PropTypes.any,
  hasBulkOrder: PropTypes.bool.isRequired,
  expectedShipmentDate: PropTypes.string,
  isVolumeOrder: PropTypes.bool,
};

export default memo(AdditionalInfoForm);
