import styles from './ReturnOrders.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo } from 'react';
import { routesBuilder } from 'routes';
import { LinkButton } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';

const NewReturnOrderButton = () => {
  const [canCreateDocFreeReturnOrder] = useHasAbilities(AbilityTo.CreateDocFreeReturnOrder);

  if (!canCreateDocFreeReturnOrder)
    return null;

  return (
    <LinkButton
      className={`${styles.btnCreate} ${btnStyles.btnSmall}`}
      to={routesBuilder.forCreateDocFreeReturnOrder()}
      rel="nofollow"
    >
      <SimpleText textKey="ButtonText_CreateNewReturnOrder" />
    </LinkButton>
  );
};

export default memo(NewReturnOrderButton);