import { loadNewsPageQuery } from './queries';
import { PageComponentNames } from '../componentNames';
import { map } from 'rxjs/operators';
import { initSystemPageContent } from 'behavior/pages/system';

export default (_routeData, _state$, { api }) => api.graphApi(loadNewsPageQuery).pipe(
  map(({ pages, news }) => {
    const page = pages.news;
    page.news = news.list.items;
    page.component = PageComponentNames.News;
    return { page };
  }),
  initSystemPageContent(),
);