import PageB2B from '../b2b/Page';
import PageB2C from '../b2c/Page';
import PageTemplate from './PageTemplate';
import CalculatedFieldsLoader from 'components/objects/productList/page/CalculatedFieldsLoader';
import { PageComponentNames } from 'behavior/pages';

export const presets = {
  'ListB2B': PageB2B,
  'List': PageB2C,
};

function productListPageRenderer(page) {
  const Page = page.preset in presets
    ? presets[page.preset]
    : presets.PageB2C;

  if (!Page.selectProps)
    throw new Error(`Product list page component ${Page.displayName} should implement selectProps method to map page state to props.`);

  return (
    <PageTemplate {...PageTemplate.selectProps(page)}>
      <CalculatedFieldsLoader products={page.products}>
        <Page {...Page.selectProps(page)} />
      </CalculatedFieldsLoader>
    </PageTemplate>
  );
}

export default {
  [PageComponentNames.ProductList]: productListPageRenderer,
};