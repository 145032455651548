import { of } from 'rxjs';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { mergeMap, map } from 'rxjs/operators';
import { orderSubmitQuery } from './queries';
import { initSystemPageContent, loadSystemPageQuery } from 'behavior/pages/system';
import { routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';
import { CheckoutProcessNames } from './constants';

export default (routeData, _state$, { api }) => {
  const { params: { transactionId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi(loadSystemPageQuery('orderSubmit')).pipe(
      map(({ pages: { orderSubmit } }) => ({
        page: {
          ...orderSubmit,
          component: PageComponentNames.OrderSubmit,
          transaction: {
            id: '',
            documentId: '',
            checkoutProcessName: CheckoutProcessNames.Order,
          },
        },
      })),
      initSystemPageContent(),
    );
  }

  if (!transactionId)
    return of(null);

  return api.graphApi(orderSubmitQuery, { id: transactionId }).pipe(
    mergeMap(({
      pages: { orderSubmit },
      paymentTransaction: transaction,
    }) => {
      if (transaction == null)
        return of(null);

      const { id, isPaymentError, failed, cancelled } = transaction;
      let createRedirectRoute;

      if (isPaymentError)
        createRedirectRoute = routesBuilder.forPaymentError;
      else if (failed)
        createRedirectRoute = routesBuilder.forOrderFailed;
      else if (cancelled)
        createRedirectRoute = routesBuilder.forOrderCancelled;

      if (createRedirectRoute)
        return of({
          action$: of(navigateTo(createRedirectRoute(id))),
        });

      return of({
        page: {
          component: PageComponentNames.OrderSubmit,
          transaction,
          ...orderSubmit,
        },
      });
    }),
    initSystemPageContent(),
  );
};
