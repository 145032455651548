import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { OrderProp } from './OrderProp';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';

const SalesAgreementLink = ({ agreementId, text }) => {
  const link = useMemo(() => {
    const route = routesBuilder.forSalesAgreement(agreementId);
    return <Link to={route}>{agreementId}</Link>;
  }, [agreementId]);

  return <OrderProp name={text} value={link} />;
};

SalesAgreementLink.propTypes = {
  text: PropTypes.string,
  agreementId: PropTypes.string.isRequired,
};

export default SalesAgreementLink;