import PropTypes from 'prop-types';
import Input from './Input';
import { isMaxDate, isMinDate } from 'react-date-picker/dist/shared/propTypes';
import { getMinMaxDay } from '../helpers';

const DayInput = ({ maxDate, minDate, month, year, ...otherProps }) => {
  const { maxDay, minDay } = getMinMaxDay(maxDate, minDate, month, year);

  return <Input max={maxDay} min={minDay} name="day" {...otherProps} />;
};

DayInput.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  itemRef: PropTypes.func,
  maxDate: isMaxDate,
  minDate: isMinDate,
  month: PropTypes.number,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.number,
  year: PropTypes.number,
};

export default DayInput;
