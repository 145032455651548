export const ORDER_TEMPLATES_REQUESTED = 'ORDER_TEMPLATES_REQUESTED';
export const loadOrderTemplates = () => ({ type: ORDER_TEMPLATES_REQUESTED });

export const ORDER_TEMPLATES_RECEIVED = 'ORDER_TEMPLATES_RECEIVED';
export const orderTemplatesReceived = ({ orderTemplates }) => ({
  type: ORDER_TEMPLATES_RECEIVED,
  payload: orderTemplates,
});

export const ORDER_TEMPLATE_LINES_REQUESTED = 'ORDER_TEMPLATE_LINES_REQUESTED';
export const loadOrderTemplateLines = id => ({
  type: ORDER_TEMPLATE_LINES_REQUESTED,
  payload: { id },
});

export const ORDER_TEMPLATE_LINES_RECEIVED = 'ORDER_TEMPLATE_LINES_RECEIVED';
export const orderTemplateLinesReceived = (id, orderTemplateLines = null) => ({
  type: ORDER_TEMPLATE_LINES_RECEIVED,
  payload: { id, orderTemplateLines },
});

export const ORDER_TEMPLATES_ADDING_REQUESTED = 'ORDER_TEMPLATES_ADDING_REQUESTED';
export const addToBasket = ids => ({
  type: ORDER_TEMPLATES_ADDING_REQUESTED,
  payload: { ids },
});

export const ORDER_TEMPLATES_ADDED = 'ORDER_TEMPLATES_ADDED';
export const orderTemplatesAdded = countAdded => ({
  type: ORDER_TEMPLATES_ADDED,
  payload: countAdded,
});

export const ORDER_TEMPLATES_REMOVAL_REQUESTED = 'ORDER_TEMPLATES_REMOVAL_REQUESTED';
export const removeTemplates = ids => ({
  type: ORDER_TEMPLATES_REMOVAL_REQUESTED,
  payload: { ids },
});

export const ORDER_TEMPLATES_REMOVED = 'ORDER_TEMPLATES_REMOVED';
export const orderTemplatesRemoved = ids => ({
  type: ORDER_TEMPLATES_REMOVED,
  payload: ids,
});

export const ORDER_TEMPLATE_SAVE = 'ORDER_TEMPLATE_SAVE';
export const saveOrderTemplate = input => ({
  type: ORDER_TEMPLATE_SAVE,
  payload: { input },
});

export const ORDER_TEMPLATE_SAVED = 'ORDER_TEMPLATE_SAVED';
export const orderTemplateSavingResultReceived = savingResult => ({
  type: ORDER_TEMPLATE_SAVED,
  payload: { savingResult },
});
