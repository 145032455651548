import styles from './LastViewedProducts.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearLastViewedProducts, requestLastViewedProducts } from 'behavior/products/lastViewedTracking';
import { SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { useLoadEffect, useCurrentRouteAsBackTo } from 'utils/hooks';
import { Placeholder } from 'components/primitives/placeholders';
import { routesBuilder } from 'routes';
import ProductsGroup from '../productsGroup';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { trackProductListView, EVENT_SOURCES } from 'behavior/analytics';
import { ProductTrackingContext } from '../analytics';

const productsCountToShow = 5;

const LastViewedProducts = ({
  maxSlidesToShow,
  skipCurrent,
  expired,
  lastViewed,
  products,
  requestLastViewedProducts,
  clearLastViewedProducts,
  productId,
  trackProductListView,
}) => {
  useEffect(() => {
    if (lastViewed && !skipCurrent) {
      requestLastViewedProducts(false, productsCountToShow);
      return;
    }

    if (!products || expired)
      requestLastViewedProducts(skipCurrent, productsCountToShow);
  }, [products, skipCurrent, expired, lastViewed]);

  useLoadEffect(() => {
    if (!products || !products.length)
      return;

    const calculated = products.every(product => typeof product.price !== 'undefined');
    if (!calculated)
      return;

    const pdpTrackCondition = skipCurrent && lastViewed && productId.toLowerCase() === lastViewed.toLowerCase();
    const plpTrackCondition = !skipCurrent && !lastViewed;

    if (pdpTrackCondition || plpTrackCondition)
      trackProductListView(products, EVENT_SOURCES.lastViewedProducts);
  }, [products]);

  const backTo = useCurrentRouteAsBackTo();

  if (!products || !products.length)
    return null;

  const textPlaceholder = <Placeholder className={styles.placeholder} />;

  const links = (
    <>
      <Link
        className={styles.viewAll}
        to={routesBuilder.forLastViewed}
        options={{ backTo }}
        rel="nofollow"
      >
        <SimpleText textKey="ViewAll" placeholder={textPlaceholder} />
      </Link>
      <button className={`${linkStyles.link} ${styles.clearViewed}`} onClick={clearLastViewedProducts}>
        <SimpleText textKey="LastViewedProducts_ClearLinkText" placeholder={textPlaceholder} />
      </button>
    </>
  );

  return (
    <ProductTrackingContext.Provider value={{ trackingSource: EVENT_SOURCES.lastViewedProducts }}>
      <ProductsGroup id="lastViewedProducts" headerTextKey="LastViewedProducts_Header" links={links} products={products} maxSlidesToShow={maxSlidesToShow} />
    </ProductTrackingContext.Provider>
  );
};

LastViewedProducts.propTypes = {
  maxSlidesToShow: PropTypes.number,
  skipCurrent: PropTypes.bool,
  expired: PropTypes.bool.isRequired,
  products: PropTypes.array,
  requestLastViewedProducts: PropTypes.func.isRequired,
  clearLastViewedProducts: PropTypes.func.isRequired,
  lastViewed: PropTypes.string,
  productId: PropTypes.string,
  trackProductListView: PropTypes.func.isRequired,
};

const ConnectedLastViewedProducts = connect(
  ({
    lastViewed: {
      products,
      expired,
      lastViewed,
    },
    page: {
      product,
    },
  }) => ({
    products,
    expired,
    lastViewed,
    productId: product && product.id,
  }),
  { requestLastViewedProducts, clearLastViewedProducts, trackProductListView },
)(LastViewedProducts);

// eslint-disable-next-line react/no-multi-comp
export default props => {
  const [canViewCatalog] = useHasAbilities(AbilityTo.ViewCatalog);
  return canViewCatalog && <ConnectedLastViewedProducts {...props} />;
};
