import styles from './ChildMenuItems.module.scss';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { connect } from 'react-redux';
import { requestChildMenuItems } from 'behavior/content/childMenuItems';
import { ResponsiveLazyImage } from 'components/primitives/responsiveImages';
import { Link } from 'components/primitives/links';

const ChildMenuItems = ({ childMenuItems, noImage, requestChildMenuItems }) => {
  useEffect(() => {
    if (!childMenuItems)
      requestChildMenuItems();
  }, [childMenuItems]);

  const itemsCount = childMenuItems && childMenuItems.length;
  if (!itemsCount)
    return null;

  return (
    <Row>
      {childMenuItems.map(({ title, link, image }, itemIndex) => (
        <Col key={itemIndex} xs={12} sm={6} md={4} lg={3} className={styles.item}>
          <Link {...link} className={styles.link}>
            <ResponsiveLazyImage src={image.path || noImage} alt={title} title={title} />
            <h4 className={styles.caption}>{title}</h4>
          </Link>
        </Col>
      ))}
    </Row>
  );
};

ChildMenuItems.propTypes = {
  childMenuItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.object,
    image: PropTypes.shape({
      path: PropTypes.string,
    }),
  })),
  noImage: PropTypes.string,
  requestChildMenuItems: PropTypes.func.isRequired,
};

export default connect(
  ({
    page: { childMenuItems },
    settings,
  }) => ({
    childMenuItems,
    noImage: settings && settings.product ? settings.product.noImage.small : undefined,
  }),
  { requestChildMenuItems },
)(ChildMenuItems);