import { useLayoutEffect } from 'react';

export default function useVerticalOverflowFix() {
  useLayoutEffect(() => {
    // overflowY should be set to hidden firstly to avoid issues in Firefox with
    // incorrect document size calculations which results in scrollbar present
    // even if the content is not overflowed.
    document.body.style.overflowY = 'hidden';
    const timeoutId = setTimeout(() => document.body.style.overflowY = 'auto');

    return () => {
      clearTimeout(timeoutId);
      document.body.style.overflowY = '';
    };
  }, []);
}
