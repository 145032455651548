import type { LocalStorage } from './types';

const browserStorage: LocalStorage = {
  getItem(key) {
    const item = window.localStorage.getItem(key);
    if (item)
      return JSON.parse(item);

    return item;
  },
  setItem(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
  },
  removeItem(key) {
    window.localStorage.removeItem(key);
  },
};

export default browserStorage;
