import styles from './Lines.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import ExtendedTexts from './ExtendedTexts';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';

const ProductInfo = ({
  productUrl,
  route,
  productTitle,
  productId,
  variantTitle,
  extendedTexts,
  description,
}) => {
  const isPreview = useIsPreview();

  return (
    <>
      {productUrl
        ? (
          <WithProductClickTracking>
            {handleClick => (
              <Link
                onClick={handleClick}
                url={productUrl}
                to={route}
                className={styles.largeTitle}
              >
                {productTitle}
              </Link>
            )}
          </WithProductClickTracking>
        )
        : <span className={styles.largeTitle}>{isPreview ? <Placeholder /> : productTitle}</span>
      }
      <div className={variantTitle ? styles.variantIdWrapper : null}>
        <div className={styles.productId}>
          <SimpleText textKey="General_Product_Id_Label" /> {isPreview ? '-' : productId}
        </div>
        {variantTitle && (
          <>
            {' '}
            <div className={styles.productVariant}>
              <span className={styles.cellName}><SimpleText textKey="Variant_Label" /></span>
              {' '}
              <span>{variantTitle}</span>
            </div>
          </>
        )}
      </div>
      {description && (
        <div className={styles.productDescription}>{description}</div>
      )}
      <ExtendedTexts texts={extendedTexts} />
    </>
  );
};

ProductInfo.propTypes = {
  productUrl: PropTypes.string,
  route: PropTypes.any.isRequired,
  productTitle: PropTypes.string,
  variantTitle: PropTypes.string,
  productId: PropTypes.string,
  extendedTexts: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
  })),
  description: PropTypes.string,
};

export default memo(ProductInfo);