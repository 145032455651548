import styles from './BasketB2B.module.scss';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { useSelector } from 'react-redux';
import { getFormatPrice } from 'utils/format';
import PropTypes from 'prop-types';
import { toLocaleDate } from 'utils/format';
import { useCultureName } from 'utils/hooks';
import { useIsQuickOrderMode } from '../hooks';

const BulkStatus = ({ activeBulkOrder }) => {

  const state = useSelector(s => s);
  const culture = useCultureName();
  const isQuickOrderMode = useIsQuickOrderMode();

  if (!activeBulkOrder || activeBulkOrder==={})
    return null;

  const { bulkType } = activeBulkOrder;

  const isAmountBulkOrder = bulkType === 'Amount';

  const bulkOrderDetails = (activeBulkOrder) ? activeBulkOrder : null;
  const currency = state.settings.currency;
  const formatAsPrice = getFormatPrice(currency, false);

  const tryGetPrice = p => formatAsPrice(isNaN(p) ? 0 : p);

  const getSpent = () => {
    const basketTotal = state.basket.model?.totals?.sub;
    const bt = isNaN(basketTotal) ? 0 : basketTotal;

    if (!bulkOrderDetails && !bulkOrderDetails.totalAmount)
    {
      return tryGetPrice(bt);
    }

    return tryGetPrice(bt + bulkOrderDetails.totalAmount);
  };

  const getTotalCuage = () => {
    const bv = getBasketVolume();
    return (bv + bulkOrderDetails.totalCubage).toFixed(2);
  };

  const getBasketVolume = () => {
    const { model } = state.basket;

    if (!model || !model.productLines || model.productLines.length === 0)
    {
      return 0;
    }

    const { list } = model.productLines;

    const volumes = list.map(l => {
      const { product } = l;
      return product.volume * l.quantity;
    }).reduce((a, b) => a + b, 0);
    return volumes;
  };

  const totalVolume = getBasketVolume();

  const getLeftToSpend = () => {
    if (!bulkOrderDetails && !bulkOrderDetails.totalAmount && !bulkOrderDetails.committedAmount)
    {
      return tryGetPrice(0);
    }

    const bt = isNaN(basketTotal) ? 0 : basketTotal;
    return tryGetPrice(Math.max(bulkOrderDetails.committedAmount - bulkOrderDetails.totalAmount - bt, 0));
  };

  const getCubageRemaining = () => {
    const { amountBelowCommitment } = bulkOrderDetails;
    const bv = getBasketVolume();
    return Math.max(amountBelowCommitment - bv, 0).toFixed(2);
  };

  const bulkStatusTitlePlaceholder = <Placeholder className={styles.detailsTitlePlaceholder} />;
  const basketTotal = state.basket.model?.totals?.sub;
  const details = {
    current: tryGetPrice(basketTotal),
    difference: isAmountBulkOrder ? getLeftToSpend() : getCubageRemaining(),
    spent: getSpent(),
    total: (bulkOrderDetails && bulkOrderDetails.committedAmount) ? formatAsPrice(bulkOrderDetails.committedAmount) : 0,
    orderId: (bulkOrderDetails && bulkOrderDetails.documentId) ? bulkOrderDetails.documentId : '',
    spendDate: (bulkOrderDetails && bulkOrderDetails.endDate) ? bulkOrderDetails.endDate : '',
    shipmentDate: (bulkOrderDetails && bulkOrderDetails.expectedShipmentDate) ? bulkOrderDetails.expectedShipmentDate : '',
  };

  return (
    <div className={styles.bulkStatus}>
      <h2 className={styles.detailsTitle}>
        <SimpleText textKey="BasketBulkStatus" placeholder={bulkStatusTitlePlaceholder} />
      </h2>
      {isQuickOrderMode && 
        <div>
          <span className="BulkStatusRecalculate">
            <SimpleText textKey="BulkStatus_Recaclculate" />
          </span>
        </div>
      }
      {!isQuickOrderMode && 
        <div>
          <font>
            <div>
              {isAmountBulkOrder && <span>Including your current order for <b><font color="orange">{currency.symbol}{details.current}</font></b>, you’ve spent <b><font color="green">{currency.symbol}{details.spent}</font></b> of your <b><font color="black">{currency.symbol}{details.total}</font></b> commitment on bulk order <b><font color="black">{details.orderId}</font></b>.</span>}
              {!isAmountBulkOrder && <span>Including your current order for <b><font color="orange">{totalVolume.toFixed(2)} M³</font></b>, you’ve purchased <b><font color="green">{getTotalCuage()} M³</font></b> of your <b><font color="black">{details.total} M³</font></b> commitment on bulk order <b><font color="black">{details.orderId}</font></b>.</span>}
            </div>
            <div>
              {isAmountBulkOrder && <span>Spend <b><font color="red">{currency.symbol}{details.difference}</font></b> more by <b><font color="red">{toLocaleDate(details.spendDate, culture)}</font></b> to maintain your expected shipment date of <b><font color="orange">{toLocaleDate(details.shipmentDate, culture)}</font></b>.</span>}
              {!isAmountBulkOrder && <span>Order <b><font color="red">{details.difference} M³</font></b> more by <b><font color="red">{toLocaleDate(details.spendDate, culture)}</font></b> to maintain your expected shipment date of <b><font color="orange">{toLocaleDate(details.shipmentDate, culture)}</font></b>.</span>}
            </div>
          </font>
        </div>    
      }
      <br />
    </div>
  );
};

BulkStatus.propTypes = {
  activeBulkOrder: PropTypes.object,
};

export default BulkStatus;
