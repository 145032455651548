import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { useOnChange } from 'utils/hooks';

const AriaStatus = ({ status }) => {
  const setState = useState()[1];
  const ref = useRef();
  const timeoutRef = useRef();

  useOnChange(() => {
    ref.current = null;

    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      const { text, key } = status;

      if (text)
        ref.current = text;
      else if (key)
        ref.current = <SimpleText textKey={key} />;

      if (ref.current)
        setState({});
    }, 10);
  }, [status]);

  useEffect(() => () => timeoutRef.current && clearTimeout(timeoutRef.current), []);

  return (
    <div className="visually-hidden" role="status" aria-live="polite" aria-atomic>
      {ref.current}
    </div>
  );
};

AriaStatus.propTypes = {
  status: PropTypes.shape({
    textKey: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

export default connect(state => ({
  status: state.ariaStatus,
}))(AriaStatus);
