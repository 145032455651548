import { PageComponentNames } from '../componentNames';
import { of, merge } from 'rxjs';
import { map, mergeMap, first, shareReplay } from 'rxjs/operators';
import { DocumentAbilitiesMap, DocumentType, getDocumentField, getDocumentDetailsField } from 'behavior/documents';
import { createAbilityMiddleware } from '../middlewares';
import { CustomerTypes } from 'behavior/user/constants';
import { getDocumentPageQuery } from './queries';
import { printModeEnabled } from 'behavior/printMode';
import { isQuoteInStock } from './helpers';
import { initSystemPageContent } from '../system';
import { loadSystemPageQuery } from '../system/queries';

const pageComponentNamesPerDocumentType = {
  [DocumentType.Quote]: PageComponentNames.Quote,
  [DocumentType.Invoice]: PageComponentNames.Invoice,
  [DocumentType.ReturnOrder]: PageComponentNames.ReturnOrder,
  [DocumentType.CreditNote]: PageComponentNames.CreditNote,
  [DocumentType.ReturnReceipt]: PageComponentNames.ReturnReceipt,
  [DocumentType.Shipment]: PageComponentNames.Shipment,
  [DocumentType.OrderBulk]: PageComponentNames.BulkOrder,
};

export default (routeData, state$, dependencies) => {
  const { params: { id, documentType, originalOrderId: orderId, previewToken } } = routeData;
  const { api } = dependencies;

  if (!documentType)
    return of(null);

  const isPrintMode = printModeEnabled(state$.value.routing);

  const pageResult = {
    page: {
      component: pageComponentNamesPerDocumentType[documentType] || PageComponentNames.Order,
      id,
      documentType,
      isPrintMode,
      promotionResult: state$.value.page.promotionResult,
    },
  };

  if (previewToken) {
    const pageField = getDocumentDetailsField(documentType);

    return api.graphApi(loadSystemPageQuery(pageField)).pipe(
      map(({ pages: { [pageField]: page } }) => !page ? null : {
        page: {
          ...page,
          ...pageResult.page,
          document: {
            id: '',
            documentId: '',
            currency: {},
            shipping: { tracking: {} },
            payment: { isPaidOnAccount: false },
            addresses: {},
            totals: {},
            lines: { totalCount: 0, itemLines: [] },
          },
        },
      }),
      initSystemPageContent(),
    );
  }

  const ability = DocumentAbilitiesMap[documentType];
  const abilityMiddleware = createAbilityMiddleware(ability, false);

  const settingsLoaded$ = state$.pipe(
    first(({ settings: { loaded } }) => loaded),
    shareReplay(),
  );

  const loadDocument$ = settingsLoaded$.pipe(
    mergeMap(_ => {
      const {
        user: { customerType },
        settings: {
          tax: { mode: taxMode },
          checkout: { maxOverviewLines },
        },
      } = state$.value;

      const query = getDocumentPageQuery(
        documentType,
        customerType !== CustomerTypes.B2C,
        taxMode,
      );
      return api.graphApi(query, {
        id,
        linesLimit: isPrintMode ? null : maxOverviewLines,
        orderId,
      }).pipe(
        map(({ pages, documents }) => {
          const page = pages[getDocumentDetailsField(documentType)];

          if (!page)
            return null;

          const field = getDocumentField(documentType);
          const document = documents[field] && documents[field].byId;
          if (!document)
            return null;

          const { lines: { itemLines } } = document;
          const productIds = itemLines.map(il => il.productId);

          if (documentType === DocumentType.Quote)
            document.hasStock = isQuoteInStock(document);

          return {
            page: {
              ...page,
              ...pageResult.page,
              document,
            },
          };
        }),
        initSystemPageContent(),
      );
    }),
  );

  const next = _ => merge(of(pageResult), loadDocument$);

  return abilityMiddleware(next, routeData, state$, dependencies);
};
