import { memo } from 'react';
import PropTypes from 'prop-types';

const Tag = ({ tracker }) => {
  if (!tracker)
    return null;

  return (
    <noscript>
      <iframe
        title="GTM"
        src={`//www.googletagmanager.com/ns.html?id=${tracker.containerId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  );
};

Tag.propTypes = {
  tracker: PropTypes.shape({
    containerId: PropTypes.string.isRequired,
  }),
};

export default memo(Tag);