import { useEffect } from 'react';
import { navigateTo } from 'behavior/events';
import { useDispatch } from 'react-redux';
import { createRouteData } from './helpers';
import { isModifierKeyPressed } from 'utils/helpers';
import { logger } from 'utils/logs';
import { isAbsoluteUrl } from 'utils/url';
import type { HTMLAnchorFactory, LinkTo, LinkClickHandler } from './types';
import type { RouteData } from 'routes';

type Props = {
  createLink: HTMLAnchorFactory;
  to?: LinkTo;
  url?: string;
  onClick?: LinkClickHandler;
  omitScroll?: boolean;
  replaceHistory?: boolean;
  options?: RouteData['options'];
  disabled?: boolean;
}

const NavigationLink = ({
  createLink,
  to,
  url,
  onClick,
  omitScroll,
  replaceHistory,
  options,
  disabled,
}: Props) => {
  const dispatch = useDispatch();


  useEffect(() => {
    if (!url)
      logBrokenLinksWarning();
  }, [url]);

  const onLinkClick = (event: React.MouseEvent<HTMLAnchorElement> & KeyboardEvent) => {
    if (event.button === 1 || isModifierKeyPressed(event))
      return;

    const { currentTarget: { target } } = event;
    if (!url || isAbsoluteUrl(url) || (target && target !== '_self'))
      return;

    event.preventDefault();
    if (disabled)
      return;

    const routeData = getRouteData(to, options);
    const ignore = (onClick && onClick(event, routeData)) === false;
    if (!ignore)
      dispatch(navigateTo(routeData, url, { omitScroll, replaceHistory }));
  };

  return createLink(url, onLinkClick);
};

export default NavigationLink;

function getRouteData(to: LinkTo | undefined, options: RouteData['options']): RouteData | undefined {
  if (!to)
    return;

  const routeData = createRouteData(to);
  if (!options)
    return routeData;

  return { ...routeData, options };
}

function logBrokenLinksWarning() {
  logger.warn('There are broken links on the website. Please use document.querySelectorAll("a[data-broken]") to find them.');
}
