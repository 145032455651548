import styles from './OpenerButton.module.scss';
import { forwardRef, memo } from 'react';
import { MenuOpenIcon, MenuCloseIcon } from 'components/primitives/icons';

const OpenerButton = forwardRef(({ customClass, isActive, ...attributes }, ref) => {
  const buttonClassNames = `${styles.btnMenuOpener}${isActive ? ' active' : ''}${customClass ? ` ${customClass}` : ''}`;
  const Icon = isActive ? MenuCloseIcon : MenuOpenIcon;

  return (
    <button className={buttonClassNames} ref={ref} {...attributes}>
      <Icon className={styles.icon} />
    </button>
  );
});

export default memo(OpenerButton);