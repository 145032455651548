import { createUrl } from '../url';
import type { TrackingOptions } from './types';

export default function (target: string, options: TrackingOptions) {
  const { origin } = options;

  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: createUrl('/', origin),
    potentialAction: [
      {
        '@type': 'SearchAction',
        target: createUrl(target, origin),
        'query-input': 'required name=q',
      }],
  };
}
