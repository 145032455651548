import styles from '../WishList.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';

function LineTemplate({
  productInfo,
  productActionBlock,
  actionLinks,
  priceNode,
  uomBlock,
  addToBasketBlock,
  isGroupedLine,
  className = null,
  ...attributes
}) {

  return (
    <tr className={className} {...attributes}>
      {productActionBlock
        ? (
          <td className={styles.colProductAction} aria-hidden={!isGroupedLine}>
            {productActionBlock}
          </td>
        )
        : <td className={styles.colProductAction} />}
      <td className={styles.colProductInfo}>
        {productInfo}
        {actionLinks}
      </td>
      <td className={styles.colPrice}>
        {priceNode}
      </td>
      <td>
        {uomBlock}
      </td>
      <td>
        {addToBasketBlock}
      </td>
    </tr>
  );
}

LineTemplate.propTypes = {
  productInfo: PropTypes.node.isRequired,
  productActionBlock: PropTypes.node,
  actionLinks: PropTypes.node,
  priceNode: PropTypes.node,
  uom: PropTypes.node,
  className: PropTypes.string,
  addToBasketBlock: PropTypes.node,
  isGroupedLine: PropTypes.bool,
};

export default memo(LineTemplate);
