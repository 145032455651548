import type { StyleModule, StyleContent, StyleOptions } from './types';
import insertCss from './insertCss';

export default function reloadCssModule(id: string, module: StyleModule, updatedContent: string | StyleContent) {
  module._content = typeof updatedContent === 'string'
    ? [[id, updatedContent, '', null]]
    : updatedContent;

  const options: StyleOptions = module._insertionOptions || {};
  options.replace = true;

  return insertCss([module], options);
}
