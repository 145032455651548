import styles from '../PLP.module.scss';
import { useState, useContext, useCallback, useEffect } from 'react';
import { RichText, useSanaTexts, SimpleText } from 'components/sanaText';
import { OfflinePriceWarning } from 'components/primitives/product';
import ProductLine from './Line';
import Schema from '../Schema';
import { ViewModes } from 'behavior/pages/productList';
import Paging from './Paging';
import { FacetsContext } from '../facets';
import withPageProps, { pagePropTypes } from '../withPageProps';
import { useProductListAbilities } from './useProductListAbilities';
import ListProvider from './ListProvider';
import AddToBasketButton from './AddToBasketButton';
import StickyAddToBasketPanel from './StickyAddToBasketPanel';
import { AbilityTo } from 'behavior/user/constants';
import SelectionLostPopup from './SelectionLostPopup';
import { useAriaStatusNotification, useTracking } from '../hooks';
import { HeaderRow } from '../HeaderRow';
import { trackProductListView, EVENT_SOURCES } from 'behavior/analytics';
import { connect } from 'react-redux';
import { ProductTrackingContext } from 'components/objects/analytics';
import { useSelector, useDispatch } from 'react-redux';
import { WarningAlert } from 'components/primitives/alerts';
import { useSimpleTexts } from 'components/sanaText';
import { renderHTML } from 'utils/render';
import { initializeVolumeOrder } from 'behavior/volumeOrder';

const Page = ({
  products,
  totalCount,
  selectedSorting,
  selectedViewMode,
  viewModeSwitchEnabled,
  pagingType,
  showThumbnails,
  id,
  relevanceAvailable,
  pageTitle,
  trackProductListView,
  currentPriceLevel,
  calcLinePrice,
  baseOrderValue,
  lowerThreshold,
  hasBasket,
}) => {
  const { hasFacets, availableFacets } = useContext(FacetsContext);
  const [headerLbl] = useSanaTexts(['Aria_PLP_Header']).texts;
  const trackingSource = id === 'search' ? EVENT_SOURCES.searchResult : pageTitle;
  useTracking(products,
    ({ products }) => trackProductListView(products, trackingSource),
    [products],
  );

  const { isVolumeOrder } = useSelector(s => s.basket);
  const dispatch = useDispatch();

  const [
    volumeOrdersAlreadyHasBasketText,
  ] = useSimpleTexts([
    'VolumeOrders_Page_AlreadyHasBasket',
  ]).texts;

  useEffect(() => {

    return () => {
      // componentWillUnmount
      return false;
    };
    
  }, []);

  useEffect(() => {
    if (!isVolumeOrder) {
      dispatch(initializeVolumeOrder());
    }
  }, [isVolumeOrder]);

  const canCheckout = (baseOrderValue >= lowerThreshold) && !hasBasket;

  const gridMode = selectedViewMode === ViewModes.Grid;
  const abilities = useProductListAbilities();

  const [sortingKey, setSortingKey] = useState(true);
  const restoreSorting = useCallback(() => setSortingKey(k => !k), []);

  const [addToBasketClicked, setAddToBasketClicked] = useState(false);

  const handleAddToBasketClick = () => {
    setAddToBasketClicked(true);
  };

  useAriaStatusNotification(products, selectedViewMode, selectedSorting);

  if (!products.length) {
    return (
      <div className="msg-block">
        <RichText textKey="ProductList_NoResult" />
      </div>
    );
  }

  return (
    <ListProvider key={id}>
      <Schema products={products} />
      <OfflinePriceWarning />
      <div className={styles.panel}>
        <header className={styles.panelHeader} aria-label={headerLbl}>
          {hasBasket && !addToBasketClicked && 
            <WarningAlert>{renderHTML(volumeOrdersAlreadyHasBasketText)}</WarningAlert>}
          <HeaderRow
            key={id}
            sortingKey={sortingKey.toString()}
            totalCount={totalCount}
            selectedSorting={selectedSorting}
            sortingEnabled={false}
            relevanceAvailable={relevanceAvailable}
            hasFacets={hasFacets}
            availableFacets={availableFacets}
            viewModeSwitchEnabled={viewModeSwitchEnabled}
            selectedViewMode={selectedViewMode}
          />
          {abilities[AbilityTo.OrderProducts] && (
            <div className={styles.control}>
              <AddToBasketButton canCheckout={canCheckout} pageId={id} onClick={handleAddToBasketClick} />
            </div>
          )}
        </header>
        <div className={`${gridMode ? `${styles.gridMode} ` : ''}${styles.list}`} role="list">
          <table>
            <thead>
              <tr>
                <td><SimpleText textKey="VolumeOrder_RangeHeader" /></td>
                <td><SimpleText textKey="VolumeOrder_ItemNoHeader" /></td>
                <td><SimpleText textKey="VolumeOrder_DescriptionHeader" /></td>
                <td><SimpleText textKey="VolumeOrder_UnitVolumeHeader" /></td>
                <td><SimpleText textKey="VolumeOrder_UnitPriceHeader" /></td>
                <td><SimpleText textKey="VolumeOrder_Level1VolumeOrderPriceHeader" /></td>
                <td><SimpleText textKey="VolumeOrder_Level2VolumeOrderPriceHeader" /></td>
                <td><SimpleText textKey="VolumeOrder_QuantityHeader" /></td>
                <td><SimpleText textKey="VolumeOrder_AvailabilityDateHeader" /></td>
                <td><SimpleText textKey="VolumeOrder_TotalVolumeHeader" /></td>
                <td><SimpleText textKey="VolumeOrder_TotalPriceHeader" /></td>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <ProductTrackingContext.Provider key={product.id} value={{ product, trackingSource }}>
                  <ProductLine
                    product={product}
                    gridMode={gridMode}
                    showThumbnail={showThumbnails}
                    abilities={abilities}
                    index={index}
                    calcLinePrice={calcLinePrice}
                    currentPriceLevel={currentPriceLevel}
                  />
                </ProductTrackingContext.Provider>
              ))}
            </tbody>
          </table>
        </div>
        <div className={`${styles.panelFooter} ${styles.b2BPanelFooter}`}>
          <Paging pagingType={pagingType} totalCount={totalCount} />
          {abilities[AbilityTo.OrderProducts] && (
            <div className={styles.control}>
              <AddToBasketButton canCheckout={canCheckout} />
            </div>
          )}
        </div>
        {abilities[AbilityTo.OrderProducts] && <StickyAddToBasketPanel canCheckout={canCheckout} />}
      </div>
      <SelectionLostPopup pagingType={pagingType} restoreSorting={restoreSorting} />
    </ListProvider>
  );
};

Page.propTypes = pagePropTypes;

export default connect(state =>
  ({ pageTitle: state.page.metaTitle }),
  { trackProductListView },
)(withPageProps(Page));