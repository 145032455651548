import styles from '../MainNav.module.scss';
import { memo, useContext, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import scrollIntoView from 'scroll-into-view';
import { Link } from 'components/primitives/links';
import { VerticalSliding } from 'components/primitives/transitions';
import ListItemStateIcon from '../ListItemStateIcon';
import NavItemSubItems from '../NavItemSubItems';
import { AccordionStateContext } from './AccordionStateProvider';
import { shouldRenderNavigation, isRightArrowKeyPressed, isLeftArrowKeyPressed } from '../../helpers';
import { useLayoutShifter } from 'utils/layout';
import { isModifierKeyPressed } from 'utils/helpers';
import { MainNavItemPropTypes } from '../PropTypes';
import { useNavItemEventHandlersData } from '../hooks';
import { setFocus } from '../eventHandlers';

const scrollDefaultOptions = {
  time: 20,
  ease: t => t,
};

const NavItem = ({
  item: {
    id,
    cssClass,
    title,
    link,
    children: subItems,
  },
  isActive,
  ariaSubmenuText,
}) => {
  const [isVisible, setVisible] = useState(false);
  const ref = useRef(null);
  const linkRef = useRef(null);
  const [activeItemId, setActiveItemId] = useContext(AccordionStateContext);
  const { topFixedElementsHeight } = useLayoutShifter();

  const shouldRenderSubItems = shouldRenderNavigation(subItems);
  const { navItemEventHandlers } = useNavItemEventHandlersData(shouldRenderSubItems, false);

  let handleClick = null, handleKeyDown = null, onSubItemsVisible = null;

  if (shouldRenderSubItems) {
    handleClick = e => {
      if (e.target !== e.currentTarget)
        return;

      setVisible(!isVisible);
      setActiveItemId(id);
      linkRef.current.focus();
    };

    handleKeyDown = e => {
      const isCorrectTarget = e.target === linkRef.current;
      const isRightArrowKey = isRightArrowKeyPressed(e);

      if (!isCorrectTarget || isModifierKeyPressed(e) || (!isRightArrowKey && !isLeftArrowKeyPressed(e)))
        return;

      if (isRightArrowKey) {
        setActiveItemId(id);
        setVisible(true);
        return;
      }

      setVisible(false);
    };

    onSubItemsVisible = () => {
      // Scroll into view current opened nav item if it was not visible in the viewport.
      if (ref.current.getBoundingClientRect().top > topFixedElementsHeight)
        return;

      scrollIntoView(
        ref.current,
        { ...scrollDefaultOptions, align: { top: 0, topOffset: topFixedElementsHeight } },
      );
    };
  }

  useEffect(() => {
    if (!shouldRenderSubItems)
      return;

    if (activeItemId !== id)
      setVisible(false);

  }, [shouldRenderSubItems, activeItemId, id]);

  const navItemRootClassNames = `${styles.navItemRoot} ${isActive ? styles.active : ''} ${cssClass || ''}`;
  const hypContainerStyles = `${styles.hypContainer} ${shouldRenderSubItems ? styles.hasSubItems : ''}`;

  return (
    <li
      className={navItemRootClassNames}
      ref={ref}
      {...navItemEventHandlers}
      aria-keyshortcuts={shouldRenderSubItems ? 'ArrowRight ArrowLeft' : null}
      aria-controls={shouldRenderSubItems ? id : null}
    >
      <div
        className={hypContainerStyles}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        // Prevents focus to be set on hyp container.
        onMouseDown={shouldRenderSubItems ? e => e.preventDefault() : null}
        role="presentation"
      >
        <Link
          {...link}
          className={styles.hypTopLvl}
          ref={linkRef}
          onClick={setFocus}
          aria-haspopup={shouldRenderSubItems ? 'true' : null}
        >
          <span>{title}</span>
        </Link>
        {shouldRenderSubItems && <ListItemStateIcon active={isVisible} className={styles.listItemIcon} />}
      </div>
      {shouldRenderSubItems && (
        <VerticalSliding expanded={isVisible} onEntered={onSubItemsVisible}>
          <NavItemSubItems
            subItems={subItems}
            title={title}
            id={id}
            isAccordion
            expanded={isVisible}
            ariaSubmenuText={ariaSubmenuText}
          />
        </VerticalSliding>
      )}
    </li>
  );
};

NavItem.propTypes = {
  item: MainNavItemPropTypes,
  isActive: PropTypes.bool,
  ariaSubmenuText: PropTypes.string,
};

export default memo(NavItem);