import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import WithVolumeOrderListNavigationRoute from '../../volumeOrderList/WithVolumeOrderListNavigationRoute';
import { useCurrencyInfo } from 'utils/hooks';
import { getFormatPrice } from 'utils/format';
import { SimpleText } from 'components/sanaText';

const TieredPriceRow = ({ priceList, price, url, pageId }) => {
    const currencyInfo = useCurrencyInfo();
    const formatAsPrice = currencyInfo && getFormatPrice(currencyInfo);
    const hasPageId = pageId!=='';
    const { volumeOrderTemplateLocation } = useSelector(s => s.settings);

    return (
      <tr>
        <td>{priceList}</td>
        <td>{formatAsPrice(price, currencyInfo)}</td>
        <td>
          {hasPageId ? <WithVolumeOrderListNavigationRoute url={url} pageId={pageId}>
            {(url, routeBuilder) => (
              <Link url={url} to={routeBuilder}>
                <SimpleText textKey="CreateVolumeOrder" />
              </Link>
            )}
          </WithVolumeOrderListNavigationRoute> :
          <Link url={volumeOrderTemplateLocation}>
            <SimpleText textKey="CreateVolumeOrder" />
          </Link>}
        </td>
      </tr>
    );
};

TieredPriceRow.propTypes = {
  priceList: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  url: PropTypes.string,
  pageId: PropTypes.string,
};

export default TieredPriceRow;