import { RichText } from 'components/sanaText';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { AbilityTo, AbilityState, CustomerTypes } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import { withAbilities } from 'components/objects/user';
import { PromotionalText } from 'components/objects/promotionalTexts';
import { InfoAlert } from 'components/primitives/alerts';

const OfflinePriceWarning = props => {
  const {
    customerType,
    offlineMode,
    abilities: [
      viewPricesAbility,
      orderProductsAbility,
    ],
  } = props;

  if (!offlineMode || !viewPricesAbility || !orderProductsAbility)
    return null;

  if (!customerType || customerType === CustomerTypes.B2C)
    return null;

  const canOrderProducts = orderProductsAbility === AbilityState.Available;
  const canViewPrices = viewPricesAbility === AbilityState.Available;

  return (
    <InfoAlert>
      {!canOrderProducts && <PromotionalText textKey="ConnectionOffline_TemporaryNotAvailable" />}
      {canOrderProducts && canViewPrices && <RichText textKey="Product_OfflineMode_PriceWarning_Prices" />}
      {canOrderProducts && !canViewPrices && <RichText textKey="Product_OfflineMode_PriceWarning_NoPrices" />}
    </InfoAlert>
  );
};

OfflinePriceWarning.propTypes = {
  offlineMode: PropTypes.bool.isRequired,
  customerType: PropTypes.string,
  abilities: abilitiesPropType,
};

export default connect(
  ({ user, app }) => ({
    customerType: user.customerType,
    offlineMode: app.offlineMode,
  }),
)(withAbilities(OfflinePriceWarning, [AbilityTo.ViewPrices, AbilityTo.OrderProducts]));