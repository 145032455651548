import { of } from 'rxjs';
import { PageComponentNames } from './componentNames';

export default (error, logger) => {
  error && !error.timeId && logger.error(error);
  return of({
    statusCode: 500,
    page: {
      index: false,
      component: PageComponentNames.Error,
      data: error && (error.timeId ? error : error.response?.errors?.[0].extensions.data),
    },
  });
};