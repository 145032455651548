import { useReducer, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateCalculatedFields } from 'behavior/pages/product';
import { useLoadEffect } from 'utils/hooks';
import ProductContext, { contextInitialValue } from './ProductContext';

function productReducer(state, newData) {
  return { ...state, ...newData };
}

const ProductProvider = ({
  product,
  children,
  updateCalculatedFields,
}) => {

  const [state, updateStateWith] = useReducer(
    productReducer,
    {
      product,
    },
    stateInitializer,
  );

  const context = useMemo(() => ({
    ...state,
    updateContext: updateStateWith,
    updateUomId: uomId => {
      const options = {
        ids: [product.id],
        uomId,
      };
      updateStateWith({ uomId });
      updateCalculatedFields(options);
    },
  }), [state]);
  
  useLoadEffect(() => {
    if (!product) {
      const uomId = context.product.id === product.id
        ? context.uomId
        : product.uom && product.uom.id;

      const options = {
        ids: [product.id],
        uomId,
      };

      updateCalculatedFields(options);
    }

    const updatedData = { product };

    if (product.id !== context.product.id) {
      updatedData.uomId = product.uom && product.uom.id;
    }

    updateStateWith(updatedData);
  }, [product]);

  useEffect(() => {
    let sku;
    if (product) {
      sku = product;
      if (context.variantId && product.variants && product.variants.length)
        sku = product.variants.find(v => v.id === context.variantId);
    } else {
      if (product.id === context.product.id) {
        sku = context.calculatedInfo;
      }
    }

    updateStateWith({ calculatedInfo: createCalculatedInfo(sku || {}) });
  }, [product, context.variantId]);
  
  return (
    <ProductContext.Provider value={context}>
      {children}
    </ProductContext.Provider>
  );
};

ProductProvider.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    uom: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  children: PropTypes.node,
  updateCalculatedFields: PropTypes.func.isRequired,
};

function stateInitializer({ product }) {
  const { uom } = product;
  const uomId = uom && uom.id;

  const initialState = {
    ...contextInitialValue,
    product,
    uomId,
  };

  if (product)
    initialState.calculatedInfo = createCalculatedInfo(product);

  return initialState;
}

function createCalculatedInfo({ inventory, isOrderable }) {
  return { inventory, isOrderable };
}

export default connect(null,
  { updateCalculatedFields },
)(ProductProvider);