import type { StyleModule, StyleContent } from './types';

type CssModule = (string & { locals?: unknown }) | (StyleContent & { locals?: unknown });

export default function wrapCssModule(id: string, module: CssModule) {
  return typeof module === 'string'
    ? createStyleModule(id, [[id, module, '', null]], module.locals)
    : createStyleModule(id, module as StyleContent, module.locals);
}

function createStyleModule(id: string, content: StyleContent, locals: unknown | undefined) {
  const module: StyleModule = {
    _moduleId: id,
    _content: content,
  };

  if (locals)
    Object.assign(module, locals);

  return module;
}
