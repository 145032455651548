import { useSelector } from 'react-redux';
import { parseQuery } from 'utils/url';

export default function useQueryString(initialOnly = false) {
  const search = useSelector(state => state.routing.location?.search || '', initialOnly ? _ => true : undefined);

  let cached: Record<string, string>, searchUsedForCached: string;
  return ({
    get values() {
      if (!cached || (!initialOnly && search !== searchUsedForCached)) {
        cached = parseQuery(search);
        if (!initialOnly)
          searchUsedForCached = search;
      }

      return cached;
    },
  });
}
