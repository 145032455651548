import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { switchMap, mergeMap, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { retryWithToast } from 'behavior/errorHandling';
import {
  AGREEMENTS_REQUESTED,
  notifyAgreementsReceived,
  AGREEMENTS_SEARCH_PRODUCT_IDS,
  productIdsReceived,
} from './actions';
import {
  getAgreementsListQuery,
  getAgreementItemQuery,
  searchForProductIdsQuery,
} from './queries';

export default function salesAgreementsPageEpic(action$, _state$, { api, logger }) {
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();

  const agreements$ = action$.pipe(
    ofType(AGREEMENTS_REQUESTED),
    switchMap(({ payload }) => {
      const isSingleItemLoading = !!payload.id;

      const query = isSingleItemLoading ? getAgreementItemQuery : getAgreementsListQuery;
      const params = isSingleItemLoading
        ? { id: payload.id }
        : {
          input: {
            activeOnly: payload.activeOnly,
            page: { index: payload.index, size: payload.size },
          },
        };

      return api.graphApi(query, params).pipe(
        mergeMap(({ salesAgreements }) => {
          if (salesAgreements.list)
            return [
              unsetLoading,
              notifyAgreementsReceived(salesAgreements.list, payload.index ? true : false),
            ];

          return [
            unsetLoading,
            notifyAgreementsReceived(salesAgreements.item && [salesAgreements.item]),
          ];
        }),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        startWith(setLoading),
      );
    }),
  );

  const idsSearch$ = action$.pipe(
    ofType(AGREEMENTS_SEARCH_PRODUCT_IDS),
    distinctUntilChanged(undefined, a => a.payload.keywords),
    switchMap(({ payload }) => {
      if (!payload.keywords)
        return of(productIdsReceived(payload.keywords, null));

      return api.graphApi(searchForProductIdsQuery, payload).pipe(
        map(({ catalog }) => {
          if (!catalog.products || !catalog.products.products.length)
            return productIdsReceived(payload.keywords, null);

          return productIdsReceived(payload.keywords, catalog.products.products.map(p => p.id));
        }),
        retryWithToast(action$, logger),
      );
    }),
  );
  return merge(agreements$, idsSearch$);
}
