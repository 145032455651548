export const LANGUAGES_LOADED = 'LANGUAGES/LOADED';
export const languagesLoaded = languages => ({ type: LANGUAGES_LOADED, payload: languages });

export const ALTERNATE_URLS_REQUESTED = 'LANGUAGES/ALTERNATE_URLS/REQUESTED';
export const loadAlternateUrls = () => ({ type: ALTERNATE_URLS_REQUESTED });

export const ALTERNATE_URLS_LOADED = 'LANGUAGES/ALTERNATE_URLS/LOADED';
export const alternateUrlsLoaded = alternateUrls => ({ type: ALTERNATE_URLS_LOADED, payload: alternateUrls });

export const URL_LANGUAGE_CHANGED = 'URL/LANGUAGE/CHANGED';
export const urlLanguageChanged = newLanguageId => ({ type: URL_LANGUAGE_CHANGED, payload: newLanguageId });