import styles from '../PLP.module.scss';
import PropTypes from 'prop-types';
import OrderBox from './OrderBox';
import { AbilityTo } from 'behavior/user/constants';
import { useCurrencyInfo } from 'utils/hooks';
import { getFormatPrice } from 'utils/format';
import { renderHTML } from 'utils/render';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { StockAvailability } from 'components/objects/stockAvailability';
import { dateFromShortDate, getAvailableDate } from 'utils/baker';
import { useHasAbilities } from 'components/objects/user';

const ProductLine = ({ product, gridMode, abilities, calcLinePrice, currentPriceLevel }) => {

  const currencyInfo = useCurrencyInfo();
  const formatAsPrice = currencyInfo && getFormatPrice(currencyInfo);

  const [totalPrice, setTotalPrice] = useState(0.00);
  const [totalVolume, setTotalVolume] = useState(0.00);
  const [availableDate, setAvailableDate] = useState('Unavailable');
  const [quantity, setQuantity] = useState(0);

  const [showStock] = useHasAbilities(AbilityTo.ViewStock);

  const { quantities } = useSelector(s => s.volumeOrders);
  const { rangeTitle } = useSelector(s => s.page);

  const isUnavailable = availableDate==='Unavailable';

  const { stockStatus, isOrderable } = product;

  const formatPrice = (price, currencyInfo) => {
    if (currencyInfo.decimalDigits === 0)
      return price;

    return price.replace(/(\d+)([^\d]*)$/, '<sup>$1</sup>$2');
  };

  useEffect(() => {

    let thisProduct = quantities.filter(x => x.productId===product.id)[0];

    if (thisProduct===undefined) {
      setTotalPrice(0.00);
      setTotalVolume(0.00);
      return;
    }

    thisProduct = {
      ...thisProduct,
      ...product,
    };

    const { quantity: { value } } = thisProduct;
    const { volume } = product;
    
    setTotalPrice(calcLinePrice(thisProduct, currentPriceLevel));
    setTotalVolume(volume*value);

    setQuantity(value);

  }, [quantities, currentPriceLevel]);

  useEffect(() => {
    if (!showStock) {
      return;
    }

    const { stockStatus }  = product;

    const stockStatuses = stockStatus?.filter(ss => ss.name!=='HEAD');

    if (stockStatuses!==undefined && stockStatuses.length) {
      if (stockStatuses.some(ss => ss.name==='In Stock')) {
        setAvailableDate('Today');
        return;
      }

      const theDate = stockStatuses
        .map(x => ({ ...x, date: dateFromShortDate(x.name)}))
        .sort((a,b) => a.date-b.date)[0];

      //setAvailableDate(theDate.date.toLocaleDateString());
    }
  }, [product]);

  useEffect(() => {
    const { stockStatus }  = product;

    if (!showStock) {
      return;
    }

    setAvailableDate(getAvailableDate(stockStatus, Math.max(quantity, 1)))

  }, [quantity]);

  return (
    <tr>
      <td>{rangeTitle}</td>
      <td>{product.id}</td>
      <td>{product.title}</td>
      <td>{product.volume.toFixed(2)}</td>
      <td>{renderHTML(formatPrice(formatAsPrice(product.price), currencyInfo))}</td>
      <td>{renderHTML(formatPrice(formatAsPrice(product.level1VolumePrice), currencyInfo))}</td>
      <td>{renderHTML(formatPrice(formatAsPrice(product.level2VolumePrice), currencyInfo))}</td>
      <td>
        <OrderBox
          product={product}
          className={styles.orderBox}
          abilities={abilities}
          gridMode={gridMode}
          isUnavailable={isUnavailable}
        />
      </td>
      <td>
          {isOrderable && <StockAvailability stockStatus={stockStatus} text={availableDate} currentQuantity={quantity}  />}
      </td>
      <td>{totalVolume.toFixed(2)}</td>
      <td>{renderHTML(formatPrice(formatAsPrice(totalPrice), currencyInfo))}</td>
    </tr>
  );
};

ProductLine.propTypes = {
  product: PropTypes.object.isRequired,
  gridMode: PropTypes.bool,
  showThumbnail: PropTypes.bool,
  abilities: PropTypes.shape({
    [AbilityTo.OrderProducts]: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
  calcLinePrice: PropTypes.func,
  currentPriceLevel: PropTypes.string,
};

export default ProductLine;