import styles from './Basket.module.scss';
import PropTypes from 'prop-types';
import { getCurrencyAndNumber } from 'utils/format';
import { EmptyPriceSymbol } from 'utils/product';

const PriceNode = ({ price, currencyInfo, className = '' }) => {
  let symbol, value = EmptyPriceSymbol;
  if (price != null)
    [symbol, value] = getCurrencyAndNumber(price || 0, currencyInfo);

  const isNegative = price < 0;

  return (
    <span className={`${styles.priceNode} ${className}`}>
      <span className={styles.symbol} aria-hidden>{symbol}</span>
      <span className={styles.value + ' value'} aria-hidden>{value}</span>
      <span className="visually-hidden" role="presentation">{isNegative && '-'}{symbol + Math.abs(price)}</span>
    </span>
  );
};

PriceNode.propTypes = {
  price: PropTypes.number,
  currencyInfo: PropTypes.object,
  className: PropTypes.string,
};

export default PriceNode;
