import { THEME_LOADED, ThemeAction } from './actions';
import { ThemeVersion } from './constants';

type ThemeState = {
  values?: Record<string, string>;
  version?: string;
}

export const initialState = {};

export default function reducer(state = initialState, action: ThemeAction): ThemeState {
  switch (action.type) {
    case THEME_LOADED:
      return action.payload || { version: ThemeVersion.Default };
    default:
      return state;
  }
}
