import { createLoadPageQuery } from './queries';
import { map, tap, filter, first, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { requestPage } from './actions';
import { createApiVariables, sortProducts } from 'behavior/products/lastViewedTracking/helpers';
import { initComponent } from 'behavior/pages/helpers';
import { PageComponentNames } from 'behavior/pages/componentNames';

export default (_routeData, state$, { api, localStorage, scope }) => {
  const handle = ({ settings }) => {
    const variables = settings.lastViewedEnabled ? createApiVariables(localStorage) : undefined;
    if (variables)
      variables.loadCategories = state$.value.analytics && state$.value.analytics.isTrackingEnabled;
    const query = createLoadPageQuery(variables && variables.options);

    return api.graphApi(query, variables).pipe(
      map(data => {
        const page = data.pages.lastViewedProducts;
        if (!page)
          return null;

        const products = data.catalog
          && data.catalog.products && data.catalog.products.products;
        const lastViewed = scope === 'CLIENT' ? sortProducts(products, variables) : null;

        return { ...page, lastViewed };
      }),
      initComponent(PageComponentNames.LastViewedProducts),
      tap(result => {
        if (variables)
          result.action$ = of(requestPage(true));
      }),
    );
  };

  if (state$.value.settings.loaded)
    return handle(state$.value);

  return state$.pipe(
    filter(({ settings }) => settings.loaded),
    first(),
    switchMap(handle),
  );
};
