import { makeSimpleText } from 'utils/render';

type Props = {
  message: string;
}

const ErrorToastWithMessage = ({ message }: Props) => {
  return makeSimpleText(message);
};

export default ErrorToastWithMessage;
