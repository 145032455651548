import { useMemo, useContext } from 'react';
import SanaTextsContext from './SanaTextsContext';
import { useLoadEffect, useComputedValue } from 'utils/hooks';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { makeSimpleText } from 'utils/render';
import { insiteEditorUseSanaTextsWrapper } from 'components/visualDesigner/insiteEditor';

const useSanaTextsCore = (options, process) => {
  const { loadSanaTexts } = useContext(SanaTextsContext);

  const selectSanaTexts = useComputedValue(() => makeSanaTextsSelector(options), options, compareOptions);
  const sanaTexts = useSelector(selectSanaTexts);

  useLoadEffect(() => {
    const keysToLoad = [];

    for (const { key, value } of sanaTexts) {
      if (value === undefined)
        keysToLoad.push(key);
    }

    if (keysToLoad.length)
      loadSanaTexts(keysToLoad);
  });

  return useMemo(() => {
    let loaded = true;
    const textValues = [];

    for (const { value, fallback } of sanaTexts) {
      if (value === undefined) {
        textValues.push(null);
        loaded = false;
      } else if (value === null)
        textValues.push(fallback || null);
      else
        textValues.push(process ? process(value) : value);
    }

    return { texts: textValues, loaded };
  }, [sanaTexts]);
};

const selectSanaTexts = (sanaTexts, options) => {
  return options.map(option => {
    if (!option)
      return { key: null, value: null };

    let entry;

    if (typeof option === 'string')
      entry = { key: option };
    else
      entry = { ...option };

    entry.value = sanaTexts[entry.key];
    return entry;
  });
};

function makeSanaTextsSelector(options) {
  return createSelector(
    state => state.sanaTexts,
    sanaTexts => selectSanaTexts(sanaTexts, options),
  );
}

function compareOptions(options1, options2) {
  if (!options1 || !options2)
    return false;

  if (options1 === options2)
    return true;

  if (options1.length !== options2.length)
    return false;

  for (let i = 0; i < options1.length; i++)
    if (getOptionKey(options1[i]) !== getOptionKey(options2[i]))
      return false;

  return true;
}

function getOptionKey(option) {
  if (!option || typeof option === 'string')
    return option;

  return option.key;
}

export const useSanaTexts = insiteEditorUseSanaTextsWrapper(useSanaTextsCore);

export const useSimpleTexts = options => useSanaTexts(options, makeSimpleText);
