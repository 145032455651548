import PageB2B from '../b2b/Page';
import PageB2C from '../b2c/Page';
import { PageComponentNames } from 'behavior/pages';
import PageContainer from './PageContainer';

export const presets = {
  'ListB2B': PageB2B,
  'List': PageB2C,
};

function volumeOrderListPageRenderer(page) {
  const Page = page.preset in presets
    ? presets[page.preset]
    : presets.PageB2C;

  if (!Page.selectProps)
    throw new Error(`Product list page component ${Page.displayName} should implement selectProps method to map page state to props.`);

  return <PageContainer page={page} Page={Page} />;
}

export default {
  [PageComponentNames.VolumeOrderList]: volumeOrderListPageRenderer,
};