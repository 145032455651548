import { useCallback } from 'react';
import { executeCommandMutation } from './queries';
import { useGraphApi } from 'utils/hooks';

export default function useExtraCheckoutDataCommand(orderId, paymentMethodId) {
  const apiCall = useGraphApi();

  return useCallback(
    args => apiCall(
      executeCommandMutation,
      { input: { orderId, paymentMethodId, command: { arguments: args } } },
      ({ invoicePayment }) => invoicePayment?.executeExtraPaymentDataCommand?.result,
    ).toPromise(),
    [orderId, paymentMethodId],
  );
}
