import { useMatchMediaContext } from 'components/responsive';

export const isBrowser: boolean = typeof window !== 'undefined' && !!window.document;

export const useIsIE = (): boolean => useMatchMediaContext().isIE;

export const useIsFirefox = (): boolean => useMatchMediaContext().isFirefox;

export const useIsSafari = (): boolean => useMatchMediaContext().isSafari;

export const useIsTouchDevice = (): boolean => useMatchMediaContext().isTouchDevice;

export const useIsDesktopSafari = (): boolean => {
  const { isSafari, isTouchDevice } = useMatchMediaContext();
  return isSafari && !isTouchDevice;
};

export const useIsMobileSafari = (): boolean => {
  const { isSafari, isTouchDevice } = useMatchMediaContext();
  return isSafari && isTouchDevice;
};

export const useIsChromium = (): boolean => {
  if (!isBrowser || !window.CSS?.supports)
    return false;

  return !window.CSS.supports('-webkit-hyphens', 'none')
    && !window.CSS.supports('-moz-appearance', 'none')
    && window.CSS.supports('list-style-type', '"*"');
};

export const useIsDesktopChromium = (): boolean => {
  const isChromium = useIsChromium();
  const isTouchDevice = useIsTouchDevice();

  return isChromium && !isTouchDevice;
};

export const useIsMobileChromium = (): boolean => {
  const isChromium = useIsChromium();
  const isTouchDevice = useIsTouchDevice();

  return isChromium && isTouchDevice;
};

export const useIsLegacyMSEdge = (): boolean => isBrowser && window.CSS?.supports && window.CSS.supports('-ms-ime-align', 'auto');

export const useIsTouchScreen = (): boolean => {
  const { isIE, isTouchScreen } = useMatchMediaContext();
  return isTouchScreenPresent(isTouchScreen, isIE);
};

export const useHasMultiplePointers = (): boolean => {
  const { hasMultiplePointers, isTouchScreen, isIE } = useMatchMediaContext();
  return hasMultiplePointers || isTouchScreenPresent(isTouchScreen, isIE);
};

export const isTouchScreenPresent = (isTouchScreen: boolean, isIE: boolean): boolean => {
  return isIE ? window.navigator.maxTouchPoints > 0 : isTouchScreen;
};