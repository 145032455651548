import './DatePicker.scss';
import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import ExtendedDatePicker from './ExtendedDatePicker';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { useSelector } from 'react-redux';
import DateOnly from 'date-only';
import { DayOfWeekIndexes } from './calendar/constants';
import {
  CalendarIcon, CrossSmallIcon, MediumArrowLeftIcon,
  MediumDoubleArrowLeftIcon, MediumArrowRightIcon, MediumDoubleArrowRightIcon,
} from 'components/primitives/icons';

const defaultMinDate = new Date(1901, 0, 1);
const defaultMaxDate = new Date(2100, 11, 31);

const DatePicker = ({
  onChange,
  onKeyDown,
  onBlur,
  className = '',
  isValid = true,
  value,
  minDate = defaultMinDate,
  maxDate = defaultMaxDate,
  'aria-invalid': ariaInvalid,
  ...props
}) => {
  const language = useSelector(s => s.localization.currentLanguage);

  const [
    calendarAriaLabel,
    clearAriaLabel,
    dayAriaLabel,
    monthAriaLabel,
    yearAriaLabel,
    dayPlaceholder,
    monthPlaceholder,
    yearPlaceholder,
    navigationAriaLabel,
    nextAriaLabel,
    nextUpperRangeAriaLabel,
    prevAriaLabel,
    prevUpperRangeAriaLabel,
  ] = useSanaTexts(DatePicker.textKeys, makeSimpleText).texts;

  const [
    clearIcon,
    calendarIcon,
    prevLabelIcon,
    prevUpperRangeLabel,
    nextLabelIcon,
    nextUpperRangeLabel,
  ] = useMemo(() => ([
    <CrossSmallIcon aria-hidden />,
    <CalendarIcon aria-hidden />,
    <MediumArrowLeftIcon aria-hidden />,
    <MediumDoubleArrowLeftIcon aria-hidden />,
    <MediumArrowRightIcon aria-hidden />,
    <MediumDoubleArrowRightIcon aria-hidden />,
  ]), []);

  const parsedValue = useMemo(() => {
    return value ?
      DateOnly.parse(value) || new Date(NaN) :
      null;
  }, [value]);
  const handleChange = useCallback(newValue => {
    onChange && onChange(DateOnly.toISOString(newValue));
  }, [onChange]);

  if (parsedValue && onChange && DateOnly.compare(parsedValue, minDate) < 0)
    handleChange(minDate);

  return (
    <ExtendedDatePicker
      locale={language.cultureName || 'en-US'}
      firstDayOfWeek={language.firstDayOfWeek && DayOfWeekIndexes[language.firstDayOfWeek]}
      value={parsedValue}
      minDate={minDate}
      maxDate={maxDate}
      calendarAriaLabel={calendarAriaLabel}
      clearAriaLabel={clearAriaLabel}
      dayAriaLabel={dayAriaLabel}
      monthAriaLabel={monthAriaLabel}
      yearAriaLabel={yearAriaLabel}
      dayPlaceholder={dayPlaceholder}
      monthPlaceholder={monthPlaceholder}
      yearPlaceholder={yearPlaceholder}
      nextLabel={nextLabelIcon}
      nextUpperRangeLabel={nextUpperRangeLabel}
      prevLabel={prevLabelIcon}
      prevUpperRangeLabel={prevUpperRangeLabel}
      navigationAriaLabel={navigationAriaLabel}
      nextAriaLabel={nextAriaLabel}
      nextUpperRangeAriaLabel={nextUpperRangeAriaLabel}
      prevAriaLabel={prevAriaLabel}
      prevUpperRangeAriaLabel={prevUpperRangeAriaLabel}
      {...props}
      className={`${className}${!isValid ? ' invalid' : ''}`}
      clearIcon={value || !isValid ? clearIcon : null}
      calendarIcon={calendarIcon}
      onChange={handleChange}
      onBlur={null}
      isValid={isValid}
    />
  );
};

DatePicker.propTypes = {
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  isValid: PropTypes.bool,
  value: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

DatePicker.textKeys = [
  'Aria_DatePicker_CalendarLabel',
  'Aria_DatePicker_ClearLabel',
  'Aria_DatePicker_DayLabel',
  'Aria_DatePicker_MonthLabel',
  'Aria_DatePicker_YearLabel',
  'Day',
  'Month',
  'Year',
  'Aria_DatePicker_Calendar_ViewSwitcherLabel',
  'Aria_DatePicker_Calendar_NextLabel',
  'Aria_DatePicker_Calendar_JumpForwardsLabel',
  'Aria_DatePicker_Calendar_PrevLabel',
  'Aria_DatePicker_Calendar_JumpBackwardsLabel',
];

export default DatePicker;
