import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PropTypes from 'prop-types';
import ProductDetailsButton from './ProductDetailsButton';

const Configure = ({ product }) => (
  <ProductDetailsButton
    textKey="ConfigureProduct"
    className={`${btnStyles.btnSmall} btn-configure`}
    titleTextKey="ConfigureProduct_ListPage"
    product={product}
  />
);

Configure.propTypes = {
  product: PropTypes.object.isRequired,
};

export default Configure;
