import PropTypes from 'prop-types';
import { SearchContainerContext } from './SearchContainerContext';

const SuggestionsContainer = ({ containerProps, children }) => (
  <SearchContainerContext.Consumer>
    {({ maxHeight }) => (
      <div {...containerProps} style={maxHeight ? { maxHeight } : null}>
        {children}
      </div>
    )}
  </SearchContainerContext.Consumer>
);

SuggestionsContainer.propTypes = {
  containerProps: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default SuggestionsContainer;
