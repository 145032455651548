import Tooltip from 'react-tooltip-lite';
import './StockAvailability.css';
import PropTypes from 'prop-types';
import { toLocaleDate } from 'utils/format';
import { useCultureName } from 'utils/hooks';
import { tryToLocaleDate } from 'utils/baker';

const StockAvailability = ({ component, text, stockStatus, currentQuantity }) => {

  const cultureName = useCultureName();

  if (stockStatus==null) {
    return null;
  }

  const headStockStatus = stockStatus.filter(s => s.name==='HEAD')[0];

  if (headStockStatus===undefined)
  {
    return null;
  }

  const nonHeadStockStatuses = stockStatus.filter(s => s.name!=='HEAD');

  const stockStatusToday = nonHeadStockStatuses.filter(s => s.name==='Today');
  const stockStatusNotToday = 
    nonHeadStockStatuses.filter(s => s.name!=='Today')
    .sort((a,b) => new Date(a.name) - new Date(b.name));

  const stockStatuses = [
    ...stockStatusToday,
    ...stockStatusNotToday,
  ];

  const totalStock = (stockStatuses===undefined || !stockStatuses.length) ?
    0 :
    stockStatuses.map(s => s.stockLevel).reduce((a,b) => a+b);

  const quantityWanted = currentQuantity ? currentQuantity : 0;
  const excessQuantityWanted = Math.abs(totalStock - quantityWanted);

  const hasStockStatus = (stockStatuses!==undefined && stockStatuses.length>0);

  const leadDate = toLocaleDate(headStockStatus.leadDate);
  const today = toLocaleDate(new Date());

  const showToBeOrdered = (leadDate!==today) && (quantityWanted > totalStock);

  if (!hasStockStatus && (!currentQuantity>0 || !showToBeOrdered)) {
    return (
      <>
        {text && text}
        {component && component}
      </>
    )
  }

  return (
    <>
      <Tooltip className="stock-status-tooltip"
        content={(
            <table>
              <thead>
                <tr className="table-row-top table-row-bottom">
                  <th>Status</th>
                  <th>Qty</th>
                  <th>Available Date</th>
                </tr>
              </thead>
              <tbody>
                {hasStockStatus && stockStatuses.map((ss,i) => (
                  <tr key={i}>
                    <td>{ss.description}</td>
                    <td>{ss.stockLevel}</td>
                    <td>{tryToLocaleDate(ss.name, cultureName)}</td>
                  </tr>
                ))}
                {showToBeOrdered &&
                  <tr>
                    <td>To Be Ordered</td>
                    <td>{excessQuantityWanted}</td>
                    <td>{tryToLocaleDate(headStockStatus.leadDate, cultureName)}</td>
                  </tr>}
              </tbody>
            </table>
        )}
        direction="up"
        tagName="span"
        className="target"
        eventToggle='onClick'
      >
        {component && component }
        {text && text}
    </Tooltip>
    {(stockStatus === undefined || stockStatus.length <= 0) && 
      <>
        {component && component}
        {text && text}
      </>
    }
    </>
  );
};

StockAvailability.propTypes = {
  component: PropTypes.any,
  text: PropTypes.string,
  stockLevel: PropTypes.array,
};

export default StockAvailability;