export const FailureOrderPaymentFetchingReason = Object.freeze({
  OrderAlreadyPaid: 'ORDER_ALREADY_PAID',
  QuoteAlreadyPaid: 'QUOTE_ALREADY_PAID',
  QuoteCannotBePromoted: 'QUOTE_CANNOT_BE_PROMOTED',
});

export const FailureOrderPaymentReason = Object.freeze({
  OrderNotFound: 'ORDER_NOT_FOUND',
  OrderAlreadyPaid: 'ORDER_ALREADY_PAID',
  QuoteAlreadyPaid: 'QUOTE_ALREADY_PAID',
  QuoteCannotBePromoted: 'QUOTE_CANNOT_BE_PROMOTED',
});