import { createReducer } from 'utils/redux';
import {
  WISH_LIST_UPDATED,
} from 'behavior/wishList/actions';

export default createReducer(null, {
  [WISH_LIST_UPDATED]: onWishListUpdated,
});

function onWishListUpdated(state, action) {
  const { modifiedDate } = action.payload;
  return {
    ...state,
    modifiedDate,
  };
}
