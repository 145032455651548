import { useMemo } from 'react';
import PropTypes from 'prop-types';
import DateOnly from 'date-only';
import { ReadOnlyFormGroup } from 'components/objects/forms/formGroups';
import { toLongLocaleDate } from 'utils/format';
import { useCultureName } from 'utils/hooks';

const DateReadOnlyFormGroup = ({ displayValue, fieldName, ...props }) => {
  const culture = useCultureName();
  const dateDisplayValue = useMemo(() => {
    const date = DateOnly.parse(displayValue);
    return toLongLocaleDate(date, culture);
  }, [displayValue]);
  return (
    <ReadOnlyFormGroup
      fieldName={fieldName}
      displayValue={dateDisplayValue}
      {...props}
    />
  );
};

DateReadOnlyFormGroup.propTypes = {
  displayValue: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
};

export default DateReadOnlyFormGroup;
