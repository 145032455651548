import { memo } from 'react';
import PropTypes from 'prop-types';
import { SanaLinkButton } from 'components/primitives/links';
import { useProductRoute } from 'components/objects/productComparison/hooks';
import { WithProductClickTracking } from 'components/objects/analytics';
import ButtonPlaceholder from './Placeholder';

const ProductDetailsButton = memo(({
  textKey,
  titleTextKey,
  product: { id, url },
  className,
}) => {
  const to = useProductRoute(id);
  const placeholder = <ButtonPlaceholder />;

  return (
    <WithProductClickTracking>
      {handleClick => (
        <SanaLinkButton className={className}
          url={url}
          to={to}
          textKey={textKey}
          titleTextKey={titleTextKey}
          placeholder={placeholder}
          onClick={handleClick}
        />
      )}
    </WithProductClickTracking>
  );
});

ProductDetailsButton.propTypes = {
  textKey: PropTypes.string.isRequired,
  titleTextKey: PropTypes.string,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
};

export default ProductDetailsButton;