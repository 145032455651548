import styles from './Bom.module.scss';
import modalStyles from 'components/objects/modals/Modal.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components/primitives/table';
import { SimpleText } from 'components/sanaText';
import { useIsMobileViewport } from 'utils/hooks';
import { useFormatNumberWithCulture } from 'utils/hooks';

const Popup = ({ components }) => {
  const hasComponents = components && !!components.length;
  const formatNumber = useFormatNumberWithCulture();

  const isMobile = useIsMobileViewport();
  const hasVariants = components && components.some(component => component.variant);

  return (
    <div className={`${styles.bom} ${isMobile ? styles.mobile : ''}`} x-ms-format-detection="none">
      <h2 className={`h3 ${modalStyles.title}`}><SimpleText textKey="Product_TabPackageContents" /></h2>
      {hasComponents && (
        <Table className={`${styles.table} ${hasVariants ? styles.hasVariants : ''}`}>
          <thead>
            <tr>
              <th><SimpleText textKey="General_Product_Id" /></th>
              <th className={styles.title}><SimpleText textKey="General_Product_Title" /></th>
              {hasVariants
                ? isMobile
                  ? <th className={styles.variant}><SimpleText textKey="Variant" />, <SimpleText textKey="Quantity" /></th>
                  : <th className={styles.variant}><SimpleText textKey="Variant" /></th>
                : isMobile && <th className={styles.qty}><SimpleText textKey="Quantity" /></th>}
              <th className={styles.qty}><SimpleText textKey="Quantity" /></th>
            </tr>
          </thead>
          <tbody>
            {groupByProductId(components).map(({ key, items }) => (
              <tr key={key}>
                <td>{key}</td>
                <td className={styles.title}>{items[0].title}</td>
                <td colSpan={hasVariants ? '2' : null}>
                  <ul className={styles.nested}>
                    {items.map((component, index) => (
                      <li key={index}>
                        {hasVariants && <span className={styles.lblVariant}>{getComponentDescription(component)}</span>}
                        <span className={styles.lblQty}>{formatNumber(component.quantity)}</span>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {!hasComponents && (
        <div>
          <SimpleText textKey="PackageContents_NoComponentsFound" />
        </div>
      )}
    </div>
  );
};

Popup.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape({
    productId: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    variant: PropTypes.shape({
      description: PropTypes.string,
    }),
    vertical: PropTypes.shape({
      text: PropTypes.string,
    }),
  })),
};

export default memo(Popup);

function getComponentDescription(component) {
  const { variant } = component;
  const variantDescription = variant && variant.description;

  if (variantDescription)
    return variantDescription.replace(/(?:^,+)|(?:,+$)/g, '');

  return null;
}

function groupByProductId(components) {
  const result = [];

  for (const component of components) {
    const { productId } = component;
    let group = result.find(g => g.key === productId);

    if (!group) {
      group = { key: productId, items: [] };
      result.push(group);
    }

    group.items.push(component);
  }

  return result;
}