import { combineEpics, StateObservable, ofType, Epic, ActionsObservable } from 'redux-observable';
import { of, EMPTY, iif, ReplaySubject, Observable, Subject } from 'rxjs';
import {
  switchMap,
  retry,
  map,
  catchError,
  groupBy,
  mergeMap,
  exhaustMap,
  filter,
} from 'rxjs/operators';
import {
  ADDON_REQUESTED,
  EVENT_ACTION_PREFIX,
  addonLoaded,
  addonLoadFailed,
  addonAction,
  isWrappedAddonAction,
  AddonActions,
} from './actions';
import type { Action } from 'redux';
import type { Logger } from 'utils/logs';
import type { Registry } from './registry';
import type { AddonCreator, AddonEpicServices, AddonLoader, StateWithAddons } from './types';
import type { DefaultRootState } from 'react-redux';

type AddonEpicServicesFactory = (addonId: string, state$: StateObservable<DefaultRootState>) => AddonEpicServices;

export default function createEpic(
  addonRegistry: Registry,
  addonLoader: AddonLoader,
  addonCreator: AddonCreator,
  addonEpicServicesFactory: AddonEpicServicesFactory) {

  const addonEpics$ = new ReplaySubject<{ id: string; epic: Epic | null }>();
  addonRegistry.getEntries().forEach(({ id, addon }) => {
    addonEpics$.next({ id, epic: addon?.epic || null });
  });
  addonRegistry.updates.subscribe({
    next({ id, addon }) {
      addonEpics$.next({ id, epic: addon?.epic || null });
    },
  });

  const applyAddonEpic = (addonId: string, addonEpic: Epic | null, addonActionGroup$: Observable<Action>, state$: StateObservable<StateWithAddons>) => {
    if (!addonEpic)
      return EMPTY;

    const mapState = (state: StateWithAddons) => state.addons.scopes[addonId];
    const addonState$ = new StateObservable(
      state$.pipe(map(mapState)) as Subject<unknown>,
      mapState(state$.value),
    );

    const addonEpicServices = addonEpicServicesFactory ? addonEpicServicesFactory(addonId, state$ as any) : undefined;
    return addonEpic(addonActionGroup$ as ActionsObservable<Action>, addonState$, addonEpicServices).pipe(
      map(action => addonAction(addonId, action)),
    );
  };

  const addonActionsEpic = (action$: Observable<Action>, state$: StateObservable<StateWithAddons>) => action$.pipe(
    filter(isWrappedAddonAction),
    map(action => action.payload),
    groupBy(payload => payload.addonId, payload => payload.action),
    mergeMap(addonActionGroup$ => iif(
      () => addonActionGroup$.key === '*',
      addonEpics$.pipe(
        groupBy(addon => addon.id),
        mergeMap(addonVersions => addonVersions.pipe(
          switchMap(addon => applyAddonEpic(addon.id, addon.epic, addonActionGroup$, state$)),
        )),
      ),
      addonEpics$.pipe(
        filter(addon => addon.id === addonActionGroup$.key),
        switchMap(addon => applyAddonEpic(addon.id, addon.epic, addonActionGroup$, state$)),
      ),
    )),
  );

  const eventsEpic = (action$: Observable<Action>, state$: StateObservable<StateWithAddons>) => action$.pipe(
    filter(action => action.type && action.type.startsWith(EVENT_ACTION_PREFIX)),
    eventActions$ => addonEpics$.pipe(
      groupBy(addon => addon.id),
      mergeMap(addonVersions => addonVersions.pipe(
        switchMap(addon => applyAddonEpic(addon.id, addon.epic, eventActions$, state$)),
      )),
    ),
  );

  const loadEpic = (action$: Observable<AddonActions>, _state$: unknown, { logger }: { logger: Logger }) => action$.pipe(
    ofType(ADDON_REQUESTED),
    map(action => action.payload),
    groupBy(payload => payload.id),
    // parallel pipelines for add-ons with different identifiers
    mergeMap(idGroup$ => idGroup$.pipe(
      groupBy(payload => payload.hash || ''),
      // switch to pipeline with latest add-on hash value
      switchMap(hashGroup$ => hashGroup$.pipe(
        // do not (re)start addon loading while already in-progress (with the same hash)
        exhaustMap(({ id, hash }) => addonLoader(id, hash).pipe(
          retry(3),
          map(factory => addonCreator(id, factory)),
          map(addon => addonLoaded(id, hash, addon)),
          catchError(e => {
            logger.error(e);
            return of(addonLoadFailed(id, hash, e));
          }),
        )),
      )),
    )),
  );

  return combineEpics(
    loadEpic,
    addonActionsEpic,
    eventsEpic,
  );
}