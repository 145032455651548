import { OrderAuthorizationAction } from './constants';

export const DOCUMENT_RECEIVED = 'DOCUMENT_RECEIVED';
export const documentReceived = (document, documentType, appendData = false) => ({
  type: DOCUMENT_RECEIVED,
  payload: { document, documentType, appendData },
});

export const DOCUMENT_LINES_REQUESTED = 'DOCUMENT_LINES_REQUESTED';
export const loadLines = (id, documentType, orderId) => ({
  type: DOCUMENT_LINES_REQUESTED,
  payload: { id, documentType, orderId },
});

export const DOCUMENT_REORDER_REQUESTED = 'DOCUMENT_REORDER_REQUESTED';
export const reorder = (id, orderId, forceBasketClean) => ({
  type: DOCUMENT_REORDER_REQUESTED,
  payload: { id, orderId, forceBasketClean },
});

export const DOCUMENT_REORDER_RECEIVED = 'DOCUMENT_REORDER_RECEIVED';
export const reorderReceived = result => ({
  type: DOCUMENT_REORDER_RECEIVED,
  payload: { result },
});

export const QUOTE_STOCK_REQUESTED = 'QUOTE_STOCK_REQUESTED';
export const loadQuoteStock = id => ({
  type: QUOTE_STOCK_REQUESTED,
  payload: { id },
});

export const QUOTE_STOCK_RECEIVED = 'QUOTE_STOCK_RECEIVED';
export const quoteStockReceived = quote => ({
  type: QUOTE_STOCK_RECEIVED,
  payload: { quote },
});

export const QUOTE_PROMOTION_REQUESTED = 'QUOTE_PROMOTION_REQUESTED';
export const promoteQuote = (id, ignoreCreditLimitWarnings) => ({
  type: QUOTE_PROMOTION_REQUESTED,
  payload: { id, ignoreCreditLimitWarnings },
});

export const QUOTE_PROMOTION_RESULT_RECEIVED = 'QUOTE_PROMOTION_RESULT_RECEIVED';
export const quotePromotionResultReceived = result => ({
  type: QUOTE_PROMOTION_RESULT_RECEIVED,
  payload: result,
});

export const DOCUMENT_EDIT_REQUESTED = 'DOCUMENT_EDIT_REQUESTED';
export const edit = (id, forceBasketClean = false) => ({
  type: DOCUMENT_EDIT_REQUESTED,
  payload: { id, forceBasketClean },
});

export const DOCUMENT_EDIT_RECEIVED = 'DOCUMENT_EDIT_RECEIVED';
export const editReceived = result => ({
  type: DOCUMENT_EDIT_RECEIVED,
  payload: { result },
});

export const ORDER_AUTHORIZATION_ACTION_REQUESTED = 'ORDER_AUTHORIZATION_ACTION_REQUESTED';
const authorizeOrder = (actionType, input) => ({
  type: ORDER_AUTHORIZATION_ACTION_REQUESTED,
  payload: { actionType, input },
});

export const approveOrder = orderId => authorizeOrder(OrderAuthorizationAction.Approve, { orderId });
export const declineOrder = (orderId, feedbackMessage) => authorizeOrder(OrderAuthorizationAction.Decline, {
  orderId,
  feedbackMessage,
});
export const cancelOrderAuthorization = orderId => authorizeOrder(OrderAuthorizationAction.Cancel, { orderId });

export const ORDER_AUTHORIZATION_RESULT_RECEIVED = 'ORDER_AUTHORIZATION_RESULT_RECEIVED';
export const orderAuthResultReceived = (authorizationActionResultStatus, authorizationInfo) => ({
  type: ORDER_AUTHORIZATION_RESULT_RECEIVED,
  payload: { authorizationActionResultStatus, authorizationInfo },
});
