import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OrderProp } from './OrderProp';
import { toLocaleDate } from 'utils/format';

const OrderDateProp = ({ name, date, culture, className = null }) => {
  const localeString = useMemo(() => {
    return date
      ? toLocaleDate(date, culture)
      : '';
  }, [date, culture]);

  return <OrderProp name={name} value={localeString} className={className} />;
};

OrderDateProp.propTypes = {
  name: PropTypes.string,
  date: PropTypes.string,
  culture: PropTypes.string,
  className: PropTypes.string,
};

const mapStateToProps = ({ localization }) => ({
  culture: localization.currentLanguage && localization.currentLanguage.cultureName,
});

export default connect(mapStateToProps)(OrderDateProp);
