import type { Transform } from 'react-html-parser';
import type { RouteData } from 'routes';
import { processNodes } from 'react-html-parser';
import { stylesToJSON } from 'utils/helpers';
import Link from 'components/primitives/links/base/Link';

const transformLink: Transform = (node, index, transform) => {
  if (node.type !== 'tag' || node.name !== 'a' || !node.attribs.hasOwnProperty('data-route'))
    return;

  const { 'data-route': data, href: url, style, ...attributes } = node.attribs;

  let to: RouteData | undefined = undefined;
  if (data) {
    const { routeName, routeValues: params, broken } = JSON.parse(data);

    if (!broken)
      to = { routeName, params };
  }

  return (
    <Link
      key={index}
      url={url}
      to={to}
      style={style ? stylesToJSON(style) : undefined}
      {...attributes}
    >
      {processNodes(node.children, transform)}
    </Link>
  );
};

export default transformLink;