import PropTypes from 'prop-types';
import { formatAsPrice } from 'utils/format';
import { EmptyPriceSymbol } from 'utils/product';

const FormattedPrice = ({ price, currencyInfo }) => {
  const { symbol, id: currencyId } = currencyInfo;

  if (price == null)
    return EmptyPriceSymbol;

  return (
    <>
      <span aria-hidden suppressHydrationWarning>{formatAsPrice(price, currencyInfo)}</span>
      <i className="visually-hidden">
        {`${symbol || currencyId}${price}`}
      </i>
    </>
  );
};

FormattedPrice.propTypes = {
  price: PropTypes.number,
  currencyInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
  }).isRequired,
};

export default FormattedPrice;