import styles from '../SubAccount.module.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const FieldError = ({ children, ...attributes }) => (
  <tr className={styles.validatorWrapper} aria-live="polite">
    <td className={styles.placeholder} aria-hidden />
    <td colSpan="4" className={`${styles.limitItem} ${styles.error}`}>
      <span {...attributes}>
        <FontAwesomeIcon icon={faTimesCircle} aria-hidden />
        {children}
      </span>
    </td>
  </tr>
);

FieldError.propTypes = {
  children: PropTypes.string.isRequired,
};

export default FieldError;