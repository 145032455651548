import PropTypes from 'prop-types';
import TextRow from './TextRow';
import { UpdateContextProvider } from './updateContext';
import { connect } from 'react-redux';
import BasketUnavailable from './BasketUnavailable';

const PageTemplate = ({ headerTextKey, footerTextKey, className, children, isAvailable }) => (
  isAvailable === false
    ? <BasketUnavailable />
    : (
      <>
        <TextRow textKey={headerTextKey} />
        <div id="basketPage" className={className} x-ms-format-detection="none">
          <UpdateContextProvider>
            {children}
          </UpdateContextProvider>
        </div>
        <TextRow textKey={footerTextKey} />
      </>
    )
);

PageTemplate.propTypes = {
  headerTextKey: PropTypes.string,
  footerTextKey: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isAvailable: PropTypes.bool,
};

export default connect(s => ({ isAvailable: s.basket.model?.isAvailable }))(PageTemplate);
