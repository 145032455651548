import { DOCUMENTS_RECEIVED } from './actions';
import { createReducer } from 'utils/redux';

export default createReducer(null, {
  [DOCUMENTS_RECEIVED]: onDocumentsReceived,
});

function onDocumentsReceived(state, action) {
  const {
    documents,
    page,
  } = action.payload;

  if (!state.docs || page === 0)
    return {
      ...state,
      docs: documents,
    };

  const { items, totalCount } = documents;
  return {
    ...state,
    docs: {
      items: state.docs.items.concat(items),
      totalCount,
    },
  };
}