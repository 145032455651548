import styles from '../Orders.module.scss';
import { memo, useMemo, useState, useEffect } from 'react';
import { useEffectOnChange } from 'utils/hooks';
import PropTypes from 'prop-types';
import { DocumentType } from 'behavior/documents';
import { routesBuilder } from 'routes';
import Documents, { selectPropsFromPage } from '../Documents';
import InvoicesList from './InvoicesList';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import InvoicesPaymentContext from './InvoicesPaymentContext';
import { useSimpleTexts } from 'components/sanaText';
import { useSelector } from 'react-redux';
import { DangerAlert } from 'components/primitives/alerts';
import PayInvoicesButton from './PayInvoicesButton';

const textKeys = {
  introduction: 'InvoicesHistory_IntroductionText',
  listHeader: 'RecentInvoices',
  loadMore: 'ShowNextNOrders',
  noItems: 'Orders_NoOrderHistory',
};

const invoicesRoute = routesBuilder.forInvoices();

const Invoices = memo(props => {
  const [showPrices, canPayInvoices] = useHasAbilities(AbilityTo.ViewPrices, AbilityTo.PayInvoice);
  const texts = useTexts();

  const context = usePaymentContext(props.documents);

  const [showError, setShowError] = useState();
  const createInvoiceOrderResult = useSelector(({ page }) => page.createInvoiceOrderResult);

  useEffect(() => {
    if (createInvoiceOrderResult && createInvoiceOrderResult.error)
      setShowError(true);
  }, [createInvoiceOrderResult]);

  useEffect(() => {
    if (showError)
      setShowError(false);
  }, [context, props.documents]);

  const hasSelectedInvoices = !!context.selectedInvoices.length;
  const showPayButton = canPayInvoices && hasSelectedInvoices;

  const actions = showPayButton && <PayInvoicesButton texts={texts} showPrices={showPrices} />;
  const footer = showError && (
    <DangerAlert className={styles.invoicePayError} role="alert" scrollOnAppear>
      {texts.payInvoiceErrorText}
    </DangerAlert>
  );

  return (
    <InvoicesPaymentContext.Provider value={context}>
      <Documents
        loadMoreRoute={invoicesRoute}
        textKeys={textKeys}
        documentType={DocumentType.Invoice}
        actions={actions}
        footer={footer}
        {...props}
      >
        <InvoicesList
          documents={props.documents}
          showPrices={showPrices}
          canPayInvoices={canPayInvoices}
        />
      </Documents>
    </InvoicesPaymentContext.Provider>
  );
});

Invoices.selectPropsFromPage = selectPropsFromPage;

Invoices.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })),
};

export default Invoices;

function useTexts() {
  const [totalText, payInvoiceErrorText, payButtonText] = useSimpleTexts([
    'Total',
    'YouCannotPayInvoicesNow',
    'ButtonText_PayInvoice',
  ]).texts;

  return useMemo(() => ({
    payButtonText,
    totalText,
    payInvoiceErrorText,
  }), [
    payButtonText,
    totalText,
    payInvoiceErrorText,
  ]);
}

function usePaymentContext(documents) {
  const [contextState, setContextState] = useState(initContextState);

  const context = useMemo(() => {
    const { selectedInvoices, currency } = contextState;

    return {
      selectedInvoices,
      currency,
      toggleInvoice: invoice => {
        const index = selectedInvoices.indexOf(invoice);
        if (index !== -1)
          selectedInvoices.splice(index, 1);
        else
          selectedInvoices.push(invoice);

        let updatedCurrency = currency;
        if (!selectedInvoices.length)
          updatedCurrency = null;
        else if (selectedInvoices.length === 1)
          updatedCurrency = invoice.currency;

        setContextState({
          selectedInvoices,
          currency: updatedCurrency,
        });
      },
    };
  }, [contextState]);

  useEffectOnChange(() => {
    const { selectedInvoices } = contextState;
    if (!selectedInvoices.length)
      return;

    // Documents from next page were added to collection.
    if (documents.includes(selectedInvoices[0]))
      return;

    const updatedInvoices = [];
    for (const invoice of selectedInvoices) {
      const document = documents.find(document => invoice.id === document.id);
      if (document)
        updatedInvoices.push(document);
    }

    setContextState({
      selectedInvoices: updatedInvoices,
      currency: updatedInvoices.length ? updatedInvoices[0].currency : null,
    });
  }, [documents]);

  return context;
}

function initContextState() {
  return {
    currency: null,
    selectedInvoices: [],
  };
}