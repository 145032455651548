import styles from './Alert.module.scss';
import { ErrorIcon } from 'components/primitives/icons';
import Alert, { alertPropTypes } from './Alert';

const DangerAlert = (
  {
    children,
    className = '',
    scrollOnAppear,
    role,
    ...props
  }) => (
  <Alert
    className={`${styles.danger} ${className}`}
    icon={ErrorIcon}
    scrollOnAppear={scrollOnAppear}
    role={role}
    {...props}
  >
    {children}
  </Alert>
);

DangerAlert.propTypes = alertPropTypes;

export default DangerAlert;