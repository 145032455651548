import styles from '../Checkout.module.scss';
import { memo, useRef, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  ExtraPaymentStep as ExtraPaymentStepBase,
  ExtraPaymentStepBody,
} from '../base/payment';
import { useDispatch } from 'react-redux';
import { saveExtraPaymentData } from 'behavior/pages/checkout';
import CheckoutContext from './CheckoutContext';
import { Steps } from 'behavior/pages/checkout';

const ExtraPaymentStep = ({ extraPaymentStep, isCompleted }) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const onBeforeSubmitRef = useRef();

  const {
    setLoading,
    registerStep,
    unregisterStep,
    setStepCompletion,
  } = useContext(CheckoutContext);

  useEffect(() => {
    const validateStep = async () => {
      if (formRef.current)
        return await formRef.current.validate();

      return true;
    };

    const onBeforeSubmit = async () => {
      if (onBeforeSubmitRef.current)
        onBeforeSubmitRef.current();
    };

    registerStep(
      Steps.ExtraPayment,
      styles.extraPayment,
      [Steps.Address, Steps.Payment, Steps.CustomerData, Steps.ExtraPayment],
      validateStep,
      onBeforeSubmit,
    );

    return () => unregisterStep(Steps.ExtraPayment);
  }, []);

  useEffect(() => {
    if (extraPaymentStep.isDataValid) {
      setStepCompletion(Steps.ExtraPayment, true);
      return;
    }

    if (extraPaymentStep.errors && formRef.current) {
      setStepCompletion(Steps.ExtraPayment, false);
      formRef.current.errors = extraPaymentStep.errors;
    }
  }, [extraPaymentStep]);

  const onStepSubmit = useCallback(async () => {
    if (!formRef.current) {
      setLoading(Steps.ExtraPayment);
      dispatch(saveExtraPaymentData());
      return;
    }

    if (!(await formRef.current.validate())) {
      setStepCompletion(Steps.ExtraPayment, false);
      return;
    }

    setLoading(Steps.ExtraPayment);
    dispatch(saveExtraPaymentData(formRef.current.values));
  }, []);

  return (
    <ExtraPaymentStepBase
      headerText={extraPaymentStep.title}
      headerTextKey={extraPaymentStep.titleKey}
      className={styles.extraPayment}
      isCompleted={isCompleted}
    >
      <ExtraPaymentStepBody
        extraPaymentStep={extraPaymentStep}
        formRef={formRef}
        onFormSubmit={onStepSubmit}
        onBeforeSubmitRef={onBeforeSubmitRef}
        setLoading={setLoading}
        submitFormOnBlur
      />
    </ExtraPaymentStepBase>
  );
};

ExtraPaymentStep.propTypes = {
  extraPaymentStep: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleKey: PropTypes.string.isRequired,
    isDataValid: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.shape({
      fieldName: PropTypes.string,
      message: PropTypes.string.isRequired,
    })),
  }).isRequired,
  isCompleted: PropTypes.bool,
};

export default memo(ExtraPaymentStep);
