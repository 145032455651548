import { useMemo } from 'react';
import { DateSelect } from 'components/primitives/form';
import createDateField from './createDateField';

const Primitive = props => {
  const maxDate = useMemo(() => {
    if (props.maxDate)
      return props.maxDate;

    const currentYear = new Date().getFullYear();
    return new Date(currentYear, 11, 31);
  }, [props.maxDate]);

  return <DateSelect {...props} maxDate={maxDate} />;
};

const DateDropdownField = createDateField(Primitive);
export default DateDropdownField;
