import { createReducer } from 'utils/redux';
import {
  PRODUCT_COMPARISON_INITIALIZE,
  PRODUCT_COMPARISON_ADD_PRODUCT,
  PRODUCT_COMPARISON_REMOVE_PRODUCT,
  PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS,
} from './actions';

export default createReducer(null, {
  [PRODUCT_COMPARISON_INITIALIZE]: onInitialize,
  [PRODUCT_COMPARISON_ADD_PRODUCT]: onProductAdded,
  [PRODUCT_COMPARISON_REMOVE_PRODUCT]: onProductRemoved,
  [PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS]: onAllProductsRemoved,
});

function onInitialize(state, action) {
  const { productsToCompare, widgetCollapsed } = action.payload;

  return {
    ...state,
    products: productsToCompare,
    widgetCollapsed,
  };
}

function onProductAdded(state, action) {
  const product = action.payload;

  const index = state.products.findIndex(item => item.id === product.id);
  if (index === -1) {
    return {
      ...state,
      products: [...state.products, product],
    };
  }

  const products = [...state.products];
  products[index] = action.payload;
  return {
    ...state,
    products,
  };
}

function onProductRemoved(state, action) {
  const { products } = state;
  const { id } = action.payload;

  return {
    ...state,
    products: products.filter(item => item.id !== id),
  };
}

function onAllProductsRemoved(state) {
  return {
    ...state,
    products: [],
  };
}
