const url = require('url');

export const isValidConfiguratorWindowMessage = (message, configuratorState) => {
  if (!configuratorState.productConfigurator)
    return false;
  
  const configuratorOrigin = getUrlOrigin(configuratorState.productConfigurator.configuratorUrl);
  return message.origin === configuratorOrigin;
};

const getUrlOrigin = configuratorUrl => {
  if (!configuratorUrl)
    return '';

  const parsedAbsoluteUrl = url.parse(configuratorUrl);
  return parsedAbsoluteUrl.protocol + '//' + parsedAbsoluteUrl.host;
};

export const convertToString = obj => {
  return (typeof obj === 'string' || obj instanceof String) ? obj : JSON.stringify(obj);
};