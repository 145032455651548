import styles from '../Order.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import { useDispatch } from 'react-redux';
import { approveOrder, declineOrder } from 'behavior/pages/document';
import { useModal } from 'components/objects/modals';
import DeclineAuthorizationModal from './DeclineAuthorizationModal';

const ActionButtons = ({ orderId }) => {
  const dispatch = useDispatch();

  const handleApprove = () => dispatch(approveOrder(orderId));
  const handleDecline = ({ feedbackMessage }) => void (dispatch(declineOrder(orderId, feedbackMessage), hideDeclineModal()));

  const {
    opened: declineModalOpened,
    show: showDeclineModal,
    hide: hideDeclineModal,
  } = useModal();

  return (
    <div className={styles.actionBar}>
      <div className={styles.actions}>
        <SanaButton
          textKey="Decline"
          className={`${btnStyles.btnSmall} ${styles.btnAction} ${styles.btnDecline}`}
          onClick={showDeclineModal}
        />
        <DeclineAuthorizationModal opened={declineModalOpened} handleDecline={handleDecline} hide={hideDeclineModal} />
        <SanaButton
          textKey="Approve"
          className={`${btnStyles.btnAction} ${btnStyles.btnSmall} ${styles.btnAction} ${styles.btnApprove}`}
          onClick={handleApprove}
        />
      </div>
    </div>
  );
};

ActionButtons.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default memo(ActionButtons);
