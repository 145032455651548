import { BASKET_PAGE_REQUESTED, BASKET_RECEIVED } from 'behavior/basket';

export default function (state, action) {
  switch (action.type) {
    case BASKET_PAGE_REQUESTED:
      return { ...state, linesUpdating: true };
    case BASKET_RECEIVED:
      return { 
        ...state,
        activeBulkOrder: action.payload.activeBulkOrder,
        linesUpdating: false,
      };
    default:
      return state;
  }
}