import styles from '../SubAccount.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { UseSanaTexts } from 'components/sanaText';
import { makeRichText } from 'utils/render';
import { useRoutes } from 'components/primitives/route';
import { editRoute, routeIdReplacement } from '../constants';
import { routesBuilder } from 'routes';
import { Link } from 'components/primitives/links';
import { DangerAlert } from 'components/primitives/alerts';

export const ERROR_ID = 'subAccountFormError';

const GeneralErrorMessage = ({ account }) => {
  const { general: generalError } = useFormikContext().errors;
  const [editPath] = useRoutes([editRoute]);

  const result = useMemo(() => {
    if (!generalError)
      return null;

    return (
      <DangerAlert role="alert" className={styles.generalError} id={ERROR_ID} scrollOnAppear>
        <UseSanaTexts options={['SubAccounts_CantRemoveAuthorizerRole_DependenciesFound', 'ButtonAltText_Edit']}>
          {([headerText, editAltText]) => (
            <>
              {makeRichText(headerText, [account.dependentAccounts.length])}
              <ul>
                {account.dependentAccounts.map((account, index) => (
                  <li key={index}>
                    <Link
                      url={editPath ? editPath.replace(routeIdReplacement, account.id) : ''}
                      to={routesBuilder.forSubAccount.bind(null, account.id)}
                      title={editAltText}
                      className={styles.link}
                    >
                      {account.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
        </UseSanaTexts>
      </DangerAlert>
    );
  }, [generalError, editPath]);

  return result;
};

GeneralErrorMessage.propTypes = {
  account: PropTypes.shape({
    dependentAccounts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }),
};

export default memo(GeneralErrorMessage);
