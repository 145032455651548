import styles from '../Checkout.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'components/primitives/grid';
import { CheckoutProvider } from './CheckoutProvider';
import CheckoutSteps from './CheckoutSteps';
import { useIsMobileViewport } from 'utils/hooks';
import Summary from './Summary';
import IncompleteStepsMessage from '../base/IncompleteStepsMessage';
import { AvailabilityDatesWarningMessage } from 'components/objects/availabilityDatesWarningMessage';

const MultiStepCheckout = ({ info }) => {
  const isMobile = useIsMobileViewport();

  return (
    <CheckoutProvider info={info}>
      <IncompleteStepsMessage incompleteSteps={info.incompleteSteps} />
      <AvailabilityDatesWarningMessage />
      <Row>
        <Col xs="12" md="7" lg="8" xl="9" className={`${styles.steps} ${styles.multiStep}`}>
          <CheckoutSteps info={info} />
        </Col>
        {!isMobile &&
          <Col md="5" lg="4" xl="3" className={`${styles.rightPanel} ${styles.noMethods}`}>
            <Summary info={info} />
          </Col>
        }
      </Row>
    </CheckoutProvider>
  );
};

MultiStepCheckout.propTypes = {
  info: PropTypes.object.isRequired,
};

export default memo(MultiStepCheckout);
