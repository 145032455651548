import type { GraphApiResponse } from './types';
import { Subject, throwError, pipe } from 'rxjs';
import { share, tap, distinctUntilChanged, catchError } from 'rxjs/operators';

export function createExtensionsTracker() {
  const subject = new Subject<any>();

  return {
    extensions$: subject.pipe(
      distinctUntilChanged(),
      share(),
    ),
    trackExtensions: pipe(
      tap(({ extensions }: GraphApiResponse) => {
        subject.next(extensions);
      }),
      catchError(e => {
        if (e.response)
          subject.next(e.response.extensions);

        return throwError(e);
      }),
    ),
  };
}
