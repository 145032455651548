import { memo } from 'react';
import PropTypes from 'prop-types';
import { PagingTypes } from 'behavior/pages/productList';
import LinksPaging from './LinksPaging';
import LoadMoreButton from './LoadMoreButton';
import ContinuousScroll from './ContinuousScroll';

const Paging = ({ totalCount, pagingType, ...props }) => {
  if (pagingType === PagingTypes.Links)
    return <LinksPaging totalCount={totalCount} {...props} />;

  if (pagingType === PagingTypes.LoadMore)
    return <LoadMoreButton totalCount={totalCount} />;

  if (pagingType === PagingTypes.ContinuousScrolling)
    return <ContinuousScroll totalCount={totalCount} />;

  return null;
};

Paging.propTypes = {
  totalCount: PropTypes.number,
  pagingType: PropTypes.string,
};

export default memo(Paging);