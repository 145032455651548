import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { isDocumentCancelled } from 'behavior/documents';

const ShippingStatus = ({ document }) => {
  if (isDocumentCancelled(document))
    return <SimpleText textKey="OrderStatus_Cancelled" />;

  const shippingStatus = document.shipping.status;
  if (!shippingStatus)
    return null;

  return <SimpleText textKey={`ShippingStatus_${shippingStatus}`}>{shippingStatus}</SimpleText>;
};

ShippingStatus.propTypes = {
  document: PropTypes.shape({
    shipping: PropTypes.shape({
      status: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ShippingStatus;
