import { createElement } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';

const ArrayField = ({ fieldName, children }) =>
  createElement(FieldArray, { name: fieldName }, ({ push, remove }) => children({ push, remove, createFieldName }));

ArrayField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};

export default ArrayField;

function createFieldName(arrayFieldName, index, nestedFieldName) {
  return `${arrayFieldName}.${index}.${nestedFieldName}`;
}
