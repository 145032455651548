import styles from './Faq.module.scss';
import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { renderHTML } from 'utils/render';
import { VerticalSliding } from 'components/primitives/transitions';
import { ShowHideButton } from 'components/primitives/buttons';

const FaqPageItem = ({ item, level }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpand = () => setExpanded(!expanded);
  const hasAnswer = !!item.description;
  const headerId = item.id + 'Lbl';
  const bodyId = item.id + 'Body';

  const hasChildren = !!(item.children && item.children.length);

  const HeadingTag = level < 5
    ? 'h' + (2 + level)
    : 'h6';

  level++;
  return (
    <div
      className={styles.item}
      role="listitem"
      itemScope={hasAnswer || null}
      itemType={hasAnswer ? 'http://schema.org/Question' : null}
      aria-labelledby={headerId}
    >
      <div
        className={styles.header}
        onClick={toggleExpand}
        role="presentation"
      >
        <ShowHideButton show={expanded} className={`${styles.btnShowHide} ${styles.headerItem}`} aria-controls={bodyId} />
        <HeadingTag itemProp="text" className={`h4 ${styles.title} ${styles.headerItem}`} id={headerId}>{item.title}</HeadingTag>
      </div>
      <VerticalSliding expanded={expanded}>
        <div className={styles.body}
          id={bodyId}
          role={hasChildren ? 'list' : 'presentation'}
          itemProp={hasAnswer ? 'acceptedAnswer' : null}
          itemScope={hasAnswer || null}
          itemType={hasAnswer ? 'http://schema.org/Answer' : null}
        >
          <div itemProp="text" className={`${styles.description} fr-view`}>{hasAnswer && renderHTML(item.description)}</div>
          {hasChildren && item.children.map((item, index) => <FaqPageItem key={index} item={item} level={level} />)}
        </div>
      </VerticalSliding>
    </div>
  );
};

FaqPageItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    children: PropTypes.array,
  }),
  level: PropTypes.number.isRequired,
};

export default memo(FaqPageItem);