import { of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { redirectTo } from 'behavior/routing';
import { destroyApp } from 'behavior/app';

export default ({ params }, _state$, { scope }) => of(params).pipe(
  filter(params => params && params.url),
  map(({ url: redirectUrl, routeName, params, permanent }) => {
    const internalRedirect = !!routeName;

    if (!internalRedirect && scope === 'CLIENT') {
      window.location = redirectUrl;

      return {
        redirectUrl,
        action$: of(destroyApp()),
      };
    }

    const statusCode = permanent ? 301 : 302;
    return {
      redirectUrl,
      statusCode,
      action$: of(redirectTo(redirectUrl, statusCode, { routeName, params })),
    };
  }),
);
