import { ofType } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
import { retryWithToast } from 'behavior/errorHandling';
import {
  CHECKOUT_LINES_REQUESTED,
  checkoutInfoUpdated,
} from './actions';
import { basketLoadQuery } from './queries';
import { navigateOnIncorrect } from './helpers';

export default function createEpic() {
  return function (action$, state$, { api, logger }) {
    return action$.pipe(
      ofType(CHECKOUT_LINES_REQUESTED),
      switchMap(() => api.graphApi(basketLoadQuery).pipe(
        map(({ checkout }) => {
          if (!checkout)
            return navigateOnIncorrect(state$.value.page.info);

          return checkoutInfoUpdated(checkout);
        }),
        retryWithToast(action$, logger),
      )),
    );
  };
}
