import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const SelfPostingForm = ({ formOptions }) => {
  const ref = useRef();

  useEffect(() => {
    ref.current.submit();
  }, [formOptions]);

  return (
    <form action={formOptions.url} method="POST" ref={ref}>
      {formOptions.values.map(entry => <input type="hidden" name={entry.key} value={entry.value} key={entry.key} />)}
    </form>
  );
};

SelfPostingForm.propTypes = {
  formOptions: PropTypes.shape({
    url: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string,
    }).isRequired).isRequired,
  }).isRequired,
};

export default SelfPostingForm;
