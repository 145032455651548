export const SUBMIT_INVOICE_ORDER = 'INVOICE_PAYMENT/SUBMIT_INVOICE_ORDER';
export const submitInvoiceOrder = (orderId, paymentInput, additionalCustomerData, extraPaymentData) => ({
  type: SUBMIT_INVOICE_ORDER,
  payload: { orderId, paymentInput, additionalCustomerData, extraPaymentData },
});

export const SUBMIT_INVOICE_ORDER_RESULT_RECEIVED = 'INVOICE_PAYMENT/SUBMIT_INVOICE_ORDER_RESULT_RECEIVED';
export const submitInvoiceOrderResultReceived = result => ({
  type: SUBMIT_INVOICE_ORDER_RESULT_RECEIVED,
  payload: { result },
});

export const CREATE_INVOICE_ORDER = 'INVOICE_PAYMENT/CREATE_INVOICE_ORDER';
export const createInvoiceOrder = invoices => ({
  type: CREATE_INVOICE_ORDER,
  payload: { invoices },
});

export const CREATE_INVOICE_ORDER_RESULT_RECEIVED = 'INVOICE_PAYMENT/CREATE_INVOICE_ORDER_RESULT_RECEIVED';
export const createInvoiceOrderResultReceived = result => ({
  type: CREATE_INVOICE_ORDER_RESULT_RECEIVED,
  payload: { result },
});

export const PAYMENT_METHOD_EXTRA_DATA_REQUESTED = 'INVOICE_PAYMENT/PAYMENT_METHOD_EXTRA_DATA/REQUESTED';
export const requestPaymentMethodExtraData = (orderId, paymentMethodId) => ({
  type: PAYMENT_METHOD_EXTRA_DATA_REQUESTED,
  payload: { orderId, paymentMethodId },
});

export const PAYMENT_METHOD_EXTRA_DATA_RECEIVED = 'INVOICE_PAYMENT/ADDITIONAL_CUSTOMER_DATA/RECEIVED';
export const receivePaymentMethodExtraData = (paymentMethodId, additionalCustomerData, extraPaymentData) => ({
  type: PAYMENT_METHOD_EXTRA_DATA_RECEIVED,
  payload: { paymentMethodId, additionalCustomerData, extraPaymentData },
});