import { isValidElement, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { encodeIndexIntoText } from './utils';
import { useVisualDesignerTexts } from 'components/visualDesigner/texts';
import { sanaTextRequested, setTextOnlyMode } from 'behavior/pages/visualDesigner/actions';

export default useSanaTexts => (options, process) => {
  const result = useSanaTexts(options, process);

  const sanaTextsMapping = useSelector(({ visualDesigner }) => visualDesigner.insiteEditor && visualDesigner.sanaTexts.mapping);
  if (!sanaTextsMapping)
    return result;

  return useProcessSanaTexts(sanaTextsMapping, options, result);
};

function useProcessSanaTexts(sanaTextsMapping, options, result) {
  const enabledProcessing = !options.disableInsiteEditor;

  const page = useSelector(s => enabledProcessing && s.page);
  const T = useVisualDesignerTexts();
  const dispatch = useDispatch();

  const requestedTextKeys = options.map(option => option && (typeof option === 'string' ? option : option.key));
  useEffect(() => {
    if (!options.textOnly)
      return;

    const nonEmptyTexts = requestedTextKeys.filter(Boolean);
    if (nonEmptyTexts.length > 0)
      dispatch(setTextOnlyMode(nonEmptyTexts));
  }, [requestedTextKeys.length]);

  useEffect(() => {
    const nonEmptyTexts = requestedTextKeys.filter(Boolean);
    dispatch(sanaTextRequested(nonEmptyTexts));
  }, [page, requestedTextKeys.length]);

  if (!enabledProcessing || !result.loaded)
    return result;

  const processedResult = options.insiteEditorPostLoad ? options.insiteEditorPostLoad(result) : result;
  const processedTexts = processedResult.texts.map((value, index) => {
    if (isValidElement(value))
      return value;

    if (!options.skipReplacingEmptyText && !value)
      value = T.VisualDesigner_InsiteEditor_EmptySanaText || '';

    const sanaTextKey = requestedTextKeys[index];
    const sanaTextIndex = parseInt(getKeyByValue(sanaTextsMapping, sanaTextKey));

    return encodeIndexIntoText(value, sanaTextIndex);
  });

  return { ...processedResult, texts: processedTexts };
}

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key].textKey === value);
}
