import { createReducer } from 'utils/redux';
import {
  PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED,
  PRODUCT_SELECTOR_PRODUCT_RECEIVED,
  PRODUCT_SELECTOR_PRODUCT_CLEARED,
} from './actions';

export const initialState = {
  suggestions: [],
  selectedProduct: null,
};

export default createReducer(initialState, {
  [PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED]: onSearchSuggestionsReceived,
  [PRODUCT_SELECTOR_PRODUCT_RECEIVED]: onProductReceived,
  [PRODUCT_SELECTOR_PRODUCT_CLEARED]: onProductCleared,
});

function onSearchSuggestionsReceived(state, action) {
  return { ...state, suggestions: action.payload.suggestions };
}

function onProductReceived(state, action) {
  return { ...state, selectedProduct: action.payload.product };
}

function onProductCleared(state) {
  return { ...state, selectedProduct: null };
}