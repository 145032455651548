import { memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { renderHTML, makeSimpleText } from 'utils/render';
import { getNodeText } from './helpers';

const DomHead = ({ content }) => {
  let title = '';
  const scripts = [];
  const meta = [];
  const links = [];
  const styles = [];

  const transformer = node => {
    const { name } = node;

    switch (name) {
      case 'script':
        scripts.push({
          text: getNodeText(node),
          src: node.attribs.src,
          attributes: node.attribs,
        });
        return;

      case 'meta':
        meta.push(node.attribs);
        return;

      case 'title':
        title = getNodeText(node);
        return;

      case 'link':
        links.push(node.attribs);
        return;

      case 'style':
        styles.push({
          text: getNodeText(node),
          attributes: node.attribs,
        });
        return;

      default:
        return;
    }
  };

  renderHTML(content, transformer);

  return (
    <Helmet>
      {title && <title>{makeSimpleText(title)}</title>}
      {meta.map((attributes, index) => <meta key={`m${index}`} {...attributes} />)}
      {links.map((attributes, index) => <link key={`l${index}`} {...attributes} />)}
      {styles.map(({ attributes, text }, index) => <style key={`st${index}`} {...attributes}>{text}</style>)}
      {scripts.map(({ src, text, attributes }, index) => (src
        ? <script src={src} key={`s${index}`} {...attributes} />
        : <script key={`s${index}`} {...attributes}>{text}</script>
      ))}
    </Helmet>
  );
};

DomHead.propTypes = {
  content: PropTypes.string.isRequired,
};

export default memo(DomHead);