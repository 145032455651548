export function facetUrlBuilder(options) {
  const { encode, encodeTitle, pathname, search, allFacets } = options;
  const { pathBase, fieldIndexes, selectedFacets } = init();

  return {
    getSelectedFacets() {
      return selectedFacets;
    },
    addToUrl(field, value) {
      let segments = '';

      let fieldIndex = fieldIndexes.get(field.name);
      if (fieldIndex == null)
        fieldIndex = 1000;

      let valueAdded = false;
      for (const facet of selectedFacets) {
        const facetIndex = fieldIndexes.get(facet.name);
        if (facetIndex <= fieldIndex) {
          segments += '/' + facet.segment;

          if (facetIndex === fieldIndex) {
            segments += ',' + encode(value.textTitle || value.title);
            valueAdded = true;
          }

          continue;
        }

        if (!valueAdded) {
          segments += '/' + encodeTitle(field.title) + '=' + encode(value.textTitle || value.title);
          valueAdded = true;
        }

        segments += '/' + facet.segment;
      }

      if (!valueAdded)
        segments += '/' + encodeTitle(field.title) + '=' + encode(value.textTitle || value.title);
      segments = segments.substring(1);

      return pathBase.concat(segments, '/', search);
    },
    removeFromUrl(field, value) {
      let segments = '';

      for (const facet of selectedFacets) {
        if (facet.name !== field.name) {
          segments += '/' + facet.segment;
          continue;
        }
        let titles = '';
        for (const [facetValue, encodedTitle] of facet.values) {
          if (facetValue !== value.value)
            titles += encodedTitle + ',';
        }
        if (titles) {
          segments = segments.concat('/', facet.encodedTitle, '=', titles.slice(0, -1));
        }
      }
      if (segments)
        segments = segments.substring(1);

      return segments
        ? pathBase.concat(segments, '/', search)
        : pathBase + search;
    },
    removeAllFromUrl() {
      return pathBase + search;
    },
  };

  function init() {
    const selectedFacets = [];
    let pathBase = pathname;
    const fieldIndexes = new Map();
    let i = 0;

    for (const facet of allFacets) {
      if (facet.anySelected) {
        const record = {
          name: facet.name,
          segment: null,
          encodedTitle: encodeTitle(facet.title),
          values: new Map(),
        };
        for (const value of facet.values) {
          if (value.selected) {
            record.values.set(value.value, encode(value.textTitle || value.title));
          }
        }
        selectedFacets.push(record);
        pathBase = pathBase.replace(new RegExp(`\\/${record.encodedTitle}=[^\\/]*(?:\\/?|$)`, ['i']), onMatch.bind(null, record));
      }

      fieldIndexes.set(facet.name, i);
      i++;
    }

    return { pathBase, fieldIndexes, selectedFacets };
  }
}

function onMatch(record, match) {
  const trimEnd = match.endsWith('/');
  record.segment = match.slice(1, trimEnd ? - 1 : undefined);
  return '/';
}