import { map, exhaustMap, takeUntil } from 'rxjs/operators';
import { CHILD_MENU_ITEMS_REQUESTED, childMenuItemsLoaded } from './actions';
import { childMenuItemsQuery } from './queries';
import { LOCATION_CHANGED } from 'behavior/events';
import { handleError } from 'utils/rxjs';

export default (action$, state$, { api }) => {
  const locationChanged$ = action$.ofType(LOCATION_CHANGED);

  return action$.ofType(CHILD_MENU_ITEMS_REQUESTED).pipe(
    map(_ => state$.value.routing.location.pathname),
    exhaustMap(url => api.graphApi(childMenuItemsQuery, { url }).pipe(
      map(({ navigation: { findByUrl: node } }) => childMenuItemsLoaded(node && node.children)),
      handleError(undefined, 'childMenuItems'),
      takeUntil(locationChanged$),
    )),
  );
};