import PropTypes from 'prop-types';
import PlaceholderWithoutTracking from 'react-lazy-load-image-component/src/components/PlaceholderWithoutTracking';

class PlaceholderWithoutTrackingWrapper extends PlaceholderWithoutTracking {
  constructor(props) {
    super(props);

    const supportsObserver = !props.scrollPosition && props.useIntersectionObserver;

    this.LAZY_LOAD_OBSERVER = { supportsObserver };

    if (supportsObserver) {
      this.LAZY_LOAD_OBSERVER.observer = new IntersectionObserver(
        this.checkIntersections,
        { rootMargin: props.threshold + 'px' },
      );
    }
  }

  // This lifecycle method has incorrect name componentWillUnMount in the original library
  // and so it does not work there
  componentWillUnmount() {
    if (this.LAZY_LOAD_OBSERVER && this.LAZY_LOAD_OBSERVER.observer)
      this.LAZY_LOAD_OBSERVER.observer.unobserve(this.placeholder);
  }
}

PlaceholderWithoutTrackingWrapper.propTypes = {
  ...PlaceholderWithoutTracking.propTypes,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

PlaceholderWithoutTrackingWrapper.defaultProps = {
  className: '',
  height: 0,
  placeholder: null,
  threshold: 100,
  width: 0,
};

export default PlaceholderWithoutTrackingWrapper;