import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigateTo } from 'behavior/events';
import { getViewModeProductAmount } from 'behavior/pages/volumeOrderList';
import { useLoadMoreUrlBuilder } from 'components/objects/volumeOrderList/hooks';
import { useOnChange } from 'utils/hooks';
import { getNextPageInfo } from 'behavior/paging';
import { default as ContinuousScrollPrimitive } from 'components/primitives/continuousScroll/ContinuousScroll';

const ContinuousScroll = ({ pageSize, queryStringCount, loadedProductsCount, totalCount, navigateTo }) => {
  const productsLoading = useRef(false);
  const [isVisibleInViewport, setVisibleInViewport] = useState(false);
  const productsCount = queryStringCount || loadedProductsCount;

  useOnChange(() => productsLoading.current = false, [loadedProductsCount], true);

  const {
    immediateLoad,
    pageToLoad,
    nextPageCount,
  } = getNextPageInfo(productsCount, pageSize, totalCount);

  const { url, to } = useLoadMoreUrlBuilder(nextPageCount, pageToLoad, pageSize);
  const allProductsLoaded = productsCount >= totalCount;

  useEffect(() => {
    if (!immediateLoad)
      return;

    productsLoading.current = true;
    navigateTo(to, url, { omitScroll: true, replaceHistory: true });
  }, [immediateLoad]);

  useOnChange(() => {
    if (!isVisibleInViewport || productsLoading.current || allProductsLoaded)
      return;

    productsLoading.current = true;
    setVisibleInViewport(false);
    navigateTo(to, url, { omitScroll: true, replaceHistory: true });
  }, [loadedProductsCount, productsLoading.current, isVisibleInViewport]);

  if (immediateLoad || allProductsLoaded)
    return null;

  return (
    <ContinuousScrollPrimitive
      onVisibleUpdated={setVisibleInViewport}
    />
  );
};

ContinuousScroll.propTypes = {
  pageSize: PropTypes.number,
  queryStringCount: PropTypes.number,
  loadedProductsCount: PropTypes.number,
  totalCount: PropTypes.number,
  navigateTo: PropTypes.func.isRequired,
};

export default connect(({
  routing: { routeData: { params: { viewMode, count: queryStringCount } } },
  settings: { productList },
  page: { products, selectedViewMode, defaultViewMode },
}) => ({
  pageSize: getViewModeProductAmount(viewMode || selectedViewMode || defaultViewMode, productList),
  queryStringCount,
  loadedProductsCount: products && products.length,
}), { navigateTo })(ContinuousScroll);