export const ORDER_AUTHORIZATIONS_REQUESTED = 'ORDER_AUTHORIZATIONS_REQUESTED';
export const requestAuthorizations = options => ({
  type: ORDER_AUTHORIZATIONS_REQUESTED,
  payload: { options },
});

export const ORDER_AUTHORIZATIONS_RECEIVED = 'ORDER_AUTHORIZATIONS_RECEIVED';
export const authorizationsReceived = (orderAuthorizations, pageIndex) => ({
  type: ORDER_AUTHORIZATIONS_RECEIVED,
  payload: { orderAuthorizations, pageIndex },
});

export const AUTHORIZATIONS_PENDING_COUNT_REQUESTED = 'AUTHORIZATIONS_PENDING_COUNT_REQUESTED';
export const requestPendingAuthorizationsCount = () => ({
  type: AUTHORIZATIONS_PENDING_COUNT_REQUESTED,
});

export const AUTHORIZATIONS_PENDING_COUNT_RECEIVED = 'AUTHORIZATIONS_PENDING_COUNT_RECEIVED';
export const pendingAuthorizationsCountReceived = count => ({
  type: AUTHORIZATIONS_PENDING_COUNT_RECEIVED,
  payload: { count },
});
