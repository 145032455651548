import styles from '../Checkout.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useContext } from 'react';
import CheckoutContext from './CheckoutContext';
import { Link } from 'components/primitives/links';
import { UseSanaButton } from 'components/primitives/buttons';
import { Placeholder } from 'components/primitives/placeholders';

const BackStepLink = () => {
  const { getPrevStepNavigationInfo } = useContext(CheckoutContext);
  const navigationInfo = getPrevStepNavigationInfo();
  if (navigationInfo == null)
    return null;

  return (
    <UseSanaButton textKey="Back" placeholder={<Placeholder />}>
      {text => (
        <Link
          {...navigationInfo}
          className={`${linkStyles.link} ${linkStyles.arrowed} ${styles.back}`}
        >
          {text}
        </Link>
      )}
    </UseSanaButton>
  );
};

export default BackStepLink;
