import detailsStyles from 'components/objects/product/Details.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'components/primitives/grid';
import { useProductContext } from './hooks';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import { withAbilities } from 'components/objects/user';
import QuantityBox from './QuantityBox';
import OrderBoxForm from './OrderBoxForm';
import { SanaButton } from 'components/primitives/buttons';
import { Placeholder } from 'components/primitives/placeholders';

const OrderBox = ({ abilities: [orderProductsAbility], isSelected, isMaxEnforced, quantityRemaining }) => {
  const { product, calculatedInfo } = useProductContext();
  const [addToBasket, addToBasketWithApply] = ['Add', 'ApplyAgreementAndAddToBasket'];

  if (!product)
    return null;

  const { inventory, isOrderable } = calculatedInfo;

  const {
    stockLevels,
    isOrderable: isProductOrderable,
  } = product;

  const canOrderProducts = orderProductsAbility === AbilityState.Available;
  const btnPlaceholder = <Placeholder className={detailsStyles.btnPlaceholder} />;

  const shouldShowOrderBox = (inventory !== null && stockLevels != null)
    || (canOrderProducts && isOrderable && isProductOrderable)
    || (canOrderProducts && isOrderable === false);

  if (!shouldShowOrderBox)
    return null;

  return (
    <>
      <div className={detailsStyles.orderBox}>
        {canOrderProducts && (
          <OrderBoxForm isMaxEnforced={isMaxEnforced} quantityRemaining={quantityRemaining}>
            <QuantityBox disabled={!isProductOrderable} />
            {isProductOrderable &&
              <Row className={detailsStyles.row} crossAxisAlign="center">
                <Col md="4" />
                <Col md="auto" className={detailsStyles.limitedWidth}>
                  <SanaButton
                    textKey={isSelected ? addToBasket : addToBasketWithApply}
                    type="submit"
                    className={`${btnStyles.btnAction} ${btnStyles.btnSmall}`}
                    placeholder={btnPlaceholder}
                  />
                </Col>
                <Col xs={12} className={detailsStyles.gutter} />
              </Row>
            }
          </OrderBoxForm>
        )}
      </div>
    </>
  );
};

OrderBox.propTypes = {
  abilities: abilitiesPropType,
  isSelected: PropTypes.bool,
};

export default connect(({ salesAgreements, page }) => ({
  isSelected: salesAgreements.appliedSalesAgreementId === page.agreement.id,
}))(withAbilities(OrderBox, [AbilityTo.OrderProducts]));

