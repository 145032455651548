import { memo } from 'react';
import PropTypes from 'prop-types';
import pageRenderers from './defaultPages';
import { logger } from 'utils/logs';

const PageRenderer = ({ packId, component, ...page }) => {
  if (!component)
    return null;

  const func = pageRenderers[component];
  if (!func) {
    logger.log(`Page renderer ${component} was not registered.`);
    return null;
  }

  return func(page);
};

PageRenderer.propTypes = {
  packId: PropTypes.string,
  componentName: PropTypes.string,
};

export default memo(PageRenderer);
