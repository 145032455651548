const productComparisonStorageKey = 'productComparison.products';
const widgetCollapsedStorageKey = 'productComparison.widgetCollapsed';

export const getProductsFromStorage = (localStorage, settings) => {
  const items = localStorage.getItem(productComparisonStorageKey);
  if (!items)
    return [];

  const productIds = JSON.parse(items);
  return getProductIdsToCompare(productIds, settings.product.productComparison);
};

export const getProductIdsToCompare = (productIds, productComparisonSettings) => {
  const maxProductsToCompare = productComparisonSettings.maxProductsToCompare;
  const productIdsToCompare = productIds.length < maxProductsToCompare
    ? productIds
    : productIds.slice(productIds.length - maxProductsToCompare);

  return productIdsToCompare;
};

export const addProductToStorage = (localStorage, settings, productId) => {
  let ids = getProductsFromStorage(localStorage, settings);
  if (ids.some(id => id === productId))
    return;

  ids = [...ids, productId];

  localStorage.setItem(productComparisonStorageKey, JSON.stringify(ids));
};

export const removeProductFromStorage = (localStorage, settings, productId) => {
  const ids = getProductsFromStorage(localStorage, settings);
  const stringifiedArray = JSON.stringify(ids.filter(id => id !== productId));

  localStorage.setItem(productComparisonStorageKey, stringifiedArray);
};

export const removeAllProductsFromStorage = localStorage => {
  localStorage.removeItem(productComparisonStorageKey);
};

export const setWidgetCollapsedState = (localStorage, collapsed) =>
  localStorage.setItem(widgetCollapsedStorageKey, collapsed);

export const getWidgetCollapsedState = localStorage =>
  localStorage.getItem(widgetCollapsedStorageKey);
