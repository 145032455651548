import type { AppContext } from '../app';
import type { Api } from '../api';
import { createContext } from 'react';

type ServiceContext = {
  api: Api;
  context: AppContext;
}

export default createContext({} as ServiceContext);
