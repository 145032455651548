import Row from './ContentBlockRow';
import { VdRowContainer } from 'components/visualDesigner';

export default function createRow(row, index) {
  return (
    <VdRowContainer key={row.id} index={index} row={row}>
      <Row {...row} index={index} />
    </VdRowContainer>
  );
}
