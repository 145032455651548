import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { toDashedUrl } from 'utils/url';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';

/**
 * @typedef {Object} FacetValue
 * @property {string} title Field value title.
 * @property {string} textTitle Field value title.
 * @property {string} value Field value.
 * @property {Boolean} selected Whether this value is selected.
 */

/**
 * @typedef {object} FacetInfo
 * @property {string} title Field title.
 * @property {string} name Field name.
 * @property {Boolean} anySelected Indicates whether any value is selected.
 * @property {Boolean} isCrawlable Indicates whether index page if this field selected..
 */

/**
 * @typedef {object} FacetValues
 * @property {Array<FacetValue>} values - Field values
 *
 * @typedef {FacetInfo & FacetValues} Facet
 */

/**
 * @param {Array<Facet>} [allFacets] All facets with their titles.
 * @param {function(options)} urlsBuilder URLs builder.
 * @returns {object} - obj.
 */
export function useFacetUrlsBuilder(allFacets, urlsBuilder) {
  const selector = !allFacets || !allFacets.length
    ? () => ({})
    : ({
      routing: {
        routeData,
        location: { pathname, search },
      },
    }) => ({
      pathname,
      routeData,
      search,
    });

  const { pathname, search, routeData } = useSelector(selector, shallowEqual);
  let normalizedSearch = search
    ? search.replace(/(?:\?|&)(page|count)(?:=(?:[^&]*|$))+?/i, '')
    : '';

  if (normalizedSearch.length && normalizedSearch[0] !== '?')
    normalizedSearch = '?' + normalizedSearch;

  return useMemo(() => {
    if (!allFacets || !allFacets.length)
      return null;

    const titlesCache = new Map();
    const encodeTitle = t => {
      let encoded = titlesCache.get(t);
      if (encoded != null)
        return encoded;
      encoded = encode(t);
      titlesCache.set(t, encoded);
      return encoded;
    };

    const options = {
      allFacets,
      pathname,
      search: normalizedSearch,
      encodeTitle,
      encode,
    };

    const builder = urlsBuilder(options);
    const selectedFacets = builder.getSelectedFacets();

    return {
      /**
       * @param {FacetInfo} field The field.
       * @param {FacetValue} value The value from the field to add.
       * @returns {any} construction result.
       */
      add(field, value) {
        const to = () => {
          const facets = {};
          for (const facet of selectedFacets)
            addFacetValues(facets, facet);

          if (field.name in facets)
            facets[field.name].push(value.value);
          else
            facets[field.name] = [value.value];

          return newRouteData(routeData, facets);
        };

        return {
          url: builder.addToUrl(field, value),
          to,
        };
      },
      /**
      * @param {FacetInfo} field The field.
      * @param {FacetValue} value The value from the field to add.
      * @returns {any} construction result.
      */
      remove(field, value) {
        const to = () => {
          const facets = {};
          for (const facet of selectedFacets)
            addFacetValues(facets, facet);

          if (field.name in facets)
            facets[field.name] = facets[field.name].filter(v => v !== value.value);

          return newRouteData(routeData, facets);
        };

        return {
          url: builder.removeFromUrl(field, value),
          to,
        };
      },
      removeAll() {
        const to = () => {
          const facets = {};
          return newRouteData(routeData, facets);
        };
        return {
          url: builder.removeAllFromUrl(),
          to,
        };
      },
    };
  }, [allFacets, pathname, normalizedSearch, routeData]);
}

function newRouteData(routeData, facets) {
  const params = {
    ...routeData.params,
  };
  delete params.page;
  delete params.facets;
  delete params.count;

  if (facets && Object.keys(facets).length)
    params.facets = facets;
  return { params, routeName: routeData.routeName };
}

function encode(str) {
  return toDashedUrl(stripHtmlTagsAndFormatting(str), 50).toLowerCase();
}

function addFacetValues(to, facet) {
  to[facet.name] = [...facet.values.keys()];
}