import {
  PAY_ORDER,
  PAY_ORDER_RESULT_RECEIVED,
  PAYMENT_METHOD_EXTRA_DATA_RECEIVED,
} from './actions';
import { createReducer } from 'utils/redux';

export default createReducer(null, {
  [PAY_ORDER_RESULT_RECEIVED]: onPayOrderResultReceived,
  [PAY_ORDER]: onPayOrder,
  [PAYMENT_METHOD_EXTRA_DATA_RECEIVED]: onPaymentMethodExtraDataReceived,
});

function onPayOrderResultReceived(state, action) {
  return { ...state, payOrderResult: action.payload.result };
}

function onPayOrder(state) {
  return { ...state, payOrderResult: null };
}

function onPaymentMethodExtraDataReceived(state, action) {
  const { paymentMethodId, additionalCustomerData, extraPaymentData } = action.payload;

  return {
    ...state,
    additionalCustomerData,
    extraPaymentData: extraPaymentData && { paymentMethodId, ...extraPaymentData },
  };
}