import { mergeMap } from 'rxjs/operators';
import { timer, throwError, Observable } from 'rxjs';

const defaultCodes = [401];

export default (
  maxRetryAttempts: number,
  scalingDuration = 100,
  excludedCodes = defaultCodes,
) => {
  maxRetryAttempts--;
  return function (attempts: Observable<any>): Observable<any> {
    return attempts.pipe(
      mergeMap((error, retryAttempt) => {
        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (retryAttempt > maxRetryAttempts || excludedCodes.find(code => code === error.status)) {
          return throwError(error);
        }
        // retry after 0ms, 200ms, etc...
        return timer(retryAttempt * 2 * scalingDuration);
      }),
    );
  };
};
