import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import { TextBoxField } from '../../../fields';
import validationFieldPropType from '../../fields/validationFieldPropType';

const ZipCodeField = ({
  fieldName,
  fieldTitle,
  countryField,
  perCountryPatterns,
  validation,
}) => {
  const countryFieldName = countryField?.name;
  validation = { ...validation, countryDependentRegex: { perCountryPatterns, countryFieldName } };

  const { values, validateField } = useFormikContext();
  const countryId = countryFieldName ? getIn(values, countryFieldName) : null;
  useEffect(() => {
    validateField(fieldName);
  }, [countryId]);

  return (
    <TextBoxField fieldName={fieldName} fieldTitle={fieldTitle} validation={validation} />
  );
};

ZipCodeField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldTitle: PropTypes.string,
  countryField: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  perCountryPatterns: PropTypes.array,
  validation: validationFieldPropType,
};

export default ZipCodeField;
