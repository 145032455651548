export const getProductsTrackingDataFromLines = productLines => {
  const products = [];

  productLines.forEach(line => {
    if (!line.subLines) {
      products.push({
        ...line.product,
        uom: line.uom,
        price: line.price,
      });
      return;
    }

    line.subLines.forEach(subLine => {
      products.push({
        ...line.product,
        uom: subLine.uom,
        variant: subLine.variationId,
        price: subLine.price,
      });
    });
  });

  return products;
};