import styles from './BasketB2B.module.scss';
import basketStyles from '../Basket.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import PropTypes from 'prop-types';
import { routesBuilder } from 'routes';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { UseSanaButton } from 'components/primitives/buttons';
import { Link } from 'components/primitives/links';
import { Placeholder } from 'components/primitives/placeholders';
import SaveOrderTemplate from './SaveOrderTemplate';
import { AddToWishlist } from './wishlist';
import { useUpdateContext } from '../updateContext';
import { useDispatch } from 'react-redux';
import { clearBasket } from 'behavior/basket';
import { FolderOpenIcon, TrashCanIcon, CartRefreshIcon } from 'components/primitives/icons';
import { useHandlerLockerInPreview } from 'components/objects/preview';

const Actions = ({ isEmpty, isVolumeOrder }) => {
  const [canUseWishlist, canUseOrderTemplates] = useHasAbilities(AbilityTo.UseWishlist, AbilityTo.UseOrderTemplates);
  const { submit } = useUpdateContext();
  const dispatch = useDispatch();
  const previewHandlerLocker = useHandlerLockerInPreview();
  const disabledClass = isEmpty ? ' disabled' : '';

  const placeholder = <Placeholder />;
  return (
    <div className={styles.buttons}>
      <UseSanaButton textKey="RecalculateBasket" placeholder={placeholder}>
        {text => (
          <button
            className={`${linkStyles.link} ${styles.recalculateBtn}${disabledClass}`}
            type="button"
            aria-disabled={isEmpty}
            onClick={isEmpty ? null : submit}
            tabIndex={isEmpty ? -1 : null}
          >
            <span className={styles.iconWrapper} aria-hidden>
              <CartRefreshIcon className={styles.icon} />
            </span>
            {text}
          </button>
        )}
      </UseSanaButton>
      {canUseWishlist && !isVolumeOrder &&
        <AddToWishlist placeholder={placeholder} isEmpty={isEmpty} />
      }
      {canUseOrderTemplates && !isVolumeOrder &&
        <>
          <SaveOrderTemplate placeholder={placeholder} isEmpty={isEmpty} />
          <UseSanaButton textKey="LoadTemplate" placeholder={placeholder}>
            {text => (
              <Link
                to={routesBuilder.forOrderTemplates} options={{ backTo: { routeData: routesBuilder.forBasket() } }}
                className={`${linkStyles.link} ${styles.loadTemplateBtn}`}
                onClick={previewHandlerLocker}
              >
                <span className={styles.iconWrapper} aria-hidden>
                  <FolderOpenIcon className={styles.icon} />
                </span>
                {text}
              </Link>
            )}
          </UseSanaButton>
        </>
      }
      <UseSanaButton textKey="ClearBasket" placeholder={placeholder}>
        {text => (
          <button
            className={`${linkStyles.link} ${basketStyles.clearBtn}${disabledClass}`}
            onClick={isEmpty ? null : dispatch.bind(null, clearBasket())}
            aria-disabled={isEmpty}
            tabIndex={isEmpty ? -1 : null}
          >
            <span className={styles.iconWrapper} aria-hidden>
              <TrashCanIcon className={styles.icon} />
            </span>
            {text}
          </button>
        )}
      </UseSanaButton>
    </div>
  );
};

Actions.propTypes = {
  isEmpty: PropTypes.bool.isRequired,
  isVolumeOrder: PropTypes.bool,
};

export default Actions;
