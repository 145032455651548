import styles from './Alert.module.scss';
import { WarningIcon } from 'components/primitives/icons';
import Alert, { alertPropTypes } from './Alert';

const WarningAlert = ({
  children,
  className = '',
  scrollOnAppear,
  role,
  ...props
}) => (
  <Alert
    className={styles.warning + ' ' + className}
    icon={WarningIcon}
    scrollOnAppear={scrollOnAppear}
    role={role}
    {...props}
  >
    {children}
  </Alert>
);

WarningAlert.propTypes = alertPropTypes;

export default WarningAlert;