export const FormLockKeys = Object.freeze({
  Registration: 'registration',
  SubAccount: 'subAccount',
  ChangePassword: 'changePassword',
  ContactUs: 'contactUs',
  Review: 'review',
  ForgotPassword: 'forgotPasswrod',
  SaveOrderTemplate: 'saveOrderTemplate',
  CreateProspect: 'createProspect',
  EditProfile: 'editProfile',
  CreateDocFreeReturnOrder: 'createDocFreeReturnOrder',
  CreateDocBasedReturnOrder: 'createDocBasedReturnOrder',
});