import styles from './MyAccount.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import { withAbilities } from 'components/objects/user';
import { SimpleText, RichText, UseSanaTexts } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { InfoAlert } from 'components/primitives/alerts';
import { routesBuilder } from 'routes';
import { abilitiesPropType } from 'behavior/user';
import { requestDocuments } from 'behavior/pages/documents';
import { DocumentType } from 'behavior/documents';
import { OrdersList } from '../documents';
import { useDispatch } from 'react-redux';
import { useOnChange } from 'utils/hooks';
import Spinner from 'components/primitives/spinner/Spinner';
import { useIsPreview } from 'components/objects/preview';

const pageSize = 3;

const RecentOrders = ({ abilities: [viewOrdersAbility, createProspectAbility], orders }) => {
  const dispatch = useDispatch();
  const viewAllOptions = useMemo(() => ({ backTo: { routeData: routesBuilder.forMyAccount() } }), []);
  const isPreview = useIsPreview();

  useOnChange(() => {
    if (viewOrdersAbility !== AbilityState.Available || orders || isPreview)
      return;

    const from = new Date();
    from.setMonth(from.getMonth() - 3);

    const options = {
      page: {
        index: 0,
        size: pageSize,
      },
      orderDate: {
        from,
      },
    };

    dispatch(requestDocuments(options, DocumentType.Order));
  }, [viewOrdersAbility, orders, isPreview]);

  const showOrders = (orders && !!orders.length) || isPreview;

  const titleClass = styles.title + ' h3';
  if (viewOrdersAbility === AbilityState.TemporaryNotAvailable)
    return (
      <div className={styles.orderInfo}>
        <div className={styles.heading}>
          <h2 className={titleClass}><SimpleText textKey="RecentOrders" /></h2>
        </div>
        <InfoAlert>
          <RichText textKey="OrderHistoryIsNotAvailable" />
        </InfoAlert>
      </div>
    );

  if (!viewOrdersAbility)
    return <UseSanaTexts options={['RecentOrders', 'ViewAll', 'Orders_NoOrderHistory']} />;

  return (
    <div className={styles.orderInfo}>
      {viewOrdersAbility === AbilityState.Available &&
        <>
          <div className={styles.heading}>
            <h2 className={titleClass}><SimpleText textKey="RecentOrders" /></h2>
            {showOrders &&
              <Link to={routesBuilder.forOrders} options={viewAllOptions}>
                <SimpleText textKey="ViewAll" />
              </Link>
            }
            {(!orders && !isPreview) && <Spinner className={styles.spinner} />}
          </div>
        {showOrders && <OrdersList documents={orders} previewDocumentsCount={pageSize} />}
          {orders && !orders.length &&
            <div className="msg-block">
              <SimpleText textKey="Orders_NoOrderHistory" />
            </div>
          }
        </>
      }

      {createProspectAbility === AbilityState.Available &&
        <Link
          className={`${btnStyles.btn} ${btnStyles.btnSmall}`}
          to={routesBuilder.forCreateProspect}
          rel="nofollow"
        >
          <span className="btn-cnt"><SimpleText textKey="MyAccount_CreateProspectTitle" /></span>
        </Link>
      }
    </div>
  );
};

RecentOrders.propTypes = {
  abilities: abilitiesPropType,
  orders: PropTypes.array,
};

export default withAbilities(RecentOrders, [AbilityTo.ViewOrders, AbilityTo.CreateProspect]);