import { RouteName } from 'routes';

export const mapRouteToInput = ({ routeName, params }) => ({
  routeName: params?.previewToken ? RouteName.PagePreview : routeName,
  params: params ? Object.keys(params).map(k => ({ key: mapParamKey(k), value: stringifyParamValue(params[k]) })) : [],
});

function mapParamKey(key) {
  if (key === 'previewToken')
    return 'token';

  return key;
}

function stringifyParamValue(value) {
  if (typeof value === 'string')
    return value;

  return JSON.stringify(value);
}
