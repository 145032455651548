import { of, merge, EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  map, switchMap, mergeMap, pluck,
  groupBy, filter, catchError, exhaustMap,
} from 'rxjs/operators';
import {
  NAVIGATION_LOAD,
  MAIN_NAVIGATION_CHILDREN_LOAD,
  loadNavigation,
  navigationLoaded,
  mainNavigationChildrenLoaded,
} from './actions';
import { queryFactories, loadMainNavigationChildrenQuery } from './queries';
import { VIEWER_CHANGED, LANGUAGE_CHANGED } from 'behavior/events';
import { USER_PROFILE_UPDATED } from 'behavior/user';
import { NavigationKeys } from './constants';
import { retryWithToast } from 'behavior/errorHandling';
import { handleError, ERROR_PRIORITIES } from 'utils/rxjs';

export default function (action$, state$, { api, logger }) {
  const reload$ = action$.pipe(
    ofType(LANGUAGE_CHANGED, VIEWER_CHANGED, USER_PROFILE_UPDATED),
    mergeMap(_ => Object.keys(state$.value.navigation)),
    mergeMap(groupCode => of(loadNavigation(groupCode))),
  );

  const fromAction$ = action$.pipe(ofType(NAVIGATION_LOAD));

  const loadNavigation$ = merge(reload$, fromAction$).pipe(
    pluck('payload', 'groupCode'),
    filter(groupCode => groupCode !== NavigationKeys.Main || state$.value.settings.loaded),
    groupBy(groupCode => groupCode),
    mergeMap(group => group.pipe(
      switchMap(groupCode => {
        const queryFactory = queryFactories[groupCode];
        if (!queryFactory) {
          logger.error(`Query factory for ${groupCode} is not registered.`);
          return EMPTY;
        }

        const errorHandler = state$.value.navigation[groupCode]
          ? retryWithToast(action$, logger)
          : handleError(ERROR_PRIORITIES.HIGH, 'navigation', _ => of(navigationLoaded(groupCode, null)));

        return api.graphApi(queryFactory(state$.value)).pipe(
          pluck('navigation', 'items'),
          map(items => navigationLoaded(groupCode, items)),
          errorHandler,
        );
      }),
    )),
  );

  const loadChildren$ = action$.pipe(
    ofType(MAIN_NAVIGATION_CHILDREN_LOAD),
    map(action => action.payload),
    groupBy(({ parentId }) => parentId),
    mergeMap(group => group.pipe(
      exhaustMap(({ parentId }) => api.graphApi(loadMainNavigationChildrenQuery, { id: parentId }).pipe(
        pluck('navigation', 'items'),
        map(items => mainNavigationChildrenLoaded(parentId, items)),
        catchError(e => {
          logger.error(e);
          return of(mainNavigationChildrenLoaded(parentId, null));
        }),
      )),
    )),
  );

  return merge(loadNavigation$, loadChildren$);
}