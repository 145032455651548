export const styleTranslate = (x, y, uom) => {
  const translateX = x + uom;
  const translateY = y + uom;
  return {
    WebkitTransform: `translate(${translateX}, ${translateY})`,
    MozTransform: `translate(${translateX}, ${translateY})`,
    MsTransform: `translate(${translateX}, ${translateY})`,
    transform: `translate(${translateX}, ${translateY})`,
  };
};

export const getContentWidth = domElement => {
  if (!domElement)
    return null;

  const styles = window.getComputedStyle
    ? window.getComputedStyle(domElement, null)
    : domElement.currentStyle;

  const [paddingLeft, paddingRight, borderLeft, borderRight] = [
    styles.paddingLeft,
    styles.paddingRight,
    styles.borderLeftWidth,
    styles.borderRightWidth,
  ].map(t => parseInt(t) || 0);

  return domElement.clientWidth - paddingLeft - paddingRight - borderLeft - borderRight;
};

export const getOuterWidth = domElement => {
  if (domElement) {
    const styles = window.getComputedStyle
      ? window.getComputedStyle(domElement, null)
      : domElement.currentStyle;

    const [marginLeft, marginRight, borderLeft, borderRight, paddingLeft, paddingRight] = [
      styles.marginLeft,
      styles.marginRight,
      styles.borderLeftWidth,
      styles.borderRightWidth,
      styles.paddingLeft,
      styles.paddingRight,
    ].map(t => (parseInt(t) || 0));

    return domElement.clientWidth + marginLeft + marginRight + borderLeft + borderRight + paddingLeft + paddingRight;
  }
  return null;
};
