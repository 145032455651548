import styles from '../Checkout.module.scss';
import PropTypes from 'prop-types';
import { getFormatPrice } from 'utils/format';

export const MethodCost = ({ cost, id, ariaLabel, freeCostText, currency }) => {
  if (cost == null)
    return null;

  if (!cost)
    return <span className={styles.free} id={id}>{freeCostText}</span>;

  const currencySymbol = currency.symbol || currency.id;

  return (
    <span className={styles.cost} id={id}>
      {!!ariaLabel && <i className="visually-hidden">{ariaLabel}</i>}
      <span aria-hidden>
        {currencySymbol}
        <span className={styles.value}>
          {getFormatPrice(currency, false)(cost)}
        </span>
      </span>
      <i className="visually-hidden">
        {currencySymbol + cost}
      </i>
    </span>
  );
};

MethodCost.propTypes = {
  cost: PropTypes.number,
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  freeCostText: PropTypes.string,
  currency: PropTypes.shape({
    symbol: PropTypes.string,
    id: PropTypes.string.isRequired,
  }).isRequired,
};
