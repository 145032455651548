import type { StyleModule } from './types';
import React from 'react';

type StyleContext = {
  insertCss: (styles: StyleModule[]) => RemoveCss | void;
  isReactDOMServer?: boolean;
}

type RemoveCss = () => void;

export default React.createContext({} as StyleContext);
