import styles from './Link.module.scss';
import { memo } from 'react';
import BackTo from './BackTo';
import { Container } from 'components/primitives/grid';
import type { RouteName, RouteData, SystemRouteData } from 'routes';

type Props = {
  options?: {
    route?: SystemRouteData;
    url?: string;
    texts?: [text: string, title: string];
    supportedRoutes?: Set<RouteName>;
  };
  pageBackTo?: {
    routeData: RouteData;
    url: string;
  };
}

const BackToContainer = ({ options, pageBackTo }: Props) => {
  if (pageBackTo) {
    return (
      <Container className={styles.backTo}>
        <BackTo to={pageBackTo.routeData} url={pageBackTo.url} />
      </Container>
    );
  } else if (options && options.route) {
    const { route: to, url, texts: toTexts, supportedRoutes } = options;
    return (
      <Container className={styles.backTo}>
        <BackTo to={to} url={url} supportedRoutes={supportedRoutes} toTexts={toTexts} />
      </Container>
    );
  }

  return null;
};

export default memo(BackToContainer);
