export const useGetConsolidatedItemLines = itemLines => {

  let consolidatedItemLines = [];

  const lineIsEquivalent = (l1, l2) => {
    return l1.productId === l2.productId &&
      l1.shippingStatus === l2.shippingStatus &&
      l1.price === l2.price &&
      l1.discountPercentage === l2.discountPercentage &&
      l1.uom?.id === l2.uom?.id &&
      l1.originalOrderId === l2.originalOrderId;
  };

  const getEquivalentLine = line => {
    return consolidatedItemLines.find(cl => lineIsEquivalent(cl, line));
  };

  const alreadyHaveLine = line => {
    return consolidatedItemLines.some(cl => lineIsEquivalent(cl, line));
  };

  const replaceEquivalentLine = el => {
    const index = consolidatedItemLines.findIndex(cl => lineIsEquivalent(cl, el));
    consolidatedItemLines[index] = el;
  };

  if (!itemLines || !itemLines.length) {
    return [];
  }

  itemLines.forEach(line => {
    if (alreadyHaveLine(line)) {
      const el = { ...getEquivalentLine(line) };
      el.quantity += line.quantity;
      el.quantityShipped += line.quantityShipped;
      el.quantityInvoiced += line.quantityInvoiced;
      el.quantityOutstanding += line.quantityOutstanding;
      el.subtotal += line.subtotal;

      replaceEquivalentLine(el);
      return;
    }
    consolidatedItemLines = [...consolidatedItemLines, line];
  });

  return consolidatedItemLines;
};

export const useGetUnifiedLines = lines => {

  const getLineObject = (line, type) => {
    return {
      type,
      line,
    };
  };

  const getLineObjects = (arr, type) => {
    return arr.map(line => getLineObject(line, type));
  };

  const { serviceLines, extendedTextLines, itemLines } = lines;

  const consolidatedItemLines = useGetConsolidatedItemLines(itemLines);

  const unifiedLines = [
    ...getLineObjects(consolidatedItemLines, 'item'), 
    ...getLineObjects(serviceLines, 'service'), 
    ...getLineObjects(extendedTextLines, 'extendedText'),
  ].sort((a, b) => a.line.lineNo - b.line.lineNo);

  return unifiedLines;
};