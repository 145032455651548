import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSimpleTexts, useSanaTexts } from 'components/sanaText';
import {
  SanaForm,
  NewPasswordFormGroup,
  FormGroup,
} from 'components/objects/forms';
import { PasswordField } from 'components/objects/forms/fields';
import { resetPassword } from 'behavior/pages/resetPassword';
import { navigateTo } from 'behavior/events';
import { formatText } from 'utils/formatting';
import { toasts } from 'behavior/toasts';
import { makeRichText } from 'utils/render';
import { routesBuilder } from 'routes';
import { connect } from 'react-redux';
import { useHandlerLockerInPreview } from 'components/objects/preview';

const ResetPasswordForm = ({
  token,
  minPasswordLength,
  resetResult,
  navigateTo,
  resetPassword,
  name,
  email,
  className = null,
}) => {
  const [successMsgText] = useSanaTexts(['PasswordSuccessfullyUpdated']).texts;

  useEffect(() => {
    if (!resetResult || !('success' in resetResult))
      return;

    if (resetResult.success) {
      toasts.success(makeRichText(successMsgText));
      navigateTo(routesBuilder.forLogin());
    } else {
      navigateTo(routesBuilder.forForgotPassword());
    }
  }, [resetResult]);

  const [
    newPasswordTitle,
    minPasswordPlaceholderTemplate,
    repeatPasswordTitle,
    comparisonErrorMessage,
  ] = useSimpleTexts([
    'ChangePassword_NewPassword',
    'CreateAccount_MinPasswordLength',
    'ChangePassword_RetypePassword',
    'ChangePassword_CompareFailed',
  ]).texts;

  const newPasswordPlaceholder = formatText(minPasswordPlaceholderTemplate, [minPasswordLength]);
  const onSubmit = useHandlerLockerInPreview(data => resetPassword(token, data.password));

  return (
    <SanaForm
      className={className}
      name="ResetPassword"
      onSubmit={onSubmit}
    >
      <NewPasswordFormGroup
        fieldName="password"
        fieldTitle={newPasswordTitle}
        placeholder={newPasswordPlaceholder}
        userData={[name, email]}
      />
      <FormGroup
        fieldName="repeat"
        fieldTitle={repeatPasswordTitle}
        fieldComponent={PasswordField}
        autoComplete="off"
        maxLength={30}
        required
        validation={{
          required: { allowWhitespace: true },
          equalsTo: { to: 'password', message: comparisonErrorMessage },
        }}
      />
      <SanaForm.SubmitRow textKey="Save" />
    </SanaForm>
  );
};

ResetPasswordForm.propTypes = {
  token: PropTypes.string.isRequired,
  resetResult: PropTypes.shape({
    success: PropTypes.any,
  }),
  minPasswordLength: PropTypes.number,
  navigateTo: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
};

export default connect(
  ({ page, settings }) => ({
    token: page.token,
    minPasswordLength: settings.profile.minPasswordLength,
    resetResult: page.result,
    name: page.userData.name,
    email: page.userData.email,
  }),
  { navigateTo, resetPassword },
)(ResetPasswordForm);