import { LOADING_INDICATOR_SET, LOADING_INDICATOR_UNSET } from './actions';
import { NAVIGATION_REQUESTED, NAVIGATING, NAVIGATED } from 'behavior/routing';

export default function reducer(state = null, action) {
  switch (action.type) {
    case LOADING_INDICATOR_SET:
      return true;
    case NAVIGATION_REQUESTED:
    case NAVIGATING:
      return state === false ? true : state;
    case LOADING_INDICATOR_UNSET:
    case NAVIGATED:
      return false;
    default:
      return state;
  }
}