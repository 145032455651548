import { memo } from 'react';
import { DocumentType } from 'behavior/documents';
import { routesBuilder } from 'routes';
import Documents, { selectPropsFromPage } from '../Documents';
import ReturnOrdersList from './ReturnOrdersList';
import NewReturnOrderButton from './NewReturnOrderButton';

const textKeys = {
  introduction: 'ReturnOrdersHistory_IntroductionText',
  listHeader: 'RecentReturnOrders',
  loadMore: 'ShowNextNReturnOrders',
  noItems: 'NoReturnOrdersHistory',
};

const ordersRoute = routesBuilder.forReturnOrders();

const ReturnOrders = memo(props => (
  <>
    <NewReturnOrderButton />
    <Documents
      loadMoreRoute={ordersRoute}
      textKeys={textKeys}
      documentType={DocumentType.ReturnOrder}
      {...props}
    >
      <ReturnOrdersList documents={props.documents} />
    </Documents>
  </>
));

ReturnOrders.selectPropsFromPage = selectPropsFromPage;

export default ReturnOrders;
