import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import FormGroupTemplate from '../../formGroups/FormGroupTemplate';
import ErrorMessage from '../../ErrorMessage';
import { getIn, useFormikContext } from 'formik';
import { createLabelId, createErrorMsgId } from '../../helpers';

const FormGroup = ({ label, field, description, className }) => {
  const formik = useFormikContext();

  const { required, fieldName } = field.props;
  const focusToFieldOnClick = field.type.notNative;
  const cloneLabel = required || focusToFieldOnClick;

  let fieldError = getIn(formik.errors, fieldName);
  if (fieldError && getIn(formik.touched, fieldName))
    className = className ? `${className} row-error` : 'row-error';
  else
    fieldError = null;

  const formName = formik.status.formName;
  const labelId = createLabelId(formName, fieldName);
  const errorId = createErrorMsgId(formName, fieldName);

  return (
    <FormGroupTemplate
      className={className}
      label={cloneLabel ? cloneElement(label, { required, focusToFieldOnClick }) : label}
      field={focusToFieldOnClick ? cloneElement(field, { labelId }) : field}
      error={<ErrorMessage fieldName={fieldName} id={errorId}>{fieldError}</ErrorMessage>}
      description={description}
      aria-labelledby={labelId}
    />
  );
};

FormGroup.propTypes = {
  className: PropTypes.string,
  label: PropTypes.element.isRequired,
  field: PropTypes.element.isRequired,
  description: PropTypes.node,
};

export default FormGroup;
