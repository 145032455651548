import PropTypes from 'prop-types';
import { ReadOnlyFormGroup } from 'components/objects/forms/formGroups';

const StateReadOnlyFormGroup = ({ displayValue: stateId, fieldName, states, ...props }) => {
  const state = states && states.find(state => state.code === stateId);
  return (
    <ReadOnlyFormGroup
      fieldName={fieldName}
      displayValue={state && state.name || stateId}
      {...props}
    />
  );
};

StateReadOnlyFormGroup.propTypes = {
  displayValue: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  states: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  })),
};

export default StateReadOnlyFormGroup;
