import styles from './DocFreeReturnOrder.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FieldArray, useFormikContext, getIn } from 'formik';
import { SimpleText, useSimpleTexts } from 'components/sanaText';
import { useOnLocationChanged, useIsMobileViewport } from 'utils/hooks';
import { UomTitle, useCanViewUom } from 'components/primitives/product';
import { Table } from 'components/primitives/table';
import { Button } from 'components/primitives/buttons';
import { requestProducts, receiveProducts } from 'behavior/pages/createDocFreeReturnOrder';
import { FormGroup } from 'components/objects/forms';
import { DropdownField, TextAreaField } from 'components/objects/forms/fields';
import { getFormatNumber } from 'utils/format';
import { TrashCanButtonIcon } from 'components/primitives/icons';

const ReturnOrderLines = ({ name, insert, remove, setProductAddCallback }) => {
  const dispatch = useDispatch();
  const { products, returnReasons, culture } = useSelector(({ page, localization }) => ({
    products: page.products,
    returnReasons: page.returnReasons.forOrderLines,
    culture: localization.currentLanguage.cultureName,
  }), shallowEqual);
  const [
    unitOfMeasureTitle,
    returnReasonTitle,
    commentTitle,
    removeBtnTitle,
  ] = useSimpleTexts([
    'UnitOfMeasure',
    'ReturnRequest_Reason',
    'OrderComments',
    'ButtonAltText_Remove',
  ]).texts;

  const isMobile = useIsMobileViewport();
  const canViewUom = useCanViewUom();
  const orderLineReturnReasons = useMemo(() => returnReasons.map(({ id, name }) => ({ value: id, name })), [returnReasons]);
  const { values } = useFormikContext();
  const lines = getIn(values, name);
  const formatNumber = getFormatNumber(culture);

  useEffect(() => {
    setProductAddCallback(() => (line, product) => {
      insert(0, line);
      dispatch(receiveProducts([product]));
    });

    return () => setProductAddCallback(null);
  }, [insert, setProductAddCallback]);

  useOnLocationChanged(() => {
    const productsIds = lines?.map(line => line.productId);
    productsIds?.length && dispatch(requestProducts(productsIds));
  });

  if (!lines || lines.length === 0 || Object.keys(products).length === 0)
    return null;

  return (
    <div className={styles.tableWrapper}>
      <Table className={styles.table}>
        <thead>
          <tr>
            <th id="headerId"><SimpleText textKey="General_Product_Id" /></th>
            <th id="headerTitle"><SimpleText textKey="General_Product_Title" /></th>
            <th id="headerQuantity"><SimpleText textKey="Qty" /></th>
            {canViewUom && <th id="headerUOM" title={unitOfMeasureTitle}><SimpleText textKey="UOM" /></th>}
            <th className={styles.colDelete} />
          </tr>
        </thead>
        {lines.map((line, index) => {
          const product = products[line.productId];
          if (!product)
            return null;

          const uom = product.uoms.find(uom => uom.id === line.uomId);

          return (
            <tbody key={index}>
              <tr className={styles.upperRow}>
                <th scope="row" aria-labelledby="headerId">{line.productId}</th>
                <td aria-labelledby="headerTitle" className="text-left">
                  <div className={styles.productTitle}>{product.title}</div>
                  {line.variantId && (
                    <div className={styles.variantTitle}>
                      {getVariantTitle(line.variantId, product.variantComponentGroups)}
                    </div>
                  )}
                </td>
                <td aria-labelledby="headerQuantity">{formatNumber(line.quantity)}</td>
                {canViewUom && (
                  <td aria-labelledby="headerUOM">
                    <UomTitle id={line.uomId} description={uom?.description} />
                  </td>
                )}
                <td className={styles.colDelete}>
                  <Button
                    title={isMobile ? undefined : removeBtnTitle}
                    type="button"
                    className={`${btnStyles.btnBase} ${btnStyles.noIcon} ${styles.btnDelete}`}
                    onClick={() => remove(index)}
                  >
                    <TrashCanButtonIcon className={styles.icon} aria-hidden />
                    {isMobile &&
                      <span className={styles.btnCnt}>
                        <SimpleText textKey="ButtonText_Remove" />
                      </span>
                    }
                  </Button>
                </td>
              </tr>
              <tr className={styles.innerRow}>
                <td colSpan={canViewUom ? 5 : 4}>
                  <div className={styles.productToReturn}>
                    {orderLineReturnReasons.length !== 0 && (
                      <FormGroup
                        fieldName={`${name}[${index}].reasonId`}
                        fieldTitle={returnReasonTitle}
                        fieldComponent={DropdownField}
                        items={orderLineReturnReasons}
                        placeholderTextKey="ReturnRequest_SelectReasonPlaceholder"
                        required
                        validation={{ required: true }}
                        className={styles.reasonField}
                      />
                    )}
                    <FormGroup
                      fieldName={`${name}[${index}].comment`}
                      fieldTitle={commentTitle}
                      fieldComponent={TextAreaField}
                      maxLength={900}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          );
        })}
      </Table>
    </div>
  );
};

ReturnOrderLines.propTypes = {
  name: PropTypes.string.isRequired,
  insert: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  setProductAddCallback: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/no-multi-comp
const ReturnOrderLinesFieldArray = ({ name, ...props }) => (
  <FieldArray name={name}>
    {({ insert, remove }) => <ReturnOrderLines name={name} insert={insert} remove={remove} {...props} />}
  </FieldArray>
);

ReturnOrderLinesFieldArray.propTypes = {
  name: PropTypes.string.isRequired,
};

export default memo(ReturnOrderLinesFieldArray);

function getVariantTitle(variantId, variantComponentGroups) {
  let title;

  for (const variantGroup of variantComponentGroups) {
    const component = variantGroup.components.find(component => component.variants.includes(variantId));
    if (component && component.name)
      title = title ? title + ', ' + component.name : component.name;
  }

  return title;
}