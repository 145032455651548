import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { renderHTML } from 'utils/render';
import { getNodeText } from './helpers';

const DomElement = ({ content }) => {
  const scripts = [];

  const transformer = node => {
    if (node.name === 'script') {
      scripts.push({
        text: getNodeText(node),
        src: node.attribs.src,
        attributes: node.attribs,
      });
    }
  };

  useEffect(() => {
    const elements = [];

    scripts.forEach(({ text, src, attributes }) => {
      const element = document.createElement('script');
      if (src)
        element.src = src;
      else
        element.textContent = text;

      Object.assign(element, attributes);

      elements.push(element);
      document.body.appendChild(element);
    });

    return () => {
      elements.forEach(
        element => document.body.removeChild(element),
      );
    };
  }, [content]);

  return renderHTML(content, transformer);
};

DomElement.propTypes = {
  content: PropTypes.string.isRequired,
};

export default memo(DomElement);