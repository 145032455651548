import { NonOrderableReason } from './nonOrderableReason';
import PropTypes from 'prop-types';

const NonOrderableReasonPropType = PropTypes.oneOf([
  NonOrderableReason.NotAvailable,
  NonOrderableReason.NotInStock,
  NonOrderableReason.QuantityNotInStock,
  NonOrderableReason.Custom,
  NonOrderableReason.CustomerSpecificAssortments,
]);

export const LinesPropType = PropTypes.arrayOf(PropTypes.shape({
  description: PropTypes.string,
  reason: NonOrderableReasonPropType,
}));