import styles from '../SanaForm.module.scss';
import PropTypes from 'prop-types';
import { createLabelId } from '../helpers';
import { useFormikContext } from 'formik';
import FormGroupTemplate from './FormGroupTemplate';
import { RadioField } from '../fields';
import FieldError from '../FieldError';

const RadioFormGroup = ({
  fieldName,
  fieldTitle,
  fieldComponent: FieldComponent = RadioField,
  ...fieldProps
}) => {
  const formik = useFormikContext();
  const formName = formik.status.formName;
  const labelId = createLabelId(formName, fieldName);

  return (
    <FormGroupTemplate
      label={(
        <div id={labelId} className={styles.radioLabel}>
          {fieldTitle}{fieldTitle && fieldProps.required && '*' || null}
        </div>
      )}
      field={(
        <FieldComponent
          fieldName={fieldName}
          fieldTitle={fieldTitle}
          {...fieldProps}
        />
      )}
      error={<FieldError fieldName={fieldName} />}
      role="group"
      aria-labelledby={labelId}
    />
  );
};

RadioFormGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldTitle: PropTypes.string,
  fieldComponent: PropTypes.elementType,
};

export default RadioFormGroup;