import styles from './ProductConfigurator.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, useModal } from 'components/objects/modals';
import { finishProductConfigurator } from 'behavior/productConfigurator/actions';

const ProductConfigurator = ({ productConfigurator, finishConfigurator }) => {
  if (!productConfigurator)
    return null;

  const { configuratorUrl } = productConfigurator;
  const { opened, toggle } = useModal();
  const onClose = () => {
    toggle();
    finishConfigurator();
  };

  if (configuratorUrl && !opened)
    toggle();

  return (configuratorUrl &&
    <Modal opened={opened} hide={onClose} fullscreen>
      <iframe title="Product configurator" src={configuratorUrl} className={styles.iframe} />
    </Modal>
  );
};

ProductConfigurator.propTypes = {
  productConfigurator: PropTypes.object,
  finishConfigurator: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    productConfigurator: state.productConfigurator,
  };
};

const mapDispatchToProps = {
  finishConfigurator: finishProductConfigurator,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductConfigurator);