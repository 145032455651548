import styles from './DocBasedReturnOrder.module.scss';
import { useSelector } from 'react-redux';
import { SimpleText, RichText } from 'components/sanaText';
import { InfoAlert } from 'components/primitives/alerts';
import DocBasedReturnOrderForm from './DocBasedReturnOrderForm';

const DocBasedReturnOrder = () => {
  const invoice = useSelector(({ page }) => page.invoice);

  return (
    <>
      <h1 className={styles.pageHeading}>
        <span><SimpleText textKey="ReturnRequest_Header" /></span>
        <small><SimpleText textKey="ReturnRequest_ForInvoice" /> {invoice.documentId}</small>
      </h1>
      {invoice.canBeReturned
        ? (
          <>
            <div><RichText textKey="ReturnRequest_Description" /></div>
            <DocBasedReturnOrderForm />
          </>
        )
        : <InfoAlert><SimpleText textKey="OrderHasNoProductsThatCanBeReturned" /></InfoAlert>
      }
    </>
  );
};

export default DocBasedReturnOrder;